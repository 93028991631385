import type { ReactNode } from 'react'
import { Popover } from '@cotiss/common/components/popover.component'
import { useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'

type Props = {
  children: ReactNode
  onOpen?: () => void
  onClose?: () => void
}

export const AdvancedFiltersDropdown = ({ children, onOpen, onClose }: Props) => {
  const { advancedFiltersDispatch } = useAdvancedFiltersContext()

  function handleOpenChange(isOpen: boolean) {
    if (isOpen) {
      onOpen?.()
    } else {
      onClose?.()
      advancedFiltersDispatch({ type: 'CLEAR_EMPTY_FILTERS' })
    }
  }

  return <Popover onOpenChange={handleOpenChange}>{children}</Popover>
}
