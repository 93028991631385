import { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex, some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { usePerformanceScorecardAnalytics } from '@cotiss/performance/hooks/use-performance-scorecard-analytics.hook'
import { PerformanceScorecardViewTeamEvaluatorsTab } from '@cotiss/performance/tabs/performance-scorecard-view-team-evaluators.tab'
import { PerformanceScorecardViewTeamOwnersTab } from '@cotiss/performance/tabs/performance-scorecard-view-team-owners.tab'

type PerformanceScorecardViewTeamTab = 'owners' | 'evaluators'
const PERFORMANCE_SCORECARD_VIEW_TEAM_TABS: VerticalTabModel<PerformanceScorecardViewTeamTab>[] = [
  { id: 'owners', label: 'Owners' },
  { id: 'evaluators', label: 'Evaluators' },
]

export const PerformanceScorecardViewTeamTab = memo(() => {
  const { replace, push } = useHistory()
  const { track } = usePerformanceScorecardAnalytics()
  const { step, transition, onTransition } = useTransition()
  const { performanceScorecardId, tab, nestedTab } = useParams<{
    performanceScorecardId: string
    tab?: string
    nestedTab?: PerformanceScorecardViewTeamTab
  }>()

  useEffect(() => {
    track('performance_scorecard_view_team_tab_view')
  }, [])

  useEffect(() => {
    if (!nestedTab || !some(PERFORMANCE_SCORECARD_VIEW_TEAM_TABS, { id: nestedTab })) {
      replace(
        routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
          performanceScorecardId,
          tab,
          nestedTab: PERFORMANCE_SCORECARD_VIEW_TEAM_TABS[0].id,
        })
      )
    }

    const newStep = findIndex(PERFORMANCE_SCORECARD_VIEW_TEAM_TABS, ({ id }) => id === nestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [nestedTab])

  return (
    <div className="flex items-start">
      <VerticalTabs<PerformanceScorecardViewTeamTab>
        className="sticky top-28 mr-8"
        tab={nestedTab}
        tabs={PERFORMANCE_SCORECARD_VIEW_TEAM_TABS}
        onChange={({ id: nestedTab }) =>
          push(
            routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', { performanceScorecardId, tab, nestedTab })
          )
        }
      />

      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} className="w-full" transition={transition}>
          {step === 1 && <PerformanceScorecardViewTeamOwnersTab />}
          {step === 2 && <PerformanceScorecardViewTeamEvaluatorsTab />}
        </TransitionContainer>
      </AnimatePresence>
    </div>
  )
})
