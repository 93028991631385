import { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { find, findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { SettingsTab } from '@cotiss/settings/pages/settings.page'
import { SettingsOrganisationAccountMembersTab } from '@cotiss/settings/tabs/settings-organisation-account-members.tab'
import { SettingsOrganisationDisplayTab } from '@cotiss/settings/tabs/settings-organisation-display.tab'

export type SettingsOrganisationTab = 'display' | 'account-members'

const SETTINGS_ORGANISATION_TABS: VerticalTabModel<SettingsOrganisationTab>[] = [
  { id: 'display', label: 'Display' },
  { id: 'account-members', label: 'Account members' },
]

export const SettingsOrganisationTab = memo(() => {
  const { tab, nestedTab } = useParams<{ tab?: SettingsTab; nestedTab?: SettingsOrganisationTab }>()
  const { step, transition, onTransition } = useTransition()
  const { replace, push } = useHistory()

  useEffect(() => {
    if (!nestedTab || !find(SETTINGS_ORGANISATION_TABS, (tab) => tab.id === nestedTab)) {
      replace(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab, nestedTab: SETTINGS_ORGANISATION_TABS[0].id }))
    }

    const newStep = findIndex(SETTINGS_ORGANISATION_TABS, ({ id }) => id === nestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
    }
  }, [nestedTab])

  return (
    <div className="flex items-start justify-between">
      <VerticalTabs<SettingsOrganisationTab>
        className="sticky top-24 mr-8"
        tab={nestedTab}
        tabs={SETTINGS_ORGANISATION_TABS}
        onChange={({ id }) => push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab, nestedTab: id }))}
      />
      <div className="w-full min-w-0">
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} className="w-full" transition={transition}>
            {step === 1 && <SettingsOrganisationDisplayTab />}
            {step === 2 && <SettingsOrganisationAccountMembersTab />}
          </TransitionContainer>
        </AnimatePresence>
      </div>
    </div>
  )
})
