import { memo, useCallback, useEffect } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { filterService } from '@cotiss/common/services/filter.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { TabLabelWithCount } from '@cotiss/common/containers/tabs/tab-label-with-count.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { AdvancedFiltersPillList } from '@cotiss/common/modals/advanced-filters/advanced-filters-pill-list.component'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { ContractSearchInput } from '@cotiss/contract/components/contract-search-input.component'
import { ContractListStatusCards } from '@cotiss/contract/components/contract-list-status-cards.component'
import { ContractListTabs } from '@cotiss/contract/components/contract-list-tabs.component'
import { ContractList } from '@cotiss/contract/components/contract-list.component'
import {
  CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB,
  CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS,
  CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS,
  ContractSearchAndFiltersPrimaryTabKeys,
} from '@cotiss/contract/contract.constants'
import { ContractCreateDrawer } from '@cotiss/contract/drawers/contract-create.drawer'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { useContractStatusCardFilterTotals } from '@cotiss/contract/hooks/use-contract-status-card-filter-totals.hook'
import { ContractSearchAndFiltersProvider, useContractSearchAndFilters } from '@cotiss/contract/components/contract-search-and-filters'
import { useContractFilterTotals } from '@cotiss/contract/hooks/use-contract-filter-totals.hook'
import { AdvancedFiltersDropdownButton } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-button.component'
import { useParams } from 'react-router-dom'
import { utilService } from '@cotiss/common/services/util.service'

const ContractListPageInternal = memo(() => {
  const { openDrawer } = useCallout()
  const { permissions } = useUserAccess()
  const { track } = useAnalytics()

  const { queryState } = useContractSearchAndFilters()

  useEffect(() => {
    track('contract_list_view')
  }, [queryState.primaryTab])

  const tabFilterTotals = useContractFilterTotals({
    totalFilters: CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS,
  })
  const statusCardFilterTotals = useContractStatusCardFilterTotals()

  const refreshTotals = useCallback(() => {
    tabFilterTotals.refreshTotals()
    statusCardFilterTotals.refreshTotals()
  }, [])

  const CONTRACT_LIST_TABS: TabModel<ContractSearchAndFiltersPrimaryTabKeys>[] = [
    { id: 'active', label: <TabLabelWithCount count={tabFilterTotals?.totals?.active?.total} label="All contracts" /> },
    { id: 'mine', label: <TabLabelWithCount count={tabFilterTotals?.totals?.mine?.total} label="My contracts" /> },
    { id: 'archived', label: <TabLabelWithCount count={tabFilterTotals?.totals?.archived?.total} label="Archive" /> },
  ]

  if (!permissions.hasContractAccess || !permissions.isBuyer) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header variant="primary">
        <Text className="font-semibold" size="h5" variant="heading">
          Contract repository
        </Text>
        <Button variant="primary" size="md" onClick={() => openDrawer(<ContractCreateDrawer />)}>
          + Create contract
        </Button>
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <ContractListTabs tabs={CONTRACT_LIST_TABS} tab={queryState.primaryTab} />
        <VerticalDivider className="h-7" />
        <ContractSearchInput className="flex-1" />
        <VerticalDivider className="h-7" />
        <AdvancedFiltersDropdownButton title="Filter contracts" onOpen={() => track('contract_list_filters_dropdown_open')} />
      </div>
      <PageContent>
        <div className="flex flex-col gap-5">
          <AdvancedFiltersPillList />
          {queryState.primaryTab !== 'archived' ? <ContractListStatusCards statusCardFilterTotals={statusCardFilterTotals} /> : null}
          <ContractList refreshTotals={refreshTotals} />
        </div>
      </PageContent>
    </Page>
  )
})

export const ContractListPage = memo(() => {
  const { tab: initialPrimaryTab } = useParams<{ tab?: ContractSearchAndFiltersPrimaryTabKeys }>()
  const { q: initialSearchQuery, statusCard } = utilService.getUrlSearchParams({ params: ['q', 'statusCard'] })
  const initialAdvancedFilters = filterService.getFiltersFromUrl()

  const initialState = {
    searchQuery: initialSearchQuery,
    advancedFilters: initialAdvancedFilters,
    statusCard,
    statusCardFilters: CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS,
    primaryTabFilters: CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS,
    primaryTab: initialPrimaryTab ?? CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB,
  }

  return (
    <ContractSearchAndFiltersProvider initialState={initialState}>
      <ContractListPageInternal />
    </ContractSearchAndFiltersProvider>
  )
})
