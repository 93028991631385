import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { SettingsPage } from '@cotiss/settings/pages/settings.page'

const SettingsLayout = memo(() => (
  <Switch>
    <Route path="/settings/:tab?/:nestedTab?/:subNestedTab?" exact>
      <SettingsPage />
    </Route>
  </Switch>
))

export default SettingsLayout
