import { FormEvent, memo, useState } from 'react'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { EvaluationEventSubmissionImportFromLegacyTenderDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-submission-import-from-legacy-tender.drawer'
import { EvaluationEventSubmissionImportManualDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-submission-import-manual.drawer'
import { EvaluationEventSubmissionImportViaContactDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-submission-import-via-contact.drawer'

type ImportMethod = 'import-via-sourcing-event' | 'import-via-contact' | 'import-manual'

export const EvaluationEventSubmissionImportTypeModal = memo(() => {
  const { openDrawer, closeModal } = useCallout()
  const isProcurementsEnabled = useFeature('procurements')
  const [importMethod, setImportMethod] = useState<ImportMethod>()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    closeModal()
    if (importMethod === 'import-via-sourcing-event') {
      openDrawer(<EvaluationEventSubmissionImportFromLegacyTenderDrawer />)
    } else if (importMethod === 'import-via-contact') {
      openDrawer(<EvaluationEventSubmissionImportViaContactDrawer />)
    } else if (importMethod === 'import-manual') {
      openDrawer(<EvaluationEventSubmissionImportManualDrawer />)
    }
  }

  return (
    <Form className="min-w-[800px] max-w-[800px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Select how you would like to import your submissions" supplementary="You can only select one import method at a time" />
      <ModalContent className="grid grid-cols-3 gap-2">
        {/* Disabling import via sourcing event if they don't have the feature enabled, as this is how we control what modules the customer is paying
          for. And if they haven't enabled Sourcing, then there won't be any sourcing events to import submissions from. */}
        <RadioCard<ImportMethod>
          className="border border-gray-100 p-3.5 mr-2"
          value="import-via-sourcing-event"
          name="evaluation-submission-import-method"
          onChange={() => setImportMethod('import-via-sourcing-event')}
          isSelected={importMethod === 'import-via-sourcing-event'}
          isDisabled={!isProcurementsEnabled}
          isRequired
        >
          <div className="flex justify-between">
            <Badge shape="square" variant="secondary" state="translucent">
              <Icon icon="log-in-03" size={16} />
            </Badge>
            <Radio className="ml-2" isChecked={importMethod === 'import-via-sourcing-event'} />
          </div>
          <Text className="font-medium mt-2">Import from sourcing event</Text>
          <Text size="sm" variant="light">
            Link evaluation to a sourcing event and select the submissions that you would like to upload.
          </Text>
        </RadioCard>
        <RadioCard<ImportMethod>
          className="border border-gray-100 p-3.5 mr-2"
          value="import-via-contact"
          name="evaluation-submission-import-method"
          onChange={() => setImportMethod('import-via-contact')}
          isSelected={importMethod === 'import-via-contact'}
          isRequired
        >
          <div className="flex justify-between">
            <Badge shape="square" variant="secondary" state="translucent">
              <Icon icon="user-01" size={16} />
            </Badge>
            <Radio className="ml-2" isChecked={importMethod === 'import-via-contact'} />
          </div>
          <Text className="font-medium mt-2">Import through contact</Text>
          <Text size="sm" variant="light">
            Create submission entry linked to an existing vendor and manually upload submission files.
          </Text>
        </RadioCard>
        <RadioCard<ImportMethod>
          className="border border-gray-100 p-3.5 mr-2"
          value="import-manual"
          name="evaluation-submission-import-method"
          onChange={() => setImportMethod('import-manual')}
          isSelected={importMethod === 'import-manual'}
          isRequired
        >
          <div className="flex justify-between">
            <Badge shape="square" variant="secondary" state="translucent">
              <Icon icon="upload-01" size={16} />
            </Badge>
            <Radio className="ml-2" isChecked={importMethod === 'import-manual'} />
          </div>
          <Text className="font-medium mt-2">Import manually</Text>
          <Text size="sm" variant="light">
            Create custom submission entry and manually upload submission files into envelope buckets.
          </Text>
        </RadioCard>
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button className="mr-2" onClick={() => closeModal()} state="ghost" variant="link">
          Cancel
        </Button>
        <Button type="submit" variant="secondary">
          Next
        </Button>
      </ModalFooter>
    </Form>
  )
})
