import { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { SettingsConflictOfInterestTemplateTab } from '@cotiss/settings/tabs/settings-conflict-of-interest-template.tab'
import { SettingsGeneralTab } from '@cotiss/settings/tabs/settings-general.tab'
import { SettingsModulesTab } from '@cotiss/settings/tabs/settings-modules.tab'
import { SettingsOrganisationTab } from '@cotiss/settings/tabs/settings-organisation.tab'
import { SettingsPreferencesTab } from '@cotiss/settings/tabs/settings-preferences.tab'
import { SettingsSubscriptionTab } from '@cotiss/settings/tabs/settings-subscription.tab'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

export type SettingsTab = 'general' | 'organisation' | 'preferences' | 'subscription' | 'conflict-of-interest-template' | 'modules'

export const SettingsPage = memo(() => {
  const { permissions } = useUserAccess()
  const { push, replace } = useHistory()
  const { tab } = useParams<{ tab?: SettingsTab }>()

  const SETTINGS_TABS: TabModel<SettingsTab>[] = useMemo(
    () =>
      filter(
        [
          { id: 'general', label: 'Personal details' },
          { id: 'preferences', label: 'Preferences' },
          { id: 'organisation', label: 'Organisation' },
          { id: 'modules', label: 'Modules', isHidden: !permissions.hasBuyerAccess },
          { id: 'subscription', label: 'Subscription' },
          { id: 'conflict-of-interest-template', label: 'Conflict of interest template', isHidden: !permissions.hasBuyerAccess },
        ],
        ({ isHidden }) => !isHidden
      ),
    [permissions]
  )

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(SETTINGS_TABS, ({ id }) => id === tab) + 1 })

  useEffect(() => {
    if (!tab || !find(SETTINGS_TABS, ({ id }) => id === tab)) {
      replace(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: SETTINGS_TABS[0].id }))
    }

    const newStep = findIndex(SETTINGS_TABS, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab])

  const activeTabId = useMemo(() => SETTINGS_TABS[step - 1].id, [step, SETTINGS_TABS])

  return (
    <Page>
      <Header variant="primary">
        <Text className="font-semibold" size="h5" variant="heading">
          Settings
        </Text>
      </Header>

      <PageContent>
        <Tabs<SettingsTab>
          className="border-b border-gray-300 w-full mb-8"
          tab={activeTabId}
          tabs={SETTINGS_TABS}
          onChange={({ id: tab }) => push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab }))}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {activeTabId === 'general' && <SettingsGeneralTab />}
            {activeTabId === 'preferences' && <SettingsPreferencesTab />}
            {activeTabId === 'organisation' && <SettingsOrganisationTab />}
            {activeTabId === 'modules' && <SettingsModulesTab />}
            {activeTabId === 'subscription' && <SettingsSubscriptionTab />}
            {activeTabId === 'conflict-of-interest-template' && <SettingsConflictOfInterestTemplateTab />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
