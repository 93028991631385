import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex, includes } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Banner } from '@cotiss/common/components/banner.component'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationListTable } from '@cotiss/evaluation/components/evaluation-list-table.component'
import { EvaluationConsensusTab } from '@cotiss/evaluation/tabs/evaluation-consensus.tab'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TenderResponsePriceScore } from '@cotiss/tender-response/components/tender-response-price-score.component'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'
import { TenderFlowEvaluateStepPanelConsensusScoring } from '@cotiss/tender-flow/components/tender-flow-evaluate-step-panel-consensus-scoring.component'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { EvaluationIndividualTab } from '@cotiss/evaluation/tabs/evaluation-individual.tab'
import { EvaluationPriceTab } from '@cotiss/evaluation/tabs/evaluation-price.tab'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType, useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

export type TenderFlowEvaluateStepPanelTab = 'individual' | 'consensus' | 'price'
type ActiveEntity =
  | {
      type: 'individual'
      userId: string
    }
  | {
      type: 'consensus'
      tenderResponseReport: TenderResponseReportModel
    }
  | {
      type: 'price'
      tenderResponseReport: TenderResponseReportModel
    }

export const TenderFlowEvaluateStepPanel = memo(() => {
  const { replace } = useHistory()
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { resendTenderInvitation } = useMutateTender()
  const [nestedTransition, setNestedTransition] = useState<TransitionType>('right')
  const [activeEntity, setActiveEntity] = useState<ActiveEntity | null>(null)
  const { tenderId, step: tenderFlowStep, tab } = useParams<{ tenderId: string; step: TenderFlowStep; tab?: TenderFlowEvaluateStepPanelTab }>()
  const { tender } = useGetTender(tenderId)
  const isEvaluateComplete = includes(tender?.validation.finishedStages || [], 'evaluate')
  const isConsensusComplete = includes(tender?.validation.finishedStages || [], 'consensus')
  const isPriceComplete = includes(tender?.validation.finishedStages || [], 'price')
  const tabs: VerticalTabModel<TenderFlowEvaluateStepPanelTab>[] = [
    { id: 'individual', label: 'Individual evaluation', tasksRemaining: isEvaluateComplete ? 0 : 1 },
    { id: 'consensus', label: 'Consensus scoring', isLocked: !isEvaluateComplete, tasksRemaining: isConsensusComplete ? 0 : 2 },
    { id: 'price', label: 'Price scoring', isLocked: !isConsensusComplete, tasksRemaining: isPriceComplete ? 0 : 3 },
  ]

  let tabToSet: TenderFlowEvaluateStepPanelTab | undefined
  if (includes(['individual', 'consensus', 'price'], tab)) {
    tabToSet = tab
  } else {
    tabToSet = !isEvaluateComplete ? 'individual' : isConsensusComplete ? 'price' : 'consensus'
  }

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tabToSet) + 1 })
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    if (activeEntity?.type === 'individual') {
      return [
        {
          label: 'Individual evaluation',
          onClick: () => {
            closeDrawer()
            handleSetEntity(null, 'left')
          },
        },
      ]
    }

    if (activeEntity?.type === 'consensus') {
      return [{ label: 'Consensus scoring', onClick: () => handleSetEntity(null, 'left') }]
    }

    if (activeEntity?.type === 'price') {
      return [{ label: 'Price scoring', onClick: () => handleSetEntity(null, 'left') }]
    }

    return []
  }, [activeEntity])

  useEffect(() => {
    let tabToSet: TenderFlowEvaluateStepPanelTab | undefined
    if (includes(['individual', 'consensus', 'price'], tab)) {
      tabToSet = tab
    } else {
      tabToSet = !isEvaluateComplete ? 'individual' : isConsensusComplete ? 'price' : 'consensus'
    }

    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: tenderFlowStep, tab: tabToSet }))
  }, [])

  const handleResendInvitation = async () => {
    if (!tender) {
      return
    }

    try {
      setIsSaving(true)
      await resendTenderInvitation(tender._id, { emailType: 'chair-invitation' })
      openToast('The chair invitation has been resent.')
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleTabChange = (tab: TenderFlowEvaluateStepPanelTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: tenderFlowStep, tab }))
    onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
  }

  const handleSetEntity = (entity: ActiveEntity | null, transition: TransitionType = 'right') => {
    setNestedTransition(transition)
    setTimeout(() => setActiveEntity(entity), 0)
  }

  const renderInitial = () => (
    <>
      {includes(['evaluate', 'consensus', 'price'], tender?.status) && (
        <Banner className="mb-6" icon="clock" variant="primary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Evaluation is taking place
            </Text>
            <Text size="sm">
              The evaluation chair is conducting the evaluations. Please check back once the evaluation is complete to view the results
            </Text>
          </div>
          <Button className="ml-2" onClick={handleResendInvitation} state="text" variant="link" isLoading={isSaving}>
            Resend chair invitation
          </Button>
        </Banner>
      )}
      <div className="flex items-start justify-between">
        <div className="sticky top-28 mr-12">
          <Text className="uppercase mb-2" variant="light" size="xs">
            Evaluation path
          </Text>

          <VerticalTabs<TenderFlowEvaluateStepPanelTab> tab={tab} tabs={tabs} onChange={({ id }) => handleTabChange(id)} />
        </div>
        <div className="w-full min-w-0">
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {step === 1 && <EvaluationIndividualTab onView={(userId) => handleSetEntity({ type: 'individual', userId })} />}
              {step === 2 && (
                <EvaluationConsensusTab onView={(tenderResponseReport) => handleSetEntity({ type: 'consensus', tenderResponseReport })} />
              )}
              {step === 3 && <EvaluationPriceTab onView={(tenderResponseReport) => handleSetEntity({ type: 'price', tenderResponseReport })} />}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </div>
    </>
  )

  return (
    <AnimatePresence mode="wait" initial={false}>
      {!activeEntity && (
        <TransitionContainer key={1} transition={nestedTransition}>
          {renderInitial()}
        </TransitionContainer>
      )}
      {activeEntity?.type === 'individual' && (
        <TransitionContainer key={2} transition={nestedTransition}>
          <EvaluationListTable tenderId={tenderId} userId={activeEntity.userId} breadcrumbs={breadcrumbs} />
        </TransitionContainer>
      )}
      {activeEntity?.type === 'consensus' && (
        <TransitionContainer key={3} transition={nestedTransition}>
          <TenderFlowEvaluateStepPanelConsensusScoring initialTenderResponseReport={activeEntity.tenderResponseReport} breadcrumbs={breadcrumbs} />
        </TransitionContainer>
      )}
      {activeEntity?.type === 'price' && (
        <TransitionContainer key={4} transition={nestedTransition}>
          <TenderResponsePriceScore tenderResponseId={activeEntity.tenderResponseReport._id} breadcrumbs={breadcrumbs} />
        </TransitionContainer>
      )}
    </AnimatePresence>
  )
})
