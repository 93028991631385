import { memo, useEffect } from 'react'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { PreferredSupplierBuyerList } from '@cotiss/preferred-supplier/components/preferred-supplier-buyer-list.component'
import { useListPreferredSupplierBuyer } from '@cotiss/preferred-supplier/resources/use-list-preferred-supplier-buyer.resource'

export const PreferredSupplierBuyerListPage = memo(() => {
  const { track } = useAnalytics()
  const { preferredSupplierBuyers, isLoading } = useListPreferredSupplierBuyer()

  useEffect(() => {
    track('preferred_supplier_buyer_list_view')
  }, [])

  return (
    <Page>
      <Header variant="primary">
        <Text className="font-semibold" size="h5" variant="heading">
          Buyers
        </Text>
      </Header>
      <PageContent>
        <PreferredSupplierBuyerList isLoading={isLoading} preferredSupplierBuyers={preferredSupplierBuyers} />
      </PageContent>
    </Page>
  )
})
