import { memo } from 'react'
import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { MetafieldForm } from '@cotiss/metafield/components/metafield-form.component'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'

export const ProcurementOverviewBackgroundMetafieldsTab = memo(() => {
  const { procurementId } = useParams<{ procurementId: string }>()
  const { metafields } = useListMetafield({ entityType: 'PROCUREMENT_PLAN' })
  const { metafieldValues } = useListMetafieldValue({ resourceId: procurementId })

  return (
    <>
      <Card className="max-w-5xl">
        <CardFormHeader heading="Custom fields" />

        {map(metafields, (metafield, index) => (
          <div key={metafield._id}>
            <MetafieldForm
              className={index ? 'mt-8' : ''}
              resourceId={procurementId}
              metafield={metafield}
              metafieldValues={metafieldValues}
              isEditable
            />
          </div>
        ))}
      </Card>
    </>
  )
})
