import { memo, useMemo } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { filter, map, sortBy } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { utilService } from '@cotiss/common/services/util.service'
import { EvaluationEventSubCriteriaRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-sub-criteria-create-update.drawer'
import { EVALUATION_EVENT_RATING_SCALE_NAME_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment
  weightedPercentageById?: Record<string, number>
  overallWeightedPercentageById?: Record<string, number>
  isEditable?: boolean
  isLoading?: boolean
}

export const EvaluationEventSubCriteriaList = memo((props: Props) => {
  const { evaluationEnvelope, parentEvaluationCriteriaItem, weightedPercentageById, overallWeightedPercentageById, isEditable, isLoading } = props
  const { openModal, openDrawer } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { evaluationCriteria, mutateDeleteEvaluationCriteria } = useEvaluationCriteria()

  const handleDelete = async (evaluationCriteriaId: string) => {
    track('evaluation_event_wizard_sub_criteria_delete_submit')
    mutateDeleteEvaluationCriteria({ evaluationCriteriaId })
  }

  const { columns } = useMemo(() => {
    const subCriteria = filter(evaluationCriteria, {
      evaluationEnvelopeId: evaluationEnvelope.id,
      parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id,
    })
    const sortedSubCriteria = sortBy(subCriteria, 'index')

    const columns: TableColumn[] = [
      {
        heading: 'Sub-criteria',
        rows: [
          ...map(sortedSubCriteria, (evaluationSubCriteriaItem) => ({
            content: () => (
              <div className="flex items-start">
                <Text className="mr-2">
                  {parentEvaluationCriteriaItem.index}.{evaluationSubCriteriaItem.index}.
                </Text>
                <Text>{evaluationSubCriteriaItem.content}</Text>
              </div>
            ),
            cta: (
              <TableRowCta
                cta={{
                  label: 'View',
                  onClick: () =>
                    openDrawer(
                      <EvaluationEventSubCriteriaRatingScaleDrawer
                        evaluationEnvelope={evaluationEnvelope}
                        parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                        evaluationSubCriteriaItem={evaluationSubCriteriaItem}
                        isEditable={isEditable}
                      />
                    ),
                }}
                actions={
                  isEditable
                    ? [
                        {
                          label: 'Delete',
                          onClick: () => {
                            openModal(
                              <ConfirmModal
                                heading="Delete sub-criteria"
                                description="Are you sure you want to delete this sub criteria?"
                                onSubmit={() => handleDelete(evaluationSubCriteriaItem.id)}
                              />
                            )
                          },
                        },
                      ]
                    : undefined
                }
              />
            ),
          })),
          ...(weightedPercentageById
            ? [
                {
                  content: () => <Text className="font-medium">Totals</Text>,
                },
              ]
            : []),
        ],
      },
      {
        heading: 'Rating scale',
        thClassName: 'w-40',
        rows: [
          ...map(sortedSubCriteria, (evaluationSubCriteriaItem) => ({
            tdClassName: 'align-top',
            content: () => (
              <div>
                <Text>{EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationSubCriteriaItem.ratingScale || evaluationEnvelope.defaultRatingScale]}</Text>
                {evaluationSubCriteriaItem.ratingScale !== evaluationEnvelope.defaultRatingScale && (
                  <Badge className="mt-2" variant="info" state="outline" size="sm">
                    Updated
                  </Badge>
                )}
              </div>
            ),
          })),
          ...(weightedPercentageById
            ? [
                {
                  content: () => <></>,
                },
              ]
            : []),
        ],
      },
      // Hide scoring requirements if weights are present, this is because with the weight columns showing there is not enough space to show the scoring requirements
      // without the CTA getting cut off. This is a temporary solution until we can find a better way to display the scoring requirements.
      ...(!weightedPercentageById
        ? [
            {
              heading: 'Scoring requirements',
              rows: [
                ...map(sortedSubCriteria, (evaluationSubCriteriaItem) => ({
                  tdClassName: 'align-top',
                  content: () => (
                    <div>
                      <Text className="whitespace-pre-wrap line-clamp-3">{evaluationSubCriteriaItem.supplementary || '--'}</Text>
                    </div>
                  ),
                })),
              ],
            },
          ]
        : []),
      ...(weightedPercentageById && overallWeightedPercentageById
        ? [
            {
              heading: 'Weight',
              thClassName: 'text-right w-28',
              rows: [
                ...map(sortedSubCriteria, ({ id }) => ({
                  tdClassName: 'text-right align-top',
                  content: () => (
                    <Text>{weightedPercentageById[id] ? utilService.formatAsPercentage(Number(weightedPercentageById[id]) * 100) : '--'}</Text>
                  ),
                })),
                {
                  tdClassName: 'text-right',
                  content: () => (
                    <Text className="font-medium" variant="secondary">
                      100.00%
                    </Text>
                  ),
                },
              ],
            },
            {
              heading: 'Envelope weight',
              thClassName: 'text-right w-32',
              rows: [
                ...map(sortedSubCriteria, ({ id, parentEvaluationCriteriaId }) => ({
                  tdClassName: 'text-right align-top',
                  content: () => (
                    <>
                      <Text>
                        {utilService.formatAsPercentage(
                          Number(weightedPercentageById[id]) * Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100
                        ) || '--'}
                      </Text>
                      <Text className="mt-1" variant="light" size="sm">
                        ({utilService.formatAsPercentage(Number(weightedPercentageById[id]) * 100, 0)} x{' '}
                        {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100, 0)})
                      </Text>
                    </>
                  ),
                })),
                {
                  tdClassName: 'text-right',
                  content: () => (
                    <Text className="font-medium" variant="secondary">
                      {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaItem.id]) * 100)}
                    </Text>
                  ),
                },
              ],
            },
            {
              heading: 'Overall weight',
              thClassName: 'text-right w-40',
              rows: [
                ...map(sortedSubCriteria, ({ id, parentEvaluationCriteriaId }) => ({
                  tdClassName: 'text-right align-top',
                  content: () => (
                    <>
                      <Text>
                        {utilService.formatAsPercentage(
                          Number(weightedPercentageById[id]) *
                            Number(weightedPercentageById[parentEvaluationCriteriaId as string]) *
                            Number(weightedPercentageById[evaluationEnvelope.id]) *
                            100
                        ) || '--'}
                      </Text>
                      <Text className="mt-1" variant="light" size="sm">
                        ({utilService.formatAsPercentage(Number(weightedPercentageById[id]) * 100, 0)} x{' '}
                        {utilService.formatAsPercentage(Number(weightedPercentageById[parentEvaluationCriteriaId as string]) * 100, 0)} x{' '}
                        {utilService.formatAsPercentage(Number(weightedPercentageById[evaluationEnvelope.id]) * 100, 0)})
                      </Text>
                    </>
                  ),
                })),
                {
                  tdClassName: 'text-right',
                  content: () => (
                    <Text className="font-medium" variant="secondary">
                      {utilService.formatAsPercentage(Number(overallWeightedPercentageById[parentEvaluationCriteriaItem.id]) * 100)}
                    </Text>
                  ),
                },
              ],
            },
          ]
        : []),
    ]

    return { columns }
  }, [evaluationCriteria])

  return (
    <Table
      columns={columns}
      emptyCta={
        isEditable && (
          <Button
            size="sm"
            variant="secondary"
            state="text"
            onClick={() =>
              openDrawer(
                <EvaluationEventSubCriteriaRatingScaleDrawer
                  evaluationEnvelope={evaluationEnvelope}
                  parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                  isEditable={isEditable}
                />
              )
            }
          >
            + Add sub-criteria
          </Button>
        )
      }
      isLoading={isLoading}
    />
  )
})
