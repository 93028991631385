import { memo, useMemo } from 'react'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { PopoverContent } from '@cotiss/common/components/popover.component'
import { Text } from '@cotiss/common/components/text.component'
import { AdvancedFiltersItem } from '@cotiss/common/modals/advanced-filters/advanced-filters-item.component'
import { useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { isFilterDirty } from '@cotiss/common/modals/advanced-filters/advanced-filters.utils'

type Props = { title: string }

export const AdvancedFiltersDropdownContent = memo(({ title }: Props) => {
  const { advancedFilters, filterFields, advancedFiltersDispatch } = useAdvancedFiltersContext()

  const canClearFilters = useMemo(() => {
    return advancedFilters.length > 1 || advancedFilters.some(isFilterDirty)
  }, [advancedFilters])

  const canAddFilter = advancedFilters?.length < 5

  const filterTypeOptions = map(filterFields, ({ displayName }, key) => ({
    value: key,
    label: displayName,
  }))

  return (
    <PopoverContent sideOffset={5} side="bottom" align="end">
      <div className="flex flex-col bg-white rounded-lg w-[814px] max-h-[400px]">
        <div className="flex items-center justify-between px-6 h-14 flex-shrink-0 border-b">
          <div className="flex items-center w-[70px] text-center">
            <Text className="flex-shrink-0 font-medium text-secondary-900" size="h7">
              {title}
            </Text>
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-3 justify-start overflow-y-auto p-4">
          {advancedFilters.map((filter, index) => {
            const field = filter.field ?? ''
            const fieldConfig = filterFields?.[field]

            return (
              <AdvancedFiltersItem
                key={filter.id}
                itemIndex={index}
                itemsCount={advancedFilters.length}
                filter={filter}
                filterTypeOptions={filterTypeOptions}
                filterFieldConfig={fieldConfig}
              />
            )
          })}
        </div>
        <div className="flex items-center justify-between flex-shrink-0 border-t px-4 h-[60px]">
          <Button
            size="xs"
            variant="secondary"
            state="ghost"
            onClick={() => advancedFiltersDispatch({ type: 'ADD_FILTER' })}
            isDisabled={!canAddFilter}
            className="gap-1"
          >
            <Icon icon="plus-01" /> Add filter
          </Button>
          <Button
            size="xs"
            variant="secondary"
            state="ghost"
            onClick={() => advancedFiltersDispatch({ type: 'CLEAR_ALL_FILTERS' })}
            isDisabled={!canClearFilters}
            className="gap-1"
          >
            <Icon icon="trash-01" /> Clear all
          </Button>
        </div>
      </div>
    </PopoverContent>
  )
})
