import { memo } from 'react'
import classNames from 'classnames'
import { differenceInDays, formatDistanceToNowStrict, isBefore, parseISO } from 'date-fns'
import { Badge } from '@cotiss/common/components/badge.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { TenderModel, TenderPopulatedModel } from '@cotiss/tender/tender.models'

type Props = {
  className?: string
  tender: TenderPopulatedModel | TenderModel
}

export const TenderTimeRemainingBadge = memo(({ className, tender }: Props) => {
  if (!tender.tenderPeriod?.endDate) {
    return null
  }

  const today = new Date()
  const endDate = parseISO(tender.tenderPeriod.endDate)
  const isClosed = isBefore(endDate, today)
  const isUrgent = differenceInDays(endDate, today) < 10
  const classes = classNames(className, 'inline-flex items-center whitespace-nowrap')

  return (
    <Badge className={classes} size="sm" variant={isUrgent ? 'danger' : 'neutral'} state="translucent">
      <Icon className="mr-1" icon="clock" size={14} />
      {isClosed ? 'Closed' : formatDistanceToNowStrict(endDate)}
    </Badge>
  )
})
