import { memo, useMemo } from 'react'
import { find, map } from 'lodash'
import ReactSelect from 'react-select'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { MultiSelectOption } from '@cotiss/common/models/form.model'
import { UserLineItem } from '@cotiss/user/components/user-line-item.component'
import { userService } from '@cotiss/user/user.service'

type User = {
  _id: string
  firstname: string
  surname: string
  email: string
}

type Props = {
  className?: string
  value: string
  options: User[]
  onChange: (value: string) => void
  isMulti?: boolean
  isRequired?: boolean
  isDisabled?: boolean
}

// TODO: Replace this with the MultiSelect component
// TODO: This logic is duplicated in the `UserMultiSelect` component.

export const UserSelect = memo(({ className, value, options, onChange, isMulti, isRequired, isDisabled }: Props) => {
  const { user, userValue, userOptions } = useMemo(() => {
    const user = find(options, { _id: value })
    const userOptions = map(options, (user) => ({ value: user._id, label: userService.getFullName(user) }))
    const userValue = find(userOptions, { value })

    return { user, userValue, userOptions }
  }, [value, options])

  const handleChange = ({ value }: MultiSelectOption) => {
    onChange(value)
  }

  return (
    <div className={className}>
      <ReactSelect
        value={userValue}
        options={userOptions}
        onChange={(userValue) => handleChange(userValue as MultiSelectOption)}
        controlShouldRenderValue={false}
        isClearable={false}
        backspaceRemovesValue={false}
        components={{ IndicatorSeparator: null }}
        required={isRequired}
        isDisabled={isDisabled || (!isMulti && Boolean(userValue))}
      />
      {user && (
        <div className="flex items-center justify-between mt-8">
          <UserLineItem {...user} />
          <Button onClick={() => onChange('')} state="ghost" shape="square" size="sm" isDisabled={isDisabled}>
            <Icon icon="x-close" size={20} />
          </Button>
        </div>
      )}
    </div>
  )
})
