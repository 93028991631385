import { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { routerService } from '@cotiss/common/services/router.service'
import {
  PROJECT_OVERVIEW_TABS,
  ProcurementOverviewHeader,
  ProcurementOverviewTab,
} from '@cotiss/procurement/components/procurement-overview-header.component'
import { ProcurementSettings } from '@cotiss/procurement/components/procurement-settings.component'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { ProcurementOverviewAllActivityTab } from '@cotiss/procurement/tabs/procurement-overview-all-activity.tab'
import { ProcurementOverviewAllRequestsTab } from '@cotiss/procurement/tabs/procurement-overview-all-requests.tab'
import { ProcurementOverviewBackgroundTab } from '@cotiss/procurement/tabs/procurement-overview-background.tab'
import { ProcurementOverviewConflictOfInterestTab } from '@cotiss/procurement/tabs/procurement-overview-conflict-of-interest.tab'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

export const ProcurementOverviewPage = memo(() => {
  const { replace } = useHistory()
  const { permissions } = useUserAccess()
  const { procurementId, tab } = useParams<{ procurementId: string; tab?: ProcurementOverviewTab }>()
  const { procurement } = useGetProcurement(procurementId)
  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(PROJECT_OVERVIEW_TABS, ({ id }) => id === tab) + 1 })
  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Overview',
      onClick: () => replace(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab: 'background' })),
    },
  ]

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId, tab: PROJECT_OVERVIEW_TABS[0].id }))
    }

    const newStep = findIndex(PROJECT_OVERVIEW_TABS, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab])

  useEffect(() => {
    // Kick user back to procurements list view if procurement is archived
    if (procurement?.isArchived) {
      replace(routerService.getHref('/project/list/:tab?'))
    }
  }, [procurement])

  if (!permissions.hasProcurementAccess) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <ProcurementOverviewHeader />
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <ProcurementOverviewBackgroundTab />}
            {step === 2 && <ProcurementOverviewAllRequestsTab />}
            {step === 3 && <ProcurementOverviewAllActivityTab />}
            {step === 4 && <ProcurementOverviewConflictOfInterestTab procurementId={procurementId} />}
            {step === 5 && <ProcurementSettings procurementId={procurementId} breadcrumbs={breadcrumbs} />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
