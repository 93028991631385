import { memo } from 'react'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Label } from '@cotiss/common/components/label.component'
import { Pill } from '@cotiss/common/components/pill.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { UnspscCategoriesModal } from '@cotiss/common/modals/unspsc-categories.modal'
import { useGetSupplierProfile } from '@cotiss/supplier/resources/use-get-supplier-profile.resource'
import { useMutateSupplier } from '@cotiss/supplier/resources/use-mutate-supplier.resource'

type Props = {
  organisationId?: string
  isEditable?: boolean
}

export const SupplierViewGeneralFormUnspscCategories = memo(({ organisationId, isEditable }: Props) => {
  const { openModal, closeModal } = useCallout()
  const { updateSupplierProfile } = useMutateSupplier()
  const { supplierProfile, isLoading } = useGetSupplierProfile(organisationId)

  const handleUpdate = async (categories: string[]) => {
    await updateSupplierProfile({ categories })
    closeModal()
  }

  const handleOpenModal = () => {
    supplierProfile &&
      openModal(<UnspscCategoriesModal selectedCategoryCodes={map(supplierProfile.categories, ({ _id }) => _id)} onChange={handleUpdate} />)
  }

  return (
    <div className="flex items-start justify-between">
      <div className="w-full">
        <Label>UNSPSC Categories</Label>
        {isLoading && <Skeleton className="h-2 w-1/4" isRounded />}
        {!isLoading && (
          <>
            {!supplierProfile?.categories.length && <Text>--</Text>}
            {map(supplierProfile?.categories, ({ _id, description }) => (
              <Pill key={_id} className="mt-1 mr-1">
                {description}
              </Pill>
            ))}
          </>
        )}
      </div>

      {isEditable && !isLoading && (
        <Button className="ml-4" onClick={handleOpenModal} variant="secondary" state="translucent" size="sm" isDisabled={isLoading}>
          Update
        </Button>
      )}
    </div>
  )
})
