import { memo, useState } from 'react'
import { Banner } from '@cotiss/common/components/banner.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalConfirmationButton } from '@cotiss/common/containers/callout/modal/modal-confirmation-button.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { PreferredSupplierInvitationLinkModal } from '@cotiss/preferred-supplier/modals/preferred-supplier-invitation-link.modal'

type Props = {
  organisationId: string
}

export const PreferredSupplierInvitationLinkRegenerateConfirmationModal = memo(({ organisationId }: Props) => {
  const { openModal } = useCallout()
  const [isAcknowledged, setIsAcknowledged] = useState(false)

  return (
    <Form className="relative min-w-[450px] max-w-[450px]">
      <ModalHeader
        heading="Are you sure you want to regenerate this link?"
        onClose={() => openModal(<PreferredSupplierInvitationLinkModal organisationId={organisationId} />)}
      />
      <ModalContent>
        <Banner icon="alert-circle" variant="danger">
          <div>
            <Text className="font-medium">Warning</Text>
            <Text className="mt-1" size="sm">
              Users with access to the old link who have not already accepted the invitation will no longer be able to access the contact invitation.
            </Text>
          </div>
        </Banner>
        <label className="flex mt-6 cursor-pointer" htmlFor="preferred-supplier-invitation-link-regenerate">
          <Checkbox
            id="preferred-supplier-invitation-link-regenerate"
            className="mr-2 mt-1"
            isChecked={isAcknowledged}
            onChange={() => setIsAcknowledged(!isAcknowledged)}
          />

          <Text>I acknowledge that regenerating this link will invalidate access for the current link</Text>
        </label>
      </ModalContent>
      <ModalConfirmationButton
        onClick={() => openModal(<PreferredSupplierInvitationLinkModal organisationId={organisationId} willRegenerateLinkOnOpen />)}
        isDisabled={!isAcknowledged}
      >
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
