import { useCallback, useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'

export const ModalContainer = () => {
  const { closeModal, modal } = useCallout()
  const modalRef = useRef<HTMLDivElement>(null)

  const handleKeydown = useCallback(({ code }: KeyboardEvent) => {
    if (code !== 'Escape') {
      return
    }

    closeModal()
  }, [])

  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden'
      window.scroll(0, 0)
      window.addEventListener('keydown', handleKeydown)
    } else {
      document.body.style.overflow = 'auto'
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [modal])

  return (
    <AnimatePresence>
      {Boolean(modal) && (
        <div className="flex items-center justify-center absolute top-0 right-0 left-0 bottom-0 p-4 z-modal">
          <motion.div
            className="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-60 z-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
          />
          <motion.div
            className="relative bg-white rounded-lg shadow-xl z-modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.2 }}
            exit={{ opacity: 0 }}
            ref={modalRef}
          >
            {modal}
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}
