import { memo } from 'react'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'

type Props = {
  className?: string
  isSetupComplete: boolean
  size?: BadgeSize
}

export const EvaluationEventSubmissionSetupCompleteBadge = memo(({ className, isSetupComplete, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={isSetupComplete ? 'success' : 'neutral'} state="translucent">
    {isSetupComplete ? 'Complete' : 'Incomplete'}
  </Badge>
))
