import { memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { SettingsTwoFactorRegisterForm } from '@cotiss/settings/components/settings-two-factor-register-form.component'
import { SettingsTwoFactorUnregisterForm } from '@cotiss/settings/components/settings-two-factor-unregister-form.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const SettingsGeneralTwoFactorForm = memo(() => {
  const [isEditing, setIsEditing] = useState(false)
  const { user } = useGetLoggedInUser()
  const isEnabled = Boolean(user?.otpEnabled)

  if (isEditing) {
    return isEnabled ? (
      <SettingsTwoFactorUnregisterForm onClose={() => setIsEditing(false)} />
    ) : (
      <SettingsTwoFactorRegisterForm onClose={() => setIsEditing(false)} />
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">Two factor authentication</Text>
        <Text>{isEnabled ? 'On' : 'Off'}</Text>
      </div>

      <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm">
        Update
      </Button>
    </div>
  )
})
