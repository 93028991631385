import { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { ContractStatus } from '@cotiss/contract/contract.model'

const getVariant = (status: ContractStatus): BaseVariant => {
  switch (status) {
    case 'DRAFTING':
      return 'warning'
    case 'PENDING_SIGNATURES':
    case 'PENDING_APPROVAL':
      return 'info'
    case 'CEASED':
      return 'neutral'
    case 'PUBLISHED':
    case 'UNPUBLISHED':
      return 'success'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ContractStatus
  size?: BadgeSize
}

export const ContractStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="outline">
    {upperFirst(lowerCase(status))}
  </Badge>
))
