import { FormEvent, memo, useState } from 'react'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { ForumQuestionModel } from '@cotiss/forum/forum.models'

type Props = {
  forumQuestion: ForumQuestionModel
  onSubmit: (documents: DocumentModel[]) => void
}

export const ForumResponseDocumentUploadModal = memo(({ onSubmit }: Props) => {
  const { closeModal } = useCallout()
  const [attachments, setAttachments] = useState<DocumentModel[]>([])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    onSubmit(attachments)
    closeModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload documents" />
      <ModalContent>
        <DocumentUpload documents={attachments} onChange={setAttachments} />
      </ModalContent>
      <ModalFooter isForm />
    </Form>
  )
})
