import { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { TableVariant } from '@cotiss/common/components/table.component'

type Props = {
  className?: string
  children: ReactNode
  variant?: TableVariant
}

export const TableEmptyContainer = memo(({ className, children, variant = 'white' }: Props) => {
  const classes = classNames(className, 'flex items-center justify-center border border-grey-200 p-4 w-full h-64', {
    'bg-primary-50': variant === 'primary',
    'bg-white': variant === 'white',
  })

  return <div className={classes}>{children}</div>
})
