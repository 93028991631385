import { memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'

type Props = {
  id?: string
  className?: string
  onClick: () => void
  size?: 'sm' | 'md'
  variant?: 'primary' | 'secondary'
  isOn?: boolean

  isDisabled?: boolean
}

export const Switch = memo(({ id, className, onClick, size = 'md', variant = 'primary', isOn, isDisabled }: Props) => {
  const classes = classNames(
    className,
    'relative inline-flex shrink-0 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200',
    {
      'bg-primary-500': isOn && variant === 'primary' && !isDisabled,
      'bg-secondary-600': isOn && variant === 'secondary' && !isDisabled,
      'bg-gray-300': !isOn || isDisabled,
      'h-6 w-11': size === 'md',
      'h-4 w-8': size === 'sm',
    }
  )
  const handleClasses = classNames('inline-block bg-white rounded-full shadow transition ease-in-out duration-200', {
    'translate-x-5': size === 'md' && isOn,
    'translate-x-4': size === 'sm' && isOn,
    'h-5 w-5': size === 'md',
    'h-3 w-3': size === 'sm',
  })

  return (
    <Button id={id} className={classes} onClick={onClick} state="raw" isDisabled={isDisabled}>
      <div className={handleClasses} />
    </Button>
  )
})
