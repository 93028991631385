import { memo, useEffect, useState } from 'react'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { Card } from '@cotiss/common/components/card.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { EXTERNAL_LINK } from '@cotiss/common/constants/external-link.constants'
import { EditableField } from '@cotiss/common/components/editable-field.component'
import { Field } from '@cotiss/common/components/field.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { PriceItemAddUpdateModal } from '@cotiss/price-item/modals/price-item-add-update.modal'
import { priceItemService } from '@cotiss/price-item/price-item.service'
import { useListPriceItem } from '@cotiss/price-item/resources/use-list-price-item.resource'
import { useMutatePriceItem } from '@cotiss/price-item/resources/use-mutate-price-item.resource'
import { TenderRequestPriceTableItemCta } from '@cotiss/tender/components/tender-request-price-table-item-cta.component'
import { TenderRequestPriceTableEditModal } from '@cotiss/tender/modals/tender-request-price-table-edit.modal'
import { UpdateTenderBody, useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type FormData = {
  priceTableRequirePaymentDate?: boolean
  priceTableEnabled?: boolean
  priceTableEnableBuyerItems?: boolean
  priceTableAllowQuantityChanges?: boolean
  priceTableAllowAdditionalItems?: boolean
}

type FieldName = keyof FormData

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderRequestPriceTable = memo(({ tenderId, isEditable }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { tender } = useGetTender(tenderId)
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { priceItems, count: priceItemsCount } = useListPriceItem({ tenderId })
  const { upsertPriceItemBulk } = useMutatePriceItem()
  const [formData, setFormData] = useState<FormData>({
    priceTableEnabled: tender?.priceTableEnabled,
    priceTableRequirePaymentDate: tender?.priceTableRequirePaymentDate,
    priceTableEnableBuyerItems: tender?.priceTableEnableBuyerItems,
    priceTableAllowQuantityChanges: tender?.priceTableAllowQuantityChanges,
    priceTableAllowAdditionalItems: tender?.priceTableAllowAdditionalItems,
  })

  useEffect(() => {
    if (!tender) {
      return
    }

    setFormData({
      priceTableEnabled: tender.priceTableEnabled,
      priceTableRequirePaymentDate: tender.priceTableRequirePaymentDate,
      priceTableEnableBuyerItems: tender.priceTableEnableBuyerItems,
      priceTableAllowQuantityChanges: tender.priceTableAllowQuantityChanges,
      priceTableAllowAdditionalItems: tender.priceTableAllowAdditionalItems,
    })
  }, [tender])

  const handleUpdateTender = async (body: UpdateTenderBody) => {
    try {
      await updateTender(tenderId, body)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleToggle = async (field: FieldName, value: boolean) => {
    setFormData({ ...formData, [field]: value })
    handleUpdateTender({ [field]: value })
  }

  const handleUploadCsv = async () => {
    try {
      const newPriceItems = await priceItemService.handlePriceItemCsvUpload()

      setIsSaving(true)
      await upsertPriceItemBulk({
        tenderId,
        items: map(newPriceItems, ({ category, description, unit, quantity }, index) => ({
          body: { description, category, unit, quantity, index: index },
        })),
      })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (!tender) {
    return null
  }

  const renderBuyerItems = () => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Category',
        rows: map(priceItems, (priceItem) => ({
          content: () => <Text size="sm">{priceItem.category}</Text>,
          cta: isEditable && <TenderRequestPriceTableItemCta tenderId={tenderId} priceItem={priceItem} />,
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(priceItems, ({ description }) => ({
          content: () => <Text size="sm">{description}</Text>,
        })),
      },
      {
        heading: 'Quantity',
        rows: map(priceItems, ({ quantity }) => ({
          content: () => <Text size="sm">{quantity}</Text>,
        })),
      },
      {
        heading: 'Unit',
        rows: map(priceItems, ({ unit }) => ({
          content: () => <Text size="sm">{unit}</Text>,
        })),
      },
    ]

    return (
      <>
        <Field className="mt-6" label="Quantity adjustments" supplementary="Allow suppliers to adjust the quantities of line items">
          {!isEditable && <Text>{tender.priceTableAllowQuantityChanges ? 'Yes' : 'No'}</Text>}
          {isEditable && (
            <div className="flex items-center ml-2.5">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={(value) => handleToggle('priceTableAllowQuantityChanges', value)}
                name="tender-request-price-table-allow-quantity-adjustments"
                isChecked={formData.priceTableAllowQuantityChanges}
                isDisabled={isSaving}
                isRequired
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={(value) => handleToggle('priceTableAllowQuantityChanges', value)}
                name="tender-request-price-table-allow-quantity-adjustments"
                isChecked={formData.priceTableAllowQuantityChanges === false}
                isDisabled={isSaving}
                isRequired
              >
                No
              </Radio>
            </div>
          )}
        </Field>
        <Field className="mt-6" label="Additional line items" supplementary="Allow suppliers to add additional line items">
          {!isEditable && <Text>{tender.priceTableAllowAdditionalItems ? 'Yes' : 'No'}</Text>}
          {isEditable && (
            <div className="flex items-center ml-2.5">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={(value) => handleToggle('priceTableAllowAdditionalItems', value)}
                name="tender-request-price-table-allow-additional-line-items"
                isChecked={formData.priceTableAllowAdditionalItems}
                isDisabled={isSaving}
                isRequired
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={(value) => handleToggle('priceTableAllowAdditionalItems', value)}
                name="tender-request-price-table-allow-additional-line-items"
                isChecked={formData.priceTableAllowAdditionalItems === false}
                isDisabled={isSaving}
                isRequired
              >
                No
              </Radio>
            </div>
          )}
        </Field>
        <div className="flex items-center justify-between bg-primary-100 border-x border-t border-primary-200 rounded-t-lg px-4 py-3 mt-6">
          <div className="mr-4">
            <Text className="font-semibold" variant="heading">
              Pricing table
            </Text>
            <Text className="mt-1" variant="light" size="sm">
              Complete the pricing table below, or upload CSV
            </Text>
          </div>
          {isEditable && (
            <div className="flex items-center space-x-2">
              <Button
                size="sm"
                state="translucent"
                variant="secondary-dark"
                isDisabled={isSaving}
                isExternalLink
                href={EXTERNAL_LINK.tenderRequestPricingTableImportTemplate}
              >
                <Icon className="mr-1" icon="download-01" />
                Download template
              </Button>
              <Button onClick={handleUploadCsv} size="sm" state="translucent" variant="secondary-dark" isDisabled={isSaving}>
                <Icon className="mr-1" icon="upload-01" />
                Upload CSV
              </Button>
              <Button onClick={() => openModal(<PriceItemAddUpdateModal tenderId={tenderId} />)} variant="secondary" size="sm" isDisabled={isSaving}>
                + Add row
              </Button>
            </div>
          )}
        </div>
        <ScrollableTable
          fixedColumns={fixedColumns}
          columns={columns}
          emptyCta={
            isEditable ? (
              <Button
                onClick={() => openModal(<PriceItemAddUpdateModal tenderId={tenderId} />)}
                state="text"
                variant="secondary"
                isDisabled={isSaving}
              >
                + Add row
              </Button>
            ) : null
          }
        />
      </>
    )
  }

  const renderContent = () => {
    if (!formData.priceTableEnabled) {
      return null
    }

    return (
      <>
        {isEditable && (
          <EditableField
            className="mt-6"
            label="Currency"
            textValue={tender?.currency}
            onClick={() => openModal(<TenderRequestPriceTableEditModal tender={tender} field="currency" label="Currency" />)}
            isDisabled={isSaving}
          />
        )}
        {!isEditable && (
          <Field className="mt-6" label="Currency">
            <Text>{tender?.currency || '--'}</Text>
          </Field>
        )}
        <Field
          className="mt-6"
          label="Require supplier to enter payment date"
          supplementary="Require suppliers to enter a payment date for each line item"
        >
          {!isEditable && <Text>{formData.priceTableRequirePaymentDate ? 'Yes' : 'No'}</Text>}
          {isEditable && (
            <div className="flex items-center ml-2.5">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={(value) => handleToggle('priceTableRequirePaymentDate', value)}
                name="tender-request-price-table-buyer-items-enabled"
                isChecked={formData.priceTableRequirePaymentDate}
                isDisabled={isSaving}
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={(value) => handleToggle('priceTableRequirePaymentDate', value)}
                name="tender-request-price-table-buyer-items-enabled"
                isChecked={formData.priceTableRequirePaymentDate === false}
                isDisabled={isSaving}
              >
                No
              </Radio>
            </div>
          )}
        </Field>
        {formData.priceTableRequirePaymentDate && isEditable && (
          <EditableField
            label="Discount rate"
            textValue={tender?.npv ? `${tender.npv}%` : undefined}
            isDisabled={isSaving}
            onClick={() =>
              openModal(
                <TenderRequestPriceTableEditModal
                  tender={tender}
                  field="npv"
                  label="Discount rate"
                  supplementary="The rate that the value of projected cash flows are discounted to the present. If you're not sure what to use it's recommended using 6% for Telecommunications, media and technology and 5% for all other projects"
                />
              )
            }
          />
        )}
        {formData.priceTableRequirePaymentDate && !isEditable && (
          <Field
            className="mt-6"
            label="Discount rate"
            supplementary="The rate that the value of projected cash flows are discounted to the present. If you're not sure what to use it's recommended using 6% for Telecommunications, media and technology and 5% for all other projects"
          >
            <Text>{tender?.npv ? `${tender.npv}%` : '--'}</Text>
          </Field>
        )}
        <Field
          className="mt-6"
          label="Enter my own line items"
          supplementary="Do you want to enter your own line items for suppliers to complete? You will still have the option for suppliers to add their own line items."
        >
          {!isEditable && <Text>{formData.priceTableEnableBuyerItems ? 'Yes' : 'No'}</Text>}
          {isEditable && (
            <div className="flex items-center ml-2.5">
              <Radio<boolean>
                className="mr-4"
                value={true}
                onChange={(value) => handleToggle('priceTableEnableBuyerItems', value)}
                name="tender-request-price-table-buyer-items-enabled"
                isChecked={formData.priceTableEnableBuyerItems}
                isDisabled={isSaving}
              >
                Yes
              </Radio>
              <Radio<boolean>
                value={false}
                onChange={(value) => handleToggle('priceTableEnableBuyerItems', value)}
                name="tender-request-price-table-buyer-items-enabled"
                isChecked={formData.priceTableEnableBuyerItems === false}
                isDisabled={isSaving || priceItemsCount > 0}
              >
                No
              </Radio>
            </div>
          )}
        </Field>

        {formData.priceTableEnableBuyerItems && renderBuyerItems()}
      </>
    )
  }

  return (
    <Card>
      <CardHeader>
        <Text className="font-semibold" variant="heading" size="h4">
          Line items
        </Text>
      </CardHeader>
      <Field className="mt-6" label="Pricing Table" supplementary="Enable line items for supplier to input their pricing estimations">
        {!isEditable && <Text>{formData.priceTableEnabled ? 'Yes' : 'No'}</Text>}
        {isEditable && (
          <div className="flex items-center ml-2.5">
            <Radio<boolean>
              className="mr-4"
              value={true}
              onChange={(value) => handleToggle('priceTableEnabled', value)}
              name="tender-request-price-table-buyer-items-enabled"
              isChecked={formData.priceTableEnabled}
              isDisabled={isSaving}
            >
              Yes
            </Radio>
            <Radio<boolean>
              value={false}
              onChange={(value) => handleToggle('priceTableEnabled', value)}
              name="tender-request-price-table-buyer-items-enabled"
              isChecked={formData.priceTableEnabled === false}
              isDisabled={isSaving}
            >
              No
            </Radio>
          </div>
        )}
      </Field>

      {renderContent()}
    </Card>
  )
})
