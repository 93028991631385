import { memo, useMemo, useState } from 'react'
import { GqlPagination, GqlPerformanceScorecardMetricFieldsFragment } from '@gql'
import { filter, includes, map } from 'lodash'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Badge } from '@cotiss/common/components/badge.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { PerformanceScorecardEvaluatorAddFormData } from '@cotiss/performance/drawers/performance-scorecard-evaluator-add.drawer'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { userService } from '@cotiss/user/user.service'

type Props = {
  performanceScorecardId: string
  formData: PerformanceScorecardEvaluatorAddFormData
  setFormData: (formData: PerformanceScorecardEvaluatorAddFormData) => void
  onBack: () => void
  isDisabled?: boolean
}

export const PerformanceScorecardEvaluatorAddMetricStep = memo(({ performanceScorecardId, formData, setFormData, onBack, isDisabled }: Props) => {
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { pagination } = await queryPerformanceScorecardMetricList({
        filter: { performanceScorecardId, isArchived: false },
        pagination: { page: currentPage, pageSize: 20 },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  const handleChange = (performanceScorecardMetric: GqlPerformanceScorecardMetricFieldsFragment) => {
    if (includes(formData.performanceScorecardMetricIds, performanceScorecardMetric.id)) {
      setFormData({
        ...formData,
        performanceScorecardMetricIds: filter(
          formData.performanceScorecardMetricIds,
          (performanceMetricId) => performanceMetricId !== performanceScorecardMetric.id
        ),
      })
    } else {
      setFormData({ ...formData, performanceScorecardMetricIds: [...formData.performanceScorecardMetricIds, performanceScorecardMetric.id] })
    }
  }

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(performanceScorecardMetrics, (performanceScorecardMetric) => ({
          content: () => (
            <Checkbox
              value={performanceScorecardMetric.id}
              onChange={() => handleChange(performanceScorecardMetric)}
              isChecked={includes(formData.performanceScorecardMetricIds, performanceScorecardMetric.id)}
              isDisabled={isDisabled}
            />
          ),
        })),
      },
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => (
            <Text className="truncate" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Group',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => <Text>{performanceMetric.group}</Text>,
        })),
      },
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => <Text>{performanceMetric.description}</Text>,
        })),
      },
      {
        heading: 'Created by',
        rows: map(performanceScorecardMetrics, ({ performanceMetric }) => ({
          content: () => (performanceMetric.createdByUser ? <Avatar>{userService.getInitials(performanceMetric.createdByUser)}</Avatar> : '--'),
        })),
      },
      {
        heading: 'Created at',
        rows: map(performanceScorecardMetrics, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceScorecardMetrics, formData])

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Select user',
      onClick: onBack,
    },
    {
      label: formData.evaluatorUser ? userService.getFullName(formData.evaluatorUser) : '',
      isLoading,
    },
  ]

  if (isError) {
    return (
      <>
        <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
        <ErrorPanel />
      </>
    )
  }

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <TableHeader className="flex items-center justify-between">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Search and select metric
          </Text>
          <Text size="sm">Select options below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 2 of 2
        </Badge>
      </TableHeader>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
