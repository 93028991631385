import { memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ProgressBar } from '@cotiss/common/components/progress-bar.component'
import { Text } from '@cotiss/common/components/text.component'
import { FileUploadStatus } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { documentService } from '@cotiss/document/document.service'

type Props = {
  className?: string
  file?: File
  status: FileUploadStatus
  document?: DocumentModel
  onCancel: () => void
}

export const DocumentUploadItem = memo(({ className, file, status, document, onCancel }: Props) => {
  const classes = classNames(className, 'border border-gray-100 rounded-lg p-2')

  const renderIcon = () => {
    if (status === 'saving') {
      return (
        <div className="inline-flex items-center justify-center shrink-0 bg-secondary-200 rounded-full w-6 h-6 mr-2">
          <Icon icon="file-04" variant="link" />
        </div>
      )
    }

    if (status === 'success') {
      return (
        <div className="inline-flex items-center justify-center shrink-0 bg-secondary-200 rounded-full w-6 h-6 mr-2">
          <Icon icon="check" variant="link" />
        </div>
      )
    }

    if (status === 'error') {
      return (
        <div className="inline-flex items-center justify-center shrink-0 bg-pink-200 rounded-full w-6 h-6 mr-2">
          <Icon icon="x-close" variant="danger" size={20} />
        </div>
      )
    }
  }

  return (
    <div className={classes}>
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-between mr-2">
          {renderIcon()}
          <Text>{file?.name || document?.fileName || '–'}</Text>
        </div>
        <div className="flex items-center justify-between shrink-0">
          {document && (
            <Text className="mr-2" variant="light" size="sm">
              {documentService.formatSize(document.fileSize)}
            </Text>
          )}
          <Button onClick={() => onCancel()} state="ghost" size="sm">
            <Icon icon="x-close" />
          </Button>
        </div>
      </div>
      {status === 'saving' && <ProgressBar className="mt-2" total={100} completed={95} isFakeLoader />}
      {status === 'error' && (
        <Text className="mt-2" variant="danger" size="sm">
          Upload failed. Please try again
        </Text>
      )}
    </div>
  )
})
