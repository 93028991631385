import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { ContractSummaryScheduleOfRates } from '@cotiss/contract/components/contract-summary-schedule-of-rates.component'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractScheduledRatesHistoryModal } from '@cotiss/contract/modals/contract-scheduled-rates-history.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

export const ContractViewScheduledRatesTab = memo(() => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openModal } = useCallout()
  const { contract, showHistory } = useMemo(() => {
    const contract = contractShell ? contractService.getContract(contractShell) : null
    const showHistory = (contractShell?.contracts.filter(({ variationTypes }) => variationTypes?.includes('scheduled_rates')) || []).length > 1
    return { contract, showHistory }
  }, [])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              Schedule of rates
            </Text>
          </div>
        </CardHeader>
        <Skeleton className="h-8 w-full" />
      </Card>
    )
  }

  if (!contract) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>
          <Text className="font-semibold" size="h5">
            Schedule of rates
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>

      {showHistory && (
        <div className="flex justify-end w-full mb-4">
          <Button
            size="sm"
            variant="secondary"
            state="text"
            onClick={() => openModal(<ContractScheduledRatesHistoryModal contractShellId={contractShellId} />)}
          >
            View history
          </Button>
        </div>
      )}
      <ContractSummaryScheduleOfRates variant="inline" showTableHeader={false} contractId={contract?._id} contractShellId={contractShellId} />
    </Card>
  )
})
