import { memo, useMemo } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Header } from '@cotiss/common/components/header.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { RouterPath, routerService } from '@cotiss/common/services/router.service'
import { ConflictOfInterestView } from '@cotiss/conflict-of-interest/components/conflict-of-interest-view.component'
import { useGetConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-get-conflict-of-interest.resource'

export const ConflictOfInterestViewPage = memo(() => {
  const { path } = useRouteMatch()
  const { conflictOfInterestId } = useParams<{ conflictOfInterestId: string }>()
  const { conflictOfInterest } = useGetConflictOfInterest(conflictOfInterestId)

  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    switch (path as RouterPath) {
      case '/conflict-of-interest/me/view/:conflictOfInterestId':
        return [
          {
            label: 'Conflict of interest',
            href: routerService.getHref('/conflict-of-interest/me/list'),
          },
          {
            label: 'View',
          },
        ]
      case '/conflict-of-interest/view/:conflictOfInterestId':
        return [
          {
            label: 'Conflict of interest',
            href: routerService.getHref('/conflict-of-interest/list'),
          },
          {
            label: 'View',
          },
        ]
      case '/procurement/overview/:procurementId/conflict-of-interest/view/:conflictOfInterestId':
        return [
          {
            label: 'Procurements',
            href: routerService.getHref('/project/list/:tab?'),
          },
          {
            label: 'Overview',
            href: routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', {
              procurementId: conflictOfInterest?.procurement._id || '',
            }),
            isLoading: !conflictOfInterest,
          },
          {
            label: 'Conflict of interest',
            href: routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', {
              procurementId: conflictOfInterest?.procurement._id || '',
              tab: 'conflict-of-interest',
            }),
            isLoading: !conflictOfInterest,
          },
          {
            label: 'View',
          },
        ]
    }

    return []
  }, [conflictOfInterest, path])

  return (
    <Page>
      <Header>
        <Breadcrumb breadcrumbs={breadcrumbs} />
        <Text className="font-semibold mt-2" size="h5" variant="heading">
          Conflict of interest
        </Text>
      </Header>
      <ConflictOfInterestView conflictOfInterestId={conflictOfInterestId} />
    </Page>
  )
})
