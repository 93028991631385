import { memo } from 'react'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ApprovalModel } from '@cotiss/approval/approval.models'
import { ApprovalStatusBadge } from '@cotiss/approval/components/approval-status-badge.component'
import { UserLineItem } from '@cotiss/user/components/user-line-item.component'

type Props = {
  rejectedApprovals: ApprovalModel[]
}

export const ApprovalFeedbackModal = memo(({ rejectedApprovals }: Props) => {
  const { closeModal } = useCallout()

  return (
    <div className="relative min-w-[450px] max-w-[450px]">
      <ModalContent isScrollable>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square">
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5">
          Feedback for review
        </Text>

        <Text className="mt-2" variant="light" size="sm">
          Review approval feedback. Once ready, please re-request approval.
        </Text>

        {map(rejectedApprovals, (approval) => (
          <div key={approval._id} className="bg-primary-50 rounded-lg mt-4 p-2">
            <div className="flex items-center justify-between mb-2">
              <UserLineItem {...approval.assigned} />
              <ApprovalStatusBadge status={approval.status} />
            </div>
            <Hr className="my-2" />
            <Text className="break-words whitespace-pre-wrap">{approval.feedback}</Text>
          </div>
        ))}
      </ModalContent>
    </div>
  )
})
