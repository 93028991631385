import { memo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { VerticalTabs } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { ConflictOfInterestViewCompleted } from '@cotiss/conflict-of-interest/components/conflict-of-interest-view-completed.component'
import {
  CONFLICT_OF_INTEREST_BANNER_HEADER_MAP,
  CONFLICT_OF_INTEREST_BANNER_ICON_MAP,
  CONFLICT_OF_INTEREST_BANNER_SUBTEXT_MAP,
  CONFLICT_OF_INTEREST_BANNER_VARIANT_MAP,
} from '@cotiss/conflict-of-interest/conflict-of-interest.constants'
import { conflictOfInterestService } from '@cotiss/conflict-of-interest/conflict-of-interest.service'
import { ConflictOfInterestResolutionModal } from '@cotiss/conflict-of-interest/modals/conflict-of-interset-resolution.modal'
import { useGetConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-get-conflict-of-interest.resource'
import { ConflictOfInterestViewAgreementTab } from '@cotiss/conflict-of-interest/tabs/conflict-of-interest-view-agreement.tab'
import { ConflictOfInterestViewConfirmationTab } from '@cotiss/conflict-of-interest/tabs/conflict-of-interest-view-confirmation.tab'
import { ConflictOfInterestViewDeclarationTab } from '@cotiss/conflict-of-interest/tabs/conflict-of-interest-view-declaration.tab'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type ConflictOfInterestViewTab = 'agreement' | 'declaration-form' | 'confirmation'

type Props = {
  conflictOfInterestId: string
}

export const ConflictOfInterestView = memo(({ conflictOfInterestId }: Props) => {
  const { openModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const { conflictOfInterest } = useGetConflictOfInterest(conflictOfInterestId)
  const { step, transition, onTransition } = useTransition()

  const tabs: VerticalTabModel<ConflictOfInterestViewTab>[] = [
    { id: 'agreement', label: 'Agreement', tasksRemaining: 1 },
    {
      id: 'declaration-form',
      label: 'Declaration Form',
      isLocked: conflictOfInterest && ['agreement'].includes(conflictOfInterest.step),
      tasksRemaining: 2,
    },
    {
      id: 'confirmation',
      label: 'Confirm & Sign',
      isLocked: conflictOfInterest && ['declaration-form', 'agreement'].includes(conflictOfInterest.step),
      tasksRemaining: 3,
    },
  ]

  const handleTabChange = (tab: ConflictOfInterestViewTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'bottom' : 'top' })
  }

  // TODO: Add loading states
  if (!conflictOfInterest || !user) {
    return null
  }

  return (
    <PageContent>
      {conflictOfInterest.dateSigned || conflictOfInterest.recipient._id !== user._id ? (
        <>
          <Banner
            icon={CONFLICT_OF_INTEREST_BANNER_ICON_MAP[conflictOfInterestService.getConflictOutcome(conflictOfInterest)]}
            className="mb-4"
            variant={CONFLICT_OF_INTEREST_BANNER_VARIANT_MAP[conflictOfInterestService.getConflictOutcome(conflictOfInterest)]}
            iconVariant="info"
          >
            <div className="mr-6">
              <Text className="font-semibold" variant="heading">
                {CONFLICT_OF_INTEREST_BANNER_HEADER_MAP[conflictOfInterestService.getConflictOutcome(conflictOfInterest)]}
              </Text>
              <Text size="sm">{CONFLICT_OF_INTEREST_BANNER_SUBTEXT_MAP[conflictOfInterestService.getConflictOutcome(conflictOfInterest)]}</Text>
            </div>
            {conflictOfInterestService.getConflictOutcome(conflictOfInterest) === 'pending' && (
              <Button
                className="shrink-0"
                isDisabled={conflictOfInterest.recipient._id === user._id}
                onClick={() => openModal(<ConflictOfInterestResolutionModal conflictOfInterest={conflictOfInterest} />)}
                size="sm"
              >
                Resolve
              </Button>
            )}
          </Banner>
          <ConflictOfInterestViewCompleted conflictOfInterestId={conflictOfInterestId} />
        </>
      ) : (
        <div className="flex items-start justify-between">
          <div className="sticky top-24 mr-12">
            <VerticalTabs<ConflictOfInterestViewTab> tab={tabs[step - 1].id} tabs={tabs} onChange={({ id }) => handleTabChange(id)} />
          </div>
          <div className="w-full">
            <AnimatePresence initial={false} mode="wait">
              <TransitionContainer key={step} className="w-full" transition={transition}>
                {step === 1 && (
                  <ConflictOfInterestViewAgreementTab conflictOfInterest={conflictOfInterest} onSuccess={() => handleTabChange('declaration-form')} />
                )}
                {step === 2 && (
                  <ConflictOfInterestViewDeclarationTab conflictOfInterest={conflictOfInterest} onSuccess={() => handleTabChange('confirmation')} />
                )}
                {step === 3 && (
                  <ConflictOfInterestViewConfirmationTab conflictOfInterest={conflictOfInterest} onSuccess={() => handleTabChange('agreement')} />
                )}
              </TransitionContainer>
            </AnimatePresence>
          </div>
        </div>
      )}
    </PageContent>
  )
})
