import { FormEvent, memo, useMemo, useState } from 'react'
import classNames from 'classnames'
import { filter, map } from 'lodash'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { accessControlService } from '@cotiss/access-control/access-control.service'
import { UserLineItemSkeleton } from '@cotiss/user/components/user-line-item-skeleton.component'
import { UserLineItem } from '@cotiss/user/components/user-line-item.component'
import { UserMultiSelect } from '@cotiss/user/components/user-multi-select.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { useListAccessControl } from '@cotiss/access-control/resources/use-list-access-control.resource'
import { useMutateAccessControl } from '@cotiss/access-control/resources/use-mutate-access-control.resource'
import { Hr } from '@cotiss/common/components/hr.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Text } from '@cotiss/common/components/text.component'
import { Card } from '@cotiss/common/components/card.component'

type FormData = {
  owners: string[]
  collaborators: string[]
}

type Props = {
  procurementId?: string
  breadcrumbs?: BreadcrumbModel[]
}

export const ProcurementSettings = memo(({ procurementId, breadcrumbs: rootBreadcrumbs }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { createAccessControlBulk, removeAccessControlBulk } = useMutateAccessControl()
  const [formData, setFormData] = useState<FormData>({ owners: [], collaborators: [] })
  const { users, isLoading: isUsersLoading } = useListUser({ permissions: ['ADMIN', 'PROCUREMENT_MANAGER'] })
  const { accessControls, isLoading: isAccessControlsLoading } = useListAccessControl({ resourceId: procurementId })
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => (rootBreadcrumbs ? [...rootBreadcrumbs, { label: 'Settings' }] : []), [rootBreadcrumbs])
  const { owners, collaborators, isLoading } = useMemo(() => {
    const owners = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'procurement:owner'))
    const collaborators = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'procurement:collaborator'))
    const isLoading = isAccessControlsLoading || isUsersLoading

    return { owners, collaborators, isLoading }
  }, [accessControls, isUsersLoading, isAccessControlsLoading])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!procurementId) {
      return
    }

    try {
      setIsSaving(true)
      // Procurement plan approvers
      const ownersToCreate = accessControlService.getCreateAccessControlBulkBody({
        userIdsToAdd: formData.owners,
        existingAccessControls: owners,
        roles: ['procurement:owner'],
      })
      const ownersToRemove = accessControlService.getRemoveAccessControlBulkBody({
        userIdsToAdd: formData.owners,
        existingAccessControls: owners,
        role: 'procurement:owner',
      })
      ownersToCreate && (await createAccessControlBulk(procurementId, 'PROCUREMENT', ownersToCreate))
      ownersToRemove && (await removeAccessControlBulk(procurementId, 'PROCUREMENT', ownersToRemove))

      // Go to market approvers
      const collaboratorsToCreate = accessControlService.getCreateAccessControlBulkBody({
        userIdsToAdd: formData.collaborators,
        existingAccessControls: collaborators,
        roles: ['procurement:collaborator'],
      })
      const collaboratorsToRemove = accessControlService.getRemoveAccessControlBulkBody({
        userIdsToAdd: formData.collaborators,
        existingAccessControls: collaborators,
        role: 'procurement:collaborator',
      })
      collaboratorsToCreate && (await createAccessControlBulk(procurementId, 'PROCUREMENT', collaboratorsToCreate))
      collaboratorsToRemove && (await removeAccessControlBulk(procurementId, 'PROCUREMENT', collaboratorsToRemove))

      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleEdit = (isEditing: boolean) => {
    const owners = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'procurement:owner'))
    const collaborators = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'procurement:collaborator'))

    setFormData({
      owners: map(owners, ({ user }) => user._id) || [],
      collaborators: map(collaborators, ({ user }) => user._id) || [],
    })
    setIsEditing(isEditing)
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <Field label="Owners">
            <UserMultiSelect value={formData.owners} options={users} onChange={(owners) => setFormData({ ...formData, owners })} />
          </Field>
          <Hr className="my-4" />
          <Field className="mt-8" label="Collaborators">
            <UserMultiSelect
              value={formData.collaborators}
              options={users}
              onChange={(collaborators) => setFormData({ ...formData, collaborators })}
            />
          </Field>
          <Text className="mt-8 " variant="light" size="sm">
            Tip: Adding new users to your account can be done in{' '}
            <Button
              size="sm"
              state="text"
              variant="link"
              href={routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' })}
              isLink
            >
              Settings
            </Button>
            . Make sure that the user you are adding has the &ldquo;Admin&ldquo; or &ldquo;Procurement Manager&ldquo; permission
          </Text>
        </>
      )
    }

    return (
      <>
        <Field label="Owners">
          {!isLoading && (
            <>
              {!owners.length && renderToggleLink('+ Add owners')}
              {map(owners, ({ _id, user }, index) => (
                <UserLineItem key={_id} className={classNames({ 'mt-4': index })} {...user} />
              ))}
            </>
          )}
          {isLoading && <UserLineItemSkeleton />}
        </Field>
        <Hr className="my-4" />
        <Field label="Collaborators">
          {!isLoading && (
            <>
              {!collaborators.length && renderToggleLink('+ Add collaborators')}
              {map(collaborators, ({ _id, user }, index) => (
                <UserLineItem key={_id} className={classNames({ 'mt-4': index })} {...user} />
              ))}
            </>
          )}
          {isLoading && <UserLineItemSkeleton />}
        </Field>
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
      <Card>
        <CardFormHeader
          heading="Procurement settings"
          onToggleEdit={handleToggleEdit}
          isEditing={isEditing}
          isSaving={isSaving}
          isEditable={!isLoading}
        />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
