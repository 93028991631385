import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { TRANSITION_DURATION } from '@cotiss/common/constants/transition.constants'

type Props = {
  className?: string
  children?: ReactNode
}

export const Page = forwardRef<HTMLDivElement, Props>(({ className, children }, ref) => {
  const classes = classNames(className, 'relative bg-primary-50 min-h-full h-full w-full overflow-y-auto z-page')

  return (
    <AnimatePresence>
      <motion.div
        className={classes}
        ref={ref}
        initial={{ y: -5, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: TRANSITION_DURATION.lg }}
        exit={{ y: -5, opacity: 0 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
})
