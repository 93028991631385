import { memo, useMemo, useState } from 'react'
import { map } from 'lodash'
import { MultiSelect_DEPRECATED } from '@cotiss/common/components/deprecated/multi-select.component'
import { MultiSelectOption } from '@cotiss/common/models/form.model'
import { MetafieldMultiArrayStringModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'

type Props = {
  className?: string
  metafield: MetafieldMultiArrayStringModel
  metafieldValue?: MetafieldValueModel
  onChange: (value: any) => void
}

export const MetafieldFormMultiArrayString = memo(({ className, metafield, metafieldValue, onChange }: Props) => {
  const [formData, setFormData] = useState<MultiSelectOption<string>[]>(map(metafieldValue?.fieldValue?.value, (value) => ({ label: value, value })))
  const options = useMemo(() => map(metafield.fieldValues, (option) => ({ label: option, value: option })), [metafield])

  const handleChange = (value: MultiSelectOption<string>[]) => {
    setFormData(value)
    onChange({ value: map(value, (option) => option.value) })
  }

  return (
    <MultiSelect_DEPRECATED
      className={className}
      options={options}
      value={formData}
      onChange={(value) => handleChange(value)}
      placeholder={metafield.validation?.placeholder || metafield.fieldLabel}
      isRequired={metafield.isRequired}
    />
  )
})
