import { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { includes, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { PerformanceScorecardStatusBadge } from '@cotiss/performance/components/performance-scorecard-status-badge.component'
import { PerformanceScorecardCreateDrawer } from '@cotiss/performance/drawers/performance-scorecard-create.drawer'
import { usePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  isArchived?: boolean
}

export const PerformanceScorecardList = memo(({ isArchived = false }: Props) => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const [isError, setIsError] = useState(false)
  const { openDrawer, openModal } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [isDownloading, setIsDownloading] = useState(false)
  const [pagination, setPagination] = useState<GqlPagination>()
  const canCreatePerformanceScorecard = useMemo(() => includes(user?.permissions, 'PROCUREMENT_MANAGER'), [user])
  const {
    performanceScorecards,
    setPerformanceScorecard,
    queryPerformanceScorecardList,
    queryPerformanceScorecardListCsv,
    mutateUpdatePerformanceScorecard,
  } = usePerformanceScorecard()

  const handleQueryPerformanceScorecardList = async () => {
    try {
      setPerformanceScorecard(null)

      const { pagination } = await queryPerformanceScorecardList({
        filter: {
          isArchived,
          isUserContext: true,
        },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    track(isArchived ? 'performance_scorecard_list_archived_view' : 'performance_scorecard_list_active_view')
  }, [isArchived])

  useAsyncEffect(async () => {
    handleQueryPerformanceScorecardList()
  }, [currentPage])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Scorecard',
        rows: map(performanceScorecards, (performanceScorecard) => ({
          content: () => (
            <Text className="font-medium truncate" title={performanceScorecard.name}>
              {performanceScorecard.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={
                !isArchived
                  ? {
                      href: routerService.getHref('/performance/scorecard/view/:performanceScorecardId/:tab?/:nestedTab?', {
                        performanceScorecardId: performanceScorecard.id,
                      }),
                      label: (
                        <>
                          View <Icon className="ml-1" icon="arrow-right" />
                        </>
                      ),
                    }
                  : undefined
              }
              actions={
                canCreatePerformanceScorecard
                  ? [
                      {
                        onClick: () =>
                          openModal(
                            <ConfirmModal
                              heading={performanceScorecard.isArchived ? 'Unarchive' : 'Archive'}
                              description={`Are you sure you want to ${performanceScorecard.isArchived ? 'unarchive' : 'archive'} this scorecard?`}
                              onSubmit={() => handleArchive(performanceScorecard.id, !performanceScorecard.isArchived)}
                            />
                          ),
                        label: performanceScorecard.isArchived ? 'Unarchive' : 'Archive',
                      },
                    ]
                  : undefined
              }
            />
          ),
        })),
      },
      {
        heading: 'Vendor',
        rows: map(performanceScorecards, ({ contact }) => ({
          content: () => <Text>{contact.name}</Text>,
        })),
      },
      {
        heading: 'Contract',
        rows: map(performanceScorecards, ({ contractShell }) => ({
          content: () => <Text>{contractShell?.title || '--'}</Text>,
        })),
      },
      {
        heading: 'Team',
        rows: map(performanceScorecards, ({ performanceScorecardUsers }) => ({
          content: () => <UserAvatarGroup users={map(performanceScorecardUsers, ({ user }) => user)} />,
        })),
      },
      {
        heading: 'Status',
        rows: map(performanceScorecards, ({ status }) => ({
          content: () => <PerformanceScorecardStatusBadge status={status} size="sm" />,
        })),
      },
    ]

    return { columns }
  }, [performanceScorecards])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  const handleDownload = async () => {
    try {
      setIsDownloading(true)
      const csv = await queryPerformanceScorecardListCsv({
        filter: {
          isArchived,
          isUserContext: true,
        },
      })
      utilService.downloadCsv({ csv, filename: `performance_scorecards_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv` })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsDownloading(false)
  }

  const handleArchive = async (performanceScorecardId: string, isArchived: boolean) => {
    track(isArchived ? 'performance_scorecard_list_archive_submit' : 'performance_scorecard_list_unarchive_submit')

    await mutateUpdatePerformanceScorecard({ performanceScorecardId, isArchived })
    await handleQueryPerformanceScorecardList()
  }

  if (!performanceScorecards.length && !isLoading) {
    if (isArchived) {
      return <NoDataPlaceholder label="You have not been added to any archived scorecards." />
    }

    return (
      <NoDataPlaceholder
        label="You have not been added to any scorecards yet."
        ctaLabel={canCreatePerformanceScorecard ? '+ New scorecard' : undefined}
        onCtaClick={canCreatePerformanceScorecard ? () => openDrawer(<PerformanceScorecardCreateDrawer />) : undefined}
      />
    )
  }

  return (
    <>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text size="md" className="flex items-center">
          Results{' '}
          {isLoading ? (
            <Skeleton className="h-3 w-6 inline-block" />
          ) : (
            <Text className="ml-1" variant="light">
              ({pagination?.totalCount})
            </Text>
          )}
        </Text>
        <Button
          size="xs"
          variant="secondary"
          state="ghost"
          isDisabled={isLoading}
          isLoading={isDownloading}
          onClick={handleDownload}
          className="gap-1"
        >
          <Icon icon="download-01" /> <span>Download CSV</span>
        </Button>
      </TableHeader>

      <Table columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
    </>
  )
})
