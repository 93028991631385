import { memo } from 'react'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ConflictOfInterestModel } from '@cotiss/conflict-of-interest/conflict-of-interest.model'
import { ConflictOfInterestViewDeclarationDocumentsModal } from '@cotiss/conflict-of-interest/modals/conflict-of-interest-view-declaration-documents.modal'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { userService } from '@cotiss/user/user.service'

type Props = {
  conflictOfInterest?: ConflictOfInterestModel
  isLoading: boolean
}

export const ConflictOfInterestViewCompletedDetails = memo(({ conflictOfInterest, isLoading }: Props) => {
  const { openModal } = useCallout()

  return (
    <>
      <Field label="Recipient">
        {isLoading && <Skeleton className="h-4 w-24" />}
        {!isLoading && conflictOfInterest && (
          <Text>{userService.getFullName({ firstname: conflictOfInterest.recipient.firstname, surname: conflictOfInterest.recipient.surname })}</Text>
        )}
      </Field>
      <Field label="Procurement" className="mt-4">
        {isLoading && <Skeleton className="h-4 w-24" />}
        {!isLoading && conflictOfInterest && <Text>{conflictOfInterest.procurement.title}</Text>}
      </Field>
      <Field label="Date sent" className="mt-4">
        {isLoading && <Skeleton className="h-4 w-24" />}
        {!isLoading && conflictOfInterest && <Text>{datetimeService.format(conflictOfInterest.createdAt, 'd MMMM yyyy h:mm aaa')}</Text>}
      </Field>
      <Field label="Date signed" className="mt-4">
        {isLoading && <Skeleton className="h-4 w-24" />}
        {!isLoading && conflictOfInterest && (
          <Text>{conflictOfInterest.dateSigned ? datetimeService.format(conflictOfInterest.dateSigned, 'd MMMM yyyy h:mm aaa') : '--'}</Text>
        )}
      </Field>
      {conflictOfInterest?.conflictDeclared && (
        <>
          <Hr className="my-4" />
          <Field label="Disclosure" supplementary="Any information relevant to the disclosure." className="mt-8">
            {isLoading && <Skeleton className="h-4 w-24" />}
            {!isLoading && <Text className="whitespace-pre-wrap">{conflictOfInterest.conflictDetails ?? '--'}</Text>}
          </Field>
          <Field label="Mitigation" supplementary="Action proposed to reduce, mitigate, or eliminate the conflict of interest." className="mt-8">
            {isLoading && <Skeleton className="h-4 w-24" />}
            {!isLoading && <Text className="whitespace-pre-wrap">{conflictOfInterest.conflictMitigation ?? '--'}</Text>}
          </Field>
        </>
      )}
      {conflictOfInterest?.resolvedDate && (
        <>
          <Hr className="my-4" />
          <Field label="Date resolved" className="mt-4">
            {isLoading && <Skeleton className="h-4 w-24" />}
            {!isLoading && <Text>{datetimeService.format(conflictOfInterest.resolvedDate, 'd MMMM yyyy h:mm aaa')}</Text>}
          </Field>
          <Field label="Resolved by" className="mt-4">
            {isLoading && <Skeleton className="h-4 w-24" />}
            {!isLoading && (
              <Text>
                {conflictOfInterest.resolvedBy
                  ? userService.getFullName({ firstname: conflictOfInterest.resolvedBy.firstname, surname: conflictOfInterest.resolvedBy.surname })
                  : '--'}
              </Text>
            )}
          </Field>
          <Field label="Justification" className="mt-4">
            {isLoading && <Skeleton className="h-4 w-24" />}
            {!isLoading && <Text className="whitespace-pre-wrap">{conflictOfInterest.resolutionReason}</Text>}
          </Field>
        </>
      )}
      <Hr className="my-4" />
      <Field label="Supporting documents" supplementary="Attach any relevant supporting document for this conflict of interest." className="mt-4">
        <DocumentList
          documents={conflictOfInterest?.conflictAttachments}
          onUpload={() =>
            openModal(conflictOfInterest ? <ConflictOfInterestViewDeclarationDocumentsModal conflictOfInterest={conflictOfInterest} /> : undefined)
          }
          isLoading={isLoading}
        />
      </Field>
    </>
  )
})
