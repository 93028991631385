import { memo, useMemo } from 'react'
import { GqlPerformanceScorecardMetricFrequency } from '@gql'
import classNames from 'classnames'
import { includes } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { Month, Weekday } from '@cotiss/common/constants/datetime.constants'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { utilService } from '@cotiss/common/services/util.service'

type Props = {
  className?: string
  startDate: Date | null
  frequency: GqlPerformanceScorecardMetricFrequency
  frequencyEndOnDay: string
  frequencyEndOnMonth: Month
  frequencyEndOnWeekday: Weekday
  frequencyMonthRepeat: string
}

export const PerformanceScorecardMetricCycleExplanation = memo((props: Props) => {
  const { className, startDate, frequency, frequencyEndOnDay, frequencyEndOnMonth, frequencyEndOnWeekday, frequencyMonthRepeat } = props
  const classes = classNames(className, 'flex items-center justify-between bg-primary-50 rounded px-6 py-4')
  const hasExplanation = useMemo(() => {
    if (!startDate) {
      return false
    }

    if (frequency === 'annually') {
      return Boolean(frequencyEndOnMonth && frequencyEndOnDay)
    }

    if (frequency === 'monthly') {
      return Boolean(frequencyMonthRepeat && frequencyEndOnDay)
    }
  }, [startDate, frequency, frequencyEndOnDay, frequencyEndOnMonth, frequencyEndOnWeekday, frequencyMonthRepeat])

  const renderStartingFromCopy = (startDate: Date) => {
    return `starting from ${datetimeService.format(startDate, 'EEEE')} the ${datetimeService.format(startDate, 'do MMM yyyy')}`
  }

  const renderContent = () => {
    if (!startDate) {
      return <Text variant="light">Metric due: --</Text>
    }

    if (frequency === 'annually' && frequencyEndOnMonth && frequencyEndOnDay) {
      return (
        <Text variant="light">
          Metric due:
          <Text className="ml-1" variant="primary" isInline>
            on the {utilService.formatIntegerToOrdinal(Number(frequencyEndOnDay))} of {frequencyEndOnMonth}
          </Text>
          , {renderStartingFromCopy(startDate)}
        </Text>
      )
    }

    if (frequency === 'monthly' && frequencyMonthRepeat && frequencyEndOnDay) {
      return (
        <Text variant="light">
          Metric due:
          <Text className="ml-1" variant="primary" isInline>
            on the {utilService.formatIntegerToOrdinal(Number(frequencyEndOnDay))} every {frequencyMonthRepeat} month
            {Number(frequencyMonthRepeat) > 1 ? 's' : ''}
          </Text>
          , {renderStartingFromCopy(startDate)}
        </Text>
      )
    }

    if (frequency === 'weekly' && frequencyEndOnWeekday) {
      return (
        <Text variant="light">
          Metric due:
          <Text className="ml-1" variant="primary" isInline>
            every {frequencyEndOnWeekday}
          </Text>
          , {renderStartingFromCopy(startDate)}
        </Text>
      )
    }

    return <Text variant="light">Metric due: --</Text>
  }

  return (
    <div className={classes}>
      <div className="flex shrink-0 items-center justify-center bg-primary-200 rounded-full h-6 w-6 mr-4">
        <Icon icon="calendar" />
      </div>
      <div className="w-full">
        {renderContent()}
        {hasExplanation && includes(['31', '30', '29'], frequencyEndOnDay) && (
          <Text variant="warning">On months that have fewer than {frequencyEndOnDay} days, collection will be due on the last day of the month.</Text>
        )}
      </div>
    </div>
  )
})
