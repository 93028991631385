import { memo } from 'react'
import NoDataListDot from '@assets/svg/no-data-list-dots.svg'
import classNames from 'classnames'
import { Button, ButtonState } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text, TextSize } from '@cotiss/common/components/text.component'

type Props = {
  className?: string
  label: string
  orientation?: 'horizontal' | 'vertical'
  variant?: 'transparent' | 'white' | 'default' | 'gray'
  illustration?: 'document-list' | 'dot-list'
  ctaLabel?: string
  ctaSize?: TextSize
  ctaState?: ButtonState
  onCtaClick?: () => void
  hasIllustration?: boolean
  isDisabled?: boolean
}

export const NoDataPlaceholder = memo((props: Props) => {
  const {
    className,
    label,
    orientation = 'horizontal',
    variant = 'default',
    illustration = 'document-list',
    ctaLabel,
    ctaSize = 'sm',
    ctaState = 'filled',
    onCtaClick,
    hasIllustration = true,
    isDisabled,
  } = props
  const classes = classNames(className, 'rounded-lg p-6', {
    'bg-primary-50': variant === 'default',
    'bg-gray-200': variant === 'gray',
    'bg-white': variant === 'white',
  })
  const contentClasses = classNames('flex', {
    'justify-center': orientation === 'horizontal',
    'flex-col items-center text-center': orientation === 'vertical',
    'max-w-[450px] mx-auto': hasIllustration,
  })
  const illustrationClasses = classNames('inline-flex items-center shrink-0 opacity-70 rounded-full w-32 h-32', {
    'mr-8': orientation === 'horizontal',
  })
  const ctaClasses = classNames('flex flex-col', {
    'items-start justify-center': orientation === 'horizontal',
    'items-center justify-center': orientation === 'vertical',
  })

  return (
    <div className={classes}>
      <div className={contentClasses}>
        {hasIllustration && (
          <>
            {illustration === 'document-list' && (
              <div className="relative">
                {/* TODO: Replace this with an svg. */}
                <>
                  <div
                    className={illustrationClasses}
                    style={{ background: 'radial-gradient(55.56% 55.56% at 50% 50%, #9793ED 0%, rgba(219, 218, 255, 0.15) 100%)' }}
                  ></div>
                  <div className="absolute top-1/2 left-6 -translate-y-1/2">
                    <div className="flex items-center justify-between bg-white rounded-md w-28 h-6 pl-2 pr-4">
                      <Icon className="shrink-0 mr-2" icon="file-04" variant="link" />
                      <div className="bg-primary-200 rounded-sm w-full h-2" />
                    </div>
                    <div className="flex items-center justify-between bg-white rounded-md w-28 h-6 pl-2 pr-4 mt-2">
                      <Icon className="shrink-0 mr-2" icon="file-04" variant="link" />
                      <div className="bg-primary-200 rounded-sm w-full h-2" />
                    </div>
                    <div className="flex items-center justify-between bg-white rounded-md w-28 h-6 pl-2 pr-4 mt-2">
                      <Icon className="shrink-0 mr-2" icon="file-04" variant="link" />
                      <div className="bg-primary-200 rounded-sm w-full h-2" />
                    </div>
                  </div>
                </>
              </div>
            )}
            {illustration === 'dot-list' && (
              <div className="w-48 mr-2">
                <NoDataListDot />
              </div>
            )}
          </>
        )}
        <div className={ctaClasses}>
          <Text variant="heading">{label}</Text>
          {ctaLabel && onCtaClick && (
            <Button className="mt-3" onClick={onCtaClick} size={ctaSize} state={ctaState} variant="secondary" isDisabled={isDisabled}>
              {ctaLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
})
