import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { DocumentViewPage } from '@cotiss/document/pages/document-view.page'

const DocumentLayout = memo(() => (
  <Switch>
    <Route path="/document/view/:id" exact>
      <DocumentViewPage />
    </Route>
  </Switch>
))

export default DocumentLayout
