import { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { startsWith } from 'lodash'
import { useLocation } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Badge } from '@cotiss/common/components/badge.component'

type Props = {
  className?: string
  label: string
  icon: ReactNode
  href: string
  variant?: 'primary' | 'secondary'
  isLoading?: boolean
  isDisabled?: boolean
  isHidden?: boolean
  isBeta?: boolean
  state?: 'simple' | 'full'
}

export const AppSideNavLink = memo((props: Props) => {
  const { className, label, icon, href, variant = 'primary', isLoading, isBeta, isDisabled, isHidden, state } = props
  const location = useLocation()
  const isActive = startsWith(location.pathname, href)
  const classes = classNames(
    className,
    'flex items-center justify-between font-medium text-sm rounded-md gap-1 w-full py-1 px-2 hover:bg-primary-100',
    {
      'text-gray-500': !isActive && !isDisabled,
      'text-secondary-500': isActive && !isDisabled,
      'pointer-events-none text-gray-300': isDisabled,
      'justify-center': state === 'simple',
    }
  )

  if (isLoading || isHidden) {
    return null
  }

  const renderIcon = () => {
    if (variant === 'primary') {
      const classes = classNames('flex items-center justify-center rounded h-6 w-6', {
        'mr-3': state === 'full',
        'bg-secondary-200': isActive,
        'bg-primary-100': !isActive,
      })

      return (
        <div className={classes}>
          <span className="flex items-center justify-center h-4 w-4">{icon}</span>
        </div>
      )
    }

    return <div className={classNames('flex shrink-0 items-center justify-center h-5 w-5', { 'mr-2': state === 'full' })}>{icon}</div>
  }

  return (
    <Button className={classes} href={href} state="raw" isLink>
      <div className="flex items-center justify-start">
        {renderIcon()}
        {Boolean(state === 'full') && label}
      </div>
      {isBeta && Boolean(state === 'full') && (
        <Badge title="Beta" variant="warning" state="translucent" shape="square" size="sm" className="uppercase">
          Beta
        </Badge>
      )}
    </Button>
  )
})
