import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex, forEach } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { RemainingTasksButton } from '@cotiss/common/components/remaining-tasks-button.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType, useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { ApprovalFeedbackModal } from '@cotiss/approval/modals/approval-feedback.modal'
import { useListApproval } from '@cotiss/approval/resources/use-list-approval.resource'
import { RecommendationView } from '@cotiss/recommendation/components/recommendation-view.component'
import { useGetRecommendation } from '@cotiss/recommendation/resources/use-get-recommendation.resource'
import { ShortListDueDiligenceList } from '@cotiss/short-list/components/short-list-due-diligence-list.component'
import { ShortListDueDiligenceView } from '@cotiss/short-list/components/short-list-due-diligence-view.component'
import { useListShortList } from '@cotiss/short-list/resources/use-list-short-list.resource'
import { ShortListPopulatedModel } from '@cotiss/short-list/short-list.models'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TenderFlowSendForApprovalConfirmModal } from '@cotiss/tender-flow/modals/tender-flow-send-for-approval-confirm.modal'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'
import { TenderResponseReportResult } from '@cotiss/tender-response/components/tender-response-report-result.component'
import { useListTenderResponseReport } from '@cotiss/tender-response/resources/use-list-tender-response-report.resource'

const TenderFlowSelectionTabs = ['evaluation-results', 'due-diligence', 'recommendation'] as const

type TenderFlowSelectionTab = (typeof TenderFlowSelectionTabs)[number]

type TenderFlowSelectionEntity = {
  type: 'due-diligence'
  shortList: ShortListPopulatedModel
}

export const TenderFlowSelectionStep = memo(() => {
  const { replace } = useHistory()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { regressTender } = useMutateTender()
  const [nestedTransition, setNestedTransition] = useState<TransitionType>('right')
  const [activeEntity, setActiveEntity] = useState<TenderFlowSelectionEntity | null>(null)
  const { tenderId, step: tenderFlowStep, tab } = useParams<{ tenderId: string; step: TenderFlowStep; tab: TenderFlowSelectionTab }>()
  const { tender } = useGetTender(tenderId)
  const { recommendation } = useGetRecommendation(tenderId)
  const { tenderResponseReports } = useListTenderResponseReport(tenderId)
  const { shortLists } = useListShortList({ recommendationId: recommendation?._id })
  const { approvals } = useListApproval({ tenderId })
  const rejectedApprovals = filter(approvals, { approvalType: 'recommendation', status: 'rejected' })
  const isEditable = tender?.status === 'selection'
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  const tabs = useMemo(() => {
    const tabs: TabModel<TenderFlowSelectionTab>[] = [
      { id: 'evaluation-results', label: 'Evaluation results', isHidden: tender?.evaluationPanelType === 'none' },
      {
        id: 'due-diligence',
        label: 'Complete due diligence',
      },
      {
        id: 'recommendation',
        label: 'Recommendation',
      },
    ]

    const tabsToDisplay = filter(tabs, (tab) => !tab.isHidden)

    return forEach(tabsToDisplay, (tab, index) => {
      tab.label = `${index + 1}. ${tab.label}`
    })
  }, [tender])

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tab) + 1 })

  const activeTabId = tabs[step - 1].id

  useEffect(() => {
    if (activeEntity?.type === 'due-diligence') {
      const updatedShortList = find(shortLists, { _id: activeEntity.shortList._id })
      updatedShortList && handleSetEntity({ type: 'due-diligence', shortList: updatedShortList })
    }
  }, [shortLists])

  const handleRegressProcurement = async () => {
    try {
      setIsSaving(true)
      tender && (await regressTender(tender._id))
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleTabChange = (tab: TenderFlowSelectionTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, tab, step: tenderFlowStep }))
  }

  const handleSetEntity = (entity: TenderFlowSelectionEntity | null, transition: TransitionType = 'right') => {
    setNestedTransition(transition)
    setTimeout(() => setActiveEntity(entity), 0)
  }

  if (!isOldEvaluationsEnabled) {
    return <FourOhThreePage />
  }

  const renderInitial = () => (
    <>
      {isEditable && (
        <Banner className="mb-6" icon="star-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Complete Due Diligence and Recommendation Report
            </Text>
            <Text size="sm">Review results and complete due diligence in order to write your recommendation.</Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            <RemainingTasksButton errors={tender.validation.validationErrors} />
            <Button
              className="ml-4"
              onClick={() => openModal(<TenderFlowSendForApprovalConfirmModal tenderId={tender._id} />)}
              size="sm"
              isDisabled={Boolean(tender.validation.validationErrors.length)}
            >
              Send for approval
            </Button>
          </div>
        </Banner>
      )}
      {tender?.status === 'selection-approval' && (
        <>
          {Boolean(!rejectedApprovals.length) && (
            <Banner className="mb-6" icon="clock" variant="primary">
              <div className="mr-6">
                <Text className="font-semibold" variant="heading">
                  Waiting for approval
                </Text>
                <Text size="sm">You will be able to download the recommendation report once it has been approved.</Text>
              </div>
            </Banner>
          )}
          {Boolean(rejectedApprovals.length) && (
            <Banner className="mb-6" icon="clock" variant="danger">
              <div className="mr-6">
                <Text className="font-semibold" variant="heading">
                  Approval rejected
                </Text>
                <Text size="sm">Your approval has been rejected.</Text>
              </div>
              <div className="flex items-center justify-center shrink-0">
                <Button
                  onClick={() => openModal(<ApprovalFeedbackModal rejectedApprovals={rejectedApprovals} />)}
                  state="text"
                  variant="link"
                  isDisabled={isSaving}
                >
                  View feedback
                </Button>
                <Button className="ml-4" onClick={handleRegressProcurement} size="sm" isLoading={isSaving}>
                  Edit selection
                </Button>
              </div>
            </Banner>
          )}
        </>
      )}

      <Tabs<TenderFlowSelectionTab>
        className="border-b border-gray-300 w-full mb-8"
        tab={tab}
        tabs={tabs}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} className="w-full" transition={transition}>
          {activeTabId === 'evaluation-results' && <TenderResponseReportResult tenderId={tenderId} tenderResponseReports={tenderResponseReports} />}
          {activeTabId === 'due-diligence' && (
            <ShortListDueDiligenceList
              isEditable={isEditable}
              shortLists={shortLists}
              tenderResponseReports={tenderResponseReports}
              onView={(shortList) => handleSetEntity({ type: 'due-diligence', shortList })}
              isScoreVisible={tender?.evaluationPanelType === 'panel' || tender?.evaluationPanelType === 'self'}
            />
          )}
          {activeTabId === 'recommendation' && recommendation && (
            <RecommendationView isEditable={isEditable} shortLists={shortLists} recommendation={recommendation} />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </>
  )

  return (
    <AnimatePresence mode="wait" initial={false}>
      {!activeEntity && (
        <TransitionContainer key={1} transition={nestedTransition}>
          {renderInitial()}
        </TransitionContainer>
      )}
      {activeEntity?.type === 'due-diligence' && (
        <TransitionContainer key={2} transition={nestedTransition}>
          <ShortListDueDiligenceView shortList={activeEntity.shortList} isEditable={isEditable} onBack={() => handleSetEntity(null, 'left')} />
        </TransitionContainer>
      )}
    </AnimatePresence>
  )
})
