import { memo, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { includes } from 'lodash'
import { pdfjs, Document, Page } from 'react-pdf'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { Text } from '@cotiss/common/components/text.component'
import { useGetWidth } from '@cotiss/common/hooks/use-get-width.hook'
import { useGetWindowDimensions } from '@cotiss/common/hooks/use-get-window-dimensions.hook'
import { DocumentModel, DownloadDocumentInfo } from '@cotiss/document/document.models'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'

type Props = {
  className?: string
  isDownloadable?: boolean
  document: DocumentModel
  onClickOpenInNewTab?: (document: DocumentModel) => void
  onClickDownload?: (document: DocumentModel) => void
}

// All versions 9+ of PdfJs use Promise.withResolvers which is not supported by some legacy browsers.
// To support these browsers, we have a polyfill in main.tsx and use the legacy script below which is a transpiled version for legacy browsers.
// Once these browsers move out of active support, we can remove this polyfill and use the non-legacy build.
pdfjs.GlobalWorkerOptions.workerSrc = '/assets/pdfjs/pdf.worker.min.mjs'

const MIME_TYPE_PDF = 'application/pdf'
const MIME_TYPE_WORD_DOC = 'application/msword'
const MIME_TYPE_WORD_DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
const WORD_MIME_TYPES = [MIME_TYPE_WORD_DOC, MIME_TYPE_WORD_DOCX] as const
const SUPPORTED_MIME_TYPES = [MIME_TYPE_PDF, ...WORD_MIME_TYPES] as const

export const DocumentViewer = memo(({ className, document, isDownloadable, onClickOpenInNewTab, onClickDownload }: Props) => {
  const isPdf = document.mimeType === MIME_TYPE_PDF
  const isSupportedMimeType = includes(SUPPORTED_MIME_TYPES, document.mimeType)
  const [pageCount, setPageCount] = useState(0)
  const [pageNumber, setPageNumber] = useState(1)
  const containerRef = useRef<HTMLDivElement>(null)
  const [documentUrl, setDocumentUrl] = useState('')
  const [isResizing, setIsResizing] = useState(false)
  const containerWidth = useGetWidth(containerRef, 300)
  const { windowWidth, windowHeight } = useGetWindowDimensions()
  const [documentInfoToDownload, setDocumentInfoToDownload] = useState<DownloadDocumentInfo>({ id: '', type: 'inline' })
  const [isLoadingPdf, setIsLoadingPdf] = useState(isPdf ? true : false)
  const { document: documentToDownload } = useGetDocument(documentInfoToDownload.id, documentInfoToDownload.type)
  const [isDownloadButtonVisible, setIsDownloadButtonVisible] = useState(isDownloadable && isSupportedMimeType)
  const { document: documentToView, isLoading: isDocumentToViewLoading } = useGetDocument(isSupportedMimeType ? document._id : undefined)
  const classes = classNames(className, {
    'flex items-start justify-center h-full w-full': !isPdf || isLoadingPdf,
    'relative overflow-y-auto overflow-x-hidden h-full': isPdf,
  })

  useEffect(() => {
    setPageNumber(1)
  }, [document])

  useEffect(() => {
    setIsResizing(true)
    setTimeout(() => setIsResizing(false), 100)
  }, [windowWidth, windowHeight])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentInfoToDownload({ id: '', type: 'inline' })
    }
  }, [documentToDownload])

  useEffect(() => {
    if (documentToView && documentToView.downloadUrl !== documentUrl) {
      setDocumentUrl(documentToView.downloadUrl)
    }
  }, [documentToView])

  const handleLoadSuccess = ({ numPages }: { numPages: number }) => {
    setIsDownloadButtonVisible(isDownloadButtonVisible)
    setPageCount(numPages)
    setIsLoadingPdf(false)
  }

  const renderPdf = () => (
    <div className="text-center">
      {!isLoadingPdf && (
        <div className="sticky top-0 flex items-center justify-center bg-gray-100 mb-4 z-1 p-2">
          <div className="flex flex-1 justify-start gap-6 truncate">
            <Text className="truncate" size="sm" variant="light">
              {document.fileName}
            </Text>
            <Text className="whitespace-nowrap" size="sm" variant="light">
              {documentService.formatSize(document.fileSize)}
            </Text>
          </div>
          <div className="flex shrink-0 md:flex-1 justify-center items-center">
            <Button
              className="shrink-0"
              onClick={() => setPageNumber(pageNumber - 1)}
              state="ghost"
              shape="square"
              size="sm"
              isDisabled={pageNumber <= 1}
            >
              <Icon icon="arrow-left" size={16} />
            </Button>
            <Text className="inline-flex items-center justify-center shrink-0 bg-gray-300 rounded-full h-5 px-2" variant="dark" size="sm">
              {pageNumber}/{pageCount}
            </Text>
            <Button
              className="shrink-0"
              onClick={() => setPageNumber(pageNumber + 1)}
              state="ghost"
              shape="square"
              size="sm"
              isDisabled={pageNumber >= pageCount}
            >
              <Icon icon="arrow-right" size={16} />
            </Button>
          </div>
          <div className="flex flex-1 justify-end">
            {isDownloadButtonVisible && (
              <div className="flex gap-2">
                <Button
                  className="flex items-center"
                  onClick={() => {
                    setDocumentInfoToDownload({ id: document._id, type: 'inline' })
                    onClickOpenInNewTab && onClickOpenInNewTab(document)
                  }}
                  variant="tertiary"
                  size="sm"
                  isDisabled={isDocumentToViewLoading}
                >
                  <Icon icon="link-external-01" />
                </Button>
                <Button
                  className="flex items-center"
                  onClick={() => {
                    setDocumentInfoToDownload({ id: document._id, type: 'attachment' })
                    onClickDownload && onClickDownload(document)
                  }}
                  variant="tertiary"
                  size="sm"
                  isDisabled={isDocumentToViewLoading}
                >
                  <Icon icon="download-01" />
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      <Document
        className={classNames({ invisible: isResizing })}
        file={documentUrl}
        onLoadSuccess={handleLoadSuccess}
        onLoadProgress={() => setIsLoadingPdf(true)}
        loading={<Spinner className="mx-auto mt-24" colour="#5B53E6" />}
        noData={<></>}
        error={<></>}
      >
        <Page
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={false}
          width={containerWidth}
          loading={<Spinner className="mx-auto mt-24" colour="#5B53E6" />}
          noData={<></>}
          error={<></>}
        />
      </Document>
    </div>
  )

  const renderWord = () => (
    <>
      {isDocumentToViewLoading && <Spinner className="mx-auto mt-24" colour="#5B53E6" />}
      {!isDocumentToViewLoading && documentToView && (
        <iframe
          className="h-full w-full"
          src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(documentToView?.downloadUrl)}`}
          title={documentToView?.fileName}
        />
      )}
    </>
  )

  const renderContent = () => {
    if (document.mimeType === 'application/pdf') {
      return renderPdf()
    }

    if (includes(WORD_MIME_TYPES, document.mimeType)) {
      return renderWord()
    }

    if (!isSupportedMimeType) {
      return (
        <div className="flex flex-col items-center justify-center">
          <Badge className="mb-2 mt-24" state="translucent" variant="secondary" shape="rect">
            Unable to preview
          </Badge>
          <Text className="font-semibold mb-2" size="h4">
            Download to view
          </Text>
          <Text variant="light">
            <Icon className="mr-1" icon="file-06" />
            {document.fileName}
          </Text>
          <Text className="mt-4" variant="light" size="sm">
            Only PDF and Word files can currently be previewed in app
          </Text>
          <Button className="mt-2" onClick={() => setDocumentInfoToDownload({ id: document._id, type: 'inline' })} variant="secondary" size="sm">
            <Icon className="mr-1" icon="download" />
            Download
          </Button>
        </div>
      )
    }
  }

  return (
    <div className={classes} ref={containerRef}>
      {renderContent()}
    </div>
  )
})
