import { memo, useMemo, useState } from 'react'
import { filter, find, map } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ContractWizardMilestoneTable } from '@cotiss/contract/components/contract-wizard-milestone-table.component'
import { ContractAssignedApproverModel } from '@cotiss/contract/contract.model'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { userService } from '@cotiss/user/user.service'
import { ContractWizardPriceDurationTable } from '@cotiss/contract/components/contract-wizard-price-duration-table.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { TimestampByModel } from '@cotiss/common/models/timestamp.model'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'

type Props = {
  contractShellId: string
}

export type PriceDurationOrMilestonesHistoryTableData = {
  activityLabel: string
  actionedBy?: TimestampByModel
  approvedBy: ContractAssignedApproverModel[]
}

export const PriceDurationsOrMilestonesHistoryModal = memo(({ contractShellId }: Props) => {
  const [selectedContractId, setSelectedContractId] = useState('')
  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const { contractSelectOptions, selectedContract, tableData } = useMemo(() => {
    const contractSelectOptions = map(
      filter(
        // Get the original contract, as well as all contracts that have been approved and are a price_duration variation
        contractService.getAllApprovedContracts(contractShell),
        (contract) =>
          contract.variationTypes.length === 0 ||
          contract.variationTypes.includes('price_duration') ||
          contract.variationTypes.includes('contract_renewal') ||
          contract.variationTypes.includes('milestone')
      ),
      ({ createdAt, _id }) => ({
        label: datetimeService.format(createdAt, 'd MMMM yyyy h:mm aaa'),
        value: _id,
      })
    )
    const selectedContract = find(contractShell?.contracts, (contract) => contract._id === selectedContractId)

    const tableData: PriceDurationOrMilestonesHistoryTableData[] = [
      {
        activityLabel: contractShell?.type === 'MILESTONE' ? 'Change to milestones table' : 'Change to price duration table',
        actionedBy: selectedContract?.lastModifiedBy,
        approvedBy: map(contractService.getApproval(selectedContract?.approvals, ['APPROVED'])?.approvers, ({ assigned }) => assigned) || [],
      },
    ]

    return { contractSelectOptions, selectedContract, tableData }
  }, [contractShell, selectedContractId])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Activity',
      rows: map(tableData, ({ activityLabel }) => ({
        content: () => (
          <Text className="flex items-center">
            <Icon icon="edit" className="mr-2 stroke-2" variant="secondary" />
            {activityLabel}
          </Text>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Actioned by',
      rows: map(tableData, ({ actionedBy }) => ({
        content: () => (
          <Text className="w-56 whitespace-normal" title={userService.getFullName(actionedBy)}>
            {userService.getFullName(actionedBy)}
          </Text>
        ),
      })),
    },
    {
      heading: 'Approved by',
      rows: map(tableData, ({ approvedBy }) => ({
        content: () => (
          <>
            {approvedBy.length <= 1 && (
              <Text className="w-56 whitespace-normal" title={userService.getFullName(approvedBy[0])}>
                {userService.getFullName(approvedBy[0])}
              </Text>
            )}
            {approvedBy.length > 1 && <UserAvatarGroup users={approvedBy || []} />}
          </>
        ),
      })),
    },
  ]

  return (
    <div className="min-w-[900px] max-w-[900px]">
      <ModalHeader heading="View history" />
      <ModalContent isScrollable>
        <Field label="Select variation time stamp" supplementary="Filter version history of the contract overview table">
          <Select_DEPRECATED
            value={selectedContractId}
            options={contractSelectOptions}
            onChange={(id) => setSelectedContractId(id)}
            isDisabled={isLoading}
            placeholder
          />
        </Field>
        <Hr className="my-4" />
        {!selectedContract && (
          <div className="min-h-[200px] flex items-center justify-center">
            <Text className="italic">Select a contract period to view changes</Text>
          </div>
        )}
        {selectedContract && (
          <>
            {!selectedContract?.priceDurations.length && !selectedContract?.milestones.length && (
              <NoDataPlaceholder illustration="dot-list" label="No contract durations" />
            )}
            {Boolean(selectedContract?.priceDurations.length || selectedContract?.milestones.length) && (
              <>
                <ScrollableTable fixedColumnsWidth={280} fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
                <TableHeader className="mt-6">
                  <Text className="font-semibold">Contract overview</Text>
                </TableHeader>
                {contractShell?.type === 'MILESTONE' && (
                  <ContractWizardMilestoneTable
                    milestones={selectedContract.milestones}
                    isVariation={Boolean(selectedContract.priceDurations.length)}
                    contractShellId={contractShellId}
                    currency={selectedContract?.metadata.currency}
                  />
                )}
                {contractShell?.type !== 'MILESTONE' && (
                  <ContractWizardPriceDurationTable
                    priceDurations={selectedContract.priceDurations}
                    isVariation={Boolean(selectedContract.priceDurations.length)}
                    contractShellId={contractShellId}
                    currency={selectedContract?.metadata.currency}
                  />
                )}
              </>
            )}
          </>
        )}
      </ModalContent>
    </div>
  )
})
