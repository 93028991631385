import { memo, useEffect, useMemo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { filter, find, map, some } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Table, TableColumn, TableRow } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { EvaluationEventEvaluatorAddModal } from '@cotiss/evaluation-event/modals/evaluation-event-evaluator-add.modal'
import { EvaluationEventPanelMemberRemoveConfirmModal } from '@cotiss/evaluation-event/modals/evaluation-event-panel-member-remove-confirm.modal'
import { EvaluationEventPanelMemberReplaceModal } from '@cotiss/evaluation-event/modals/evaluation-event-panel-member-replace.modal'
import { userService } from '@cotiss/user/user.service'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  onNext: () => void
}

export const EvaluationEventPanelUpdatePanelStep = memo(({ evaluationEnvelope, onNext }: Props) => {
  const { openModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { evaluationUsers, evaluationUserInSession } = useEvaluationUser()

  useEffect(() => {
    track('evaluation_event_panel_update_panel_view')
  }, [])

  const { columns, moderateEvaluationUser } = useMemo(() => {
    const accessControl = { resourceType: 'envelope', resourceId: evaluationEnvelope.id }
    const moderateEvaluationUser = find(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'moderate' }))
    const evaluateEvaluationUsers = filter(evaluationUsers, ({ accessControls }) => some(accessControls, { ...accessControl, access: 'evaluate' }))

    const userRows: TableRow[] = map(evaluateEvaluationUsers, (evaluationUser) => ({
      content: () => (
        <div className="flex items-center truncate">
          <Text className="truncate">{userService.getFullName(evaluationUser.user)}</Text>
          {evaluationUserInSession?.id === evaluationUser.id && (
            <Text className="ml-1" variant="light">
              (you)
            </Text>
          )}
        </div>
      ),
      // We can only change the evaluators if the envelope is in the locked or evaluate state.
      cta: (evaluationEnvelope.status === 'locked' || evaluationEnvelope.status === 'evaluate') && (
        <TableRowCta
          actions={[
            // You must have at least one evaluator per envelope. So you can only delete an evaluator if there is more than one evaluator.
            evaluateEvaluationUsers.length > 1
              ? {
                  label: 'Delete',
                  onClick: () => {
                    openModal(
                      <EvaluationEventPanelMemberRemoveConfirmModal evaluationUser={evaluationUser} evaluationEnvelope={evaluationEnvelope} />
                    )
                  },
                }
              : // Otherwise you can replace them.
                {
                  label: 'Replace',
                  onClick: () => {
                    openModal(
                      <EvaluationEventPanelMemberReplaceModal
                        evaluationUser={evaluationUser}
                        evaluationEnvelope={evaluationEnvelope}
                        access="evaluate"
                      />
                    )
                  },
                },
          ]}
        />
      ),
    }))

    const accessRows: TableRow[] = map(evaluateEvaluationUsers, () => ({
      content: () => (
        <Badge state="outline" variant="secondary">
          Evaluate
        </Badge>
      ),
    }))

    if (moderateEvaluationUser) {
      userRows.push({
        content: () => (
          <div className="flex items-center truncate">
            <Text className="truncate">{userService.getFullName(moderateEvaluationUser.user)}</Text>
            {evaluationUserInSession?.id === moderateEvaluationUser.id && (
              <Text className="ml-1" variant="light">
                (you)
              </Text>
            )}
          </div>
        ),
        cta: evaluationEnvelope.status !== 'complete' && (
          <TableRowCta
            actions={[
              {
                label: 'Replace',
                onClick: () => {
                  openModal(
                    <EvaluationEventPanelMemberReplaceModal
                      evaluationUser={moderateEvaluationUser}
                      evaluationEnvelope={evaluationEnvelope}
                      access="moderate"
                    />
                  )
                },
              },
            ]}
          />
        ),
      })

      accessRows.push({
        content: () => (
          <Badge state="outline" variant="warning">
            Moderate
          </Badge>
        ),
      })
    }

    const columns: TableColumn[] = [
      {
        heading: 'users',
        rows: userRows,
      },
      {
        heading: 'tasks',
        rows: accessRows,
      },
    ]

    return { columns, moderateEvaluationUser }
  }, [evaluationEnvelope, evaluationUsers, evaluationUserInSession])

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Evaluation panel
        </Text>

        <Button onClick={onNext} variant="secondary" size="sm">
          Continue
        </Button>
      </CardHeader>
      <TableHeader className="flex items-center justify-between">
        <div>
          <Text size="sm" variant="light">
            Envelope {evaluationEnvelope.order}
          </Text>
          <Text className="font-medium" size="lg">
            {evaluationEnvelope.name}
          </Text>
        </div>
        {/* We can only add evaluators if there is a moderation phase. Otherwise the envelope is only allowed to have one evaluator. We also only
            allow adding an evaluator if the envelope is in the locked or evaluate phase. Otherwise moderation has already started, and adding an evaluator
            isn't valid. */}
        {moderateEvaluationUser && (evaluationEnvelope.status === 'locked' || evaluationEnvelope.status === 'evaluate') && (
          <Button
            state="translucent"
            size="xs"
            variant="secondary-dark"
            onClick={() => openModal(<EvaluationEventEvaluatorAddModal evaluationEnvelope={evaluationEnvelope} />)}
          >
            + Add evaluator
          </Button>
        )}
      </TableHeader>
      <Table columns={columns} />
    </Card>
  )
})
