import { memo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { DocumentViewer } from '@cotiss/document/components/document-viewer.component'
import { DocumentModel } from '@cotiss/document/document.models'

type Props = {
  approvedDocument: DocumentModel
  signedDocument?: DocumentModel
}

type ContractSignedDocumentViewerTab = 'approved-contract' | 'signed-contract'
const CONTRACT_SIGNED_DOCUMENT_TABS: TabModel<ContractSignedDocumentViewerTab>[] = [
  { id: 'signed-contract', label: 'Signed contract' },
  { id: 'approved-contract', label: 'Approved contract' },
]

export const ContractSignedDocumentViewer = memo(({ approvedDocument, signedDocument }: Props) => {
  const { step, transition, onTransition } = useTransition()

  const handleTabChange = (tab: ContractSignedDocumentViewerTab) => {
    const newStep = findIndex(CONTRACT_SIGNED_DOCUMENT_TABS, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <div className="pt-6 h-full">
      {!signedDocument && (
        <div className="bg-gray-100 rounded-lg w-full p-6 h-full">
          <DocumentViewer document={approvedDocument} isDownloadable />
        </div>
      )}
      {signedDocument && (
        <div className="h-full flex flex-col">
          <div className="shrink-0">
            <Tabs<ContractSignedDocumentViewerTab>
              className="w-full"
              tabs={CONTRACT_SIGNED_DOCUMENT_TABS}
              onChange={({ id }) => handleTabChange(id)}
              variant="square"
            />
          </div>
          <div className="flex-1 overflow-hidden pt-6">
            <AnimatePresence initial={false} mode="wait">
              <TransitionContainer key={step} transition={transition} className="h-full">
                {step === 1 && (
                  <div className="bg-gray-100 rounded-lg w-full p-6 h-full">
                    <DocumentViewer document={signedDocument} isDownloadable />
                  </div>
                )}
                {step === 2 && (
                  <div className="bg-gray-100 rounded-lg w-full p-6 h-full">
                    <DocumentViewer document={approvedDocument} isDownloadable />
                  </div>
                )}
              </TransitionContainer>
            </AnimatePresence>
          </div>
        </div>
      )}
    </div>
  )
})
