import { createElement, memo, ReactNode } from 'react'

type Props = {
  text: string
  highlightText?: string
  highlightClassName?: string
  caseSensitive?: boolean
  highlightTag?: string
  className?: string
}

const HighlightedText = memo(
  ({
    text = '',
    highlightText = '',
    highlightClassName = 'bg-secondary-200',
    caseSensitive = false,
    highlightTag: HighlightTag = 'mark',
    className,
  }: Props) => {
    if (!text) {
      return null
    }
    if (!highlightText) {
      return <span className={className}>{text}</span>
    }

    const findOccurrences = (haystack: string, needle: string) => {
      if (!caseSensitive) {
        haystack = haystack.toUpperCase()
        needle = needle.toUpperCase()
      }
      const indexes: number[] = []
      let index = 0
      if (!haystack || !needle || haystack.length === 0 || needle.length === 0) {
        return indexes
      }
      while (index < haystack.length) {
        index = haystack.indexOf(needle, index)
        if (index === -1) {
          break
        }
        indexes.push(index)
        index += 1
      }
      return indexes
    }

    const buildChunks = (occurrences: number[]) => {
      let textIndex = 0
      let occurrencesIndex = 0
      const result: ReactNode[] = []
      while (textIndex < text.length && occurrencesIndex < occurrences.length) {
        result.push(<span key={`plain${occurrencesIndex}`}>{text.substring(textIndex, occurrences[occurrencesIndex])}</span>)
        result.push(
          createElement(
            HighlightTag,
            { className: `${highlightClassName}`, key: `highlighted${occurrencesIndex}` },
            text.substring(occurrences[occurrencesIndex], occurrences[occurrencesIndex] + highlightText.length)
          )
        )
        textIndex = occurrences[occurrencesIndex] + highlightText.length
        occurrencesIndex += 1
      }
      if (textIndex < text.length) {
        result.push(<span key="plain-ending">{text.substring(textIndex, text.length)}</span>)
      }
      return result
    }

    const occurrences = findOccurrences(text, highlightText)
    if (occurrences.length === 0) {
      return <span className={className}>{text}</span>
    }
    const chunks = buildChunks(occurrences)
    return <span className={className}>{chunks}</span>
  }
)

export default HighlightedText
