import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, findIndex, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Breadcrumb } from '@cotiss/common/components/breadcrumb.component'
import { Button } from '@cotiss/common/components/button.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Section } from '@cotiss/common/components/section.component'
import { StepModel, Steps } from '@cotiss/common/components/steps.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationEventStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-status-badge.component'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { EvaluationEventWizardCriteriaStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-criteria.step'
import { EvaluationEventWizardEnvelopesStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-envelopes.step'
import { EvaluationEventWizardGeneralStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-general.step'
import { EvaluationEventWizardPanelStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-panel.step'
import { EvaluationEventWizardRatingScaleStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-rating-scale.step'
import { EvaluationEventWizardScoringStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-scoring.step'
import { EvaluationEventWizardSubWeightingStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-sub-weighting.step'
import { EvaluationEventWizardSummaryStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-summary.step'
import { EvaluationEventWizardWeightingStep } from '@cotiss/evaluation-event/steps/evaluation-event-wizard-weighting.step'

type Step = 'general' | 'envelopes' | 'criteria' | 'weight' | 'rating-scale' | 'sub-weight' | 'panel' | 'scoring' | 'summary'

export const EvaluationEventWizardFullModal = memo(() => {
  const { push } = useHistory()
  const { closeFullModal } = useCallout()
  const [stepIndex, setStepIndex] = useState(0)
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const [transition, setTransition] = useState<TransitionType>('right')

  const handleClose = () => {
    closeFullModal()

    if (!evaluationEvent?.isSetupComplete) {
      push(routerService.getHref('/evaluation-event/list/:tab?'))
    }
  }

  useEffect(() => {
    track('evaluation_event_wizard_view')
  }, [])

  useEffect(() => {
    if (!evaluationEvent) {
      handleClose()
    }
  }, [evaluationEvent])

  const steps = useMemo(() => {
    const isWeighted = evaluationEvent?.methodology === 'weightedAttribute'
    const hasNestedCriteria = Boolean(evaluationCriteria.length && some(evaluationCriteria, { parentEvaluationCriteriaId: null, isScored: false }))

    const steps: (StepModel<Step> & { isHidden?: boolean })[] = [
      {
        id: 'general',
        label: 'General',
      },
      {
        id: 'envelopes',
        label: 'Envelopes',
      },
      {
        id: 'criteria',
        label: 'Criteria',
      },
      {
        id: 'weight',
        label: 'Weight',
        // Only show weighting step if the evaluation is weighted is enabled.
        isHidden: !isWeighted,
      },
      {
        id: 'rating-scale',
        label: 'Rating scale',
      },
      {
        id: 'sub-weight',
        label: 'Sub-weight',
        // Only show the sub-weighting step if one of the parent criteria has sub-criteria and the evaluation is weighted is enabled.
        isHidden: !hasNestedCriteria || !isWeighted,
      },
      {
        id: 'panel',
        label: 'Panel',
      },
      {
        id: 'scoring',
        label: 'Scoring access',
      },
      {
        id: 'summary',
        label: 'Summary',
      },
    ]

    return filter(steps, (step) => !step.isHidden)
  }, [evaluationEvent, evaluationEnvelopes, evaluationCriteria])

  if (!evaluationEvent) {
    return null
  }

  const handleStepChange = (newStep: StepModel<Step>) => {
    const oldStepIndex = findIndex(steps, ({ id }) => id === steps[stepIndex].id)
    const newStepIndex = findIndex(steps, ({ id }) => id === newStep.id)

    setTransition(newStepIndex > oldStepIndex ? 'right' : 'left')
    setTimeout(() => setStepIndex(newStepIndex), 0)
  }

  const handleNext = () => {
    const newStepIndex = findIndex(steps, ({ id }) => id === steps[stepIndex].id) + 1

    steps[newStepIndex] && handleStepChange(steps[newStepIndex])
  }

  const handleBack = () => {
    const newStepIndex = findIndex(steps, ({ id }) => id === steps[stepIndex].id) - 1

    newStepIndex >= 0 && handleStepChange(steps[newStepIndex])
  }

  const props = {
    onBack: handleBack,
    onNext: handleNext,
  }

  // This will only ever happen momentarily, usually just before the modal closes
  if (!steps[stepIndex]?.id) {
    return null
  }

  return (
    <div className="bg-primary-50 w-full h-full min-h-screen overflow-y-scroll">
      <Header>
        <Section isCentered>
          <div className="flex items-center justify-between">
            <div className="w-full">
              <Breadcrumb breadcrumbs={[{ label: 'Evaluate', onClick: handleClose }, { label: 'Create evaluation' }]} />
              <div className="flex items-center">
                <Text className="font-semibold mr-2" size="h4" variant="heading">
                  Create evaluation
                </Text>
                <EvaluationEventStatusBadge status={evaluationEvent.status} size="sm" />
              </div>
            </div>
            <Button className="ml-2" onClick={handleClose} state="ghost" variant="link" size="sm">
              Save and exit
            </Button>
          </div>
        </Section>
      </Header>
      <PageContent>
        <Section isCentered>
          <Steps<Step> className="mb-8" steps={steps} step={steps[stepIndex]} onChange={handleStepChange} />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={steps[stepIndex].id} transition={transition}>
              {steps[stepIndex].id === 'general' && <EvaluationEventWizardGeneralStep {...props} />}
              {steps[stepIndex].id === 'envelopes' && <EvaluationEventWizardEnvelopesStep {...props} />}
              {steps[stepIndex].id === 'criteria' && <EvaluationEventWizardCriteriaStep {...props} />}
              {steps[stepIndex].id === 'weight' && <EvaluationEventWizardWeightingStep {...props} />}
              {steps[stepIndex].id === 'rating-scale' && <EvaluationEventWizardRatingScaleStep {...props} />}
              {steps[stepIndex].id === 'sub-weight' && <EvaluationEventWizardSubWeightingStep {...props} />}
              {steps[stepIndex].id === 'panel' && <EvaluationEventWizardPanelStep {...props} />}
              {steps[stepIndex].id === 'scoring' && <EvaluationEventWizardScoringStep {...props} />}
              {steps[stepIndex].id === 'summary' && <EvaluationEventWizardSummaryStep {...props} />}
            </TransitionContainer>
          </AnimatePresence>
        </Section>
      </PageContent>
    </div>
  )
})
