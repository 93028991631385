import { memo, useMemo } from 'react'
import { GqlPerformanceScorecardMetricCycleScoreFieldsFragment } from '@gql'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BadgeState } from '@cotiss/common/components/badge.component'

type Props = {
  className?: string
  performanceScorecardMetricCycleScore?: GqlPerformanceScorecardMetricCycleScoreFieldsFragment
  size?: BadgeSize
  state?: BadgeState
}

export const PerformanceScorecardMetricCycleScoreStatusBadge = memo((props: Props) => {
  const { className, performanceScorecardMetricCycleScore, size = 'sm', state = 'translucent' } = props

  const { label, variant } = useMemo(() => {
    if (!performanceScorecardMetricCycleScore) {
      return { label: 'Not started', variant: 'neutral' } as const
    }

    if (performanceScorecardMetricCycleScore.status === 'complete') {
      return { label: 'Completed', variant: 'success' } as const
    }

    return { label: 'In progress', variant: 'warning' } as const
  }, [performanceScorecardMetricCycleScore])

  return (
    <Badge className={className} size={size} variant={variant} state={state}>
      {upperFirst(lowerCase(label))}
    </Badge>
  )
})
