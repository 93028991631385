import { memo, useEffect, useState } from 'react'
import { GqlEvaluationFieldsFragment } from '@gql'
import { Button } from '@cotiss/common/components/button.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'

type Props = {
  evaluation: GqlEvaluationFieldsFragment
}

export const EvaluationEventEvaluationCommentDrawer = memo(({ evaluation }: Props) => {
  const { openToast } = useToast()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const { mutateUpdateEvaluation } = useEvaluation()
  const [isEditing, setIsEditing] = useState(!evaluation.comment)
  const [comment, setComment] = useState(evaluation.comment || '')

  useEffect(() => {
    track('evaluation_event_evaluate_view_comment_drawer_view')
  }, [])

  const handleSubmit = async () => {
    if (!evaluationEvent) {
      return
    }

    track('evaluation_event_evaluate_view_comment_submit')

    try {
      setIsSaving(true)
      await mutateUpdateEvaluation({ evaluationId: evaluation.id, comment })
      openToast('Evaluation note saved')
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Add note
    </Text>
  )

  const renderFooter = () => (
    <>
      {isEditing && (
        <Button className="mr-2" type="submit" variant="secondary" isLoading={isSaving}>
          Save
        </Button>
      )}
      {!isEditing && (
        <Button className="mr-2" onClick={() => setIsEditing(true)} variant="secondary" state="translucent" isLoading={isSaving}>
          Edit
        </Button>
      )}
    </>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit} isNarrow>
      {isEditing && (
        <TextArea
          className="h-full"
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          placeholder="Add note"
          isDisabled={isSaving}
          autoFocus
        />
      )}
      {!isEditing && <div className="border border-grey-200 whitespace-pre-wrap rounded-xl h-full p-4">{comment}</div>}
    </Drawer>
  )
})
