import { memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { SupplierViewDrawer } from '@cotiss/supplier/drawers/supplier-view.drawer'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  organisationId: string
  tenderResponse: TenderResponsePopulatedModel
  onView?: () => void
  isScoringEnabled?: boolean
}

export const EvaluationListItemCta = memo(({ organisationId, tenderResponse, onView, isScoringEnabled }: Props) => {
  const { openNarrowDrawer } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <>
      <div className="flex items-center">
        <Button
          className="mr-2"
          onClick={() => setIsDropdownOpen(true)}
          shape="square"
          state="translucent"
          variant="secondary-dark"
          size="xs"
          isRounded
        >
          <Icon icon="dots" variant="secondary" />
        </Button>
        <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
          <DropdownContent
            className="flex items-center"
            onClick={() =>
              openNarrowDrawer(
                <SupplierViewDrawer
                  organisationId={organisationId}
                  procurementResponseId={tenderResponse.procurementResponse._id}
                  tenderResponseId={tenderResponse._id}
                />
              )
            }
          >
            View Supplier
          </DropdownContent>
        </Dropdown>
        <Button onClick={onView} state="outline" variant="secondary" size="xs" isLink isDisabled={!onView}>
          {isScoringEnabled ? 'Score' : 'View'} <Icon className="ml-1" icon="arrow-right" />
        </Button>
      </div>
    </>
  )
})
