import { FormEvent, memo, useEffect, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { PREFERENCES_EMAIL_NOTIFICATION_PERIOD_TYPE_OPTIONS } from '@cotiss/preferences/preferences.constants'
import { PreferencesEmailNotificationPeriod } from '@cotiss/preferences/preferences.models'
import { useGetPreferences } from '@cotiss/preferences/resources/use-get-preferences.resource'
import { useMutatePreferences } from '@cotiss/preferences/resources/use-mutate-preferences.resource'

type FormData = {
  emailNotificationPeriod?: PreferencesEmailNotificationPeriod
}

export const SettingsPreferencesFormEmailNotificationPeriod = memo(() => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { updatePreferences } = useMutatePreferences()
  const { preferences, isLoading } = useGetPreferences()
  const [formData, setFormData] = useState<FormData>({ emailNotificationPeriod: preferences?.emailNotificationPeriod })

  useEffect(() => {
    if (preferences) {
      setFormData({ emailNotificationPeriod: preferences.emailNotificationPeriod })
    }
  }, [preferences])

  const handleReset = () => {
    setFormData({ emailNotificationPeriod: preferences?.emailNotificationPeriod })
    setIsEditing(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updatePreferences(formData)
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>How often would you like to be emailed of unread notifications?</Label>
        <Select_DEPRECATED<PreferencesEmailNotificationPeriod>
          className="mb-4"
          value={formData.emailNotificationPeriod}
          options={PREFERENCES_EMAIL_NOTIFICATION_PERIOD_TYPE_OPTIONS}
          onChange={(emailNotificationPeriod) => setFormData({ ...formData, emailNotificationPeriod })}
          isDisabled={isSaving}
          placeholder
          isRequired
        />

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">How often would you like to be emailed of unread notifications?</Text>
        {isLoading && <Skeleton className="h-2 w-12 mt-2" variant="gray" />}
        {!isLoading && <Text>{preferences?.emailNotificationPeriod || '–'}</Text>}
      </div>

      <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm" isDisabled={isLoading}>
        Update
      </Button>
    </div>
  )
})
