import { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { usePerformanceScorecardMetricUser } from '@cotiss/performance/hooks/use-performance-scorecard-metric-user.hook'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { PerformanceScorecardEvaluatorUpdateMetricsStep } from '@cotiss/performance/steps/performance-scorecard-evaluator-update-metrics.step'
import { PerformanceScorecardEvaluatorViewMetricsStep } from '@cotiss/performance/steps/performance-scorecard-evaluator-view-metrics.step'

type Props = {
  performanceScorecardId: string
  performanceScorecardUserId: string
  onSubmit: () => Promise<void>
}

export const PerformanceScorecardEvaluatorViewUpdateDrawer = memo(({ performanceScorecardId, performanceScorecardUserId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, onTransition } = useTransition()
  const { queryPerformanceScorecardUserView } = usePerformanceScorecardUser()
  const [performanceScorecardMetricIds, setPerformanceScorecardMetricIds] = useState<string[]>([])
  const { queryPerformanceScorecardMetricUserList, mutateCreatePerformanceScorecardMetricUsers } = usePerformanceScorecardMetricUser()

  useAsyncEffect(async () => {
    try {
      await Promise.all([
        queryPerformanceScorecardMetricUserList({ filter: { performanceScorecardId, performanceScorecardUserId } }),
        queryPerformanceScorecardUserView({ performanceScorecardUserId }),
      ])
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [performanceScorecardUserId, performanceScorecardId])

  const handleSubmit = async () => {
    if (step === 1) {
      return onTransition({ step: 2 })
    }

    setIsSaving(true)

    try {
      await mutateCreatePerformanceScorecardMetricUsers({
        performanceScorecardId,
        performanceScorecardUserIds: [performanceScorecardUserId],
        performanceScorecardMetricIds,
      })
      await Promise.all([queryPerformanceScorecardMetricUserList({ filter: { performanceScorecardId, performanceScorecardUserId } }), onSubmit()])
      setPerformanceScorecardMetricIds([])
      onTransition({ step: 1, transition: 'left' })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
  }

  const handleBack = () => {
    setPerformanceScorecardMetricIds([])
    onTransition({ step: 1, transition: 'left' })
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      View evaluator
    </Text>
  )

  const renderFooter = () => {
    if (step === 2) {
      return (
        <div className="flex items-center">
          <Button type="submit" variant="secondary" isDisabled={!performanceScorecardMetricIds.length} isLoading={isSaving}>
            Save
          </Button>
          <Text className="ml-2">{performanceScorecardMetricIds.length} selected</Text>
        </div>
      )
    }
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <PerformanceScorecardEvaluatorViewMetricsStep
              performanceScorecardId={performanceScorecardId}
              performanceScorecardUserId={performanceScorecardUserId}
            />
          )}
          {step === 2 && (
            <PerformanceScorecardEvaluatorUpdateMetricsStep
              performanceScorecardId={performanceScorecardId}
              performanceScorecardUserId={performanceScorecardUserId}
              performanceScorecardMetricIds={performanceScorecardMetricIds}
              setPerformanceScorecardMetricIds={setPerformanceScorecardMetricIds}
              onBack={handleBack}
              isDisabled={isSaving}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
