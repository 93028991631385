import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { ProcurementResponseListPage } from '@cotiss/procurement-response/pages/procurement-response-list.page'
import { ProcurementResponseViewProcurementResponseTeamPage } from '@cotiss/procurement-response/pages/procurement-response-view-procurement-response-team.page'
import { ProcurementResponseViewPage } from '@cotiss/procurement-response/pages/procurement-response-view.page'

const ProcurementResponseLayout = memo(() => (
  <Switch>
    <Route path="/procurement-response/list" exact>
      <ProcurementResponseListPage />
    </Route>
    <Route path="/procurement-response/view/:procurementResponseId/team" exact>
      <ProcurementResponseViewProcurementResponseTeamPage />
    </Route>
    <Route path="/procurement-response/view/:procurementResponseId/:tab?/:nestedTab?" exact>
      <ProcurementResponseViewPage />
    </Route>
    <Redirect from="/procurement-response" to="/procurement-response/list" exact />
  </Switch>
))

export default ProcurementResponseLayout
