import { FormEvent, useState, memo, useEffect } from 'react'
import CotissLogoDarkSvg from '@assets/svg/cotiss-logo-dark.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { CotissSquares } from '@cotiss/common/components/cotiss-squares.component'
import { Form } from '@cotiss/common/components/form.component'
import { Page } from '@cotiss/common/components/page.component'
import { routerService } from '@cotiss/common/services/router.service'
import { AccountEmployeeNumberRange, AccountTenderExperience } from '@cotiss/account/account.models'
import { useMutateAccount } from '@cotiss/account/resources/use-mutate-account.resource'
import { SignUpBuyerStepAccount } from '@cotiss/auth/components/sign-up-buyer-step-account.component'
import { SignUpBuyerStepCompany } from '@cotiss/auth/components/sign-up-buyer-step-company.component'
import { SignUpBuyerStepPassword } from '@cotiss/auth/components/sign-up-buyer-step-password.component'
import { SignUpBuyerStepUser } from '@cotiss/auth/components/sign-up-buyer-step-user.component'
import { authService } from '@cotiss/auth/auth.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { Text } from '@cotiss/common/components/text.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { Icon } from '@cotiss/common/components/icon.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { localStorageService } from '@cotiss/common/services/local-storage.service'

export type SignUpBuyerFormData = {
  email: string
  firstname: string
  surname: string
  phone: string
  companyName: string
  location: {
    country?: string
    address: string
    city: string
    region?: string
    postcode: string
  }
  regions: string[]
  employeeNoRange?: AccountEmployeeNumberRange
  experience?: AccountTenderExperience
  password: string
  passwordConfirm: string
}

export const SignUpBuyerPage = memo(() => {
  const { push } = useHistory()
  const { login, logout } = useAuth()
  const { createAccount } = useMutateAccount()
  const [formError, setFormError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const [formData, setFormData] = useState<SignUpBuyerFormData>({
    email: '',
    firstname: '',
    surname: '',
    phone: '',
    companyName: '',
    location: {
      address: '',
      city: '',
      postcode: '',
    },
    regions: [],
    password: '',
    passwordConfirm: '',
  })

  useEffect(() => {
    logout()
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (step < 4) {
      onTransition({ step: step + 1 })
      return
    }

    setFormError('')

    if (formData.password !== formData.passwordConfirm) {
      setFormError('Passwords do not match')
      return
    }

    if (!authService.isPasswordValid(formData.password)) {
      setFormError('Password must be at least 8 characters long and contain at least one letter and one number')
      return
    }

    try {
      setIsSaving(true)
      await createAccount({
        firstname: formData.firstname,
        surname: formData.surname,
        email: formData.email,
        password: formData.password,
        phone: formData.phone,
        name: formData.companyName,
        location: {
          country: formData.location.country,
          address: formData.location.address,
          city: formData.location.city,
          region: formData.location.region,
          postcode: formData.location.postcode,
        },
        regions: formData.regions,
        employeeNoRange: formData.employeeNoRange,
        experience: formData.experience,
        productBuyer: 'BuyerEssentials',
      })

      await login(formData.email, formData.password)
      push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setFormError(error.message)
      setIsSaving(false)
    }
  }

  const stepProps = {
    formData,
    transition,
    setFormData,
    isDisabled: isTransitioning || isSaving,
  }

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className="relative bg-white w-5/12 h-full p-4 pr-10">
          <div className="bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <h1 className="text-white text-5xl leading-tight mt-48">
              Better decisions.
              <br />
              Simplified process.
            </h1>
          </div>
        </div>
        <div className="bg-white w-7/12 h-full p-12">
          <Button
            className={step === 1 ? 'invisible' : ''}
            onClick={() => onTransition({ step: step - 1, transition: 'left' })}
            state="ghost"
            variant="link"
          >
            <Icon icon="chevron-left" />
            Back
          </Button>

          <div className="flex items-center justify-center w-full h-full">
            <div className="w-full max-w-[350px]">
              <div className="flex items-center">
                <CotissSquares className="mr-2" size="sm" variant="secondary" />
                <CotissLogoDarkSvg className="h-3 mr-1" />
                <Text size="h7">for buyers</Text>
              </div>
              <Text className="font-semibold mt-2" size="h3">
                Sign up
              </Text>
              <Text className="mt-2" variant="light">
                Sign up to Cotiss for buyers.
              </Text>
              {Boolean(formError) && (
                <Text className="text-sm my-4" variant="danger">
                  {formError}
                </Text>
              )}
              <Form className="mt-8" onSubmit={handleSubmit} isHubSpot>
                <AnimatePresence mode="wait" initial={false}>
                  {step === 1 && <SignUpBuyerStepUser {...stepProps} />}
                  {step === 2 && <SignUpBuyerStepCompany {...stepProps} />}
                  {step === 3 && <SignUpBuyerStepAccount {...stepProps} />}
                  {step === 4 && <SignUpBuyerStepPassword {...stepProps} />}
                </AnimatePresence>

                <div className="bg-secondary-100 text-center rounded py-2 px-4 mt-4">
                  <Text className="mr-1" isInline>
                    Already have an account?
                  </Text>
                  <Button
                    className="inline-block text-sm"
                    state="text"
                    variant="link"
                    href={routerService.getHref('/login')}
                    isDisabled={isSaving}
                    isLink
                  >
                    Log in
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
})
