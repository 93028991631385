import { memo, useMemo, useState } from 'react'
import { filter, includes, map, some } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { RemainingTasksButton } from '@cotiss/common/components/remaining-tasks-button.component'
import { Text } from '@cotiss/common/components/text.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { priceItemResponseService } from '@cotiss/price-item-response/price-item-response.service'
import { useListPriceItemResponse } from '@cotiss/price-item-response/resources/use-list-price-item-response.resource'
import { TenderPriceTable } from '@cotiss/tender/components/tender-price-table.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { tenderService } from '@cotiss/tender/tender.service'
import { TenderResponsePriceTable } from '@cotiss/tender-response/components/tender-response-price-table.component'
import { TenderResponseDraftConfirmModal } from '@cotiss/tender-response/modals/tender-response-draft-confirm.modal'
import { TenderResponseNonPriceDocumentUploadModal } from '@cotiss/tender-response/modals/tender-response-non-price-document-upload.modal'
import { TenderResponsePriceDocumentUploadModal } from '@cotiss/tender-response/modals/tender-response-price-document-upload.modal'
import { TenderResponseSubmitConfirmModal } from '@cotiss/tender-response/modals/tender-response-submit-confirm.modal'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  tenderId?: string
  tenderResponse?: TenderResponsePopulatedModel
}

// TODO: Add loading state.

export const ProcurementViewTenderResponseTab = memo(({ tenderId, tenderResponse }: Props) => {
  const { openToast } = useToast()
  const { track } = useAnalytics()
  const { openModal } = useCallout()

  const [isSaving, setIsSaving] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { updateTenderResponse } = useMutateTenderResponse()
  const { priceItemResponses } = useListPriceItemResponse({ tenderResponseId: tenderResponse?._id, limit: 0, offset: 0 })
  const { tender } = useGetTender(tenderId)
  const isTenderClosed = Boolean(tender && tenderService.isTenderClosed(tender))
  const isEditable = tenderResponse?.status === 'drafting' && !isTenderClosed

  const remainingSubmissionTasks = useMemo(() => {
    const remainingTasks: { name: string; reason: string }[] = []

    if (tenderResponse?.nonPriceAttachments.length === 0) {
      remainingTasks.push({ name: 'remaining Tasks', reason: 'Upload non-price forms' })
    }

    if (!tender?.priceTableEnabled) {
      return remainingTasks
    }

    if (some(priceItemResponses, (item) => !priceItemResponseService.isComplete({ priceItemResponse: item, tender }))) {
      remainingTasks.push({ name: 'remaining Tasks', reason: 'Complete line items' })
    }

    return remainingTasks
  }, [tenderResponse, priceItemResponses, tender])

  const isSubmitResponseDisabled = useMemo(() => {
    // Tender must be open
    if (isTenderClosed || !tenderResponse || !tender || remainingSubmissionTasks.length > 0) {
      return true
    }

    return false
  }, [isTenderClosed, tenderResponse, tender, remainingSubmissionTasks])

  const handlePriceBulkRemove = async (documentIdsToRemove: string[]) => {
    if (!tenderResponse) {
      return
    }

    try {
      setIsSaving(true)

      const priceAttachments = map(
        filter(tenderResponse.priceAttachments, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )

      await updateTenderResponse(tenderResponse._id, { priceAttachments })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleNonPriceBulkRemove = async (documentIdsToRemove: string[]) => {
    if (!tenderResponse) {
      return
    }

    try {
      setIsSaving(true)

      const nonPriceAttachments = map(
        filter(tenderResponse.nonPriceAttachments, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )

      await updateTenderResponse(tenderResponse._id, { nonPriceAttachments })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow w-full p-6">
        <div className="flex items-start justify-between mb-2">
          <div>
            <Text className="font-medium" size="h7">
              Upload response forms
            </Text>
            <Text className="mt-1" size="sm" variant="light">
              Upload the completed forms into the relevant buckets, then submit your response. Please ensure your response is submitted before the
              close date.
            </Text>
            <Text className="mt-1 italic" size="sm" variant="light">
              Please note that submitted files must be less than 256MB
            </Text>
          </div>
          <div className="flex flex-shrink-0 items-center relative ml-4">
            {(tenderResponse?.status === 'drafting' || tenderResponse?.status === 'submitted') && (
              <>
                <Button
                  className="mr-4"
                  onClick={() => setIsDropdownOpen(true)}
                  shape="square"
                  state="ghost"
                  size="xs"
                  isRounded
                  isDisabled={isTenderClosed || isSaving}
                >
                  <Icon icon="dots" variant="light" />
                </Button>
                <Dropdown className="top-1/2 left-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                  <DropdownContent
                    className="flex items-center"
                    onClick={() =>
                      openModal(
                        <ConfirmModal
                          heading="Withdraw your submission"
                          description="Are you sure you want to withdraw your submission?"
                          onSubmit={() => updateTenderResponse(tenderResponse._id, { status: 'withdrawn' })}
                        />
                      )
                    }
                    isDisabled={isTenderClosed || isSaving}
                  >
                    Withdraw
                  </DropdownContent>
                </Dropdown>
              </>
            )}
            {tenderResponse?.status === 'drafting' && (
              <Button
                onClick={() => {
                  track('procurement_response_event_submit_response_submit')
                  openModal(<TenderResponseSubmitConfirmModal tenderResponse={tenderResponse} />)
                }}
                variant="primary"
                size="sm"
                isDisabled={isSubmitResponseDisabled || isSaving}
              >
                Submit response
              </Button>
            )}
            {tenderResponse?.status === 'submitted' && (
              <Button
                onClick={() => openModal(<TenderResponseDraftConfirmModal tenderResponse={tenderResponse} />)}
                state="outline"
                variant="secondary"
                size="sm"
                isDisabled={isTenderClosed || isSaving}
              >
                Edit response
              </Button>
            )}
            {tenderResponse?.status === 'withdrawn' && (
              <Button
                onClick={() =>
                  openModal(
                    <ConfirmModal
                      heading="Resume your submission"
                      description="Are you sure you want to resume your submission? Your submission will go back to 'drafting' status."
                      onSubmit={() => updateTenderResponse(tenderResponse._id, { status: 'drafting' })}
                    />
                  )
                }
                state="outline"
                variant="secondary"
                size="sm"
                isDisabled={isTenderClosed || isSaving}
              >
                Resume response
              </Button>
            )}
          </div>
        </div>

        <Hr className="mt-4" />

        {!isTenderClosed && (
          <Banner className="mt-6" icon="edit-05" variant="secondary">
            <div className="mr-6">
              <Text className="font-semibold" variant="heading">
                Complete your response before submission
              </Text>
              <Text size="sm">You will be able to resubmit before the close date</Text>
            </div>
            <div className="flex items-center justify-center shrink-0">
              <RemainingTasksButton errors={remainingSubmissionTasks} />
            </div>
          </Banner>
        )}

        {!tenderResponse?.nonPriceAttachments.length && (
          <>
            <Text className="mt-6">Non-price forms</Text>
            <NoDataPlaceholder
              label="You have not uploaded any documents yet"
              ctaLabel="+ Upload documents"
              onCtaClick={isEditable ? () => openModal(<TenderResponseNonPriceDocumentUploadModal tenderResponse={tenderResponse} />) : undefined}
            />
          </>
        )}
        {Boolean(tenderResponse?.nonPriceAttachments.length) && (
          <DocumentList
            className="mt-6"
            title="Non-price forms"
            documents={tenderResponse?.nonPriceAttachments}
            onUpload={isEditable ? () => openModal(<TenderResponseNonPriceDocumentUploadModal tenderResponse={tenderResponse} />) : undefined}
            onBulkRemove={isEditable ? handleNonPriceBulkRemove : undefined}
            isDisabled={isSaving}
          />
        )}
        {!tenderResponse?.priceAttachments.length && (
          <>
            <Text className="mt-6">Price forms</Text>
            <NoDataPlaceholder
              label="You have not uploaded any documents yet"
              ctaLabel="+ Upload documents"
              onCtaClick={isEditable ? () => openModal(<TenderResponsePriceDocumentUploadModal tenderResponse={tenderResponse} />) : undefined}
            />
          </>
        )}
        {Boolean(tenderResponse?.priceAttachments.length) && (
          <DocumentList
            className="mt-6"
            title="Price forms"
            documents={tenderResponse?.priceAttachments}
            onUpload={isEditable ? () => openModal(<TenderResponsePriceDocumentUploadModal tenderResponse={tenderResponse} />) : undefined}
            onBulkRemove={isEditable ? handlePriceBulkRemove : undefined}
            isDisabled={isSaving}
          />
        )}
        {tenderResponse?.tender?.priceTableEnabled && <TenderResponsePriceTable tenderResponseId={tenderResponse._id} isEditable={isEditable} />}
        {tenderResponse?.tender?.priceTableEnabled === undefined && tender?.priceTableEnabled && (
          <TenderPriceTable tenderId={tenderId} /> // Show the price table if the tender has price table enabled but the response hasn't been created yet.
        )}
      </div>
    </>
  )
})
