import { ReactNode, HTMLProps, memo } from 'react'
import classNames from 'classnames'
import { TrVariant_DEPRECATED, Tr_DEPRECATED } from '@cotiss/common/components/deprecated/tr.component'

type Props = HTMLProps<HTMLTableSectionElement> & {
  children: ReactNode
  className?: string
  variant?: TrVariant_DEPRECATED
}

export const Thead_DEPRECATED = memo(({ className, children, variant = 'default', ...rest }: Props) => {
  const classes = classNames(className, 'divide-y')

  return (
    <thead {...rest} className={classes}>
      <Tr_DEPRECATED variant={variant}>{children}</Tr_DEPRECATED>
    </thead>
  )
})
