import { memo, useMemo } from 'react'
import { GqlPerformanceScorecardMetricCycleFieldsFragment } from '@gql'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BadgeState } from '@cotiss/common/components/badge.component'
import { performanceService } from '@cotiss/performance/performance.service'

type Props = {
  className?: string
  performanceScorecardMetricCycle: GqlPerformanceScorecardMetricCycleFieldsFragment
  size?: BadgeSize
  state?: BadgeState
}

export const PerformanceScorecardMetricCycleStatusBadge = memo((props: Props) => {
  const { className, performanceScorecardMetricCycle, size = 'sm', state = 'translucent' } = props

  const { label, variant } = useMemo(() => {
    const status = performanceService.getPerformanceMetricCycleStatus(performanceScorecardMetricCycle)

    if (status === 'complete') {
      return { label: 'Completed', variant: 'success' } as const
    }

    if (status === 'overdue') {
      return { label: 'Overdue', variant: 'danger' } as const
    }

    return { label: 'Due now', variant: 'warning' } as const
  }, [performanceScorecardMetricCycle])

  return (
    <Badge className={className} size={size} variant={variant} state={state}>
      {upperFirst(lowerCase(label))}
    </Badge>
  )
})
