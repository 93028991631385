import { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { Icon, IconType } from '@cotiss/common/components/icon.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

type Props = {
  className?: string
  children: ReactNode
  variant: BaseVariant | 'light'
  state?: 'default' | 'raw'
  icon?: IconType
  iconVariant?: BaseVariant | 'light'
}

export const Banner = memo(({ className, children, variant, state = 'default', icon, iconVariant = variant }: Props) => {
  const classes = classNames(className, 'rounded-lg w-full', {
    'flex items-center': icon,
    'bg-primary-200': variant === 'primary',
    'bg-secondary-200': variant === 'secondary',
    'bg-green-200': variant === 'success',
    'bg-pink-200': variant === 'danger',
    'bg-orange-200': variant === 'warning',
    'bg-blue-200': variant === 'info',
    'bg-gray-200': variant === 'neutral',
    'bg-primary-50': variant === 'light',
    'py-4 px-6': state === 'default',
  })

  const renderContent = () => {
    if (icon) {
      return (
        <>
          <div className="flex items-center justify-center bg-white rounded-md w-9 h-9 mr-4">
            <Icon icon={icon} variant={iconVariant} size={20} />
          </div>
          <div className="flex items-center justify-between w-full">{children}</div>
        </>
      )
    }

    return <>{children}</>
  }

  return <div className={classes}>{renderContent()}</div>
})
