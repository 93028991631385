import { Filter } from '@cotiss/common/models/filter.model'
import { useContractSearchAndFiltersContext } from '@cotiss/contract/components/contract-search-and-filters-context.component'
import { CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS, CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS } from '@cotiss/contract/contract.constants'
import { useContractFilterTotals } from '@cotiss/contract/hooks/use-contract-filter-totals.hook'
import { useMemo } from 'react'

/* This util transforms the CONTRACT_LIST_STATUS_CARD_FILTERS into the format required by the B/E
1. It removes the additional keys from the CONTRACT_LIST_STATUS_CARD_FILTERS (e.g. iconFill, iconStroke) etc. which are used only by the F/E
2. It spreads the CONTRACT_LIST_PRIMARY_TAB_FILTERS for each item as each filter must include the filters from the currently active primary tab
*/
const transformStatusCardFilters = (statusCardFilters: Record<string, { filters: Filter[] }>, activePrimaryTabFilters: Filter[]) => {
  return Object.entries(statusCardFilters).reduce((acc, [key, value]) => {
    return {
      ...acc,
      [key]: {
        filters: [...(value?.filters || []), ...activePrimaryTabFilters],
      },
    }
  }, {})
}

export const useContractStatusCardFilterTotals = () => {
  const { queryState } = useContractSearchAndFiltersContext()
  const transformedStatusCardFilters = useMemo(() => {
    const activePrimaryTabFilters = CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS[queryState.primaryTab]?.filters || []
    return transformStatusCardFilters(CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS, activePrimaryTabFilters)
  }, [queryState.primaryTab])

  return useContractFilterTotals({ totalFilters: transformedStatusCardFilters })
}
