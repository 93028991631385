import { addMonths } from 'date-fns'
import { SelectOption_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { IconType } from '@cotiss/common/components/icon.component'
import { Filter } from '@cotiss/common/models/filter.model'
import { ContractPeriod } from '@cotiss/contract/contract.model'

export const CONTRACT_QUERY_KEYS = ['/v2/contract-shells', '/v2/contract-shells/filter'] as const
export const CONTRACT_ROUTES = [
  '/contract',
  '/contract/list',
  '/contract/list/:tab?',
  '/contract/view/:contractShellId/:tab?',
  '/contract/approval/:contractShellId/:contractId/:approvalId?',
  '/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?',
] as const

export const CONTRACT_PERIOD_OPTIONS: SelectOption_DEPRECATED<ContractPeriod>[] = [
  { value: 'day', label: 'Days' },
  { value: 'week', label: 'Weeks' },
  { value: 'month', label: 'Months' },
  { value: 'year', label: 'Years' },
]

export const CONTRACT_LIST_EXPIRY_WARNING_PERIOD_MONTHS = 3

export type ContractSearchAndFiltersPrimaryTabKeys = 'active' | 'archived' | 'mine'
export const CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB: ContractSearchAndFiltersPrimaryTabKeys = 'active'

// EXCLUDES: archived, status, expired, isMine
export const CONTRACT_SEARCH_AND_FILTERS_FILTER_FIELDS_WHITELIST = [
  'contractOwners',
  'title',
  'procurement',
  'type',
  'approver',
  'expiryDate',
  'totalExercised',
  'totalValue',
  'supplier',
  'variationStatus',
  'createdBy',
  'variatedBy',
  'internalReference',
  'contractId',
  'contractingEntity',
]

export const CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS: Record<ContractSearchAndFiltersPrimaryTabKeys, { filters: Filter[] }> = {
  active: {
    filters: [
      {
        field: 'archived',
        operation: 'IS_FALSE',
        value: true,
      },
    ],
  },
  archived: {
    filters: [
      {
        field: 'archived',
        operation: 'IS_TRUE',
        value: true,
      },
    ],
  },
  mine: {
    filters: [
      {
        field: 'archived',
        operation: 'IS_FALSE',
        value: true,
      },
      {
        field: 'isMine',
        operation: 'IS_TRUE',
        value: true,
      },
    ],
  },
}

export const CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS: Record<
  string,
  { label: string; icon: IconType; iconFill: string; iconStroke: string; filters: Filter[]; tooltip?: string }
> = {
  expiredIn3Months: {
    label: 'Expiring in 3 months',
    icon: 'flag',
    iconFill: 'bg-red-200',
    iconStroke: 'text-red-500',
    filters: [
      {
        field: 'expiryDate',
        operation: 'IS_BEFORE',
        value: addMonths(new Date(), 3),
      },
      {
        field: 'expiryDate',
        operation: 'IS_AFTER',
        value: new Date(),
      },
    ],
  },
  pendingApproval: {
    label: 'Pending approval',
    icon: 'user-check-01',
    iconFill: 'bg-blue-300',
    iconStroke: 'text-blue-600',
    tooltip: 'Total does not include variation status',
    filters: [
      {
        field: 'status',
        operation: 'IS_ANY_OF',
        value: ['PENDING_APPROVAL'],
      },
    ],
  },
  pendingSignature: {
    label: 'Pending signature',
    icon: 'contract',
    iconFill: 'bg-blue-300',
    iconStroke: 'text-blue-600',
    tooltip: 'Total does not include variation status',
    filters: [
      {
        field: 'status',
        operation: 'IS_ANY_OF',
        value: ['PENDING_SIGNATURES'],
      },
    ],
  },
  published: {
    label: 'Published',
    icon: 'check-circle-01',
    iconFill: 'bg-green-300',
    iconStroke: 'text-green-600',
    filters: [
      {
        field: 'status',
        operation: 'IS_ANY_OF',
        value: ['PUBLISHED'],
      },
    ],
  },
  inDraft: {
    label: 'In draft',
    icon: 'edit-05',
    iconFill: 'bg-orange-300',
    iconStroke: 'text-orange-600',
    filters: [
      {
        field: 'status',
        operation: 'IS_ANY_OF',
        value: ['DRAFTING'],
      },
    ],
  },
  expired: {
    label: 'Expired',
    icon: 'clock-01',
    iconFill: 'bg-gray-200',
    iconStroke: 'text-gray-700',
    filters: [
      {
        field: 'expired',
        operation: 'IS_TRUE',
        value: true,
      },
    ],
  },
  ceased: {
    label: 'Ceased',
    icon: 'clock-check',
    iconFill: 'bg-gray-200',
    iconStroke: 'text-gray-700',
    filters: [
      {
        field: 'status',
        operation: 'IS_ANY_OF',
        value: ['CEASED'],
      },
    ],
  },
}
