import { memo, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Banner } from '@cotiss/common/components/banner.component'
import { PreferredSupplierList } from '@cotiss/preferred-supplier/components/preferred-supplier-list.component'
import { PreferredSupplierAddDrawer } from '@cotiss/preferred-supplier/drawers/preferred-supplier-add.drawer'
import {
  PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB,
  PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS,
  PreferredSupplierPrimaryTabKeys,
} from '@cotiss/preferred-supplier/preferred-supplier.constants'
import { useMutatePreferredSupplier } from '@cotiss/preferred-supplier/resources/use-mutate-preferred-supplier.resource'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { AdvancedFiltersPillList } from '@cotiss/common/modals/advanced-filters/advanced-filters-pill-list.component'
import { Button } from '@cotiss/common/components/button.component'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { Header } from '@cotiss/common/components/header.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Page } from '@cotiss/common/components/page.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { filterService } from '@cotiss/common/services/filter.service'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { PreferredSupplierListTabs } from '@cotiss/preferred-supplier/components/preferred-supplier-list-tabs.component'
import { PreferredSupplierListSearchInput } from '@cotiss/preferred-supplier/components/preferred-supplier-list-search-input.component'
import {
  PreferredSupplierSearchAndFiltersProvider,
  usePreferredSupplierSearchAndFilters,
} from '@cotiss/preferred-supplier/components/preferred-supplier-search-and-filters'
import { AdvancedFiltersDropdownButton } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-button.component'

const CONTACT_LIST_TABS: TabModel<PreferredSupplierPrimaryTabKeys>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archive' },
]

const PreferredSupplierListPageInternal = memo(() => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { replace } = useHistory()
  const { permissions } = useUserAccess()
  const { openDrawer } = useCallout()
  const [isDownloading, setIsDownloading] = useState(false)
  const { exportCsv } = useMutatePreferredSupplier()
  const [isBannerHidden, setIsBannerHidden] = useState(Boolean(localStorageService.getItem('preferred-supplier-list-banner-hidden')))

  const {
    isQuerying,
    refreshPreferredSuppliers,
    processedFilters,
    queryState,
    preferredSuppliers,
    pagination,
    isLoadingPreferredSuppliers,
    handlePageChange,
    onSort,
  } = usePreferredSupplierSearchAndFilters()
  const { searchQuery } = queryState

  useEffect(() => {
    track('preferred_supplier_list_view')
  }, [queryState.primaryTab])

  useEffect(() => {
    const queryParams = utilService.generateUrlSearchParams({
      filters: JSON.stringify(queryState.advancedFilters),
      q: searchQuery,
    })
    replace({
      pathname: routerService.getHref('/preferred-supplier/list/:tab?', { tab: queryState.primaryTab }),
      search: isQuerying ? queryParams : '',
    })
  }, [queryState.advancedFilters, searchQuery, queryState.primaryTab, isQuerying])

  if (!permissions.isBuyer) {
    return <FourOhThreePage />
  }

  const handleHideBanner = () => {
    localStorageService.setItem('preferred-supplier-list-banner-hidden', 'true')
    setIsBannerHidden(true)
  }

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csvData = await exportCsv({ filters: processedFilters, searchQuery, timeZone: datetimeService.getLocalTimeZone() })

      utilService.downloadCsv({
        csv: csvData.csv,
        filename: `contact_export_${datetimeService.format(new Date(), 'd MMMM yyyy h:mm aaa')}.csv`,
      })

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  const renderBanner = () => {
    if (isBannerHidden) return null
    return (
      <Banner className="relative flex items-center" variant="secondary">
        <div className="flex items-center justify-center bg-white rounded-md w-9 h-9 mr-4">
          <Icon icon="bullet-list" variant="secondary" size={20} />
        </div>
        <div>
          <Text className="font-semibold" variant="heading">
            View your list of contacts
          </Text>
          <Text size="sm">Contacts can be added directly to the contact list or through a procurement invitation</Text>
        </div>
        <Button className="absolute top-0 right-0" onClick={handleHideBanner} state="ghost">
          <Icon icon="x-close" size={16} />
        </Button>
      </Banner>
    )
  }

  const preferredSupplierListTableHeader = (
    <TableHeader className="flex justify-between items-center" variant="white">
      <Text size="md" className="flex items-center gap-1">
        Results{' '}
        {isLoadingPreferredSuppliers ? (
          <Skeleton className="h-3 w-6 inline-block" />
        ) : (
          <Text isInline variant="light">
            ({pagination?.totalCount})
          </Text>
        )}
      </Text>
      <Tooltip tooltip="Download a CSV of contacts including applied filters">
        <Button
          size="xs"
          variant="secondary"
          state="ghost"
          isDisabled={isLoadingPreferredSuppliers}
          isLoading={isDownloading}
          onClick={handleDownloadCsv}
          className="gap-1"
        >
          <Icon icon="download-01" /> <span>Download CSV</span>
        </Button>
      </Tooltip>
    </TableHeader>
  )

  return (
    <Page>
      <Header variant="primary">
        <Text className="font-semibold" size="h5" variant="heading">
          Contacts
        </Text>
        <Button onClick={() => openDrawer(<PreferredSupplierAddDrawer onClose={refreshPreferredSuppliers} />)} size="md">
          + Add contact
        </Button>
      </Header>
      <div className="sticky top-[72px] bg-white shadow-sm z-20 w-full h-13 px-10 border-t flex items-center justify-between gap-4">
        <PreferredSupplierListTabs tab={queryState.primaryTab} tabs={CONTACT_LIST_TABS} />
        <VerticalDivider className="h-7" />
        <PreferredSupplierListSearchInput className="flex-1" />
        <VerticalDivider className="h-7" />
        <AdvancedFiltersDropdownButton title="Filter contacts" onOpen={() => track('preferred_supplier_list_filters_dropdown_open')} />
      </div>
      <PageContent>
        <div className="flex flex-col gap-5">
          {renderBanner()}
          <AdvancedFiltersPillList />
          <div>
            {preferredSupplierListTableHeader}
            <PreferredSupplierList
              isLoading={isLoadingPreferredSuppliers}
              preferredSuppliers={preferredSuppliers}
              refreshPreferredSuppliers={refreshPreferredSuppliers}
              pagination={pagination}
              onSort={onSort}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </PageContent>
    </Page>
  )
})

export const PreferredSupplierListPage = memo(() => {
  const { tab: initialPrimaryTab } = useParams<{ tab?: PreferredSupplierPrimaryTabKeys }>()
  const { q: initialSearchQuery } = utilService.getUrlSearchParams({ params: ['q'] })
  const initialAdvancedFilters = filterService.getFiltersFromUrl()

  const initialState = {
    searchQuery: initialSearchQuery,
    advancedFilters: initialAdvancedFilters,
    primaryTab: initialPrimaryTab ?? PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB,
    primaryTabFilters: PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS,
  }

  return (
    <PreferredSupplierSearchAndFiltersProvider initialState={initialState}>
      <PreferredSupplierListPageInternal />
    </PreferredSupplierSearchAndFiltersProvider>
  )
})
