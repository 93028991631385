import { FormEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalConfirmationButton } from '@cotiss/common/containers/callout/modal/modal-confirmation-button.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  tenderResponse: TenderResponsePopulatedModel
}

export const TenderResponseSubmitConfirmModal = memo(({ tenderResponse }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateTenderResponse } = useMutateTenderResponse()
  const [isSaving, setIsSaving] = useState(false)

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTenderResponse(tenderResponse._id, { status: 'submitted' })
      closeModal()
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5">
          Confirm your submission
        </Text>

        <Text className="mt-4">Are you sure you would like finalise your tender submission?</Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
