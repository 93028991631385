import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { EvaluationListPrice } from '@cotiss/evaluation/components/evaluation-list-price.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderResponseReportPriceList } from '@cotiss/tender-response/components/tender-response-report-price-list.component'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const EvaluationPriceTab = memo(({ onView }: Props) => {
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)

  return (
    <>
      {tender?.priceTableEnabled ? (
        <EvaluationListPrice evaluationType="panel" onView={onView} tenderId={tenderId} />
      ) : (
        <TenderResponseReportPriceList tenderId={tenderId} onView={onView} />
      )}
    </>
  )
})
