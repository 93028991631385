import { memo, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ContractCorrespondenceListItem } from '@cotiss/contract/components/contract-correspondence-list-item.component'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractAddEditCorrespondenceDrawer } from '@cotiss/contract/drawers/contract-add-edit-correspondence.drawer'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useListCorrespondence } from '@cotiss/correspondence/resources/use-list-correspondence.resource'

export const ContractViewCorrespondenceTab = memo(() => {
  const { openNarrowDrawer } = useCallout()
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { correspondenceItems } = useListCorrespondence({ contractShellId })
  const contract = useMemo(() => contractShell && contractService.getContract(contractShell), [contractShell])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              Notes
            </Text>
          </div>
        </CardHeader>
        <div className="w-full p-6 border rounded-md">
          <Skeleton className="h-6 w-32" />
          <Skeleton className="h-6 w-60" />
        </div>
        <div className="w-full p-6 border rounded-md">
          <Skeleton className="h-6 w-32" />
          <Skeleton className="h-6 w-60" />
        </div>
      </Card>
    )
  }

  if (!contract || !(contract.status === 'PUBLISHED' || contract.status === 'CEASED')) {
    return (
      <Card>
        <NoDataPlaceholder illustration="dot-list" label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex justify-between">
          <div>
            {isLoading && <Skeleton className="h-2 w-1/3 mb-1" />}
            {!isLoading && (
              <Text className="mb-1" size="sm" variant="light">
                {contractShell?.title || ''}
              </Text>
            )}
            <Text className="font-semibold" size="h5">
              Notes
            </Text>
          </div>
        </div>
        <div>
          <ContractStatusBadge className="mr-4" status={contract.status} />
          <Button
            variant="secondary"
            size="sm"
            onClick={() => openNarrowDrawer(<ContractAddEditCorrespondenceDrawer contractShellId={contractShellId} />)}
          >
            Add note
          </Button>
        </div>
      </CardHeader>
      {!correspondenceItems.length && <NoDataPlaceholder illustration="dot-list" label="No notes" />}
      {correspondenceItems.map((correspondence) => {
        return <ContractCorrespondenceListItem key={correspondence._id} contractShellId={contractShellId} correspondence={correspondence} />
      })}
    </Card>
  )
})
