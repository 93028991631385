import { memo, useState } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { AccordionContent } from '@cotiss/common/components/accordion-content.component'
import { AccordionToggle } from '@cotiss/common/components/accordion-toggle.component'
import { Text } from '@cotiss/common/components/text.component'
import {
  EvaluationCriteriaAccordionItem,
  EvaluationCriteriaScoreFormDataItem,
} from '@cotiss/evaluation/components/evaluation-criteria-accordion-item.component'
import { EvaluationModel } from '@cotiss/evaluation/evaluation.models'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'
import { TenderCriterionModel } from '@cotiss/tender-criteria/tender-criteria.models'

type Props = {
  className?: string
  evaluation: EvaluationModel
  tender: TenderPopulatedModel
  tenderCriterion: TenderCriterionModel
  evaluationCriteriaScores: EvaluationCriteriaScoreFormDataItem[]
  isEditable?: boolean
}

export const EvaluationCriteriaAccordion = memo(({ className, tender, evaluation, tenderCriterion, evaluationCriteriaScores, isEditable }: Props) => {
  const [isExpanded, setIsExpanded] = useState(isEditable)

  return (
    <div className={className}>
      <AccordionToggle
        contentClassName={classNames('flex items-center justify-between', { 'bg-primary-50': !isExpanded, 'bg-secondary-100': isExpanded })}
        onClick={() => setIsExpanded(!isExpanded)}
        isExpanded={isExpanded}
      >
        <Text className="text-left font-medium mr-4">{tenderCriterion.name}</Text>
      </AccordionToggle>
      {isExpanded && (
        <>
          <AccordionContent className="justify-between ml-6">
            <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
              Question
            </Text>
            <div className="">
              <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                Score
              </Text>
              {isEditable && <div className="w-20" />}
            </div>
          </AccordionContent>
          {map(evaluationCriteriaScores, (evaluationCriteriaScore, index) => (
            <EvaluationCriteriaAccordionItem
              key={index}
              tender={tender}
              evaluation={evaluation}
              evaluationCriteriaScore={evaluationCriteriaScore}
              isLast={index === evaluationCriteriaScores.length - 1}
              isEditable={isEditable}
            />
          ))}
        </>
      )}
    </div>
  )
})
