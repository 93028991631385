import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { TenderInvitationPage } from '@cotiss/tender-invitation/pages/tender-invitation.page'

const TenderInvitationLayout = memo(() => (
  <Switch>
    <Route path="/tender-invitation" exact>
      <TenderInvitationPage />
    </Route>
  </Switch>
))

export default TenderInvitationLayout
