import { FormEvent, ReactNode, memo, useState } from 'react'
import { Form } from '@cotiss/common/components/form.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalConfirmationButton } from '@cotiss/common/containers/callout/modal/modal-confirmation-button.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import classNames from 'classnames'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'

type Props = {
  heading: string
  description: ReactNode
  onSubmit: () => void | Promise<unknown>
  size?: 'sm' | 'md'
}

export const ConfirmModal = memo(({ heading, description, onSubmit, size = 'sm' }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const classes = classNames('relative', {
    'w-[450px]': size == 'sm',
    'w-[560px]': size == 'md',
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await onSubmit()
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className={classes} onSubmit={handleSubmit}>
      <ModalHeader heading={heading} isDisabled={isSaving} onClose={closeModal} />
      <ModalContent isScrollable>
        <Text>{description}</Text>
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Confirm
      </ModalConfirmationButton>
    </Form>
  )
})
