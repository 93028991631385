import { memo, useState, useMemo, useEffect, FormEvent } from 'react'
import { find, first } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractUploadSignedDocumentModal = memo(({ contractShellId, contractId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { uploadContractSignedDocument } = useMutateContractShell()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const [documents, setDocuments] = useState<DocumentModel[]>([])
  const contract = useMemo(() => contractShell?.contracts.find((contract) => contract._id === contractId), [contractShell])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user?.account?.organisation || !contract) {
      sentryService.captureException({ exception: 'Failed to load contract upload signed document modal.', extras: { contractShellId, contractId } })
      openToast(<>Couldn&apos;t load {contract ? 'user information' : 'contract'}. Please try again.</>, 'danger')
      closeModal()
    }
  }, [isLoading, user, contract])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // Currently only supporting one document shell of type CONTRACT per contract
    const documentShell = find(contract?.documentShells, { type: 'CONTRACT' })
    const documentAttachment = first(documentShell?.attachments)
    const document = first(documents)

    if (!contract?._id || !documentShell || !documentAttachment || !document) {
      return
    }

    try {
      setIsSaving(true)

      await uploadContractSignedDocument({
        contractShellId,
        contractId: contract?._id,
        documentShellId: documentShell._id,
        attachmentId: documentAttachment._id,
        documentId: document._id,
      })

      setIsSaving(false)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload contract" supplementary="Select and upload your signed contract" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload documents={documents} onChange={setDocuments} isDisabled={isLoading} isSingle />
      </ModalContent>
      <ModalFooter isDisabled={!documents.length} isSaving={isSaving} isForm />
    </Form>
  )
})
