import { memo, useMemo } from 'react'
import { compact, find, first, flatMap, map } from 'lodash'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationStatusBadge } from '@cotiss/evaluation/components/evaluation-status-badge.component'
import { EvaluationIndividualReportModel, EvaluationModel } from '@cotiss/evaluation/evaluation.models'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { RATING_SCALE_VALUE_MAP } from '@cotiss/tender/tender.constants'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { userService } from '@cotiss/user/user.service'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { useListTenderResponseReport } from '@cotiss/tender-response/resources/use-list-tender-response-report.resource'
import { useListEvaluation } from '@cotiss/evaluation/resources/use-list-evaluation.resource'
import { EvaluationListItemCta } from '@cotiss/evaluation/components/evaluation-list-item-cta.component'

type Props = {
  className?: string
  tenderId?: string
  userId?: string
  breadcrumbs?: BreadcrumbModel[]
  onView?: (evaluation: EvaluationModel) => void
  isSelfEvaluation?: boolean
}

export const EvaluationListTable = memo(({ className, tenderId, onView, userId, breadcrumbs: rootBreadcrumbs, isSelfEvaluation = false }: Props) => {
  const { tender } = useGetTender(tenderId)
  const { tenderResponseReports, isLoading: isLoadingTenderResponseReport } = useListTenderResponseReport(tenderId, true)
  const { tenderResponses, isLoading: isLoadingTenderResponse } = useListTenderResponse({ tenderId })
  const { user } = useGetLoggedInUser()

  // If it is a self evaluation then anyone can access the evaluation
  // If it an individual evaluation then they should just be accessing their own
  // If it is a chair view of the evaluation they should access the selected user
  const { evaluations, isLoading: isLoadingEvaluation } = useListEvaluation({ tenderId, userId: isSelfEvaluation ? undefined : userId || user?._id })

  const isLoading = isLoadingEvaluation || isLoadingTenderResponseReport || isLoadingTenderResponse

  const breadcrumbs = useMemo(() => {
    return rootBreadcrumbs ? [...rootBreadcrumbs, { label: userService.getFullName(first(evaluations)?.user) || 'evaluation' }] : undefined
  }, [rootBreadcrumbs])

  const maxRatingScaleScore = tender?.ratingScale ? RATING_SCALE_VALUE_MAP[tender.ratingScale].max : 10

  const processedEvaluations = useMemo(() => {
    if (!tenderResponseReports.length) {
      return []
    }

    const processedEvaluations = flatMap(tenderResponseReports, ({ evaluations }) => {
      return flatMap(evaluations, ({ tenderCriteria, nonPriceEvaluationTotal }) => {
        return flatMap(tenderCriteria, ({ tenderQuestions, _id, criterionScore }) => {
          return map(tenderQuestions, ({ score: { score, evaluation, tenderQuestion } }) => {
            return {
              score,
              evaluation,
              tenderCriteria: _id,
              tenderQuestion,
              criterionScore,
              nonPriceEvaluationTotal,
            }
          })
        })
      })
    })

    return processedEvaluations
  }, [tenderResponseReports])

  const listItems = useMemo(() => {
    const listItems = map(evaluations, (evaluation) => {
      const tenderResponse = find(tenderResponses, { _id: evaluation.tenderResponse })
      const tenderResponseReport = find(tenderResponseReports, { _id: evaluation.tenderResponse })
      const evaluationReport = find(tenderResponseReport?.evaluations, { _id: evaluation._id })

      if (!tenderResponse) {
        return null
      }

      return { evaluation, evaluationReport, tenderResponse, tenderResponseReport }
    })

    return compact(listItems)
  }, [evaluations, tenderResponses, tenderResponseReports])

  const evaluationIndividualReportData: EvaluationIndividualReportModel = useMemo(() => {
    if (!listItems.length || !processedEvaluations.length || !tenderResponseReports.length) {
      return []
    }

    const dataTableItems = map(first(tenderResponseReports)?.tenderCriteria, (criteria) => {
      return {
        _id: criteria._id,
        criteriaName: criteria.name,
        criteriaOverallWeight: criteria.weightedCriterionWeight,
        questions: map(criteria.tenderQuestions, (question) => {
          return {
            _id: question._id,
            question: question.question,
            questionWeight: question.weightedQuestionWeight,
            questionCriteriaWeight: question.nonPriceQuestionWeight,
          }
        }),
      }
    })

    return dataTableItems
  }, [tenderResponseReports, processedEvaluations, listItems])

  const renderContent = () => {
    if (!evaluationIndividualReportData.length && !listItems.length && !isLoading) {
      return (
        <div className="flex items-center justify-center h-[calc(100%-64px)]">
          <NoDataPlaceholder label="Your evaluations aren't ready yet" />
        </div>
      )
    }

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'status',
        rows: [
          {
            content: () => <></>,
          },
          {
            content: () => <></>,
          },
          ...map(listItems, (listItem) => {
            return {
              tdClassName: 'h-20',
              content: () => <EvaluationStatusBadge status={listItem.evaluation.status} />,
            }
          }),
        ],
      },
      ...flatMap(evaluationIndividualReportData, (criteria, index) => {
        const criteriaIndex = index
        return [
          ...map(criteria.questions, (question, index) => {
            return {
              // Only display the heading cell once, but span it to take up all columns
              heading:
                index === 0 ? (
                  <Text variant="light" className="truncate w-36" size="xs" title={criteria.criteriaName}>
                    {`${criteriaIndex + 1}. ${criteria.criteriaName}`}
                  </Text>
                ) : null,
              colSpan: criteria.questions.length,
              rows: [
                {
                  tdClassName: 'h-36',
                  content: () => (
                    <div className="h-28 w-40 truncate">
                      <Text className="" size="xs" title={question.question}>
                        {criteriaIndex + 1}.{index + 1}
                      </Text>
                      <Text className="whitespace-pre-wrap" size="xs" title={question.question}>
                        {question.question}
                      </Text>
                    </div>
                  ),
                },
                {
                  content: () => <Text size="sm">{(question.questionCriteriaWeight * 100).toFixed(2)}%</Text>,
                },
                ...map(listItems, (listItem) => {
                  const score = find(processedEvaluations, { evaluation: listItem.evaluation._id, tenderQuestion: question._id })?.score

                  return {
                    tdClassName: 'h-20',
                    content: () => <Text size="sm">{score !== undefined && score !== null ? `${score} / ${maxRatingScaleScore}` : '-'}</Text>,
                  }
                }),
              ],
            }
          }),
          {
            heading: (
              <Text variant="link" className="truncate w-24" size="xs">
                Totals
              </Text>
            ),
            rows: [
              {
                tdClassName: 'h-36',
                content: () => <></>,
              },
              {
                content: () => (
                  <Text variant="link" size="sm">
                    {(criteria.criteriaOverallWeight * 100).toFixed(2)}%
                  </Text>
                ),
              },
              ...map(listItems, (listItem) => {
                const score = find(processedEvaluations, { evaluation: listItem.evaluation._id, tenderCriteria: criteria._id })?.criterionScore

                return {
                  tdClassName: 'h-16',
                  content: () => (
                    <Text variant="link" size="sm">
                      {score !== undefined && score !== null ? `${score.toFixed(2)} / ${maxRatingScaleScore}` : '-'}
                    </Text>
                  ),
                }
              }),
            ],
          },
        ]
      }),
    ]

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Criteria',
        rows: [
          {
            tdClassName: 'h-36',
            content: () => (
              <Text className="truncate uppercase" variant="light" size="xs">
                Question
              </Text>
            ),
          },
          {
            content: () => (
              <Text className="truncate uppercase" variant="light" size="xs">
                Overall Weight
              </Text>
            ),
          },
          ...map(listItems, ({ tenderResponse, evaluation }) => {
            const score = find(processedEvaluations, { evaluation: evaluation._id })?.nonPriceEvaluationTotal
            return {
              tdClassName: 'h-20',
              content: () => (
                <div className="truncate" title={tenderResponse.procurementResponse.supplier.name}>
                  <Text className="truncate font-semibold mb-2">{tenderResponse.procurementResponse.supplier.name}</Text>
                  <Text variant="light" size="sm">
                    Total: {score !== undefined && score !== null ? `${score.toFixed(2)} / ${maxRatingScaleScore}` : '-'}
                  </Text>
                </div>
              ),
              cta: (
                <EvaluationListItemCta
                  onView={onView ? () => onView(evaluation) : undefined}
                  organisationId={tenderResponse.procurementResponse.supplier._id}
                  tenderResponse={tenderResponse}
                  isScoringEnabled={tender?.status === 'evaluate'}
                />
              ),
            }
          }),
        ],
      },
    ]

    if (!breadcrumbs) {
      return <ScrollableTable className={className} fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
    }

    return (
      <div>
        <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
        <ScrollableTable className={className} fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
      </div>
    )
  }

  return renderContent()
})
