import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { TaskListPage } from '@cotiss/task/pages/task-list.page'

const TaskLayout = memo(() => (
  <Switch>
    <Route path="/task/list/:tab?" exact>
      <TaskListPage />
    </Route>
    <Redirect from="/task" to="/task/list" exact />
  </Switch>
))

export default TaskLayout
