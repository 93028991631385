import { memo, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import { find, findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { SettingsTab } from '@cotiss/settings/pages/settings.page'
import { SettingsModulesMetafieldsTab } from '@cotiss/settings/tabs/settings-modules-metafields.tab'
import { SettingsModulesTab } from '@cotiss/settings/tabs/settings-modules.tab'

type SettingsModulesContactTab = 'custom-fields'
const SETTINGS_MODULES_CONTACT_TABS: VerticalTabModel<SettingsModulesContactTab>[] = [{ id: 'custom-fields', label: 'Custom fields' }]

export const SettingsModulesContactTab = memo(() => {
  const { push, replace } = useHistory()
  const { tab, nestedTab, subNestedTab } = useParams<{ tab: SettingsTab; nestedTab: SettingsModulesTab; subNestedTab?: SettingsModulesContactTab }>()
  const { step, transition, onTransition } = useTransition({
    initialStep: findIndex(SETTINGS_MODULES_CONTACT_TABS, ({ id }) => id === subNestedTab) + 1,
  })

  useEffect(() => {
    if (!subNestedTab || !find(SETTINGS_MODULES_CONTACT_TABS, (tab) => tab.id === subNestedTab)) {
      replace(
        routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', {
          tab,
          nestedTab,
          subNestedTab: SETTINGS_MODULES_CONTACT_TABS[0].id,
        })
      )
    }

    const newStep = findIndex(SETTINGS_MODULES_CONTACT_TABS, ({ id }) => id === subNestedTab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [subNestedTab])

  return (
    <Card className="mb-8 flex-1">
      <Text className="font-medium" variant="heading" size="h5">
        Contact
      </Text>
      <Text className="mt-1" variant="light">
        Adjust settings specific to the contact module
      </Text>
      <Hr className="my-4" />
      <Tabs<SettingsModulesContactTab>
        className="border-b border-gray-300 w-full mb-6"
        tab={subNestedTab}
        tabs={SETTINGS_MODULES_CONTACT_TABS}
        onChange={({ id }) => push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab, nestedTab, subNestedTab: id }))}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && <SettingsModulesMetafieldsTab entityType="SUPPLIER_PROFILE" />}
        </TransitionContainer>
      </AnimatePresence>
    </Card>
  )
})
