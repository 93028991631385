import { FormEvent, memo, useState } from 'react'
import CotissLogoDarkSvg from '@assets/svg/cotiss-logo-dark.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { map } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { CotissSquares } from '@cotiss/common/components/cotiss-squares.component'
import { ExternalLink } from '@cotiss/common/components/external-link.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { EXTERNAL_LINK } from '@cotiss/common/constants/external-link.constants'
import { apiService } from '@cotiss/common/services/api.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { utilService } from '@cotiss/common/services/util.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { SignUpRegisterOrganisationInterest } from '@cotiss/auth/components/sign-up-register-organisation-interest.component'
import { useGetPublicOrganisation } from '@cotiss/organisation/resources/use-get-public-organisation.resource'
import { useMutateUser } from '@cotiss/user/resources/use-mutate-user.resource'

type AuthDomainResult = {
  organisations: string[]
}

export const JoinAccountPage = memo(() => {
  const { logout } = useAuth()
  const { replace } = useHistory()
  const { openToast } = useToast()
  const { createRequestedUser } = useMutateUser()
  const [isSaving, setIsSaving] = useState(false)
  const { email } = useParams<{ email: string }>()
  const [isSuccess, setIsSuccess] = useState(false)
  const [organisationIds, setOrganisationIds] = useState<string[]>([])
  const [selectedOrganisationId, setSelectedOrganisationId] = useState('')
  const { publicOrganisation } = useGetPublicOrganisation(selectedOrganisationId)
  const searchParams = utilService.getUrlSearchParams({ params: ['businessNumber', 'countryCode'] })

  useAsyncEffect(async () => {
    logout()

    // TODO: We should provide a way to bulk fetch the public organisations providing all the information we need to populate these cards. Otherwise
    // TODO: we need to make a lot of individual requests, which could clog up our network and server.
    const { organisations = [] } = await apiService.get<AuthDomainResult>(
      `/v2/auth/domain${utilService.generateUrlSearchParams({ email, ...searchParams })}`
    )

    if (!organisations.length) {
      replace(routerService.getHref('/sign-up/supplier'))
      return
    }

    setOrganisationIds(organisations)
    setSelectedOrganisationId(organisations[0] || '')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      publicOrganisation?.account?._id && (await createRequestedUser({ email, account: publicOrganisation?.account?._id }))
      setIsSuccess(true)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Page>
      <div className="flex items-start justify-between h-full">
        <div className=" bg-white w-5/12 h-full p-4 pr-10">
          <div className="bg-secondary-500 rounded-lg h-full w-full p-10">
            <CotissLogoLightSvg className="h-8" />
            <div className="mt-32">
              <h1 className="text-white text-4xl font-semibold">Oops,</h1>
              <h2 className="text-white text-2xl font-medium">It looks like another member of your organisation has already created an account.</h2>
            </div>
          </div>
        </div>
        <div className="bg-white w-7/12 h-full p-12">
          <Button href={routerService.getHref('/login')} state="ghost" variant="link" isLink>
            <Icon icon="chevron-left" />
            Back
          </Button>
          <div className="px-2 max-w-xl w-full mx-auto mt-24">
            <div>
              <div className="flex items-center">
                <CotissSquares className="mr-4" variant="info" />
                <CotissLogoDarkSvg className="h-5 mr-2" />
                <Text size="h3">for suppliers</Text>
              </div>
              {isSuccess ? (
                <div className="mt-4">
                  <Text size="h5">Great! Your request has been sent.</Text>
                  <Text variant="light">Once approved, the confirmation will appear in your inbox.</Text>
                </div>
              ) : (
                <Text className="mt-2" size="h5">
                  Select your organisation and send a request to join their team.
                </Text>
              )}
            </div>
            {!isSuccess && (
              <Form className="mt-12" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 gap-x-5 gap-y-8">
                  {map(organisationIds, (organisationId) => (
                    <SignUpRegisterOrganisationInterest
                      key={organisationId}
                      organisationId={organisationId}
                      onChange={() => setSelectedOrganisationId(organisationId)}
                      isSelected={organisationId === selectedOrganisationId}
                      isDisabled={isSaving}
                    />
                  ))}
                </div>

                <div className="flex justify-between mt-6">
                  <Text size="sm">
                    If you are unsure who the administrator of this account is, please{' '}
                    <ExternalLink href={EXTERNAL_LINK.termsAndConditions} size="sm" isInline>
                      contact us.
                    </ExternalLink>
                  </Text>
                  <Button
                    className="ml-4"
                    type="submit"
                    variant="secondary"
                    state="translucent"
                    isLoading={isSaving}
                    isDisabled={!selectedOrganisationId || isSuccess}
                  >
                    Request to join
                  </Button>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
})
