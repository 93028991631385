import { memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  value: string
  name?: string
  type?: 'email' | 'number' | 'text'
}

export const FormHiddenInput = memo(({ className, value, name, type = 'text' }: Props) => {
  const classes = classNames(className, 'absolute top-0 left-0 w-px h-px pointer-events-none')

  return <input className={classes} value={value} name={name} type={type} readOnly />
})
