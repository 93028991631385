import { memo } from 'react'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'

type Props = {
  isListingOwner?: boolean
  onSubmit: () => void | Promise<unknown>
}

export const ForumAddProtectionQuestionConfirmModal = memo(({ isListingOwner, onSubmit }: Props) => {
  const description = isListingOwner
    ? 'Protecting this question will restrict access to this supplier and your organisation. Are you sure you want to continue?'
    : 'Protecting this question will restrict its access to your organisation and the listing owner, who has the ability to deny the restriction. Are you sure you want to continue?'

  return <ConfirmModal heading="Protect question" description={description} onSubmit={onSubmit} />
})
