import { memo, useMemo } from 'react'
import { areIntervalsOverlapping } from 'date-fns'
import { compact, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { OcdsCurrencyCode } from '@cotiss/common/models/ocds.model'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ContractPriceDurationItemCta } from '@cotiss/contract/components/contract-price-duration-item-cta.component'
import { ContractPriceDurationModel } from '@cotiss/contract/contract.model'
import { ContractWizardPriceDurationModal } from '@cotiss/contract/modals/contract-wizard-price-duration.modal'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { utilService } from '@cotiss/common/services/util.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  priceDurations: ContractPriceDurationModel[]
  isVariation: boolean
  contractShellId: string
  showErrors?: boolean
  currency?: OcdsCurrencyCode
  isEditable?: boolean
}

const PRICE_DURATIONS_TOTAL_ROW_ID = 'total-value-row'

// --- TODO: is the following TODO still relevant?
// TODO: This should be moved out of the "wizard" component, and probably moved to the `models.ts` file, since this is reused a bunch throughout the
// TODO: contract domain. We should also rename it, so it doesn't have "wizard" in the type.
// ---
export type ContractWizardPriceDurationTableItem = {
  _id: string
  label: string
  startDate?: Date
  endDate?: Date
  length: number
  value: number
  variation: number
  exercised: number
  index: number
  description?: string
  referenceId?: string
  valueState?: 'error' | 'none'
  lengthState?: 'error' | 'none'
}

export const ContractWizardPriceDurationTable = memo((props: Props) => {
  const { priceDurations, isVariation, contractShellId, showErrors = false, currency, isEditable } = props
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openModal } = useCallout()
  const parsedPriceDurations = useMemo(() => contractService.parseContractPriceDurations(priceDurations), [priceDurations])

  const overlappingPriceDurations: { id: string; labels: string[] }[] = useMemo(() => {
    if (priceDurations.length === 1) {
      return []
    }

    return compact(
      parsedPriceDurations.map((_priceDuration) => {
        // Don't compare date to itself
        const durationsToCompare = [...parsedPriceDurations.filter((pd) => pd._id !== _priceDuration._id)]

        const overlapping = durationsToCompare.filter(
          (pd) =>
            pd.startDate &&
            pd.endDate &&
            _priceDuration.startDate &&
            _priceDuration.endDate &&
            areIntervalsOverlapping({ start: pd.startDate, end: pd.endDate }, { start: _priceDuration.startDate, end: _priceDuration.endDate })
        )
        return overlapping.length ? { id: _priceDuration._id, labels: overlapping.map((pd) => pd.label) } : null
      })
    )
  }, [priceDurations])

  // ----- Calculate the totals row values and error state of table --------
  const { priceDurationsWithTotals, approvedLength, approvedInitialValue } = useMemo(() => {
    if (!parsedPriceDurations.length) {
      return { priceDurationsWithTotals: [], approvedLength: 0, approvedInitialValue: 0 }
    }
    /* TODO:
      originalPriceDurations will be the most recent published price durations, so when showing price duration history,
      it's not correct to use this to check for errors.
      Add a method which finds the last approved contract, relative to a given contract.
      Also rename as this should be the latest approved price durations, not the original ones
     */
    const originalPriceDurations = contractShell?.contracts.length
      ? contractService.parseContractPriceDurations(contractService.getContract(contractShell, ['PUBLISHED'])?.priceDurations || [])
      : []

    const { length: approvedLength, value: approvedInitialValue } = contractService.getContractPriceDurationTotals(originalPriceDurations)

    // This value is only used with variations.
    // Any updates to the price durations (total or length values) must still add up to these values
    // TODO: add lengths once length variations are added
    const { differenceInTotalValues, differenceInMonths } = contractService.validateContractPriceDurationOrMilestoneVariation({
      approvedValues: originalPriceDurations,
      newValues: parsedPriceDurations,
    })

    const {
      length: runningTotalLength,
      variation: runningTotalVariation,
      value: runningTotalValue,
      exercised: runningTotalExercised,
    } = contractService.getContractPriceDurationTotals(parsedPriceDurations)

    const priceDurationsWithTotals: ContractWizardPriceDurationTableItem[] = [
      ...parsedPriceDurations,
      {
        _id: PRICE_DURATIONS_TOTAL_ROW_ID,
        index: priceDurations.length + 999, // Should always be the last row in the table
        label: 'Totals',
        startDate: new Date(),
        endDate: new Date(),
        variation: runningTotalVariation,
        exercised: runningTotalExercised,
        value: runningTotalValue,
        length: runningTotalLength,
        valueState: differenceInTotalValues && showErrors ? 'error' : 'none',
        lengthState: differenceInMonths && showErrors ? 'error' : 'none',
      },
    ]

    return {
      priceDurationsWithTotals,
      approvedLength,
      approvedInitialValue,
    }
  }, [priceDurations])
  // ----------------------------------------------------------------

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: ' ',
      rows: map(priceDurationsWithTotals, ({ _id, label, exercised }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        content: () => (
          <>
            {/* If it's the 'totals' row, or if this value hasn't yet been exercised */}
            {(_id === PRICE_DURATIONS_TOTAL_ROW_ID || (_id !== PRICE_DURATIONS_TOTAL_ROW_ID && !exercised)) && (
              <div className="flex">
                {/* If this row's dates overlap with another row */}
                {overlappingPriceDurations.map((pd) => pd.id).includes(_id) && (
                  <Tooltip tooltip={`These dates overlap with ${overlappingPriceDurations.find((pd) => pd.id === _id)?.labels.join(', ')}`}>
                    <Icon icon="alert-circle" variant="danger" />
                  </Tooltip>
                )}
                <Text className="font-semibold truncate">{label}</Text>
              </div>
            )}
            {/* If it's not the 'totals' row and the value has been exercised */}
            {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && Boolean(exercised) && (
              <Tooltip tooltip="This period has been exercised">
                <Text className="font-semibold truncate" variant="light">
                  {label}
                </Text>
              </Tooltip>
            )}
          </>
        ),
        ...(isEditable &&
          !exercised &&
          label !== 'Totals' && {
            cta: <ContractPriceDurationItemCta priceDurationId={_id} contractShellId={contractShellId} />,
          }),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Description',
      rows: map(priceDurationsWithTotals, ({ _id, description }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: 'max-w-[370px]',
        content: () => (
          <Text title={description} className="whitespace-pre-wrap line-clamp-2">
            {_id === PRICE_DURATIONS_TOTAL_ROW_ID ? '' : description || '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Start date',
      rows: map(priceDurationsWithTotals, ({ _id, startDate, exercised }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        content: () => (
          <>
            {/* Don't display this value for the totals row */}
            {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && (
              <Text variant={exercised ? 'light' : overlappingPriceDurations.map((pd) => pd.id).includes(_id) ? 'danger' : 'none'}>
                {startDate ? datetimeService.format(startDate, 'do MMM yyyy') : '--'}
              </Text>
            )}
          </>
        ),
      })),
    },
    {
      heading: 'End date',
      rows: map(priceDurationsWithTotals, ({ _id, endDate, exercised }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        content: () => (
          <>
            {/* Don't display this value for the totals row */}
            {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && (
              <Text variant={exercised ? 'light' : overlappingPriceDurations.map((pd) => pd.id).includes(_id) ? 'danger' : 'none'}>
                {endDate ? datetimeService.format(endDate, 'do MMM yyyy') : '--'}
              </Text>
            )}
          </>
        ),
      })),
    },
    {
      heading: 'Length (month)',
      thClassName: 'whitespace-no-wrap',
      rows: map(priceDurationsWithTotals, ({ _id, length, lengthState, exercised }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'font-semibold' : '',
        content: () => (
          <>
            {_id === PRICE_DURATIONS_TOTAL_ROW_ID && (
              <div className="flex items-center">
                {lengthState === 'error' && (
                  <Tooltip tooltip={`Initial approved length of this contract was ${approvedLength} months`} className="gap-1">
                    <Icon icon="alert-circle" variant="danger" />
                    <Text variant="danger">{length}</Text>
                  </Tooltip>
                )}
                {lengthState !== 'error' && <Text variant="secondary">{length}</Text>}
              </div>
            )}
            {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{length}</Text>}
          </>
        ),
      })),
    },
    {
      heading: 'Initial value',
      rows: map(priceDurationsWithTotals, ({ _id, value, valueState, exercised }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'font-semibold' : '',
        content: () => (
          <>
            {_id === PRICE_DURATIONS_TOTAL_ROW_ID && (
              <div className="flex items-center">
                {valueState === 'error' && (
                  // TODO: double check this copy if we're not using initial period anymore
                  <Tooltip className="gap-1" tooltip={`Initial approved value of this contract was $${approvedInitialValue.toLocaleString()}`}>
                    <Icon icon="alert-circle" variant="danger" />
                    <Text variant="danger">
                      {currency && `${utilService.formatAsCurrency(value, currency)}`}
                      {!currency && value.toLocaleString()}
                    </Text>
                  </Tooltip>
                )}
                {valueState !== 'error' && (
                  <Text variant={_id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'secondary' : 'none'}>
                    {currency && `${utilService.formatAsCurrency(value, currency)}`}
                    {!currency && value.toLocaleString()}
                  </Text>
                )}
              </div>
            )}
            {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{value.toLocaleString()}</Text>}
          </>
        ),
      })),
    },
  ]

  if (isVariation) {
    columns.push({
      heading: 'Variation',
      rows: map(priceDurationsWithTotals, ({ _id, variation = 0, exercised }) => ({
        variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
        tdClassName: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'font-semibold' : '',
        content: () => (
          <>
            {_id === PRICE_DURATIONS_TOTAL_ROW_ID && (
              <Text variant="secondary">
                {Boolean(currency) && `${utilService.formatAsCurrency(variation, currency)}`}
                {!currency && variation.toLocaleString()}
              </Text>
            )}
            {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{variation.toLocaleString()}</Text>}
          </>
        ),
      })),
    })
  }

  columns.push({
    heading: 'Total',
    rows: map(priceDurationsWithTotals, ({ _id, value, variation = 0, exercised }) => ({
      variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
      tdClassName: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'font-semibold' : '',
      content: () => (
        <>
          {_id === PRICE_DURATIONS_TOTAL_ROW_ID && (
            <Text variant="secondary">
              {Boolean(currency) && `${utilService.formatAsCurrency(value + variation, currency)}`}
              {!currency && (value + variation).toLocaleString()}
            </Text>
          )}
          {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{(value + variation).toLocaleString()}</Text>}
        </>
      ),
    })),
  })
  columns.push({
    heading: 'Exercised',
    rows: map(priceDurationsWithTotals, ({ _id, exercised }) => ({
      variant: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'primary' : 'white',
      tdClassName: _id === PRICE_DURATIONS_TOTAL_ROW_ID ? 'font-semibold' : '',
      content: () => (
        <>
          {_id === PRICE_DURATIONS_TOTAL_ROW_ID && (
            <Text variant="secondary">
              {Boolean(currency) && `${utilService.formatAsCurrency(exercised, currency)}`}
              {!currency && exercised.toLocaleString()}
            </Text>
          )}
          {_id !== PRICE_DURATIONS_TOTAL_ROW_ID && <Text variant={exercised ? 'light' : 'none'}>{exercised.toLocaleString()}</Text>}
        </>
      ),
    })),
  })

  return (
    <ScrollableTable
      emptyCta={
        isEditable && (
          <Button
            size="sm"
            state="text"
            variant="secondary"
            onClick={() => openModal(<ContractWizardPriceDurationModal contractShellId={contractShellId} />)}
            isDisabled={isLoading}
          >
            + Add row {/* TODO: Replace this with descriptive CTA label */}
          </Button>
        )
      }
      isLoading={isLoading}
      fixedColumnsWidth={isEditable ? 180 : 150}
      fixedColumns={fixedColumns}
      columns={columns}
    />
  )
})
