import { memo } from 'react'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationEventSummaryEnvelopes } from '@cotiss/evaluation-event/components/evaluation-event-summary-envelopes.component'

export const EvaluationEventViewDetailsEnvelopesTab = memo(() => (
  <Card>
    <CardHeader className="flex items-center justify-between">
      <Text className="font-semibold" variant="heading" size="h5">
        Envelopes
      </Text>
    </CardHeader>
    <EvaluationEventSummaryEnvelopes />
  </Card>
))
