import { memo, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderUpdateViewDrawer } from '@cotiss/tender-update/drawers/tender-update-view.drawer'
import { TenderUpdateCreateUpdateModal } from '@cotiss/tender-update/modals/tender-update-create-update.modal'
import { useMutateTenderUpdate } from '@cotiss/tender-update/resources/use-mutate-tender-update.resource'
import { TenderUpdatePopulatedModel } from '@cotiss/tender-update/tender-update.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  tenderUpdate: TenderUpdatePopulatedModel
  isEditable?: boolean
  tenderResponseCount?: number
  isRead?: boolean
}

export const TenderUpdateButtons = memo(({ tenderUpdate, isEditable, tenderResponseCount, isRead }: Props) => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { user } = useGetLoggedInUser()
  const { openModal, openDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { tender } = useGetTender(tenderUpdate.tender)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { procurement } = useGetProcurement(tender?.procurement)
  const { updateTenderUpdate, createTenderUpdateView } = useMutateTenderUpdate()

  const isOwnProcurement = user?.account.organisation?._id === procurement?.procuringEntity
  const isTenderUpdateViewsEnabled = tender?.isTenderUpdateViewsEnabled

  const handlePin = async (pinned: boolean) => {
    try {
      setIsSaving(true)
      setIsDropdownOpen(false)
      await updateTenderUpdate(tenderUpdate._id, { pinned })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleHidden = async (hidden: boolean) => {
    try {
      setIsSaving(true)
      setIsDropdownOpen(false)
      await updateTenderUpdate(tenderUpdate._id, { hidden })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleViewUpdate = async () => {
    try {
      setIsSaving(true)
      track('forum_update_view_create_submit')

      await createTenderUpdateView({ tenderUpdateId: tenderUpdate._id, tenderId: tenderUpdate.tender })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <div className="relative">
      {isEditable && isOwnProcurement && (
        <>
          <Button
            className={classNames('absolute -top-2', {
              'right-[104px]': isTenderUpdateViewsEnabled,
              'right-0': !isTenderUpdateViewsEnabled,
            })}
            onClick={() => setIsDropdownOpen(true)}
            shape="square"
            state="ghost"
            isDisabled={isSaving}
          >
            <Icon icon="dots" variant="light" size={20} />
          </Button>
          <Dropdown
            className={classNames('top-4', { 'right-[104px]': isTenderUpdateViewsEnabled, 'right-0': !isTenderUpdateViewsEnabled })}
            onClose={() => setIsDropdownOpen(false)}
            isOpen={isDropdownOpen}
          >
            <DropdownContent className="flex items-center" onClick={() => openModal(<TenderUpdateCreateUpdateModal tenderUpdate={tenderUpdate} />)}>
              Edit
            </DropdownContent>
            {tenderUpdate.pinned && (
              <DropdownContent className="flex items-center" onClick={() => handlePin(false)}>
                Unpin
              </DropdownContent>
            )}
            {!tenderUpdate.pinned && (
              <DropdownContent className="flex items-center" onClick={() => handlePin(true)}>
                Pin
              </DropdownContent>
            )}
            {tenderUpdate.hidden && (
              <DropdownContent className="flex items-center" onClick={() => handleHidden(false)}>
                Show
              </DropdownContent>
            )}
            {!tenderUpdate.hidden && (
              <DropdownContent className="flex items-center" onClick={() => handleHidden(true)}>
                Hide
              </DropdownContent>
            )}
          </Dropdown>
          {tender?.isTenderUpdateViewsEnabled && tenderResponseCount !== undefined && (
            <Button
              size="xs"
              className="absolute top-0 right-0"
              state="outline"
              variant="secondary"
              onClick={() =>
                openDrawer(<TenderUpdateViewDrawer tenderId={tenderUpdate.tender} tenderUpdate={tenderUpdate} isEditable={isEditable} />)
              }
            >
              <Icon icon="visible" size={14} className="mr-1" /> Views ({tenderUpdate.views.length}/{tenderResponseCount})
            </Button>
          )}
        </>
      )}
      {!isEditable && !isOwnProcurement && isTenderUpdateViewsEnabled && !isRead && (
        <Button size="xs" className="absolute top-0 right-0" onClick={handleViewUpdate} isLoading={isSaving}>
          <Icon icon="check-square-broken" size={14} className="mr-1" /> Mark as viewed
        </Button>
      )}
      {!isEditable && !isOwnProcurement && isTenderUpdateViewsEnabled && isRead && (
        <div className="absolute top-0 right-0 flex items-center">
          <Icon icon="check-circle" size={14} className="mr-1 text-green-500" />
          <Text>Marked as viewed</Text>
        </div>
      )}
    </div>
  )
})
