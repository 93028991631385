import { FormEvent, memo, useState } from 'react'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Label } from '@cotiss/common/components/label.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Text } from '@cotiss/common/components/text.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { EVALUATION_RATING_SCALE_OPTIONS_MAP } from '@cotiss/evaluation/evaluation.constants'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'
import { useMutateTenderQuestion } from '@cotiss/tender-question/resources/use-mutate-tender-question.resource'
import { TenderQuestionScoreModel } from '@cotiss/tender-question/tender-question.models'
import { userService } from '@cotiss/user/user.service'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  tender: TenderPopulatedModel
  questionScore: TenderQuestionScoreModel
  isEditable?: boolean
  isDisabled?: boolean
}

export const TenderQuestionReportScoreItem = memo(({ tender, questionScore, isEditable, isDisabled }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isCommentaryOpen, setIsCommentaryOpen] = useState(false)
  const { updateTenderQuestionScore } = useMutateTenderQuestion()
  const [groupCommentary, setGroupCommentary] = useState(questionScore.groupCommentary || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateTenderQuestionScore(questionScore._id, { groupCommentary })
      setIsCommentaryOpen(false)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleScoreChange = async (groupScore: string) => {
    try {
      setIsSaving(true)
      await updateTenderQuestionScore(questionScore._id, { groupScore: Number(groupScore) })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleGroupCommentary = (isCommentaryOpen: boolean) => {
    setIsDropdownOpen(false)
    setIsCommentaryOpen(isCommentaryOpen)
    setGroupCommentary(questionScore.groupCommentary || '')
  }

  const renderDelta = () => {
    const { groupScore, score } = questionScore

    if (groupScore === undefined || score === undefined || groupScore === score) {
      return
    }

    if (groupScore < score) {
      return (
        <div className="flex items-center">
          <Icon icon="arrow-down" variant="danger" size={20} />
          <Text variant="danger">{score - groupScore}</Text>
        </div>
      )
    }

    if (groupScore > score) {
      return (
        <div className="flex items-center">
          <Icon icon="arrow-up" variant="success" size={20} />
          <Text variant="success">{groupScore - score}</Text>
        </div>
      )
    }
  }

  return (
    <>
      <tr>
        <td>
          <div className="flex items-center">
            <Avatar className="mr-1">{userService.getInitials(questionScore.evaluation.user)}</Avatar>
            <Text className="font-medium truncate">{userService.getFullName(questionScore.evaluation.user)}</Text>
          </div>
        </td>
        <td>
          <div className="flex items-center">
            {isEditable && (
              <Select_DEPRECATED
                value={questionScore.groupScore?.toString()}
                options={(tender.ratingScale && EVALUATION_RATING_SCALE_OPTIONS_MAP[tender.ratingScale].filter(({ value }) => value !== '')) || []}
                onChange={handleScoreChange}
                size="sm"
                isDisabled={isDisabled || isSaving}
                placeholder="-"
              />
            )}
            {!isEditable && (
              <Text>
                {questionScore.evaluation.status === 'abstained'
                  ? 'abstained'
                  : questionScore.groupScore !== undefined
                  ? questionScore.groupScore
                  : '–'}
              </Text>
            )}
            {renderDelta()}
          </div>
        </td>
        <td className="text-right">
          {!questionScore.groupCommentary && isEditable && (
            <Button onClick={() => handleToggleGroupCommentary(true)} state="text" variant="link" isDisabled={isDisabled || isSaving}>
              + comment
            </Button>
          )}
        </td>
      </tr>
      {isCommentaryOpen && (
        <tr>
          <td className="py-2" colSpan={3}>
            <Form onSubmit={handleSubmit}>
              <Label>Comment</Label>
              <div className="border-l-2 border-secondary-500 pl-2 ml-2">
                <TextArea
                  value={groupCommentary}
                  onChange={({ target }) => setGroupCommentary(target.value)}
                  placeholder="Add comments..."
                  isDisabled={isSaving}
                  isRequired
                />
              </div>
              <Button
                className="ml-5"
                type="submit"
                state="text"
                variant={!groupCommentary ? 'light' : 'link'}
                isDisabled={!groupCommentary}
                isLoading={isDisabled || isSaving}
              >
                Save
              </Button>
              <Button
                className="ml-2"
                onClick={() => handleToggleGroupCommentary(false)}
                state="text"
                variant="link"
                isDisabled={isDisabled || isSaving}
              >
                Cancel
              </Button>
            </Form>
          </td>
        </tr>
      )}
      {!isCommentaryOpen && questionScore.groupCommentary && (
        <tr>
          <td className="py-2" colSpan={2}>
            <div className="relative flex items-start justify-between border-l-2 border-secondary-500 pl-2 ml-2">
              <Text className="mt-1" size="sm" variant="light">
                {questionScore.groupCommentary}
              </Text>
            </div>
          </td>
          <td className="relative">
            {isEditable && (
              <Button
                className="absolute top-0 right-0"
                onClick={() => setIsDropdownOpen(true)}
                shape="square"
                state="ghost"
                size="xs"
                isDisabled={isDisabled || isSaving}
              >
                <Icon icon="dots" variant="light" size={20} />
              </Button>
            )}

            <Dropdown className="top-4 right-2" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent className="flex items-center" onClick={() => handleToggleGroupCommentary(true)}>
                Edit
              </DropdownContent>
            </Dropdown>
          </td>
        </tr>
      )}
    </>
  )
})
