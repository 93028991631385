import { ReactNode, memo } from 'react'
import classNames from 'classnames'

export type SkeletonVariant = 'primary' | 'secondary' | 'gray' | 'none'

type Props = {
  className?: string
  children?: ReactNode
  variant?: SkeletonVariant
  isRounded?: boolean
  isLoading?: boolean
}

export const Skeleton = memo(({ className, children, variant = 'gray', isRounded, isLoading = true }: Props) => {
  const classes = classNames(className, {
    'rounded-lg': !isRounded,
    'rounded-full': isRounded,
    'animate-pulse': isLoading,
    'bg-primary-100': variant === 'primary',
    'bg-secondary-200': variant === 'secondary',
    'bg-gray-200': variant === 'gray',
  })

  return <div className={classes}>{children}</div>
})
