import { memo, useMemo, useState } from 'react'
import { find, includes, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Text } from '@cotiss/common/components/text.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderUpdateExpanded } from '@cotiss/tender-update/components/tender-update-expanded.component'
import { TenderUpdateSkeleton } from '@cotiss/tender-update/components/tender-update-skeleton.component'
import { TenderUpdate } from '@cotiss/tender-update/components/tender-update.component'
import { useListTenderUpdate } from '@cotiss/tender-update/resources/use-list-tender-update.resource'

type Props = {
  tenderId?: string
}

export const ProcurementViewTenderUpdatesTab = memo(({ tenderId }: Props) => {
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { tenderUpdates, isLoading: isTenderUpdatesLoading, meta: tenderUpdatesMeta } = useListTenderUpdate({ tenderId })
  const [expandedTenderUpdateId, setExpandedTenderUpdateId] = useState<null | string>(null)
  const isLoading = isTenderUpdatesLoading || isTenderLoading

  const processedTenderUpdates = useMemo(() => {
    return map(tenderUpdates, (tenderUpdate) => {
      return {
        ...tenderUpdate,
        isRead: !includes(tenderUpdatesMeta?.unviewedUpdatesIds, tenderUpdate._id),
      }
    })
  }, [tenderUpdates, tenderUpdatesMeta])

  const expandedTenderUpdate = useMemo(() => {
    if (!expandedTenderUpdateId) {
      return
    }

    return find(processedTenderUpdates, (update) => update._id === expandedTenderUpdateId)
  }, [expandedTenderUpdateId, processedTenderUpdates])

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {map(Array(3), (_, index) => (
            <TenderUpdateSkeleton key={index} className="mt-4" />
          ))}
        </>
      )
    }

    if (!processedTenderUpdates.length || !tender?._id) {
      return (
        <Text className="text-center" variant="light">
          No updates have been posted.
        </Text>
      )
    }

    return (
      <>
        {!expandedTenderUpdate && Boolean(processedTenderUpdates.length) && (
          <>
            {map(processedTenderUpdates, (tenderUpdate) => (
              <TenderUpdate
                key={tenderUpdate._id}
                className="bg-primary-50 mt-4"
                tenderUpdate={tenderUpdate}
                onReadMore={setExpandedTenderUpdateId}
                isRead={tenderUpdate.isRead}
              />
            ))}
          </>
        )}
        {expandedTenderUpdate && (
          <TenderUpdateExpanded
            className="bg-primary-50"
            tenderUpdate={expandedTenderUpdate}
            onBack={() => setExpandedTenderUpdateId(null)}
            isRead={expandedTenderUpdate.isRead}
          />
        )}
      </>
    )
  }

  return (
    <>
      <div className="flex items-center mb-4">
        <Text className="font-semibold mr-1" variant="heading" size="h5">
          Updates
          {Boolean(processedTenderUpdates.length) && (
            <Text className="ml-2" variant="light" isInline>
              ({processedTenderUpdates.length})
            </Text>
          )}
        </Text>
        {Boolean(tenderUpdatesMeta?.unviewedUpdatesCount) && (
          <Badge className="ml-1" variant="warning" state="translucent">
            {tenderUpdatesMeta?.unviewedUpdatesCount} Pending
          </Badge>
        )}
      </div>
      {renderContent()}
    </>
  )
})
