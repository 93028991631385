import { FormEvent, memo, useMemo, useState } from 'react'
import { find } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { AttachmentSignatureType, ContractDocumentAttachmentSignature } from '@cotiss/contract/contract.model'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { userService } from '@cotiss/user/user.service'
import { UpdateAttachmentSignaturesBulkBody, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Select, SelectOption } from '@cotiss/common/components/select.component'

type SignatureFormData = {
  name: string
  email: string
  signatureType: AttachmentSignatureType
}

type Props = {
  existingSignatures: ContractDocumentAttachmentSignature[]
  signature?: ContractDocumentAttachmentSignature
  contractShellId: string
}

const SIGNATURE_TYPE_OPTIONS: SelectOption<AttachmentSignatureType>[] = [
  { value: 'EXTERNAL', label: 'External' },
  { value: 'INTERNAL', label: 'Internal' },
]

export const ContractSignatureModal = memo(({ existingSignatures, signature, contractShellId }: Props) => {
  const { closeModal } = useCallout()
  const { openToast } = useToast()
  const { contractShell } = useGetContractShell(contractShellId)
  const { users, isLoading: isUserListLoading } = useListUser()
  const { updateAttachmentSignaturesBulk } = useMutateContractShell()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<SignatureFormData>({
    name: signature?.name || '',
    email: signature?.email || '',
    signatureType: signature?.signatureType || 'INTERNAL',
  })

  const { userOptions, canSubmitSignature } = useMemo(
    () => ({
      userOptions: users.map((user) => {
        return { label: userService.getFullName(user), value: user._id }
      }),
      canSubmitSignature: Boolean(formData.email && formData.name && formData.signatureType),
    }),
    [users, formData]
  )

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const contract = contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null
    const documentShell = find(contract?.documentShells, { type: 'CONTRACT' })
    const attachment = documentShell?.attachments.length ? documentShell?.attachments[0] : null

    if (!contractShell || !contract || !documentShell || !attachment) {
      openToast('Something went wrong trying to save. Please try again.', 'danger')
      sentryService.captureException({
        exception: 'Tried to save contract signature but missing information',
        extras: { contractShellId: contractShell?._id },
      })
      return
    }

    let updatedSignatures: UpdateAttachmentSignaturesBulkBody[] = []

    // If we're adding a new signature
    if (!signature) {
      updatedSignatures = [...(existingSignatures || []), { attachment: attachment._id, ...formData }]
    }

    // If we're updating an existing signature
    if (signature) {
      const filteredExistingSignatures = existingSignatures.filter((_signature) => _signature._id !== signature._id)
      updatedSignatures = [...filteredExistingSignatures, { attachment: attachment._id, ...formData }]
    }

    try {
      setIsSaving(true)
      await updateAttachmentSignaturesBulk({
        contractShellId: contractShell._id,
        contractId: contract._id,
        documentShellId: documentShell._id,
        attachmentId: attachment?._id,
        body: { items: updatedSignatures },
      })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    closeModal()
  }

  return (
    <Form className="w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${signature ? 'Edit row' : 'Add row'}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Name</Label>
        {formData.name ? (
          <>
            <Input
              value={formData.name}
              onChange={({ target }) => setFormData({ ...formData, name: target.value })}
              placeholder="Enter full name"
              maxLength={100}
              isDisabled={isSaving || isUserListLoading}
              isRequired
            />
            <Label className="mt-4">Email</Label>
            <Input
              value={formData.email}
              placeholder="Enter email address"
              onChange={({ target }) => setFormData({ ...formData, email: target.value })}
              isDisabled={isSaving}
              isRequired
              type="email"
            />
            <Label className="mt-4">Role</Label>
            <Select
              isDisabled={isSaving}
              value={formData.signatureType}
              options={SIGNATURE_TYPE_OPTIONS}
              onChange={(value) => setFormData({ ...formData, signatureType: value })}
            />
          </>
        ) : (
          <Select
            value={formData.name}
            options={userOptions}
            onChange={(value) => {
              const user = users.find((user) => user._id === value)
              setFormData({ ...formData, email: user?.email || '', name: userService.getFullName(user) })
            }}
            onCreate={(name) => {
              setFormData({ ...formData, name })
            }}
            isDisabled={isSaving || isUserListLoading}
            isRequired
          />
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isDisabled={!canSubmitSignature} isForm />
    </Form>
  )
})
