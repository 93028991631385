import { memo, useState } from 'react'
import { filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { PriceItemResponseAddUpdateModal } from '@cotiss/price-item-response/modals/price-item-response-add-update.modal'
import { PriceItemResponsePopulatedModel } from '@cotiss/price-item-response/price-item-response.models'
import { priceItemResponseService } from '@cotiss/price-item-response/price-item-response.service'
import { useListPriceItemResponse } from '@cotiss/price-item-response/resources/use-list-price-item-response.resource'
import { useMutatePriceItemResponse } from '@cotiss/price-item-response/resources/use-mutate-price-item-response.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'

type Props = {
  tenderResponseId: string
  priceItemResponse: PriceItemResponsePopulatedModel
}

export const TenderResponsePriceTableItemCta = memo(({ tenderResponseId, priceItemResponse }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { tenderResponse } = useGetTenderResponse(tenderResponseId)
  const { tender } = useGetTender(tenderResponse?.tender._id)
  const { upsertPriceItemResponseBulk } = useMutatePriceItemResponse()
  const { priceItemResponses } = useListPriceItemResponse({ tenderResponseId })

  if (!tender) {
    return null
  }

  return (
    <div className="flex items-center">
      {(!priceItemResponse.priceItem || priceItemResponseService.isComplete({ priceItemResponse, tender })) && (
        <>
          <Button
            className="mr-2"
            onClick={() => setIsDropdownOpen(true)}
            shape="square"
            state="translucent"
            variant="secondary-dark"
            size="xs"
            isRounded
          >
            <Icon icon="dots" variant="secondary" />
          </Button>
          <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
            {
              // ! If there is a price item attached to the price item response, then we know it was created as a result of a price item being added to the
              // ! tender by the buyer. Then the supplier should not be able to remove this item.
            }
            <DropdownContent
              onClick={() => openModal(<PriceItemResponseAddUpdateModal tenderResponseId={tenderResponseId} priceItemResponse={priceItemResponse} />)}
            >
              <Text className="text-left">Edit item</Text>
            </DropdownContent>
            {!priceItemResponse.priceItem && (
              <DropdownContent
                isDisabled={Boolean(priceItemResponse?.priceItem)}
                onClick={() =>
                  openModal(
                    <ConfirmModal
                      heading="Remove price item"
                      description="Are you sure you want to remove this price item?"
                      onSubmit={async () => {
                        if (!tenderResponse) {
                          return
                        }

                        const priceItemResponsesToSave = filter(priceItemResponses, ({ _id }) => _id !== priceItemResponse._id)

                        await upsertPriceItemResponseBulk({
                          tenderResponseId,
                          tenderId: tenderResponse.tender._id,
                          items: map(priceItemResponsesToSave, ({ _id, category, description, unit, quantity, paymentDate, unitPrice }, index) => ({
                            priceItemResponseId: _id,
                            description,
                            category,
                            unit,
                            quantity,
                            index,
                            paymentDate,
                            unitPrice,
                          })),
                        })
                      }}
                    />
                  )
                }
              >
                <Text className="text-left">Remove item</Text>
              </DropdownContent>
            )}
          </Dropdown>
        </>
      )}
      {!priceItemResponseService.isComplete({ priceItemResponse, tender }) && (
        <Button
          state="outline"
          variant="secondary"
          size="xs"
          onClick={() => openModal(<PriceItemResponseAddUpdateModal tenderResponseId={tenderResponseId} priceItemResponse={priceItemResponse} />)}
        >
          Complete
        </Button>
      )}
    </div>
  )
})
