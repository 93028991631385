import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { EvaluationEventUserList } from '@cotiss/evaluation-event/components/evaluation-event-user-list.component'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { EvaluationEventUserAddModal } from '@cotiss/evaluation-event/modals/evaluation-event-user-add.modal'

export const EvaluationEventViewTeamUsersTab = memo(() => {
  const { openModal } = useCallout()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationUserInSession } = useEvaluationUser()

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Owners
        </Text>
        {evaluationUserInSession?.role === 'owner' && (
          <Button state="translucent" size="xs" variant="secondary-dark" onClick={() => openModal(<EvaluationEventUserAddModal />)}>
            + Add owner
          </Button>
        )}
      </CardHeader>
      <TableHeader>
        <Text className="font-medium" size="lg">
          Evaluation owners
        </Text>
      </TableHeader>
      <EvaluationEventUserList
        isEditable={evaluationUserInSession?.role === 'owner' && (evaluationEvent?.status === 'draft' || evaluationEvent?.status === 'active')}
      />
    </Card>
  )
})
