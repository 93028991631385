import { ForwardedRef, forwardRef } from 'react'
import classNames from 'classnames'
import { find, indexOf, map } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { TabsVariant } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props<T> = TabModel<T> & {
  className?: string
  variant?: TabsVariant
  activeTab: TabModel<T>
  setActiveTab: (activeTab: TabModel<T>) => void
  tabs: TabModel<T>[]
  isFull?: boolean
}

function TabButtonInner<T>(props: Props<T>, ref: ForwardedRef<HTMLButtonElement>) {
  const { className, id, label, variant, activeTab, setActiveTab, tabs, isHidden, isFull, isDisabled } = props
  const tab = find(tabs, ({ id: idToCheck }) => idToCheck === id)

  if (!tab) {
    throw new Error(`The '${id}' tab does not exist in the tabs [${map(tabs, 'id').join(', ')}].`)
  }

  const isActive = activeTab.id === id
  let classes = classNames(className, 'flex items-center justify-center text-sm whitespace-nowrap focus:outline-none', {
    hidden: isHidden,
    'mr-1': !isFull && variant !== 'underline' && indexOf(tabs, tab) !== tabs.length - 1,
    'mr-5': !isFull && variant === 'underline' && indexOf(tabs, tab) !== tabs.length - 1,
    'is-active': isActive,
    'text-gray-900': isDisabled,
  })

  if (variant === 'underline') {
    classes = classNames(classes, 'py-2.5 border-b-1 border-transparent', {
      'text-opacity-90 text-secondary-500': isActive,
      'text-opacity-40': !isActive,
    })
  }

  if (variant === 'square' || variant === 'rounded') {
    classes = classNames(classes, 'text-opacity-90 rounded-full h-7 px-3 z-1 hover:text-secondary-500', { 'text-secondary-500': isActive })
  }

  if (variant === 'raised') {
    classes = classNames(classes, 'h-7 px-3 z-1')
  }

  const showDisabledTooltip = isDisabled && typeof isDisabled === 'string'

  return (
    <button className={classes} onClick={() => setActiveTab(tab)} type="button" disabled={Boolean(isDisabled)} ref={ref}>
      <Tooltip tooltip={isDisabled} isEnabled={Boolean(showDisabledTooltip)} className="gap-1" maxWidth={250}>
        {showDisabledTooltip ? <Icon icon="lock" /> : null}
        {label}
      </Tooltip>
    </button>
  )
}

export const TabButton = forwardRef(TabButtonInner) as <T>(
  props: Props<T> & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof TabButtonInner>
