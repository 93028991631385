import { memo, useState } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { AccordionIcon } from '@cotiss/common/components/accordion-icon.component'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { ProcessedNonPriceCriterionWithQuestionsItem } from '@cotiss/tender/tender.service'
import { TenderCriterionModel } from '@cotiss/tender-criteria/tender-criteria.models'
import { TenderQuestionAccordionItem, TenderQuestionFormData } from '@cotiss/tender-question/components/tender-question-accordion-item.component'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  className?: string
  tenderCriterion: TenderCriterionModel
  tenderQuestions: TenderQuestionFormData[]
  processedNonPriceCriteria: ProcessedNonPriceCriterionWithQuestionsItem
  onQuestionAdd: (tenderCriterionId: string) => void
  onQuestionUpdate: (indexToUpdate: number, question: TenderQuestionFormData) => void
  onQuestionRemove: (indexToRemove: number, criterionId: string) => void
  isEditing?: boolean
  isDisabled?: boolean
}

export const TenderQuestionAccordion = memo((props: Props) => {
  const {
    className,
    tenderCriterion,
    tenderQuestions,
    processedNonPriceCriteria,
    onQuestionAdd,
    onQuestionUpdate,
    onQuestionRemove,
    isEditing,
    isDisabled,
  } = props
  const [isExpanded, setIsExpanded] = useState(true)
  const classes = classNames(className, 'w-full')
  const thClasses = classNames('bg-secondary-50 border-y border-gray-200 px-4 py-2')

  // TODO: Swap this out with the AccordionToggle and AccordionContent components.

  return (
    <table className={classes}>
      <thead>
        <tr className="cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          <th className="w-12 px-4 py-2">
            <AccordionIcon isExpanded={isExpanded} />
          </th>
          <th className={classNames(thClasses, 'border-l')} colSpan={3}>
            <Text className="font-medium text-left">
              {tenderCriterion.index + 1}. {tenderCriterion.name} ({tenderQuestions.length})
            </Text>
          </th>
          <th className={classNames(thClasses, 'w-24')}>
            <Text className="text-left">{processedNonPriceCriteria.percentage}</Text>
          </th>
          <th className={classNames(thClasses, 'border-r w-16')} />
        </tr>
      </thead>
      {isExpanded && (
        <tbody>
          {Boolean(tenderQuestions.length) && (
            <tr>
              <td className="px-4 py-2" />
              <td className="border-l border-b border-gray-200 px-4 py-2">
                <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                  Question
                </Text>
              </td>
              <td className="border-b border-gray-200 px-4 py-2">
                <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                  Question
                </Text>
                <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                  Raw 1 - 100
                </Text>
              </td>
              <td className="border-b border-gray-200 w-24 px-4 py-2">
                <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                  Question
                </Text>
                <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                  weight
                </Text>
              </td>
              <td className="border-b border-gray-200 px-4 py-2" />
              <td className="border-b border-r border-gray-200" />
            </tr>
          )}
          {map(tenderQuestions, (tenderQuestion, index) => (
            <TenderQuestionAccordionItem
              key={index}
              tenderCriterion={tenderCriterion}
              tenderQuestion={tenderQuestion}
              processedNonPriceCriteria={processedNonPriceCriteria}
              index={index}
              onQuestionUpdate={onQuestionUpdate}
              onQuestionRemove={onQuestionRemove}
              isEditing={isEditing}
              isDisabled={isDisabled}
            />
          ))}
          {!isDisabled && (
            <tr>
              <td />
              <td className="py-2" colSpan={5}>
                <Button onClick={() => onQuestionAdd(tenderCriterion._id)} size="sm" state="translucent" variant="secondary">
                  + Add question
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      )}
    </table>
  )
})
