import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ProcurementResponsePopulatedModel } from '@cotiss/procurement-response/procurement-response.models'

type Props = {
  procurementResponse: ProcurementResponsePopulatedModel
  isDisabled?: boolean
}

export const ProcurementResponseListItemCta = memo(({ procurementResponse, isDisabled }: Props) => (
  <>
    {isDisabled && <Icon icon="lock" variant="light" size={20} />}
    {!isDisabled && (
      <Button
        href={routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', {
          procurementId: procurementResponse.procurement._id,
        })}
        state="outline"
        variant="secondary"
        size="xs"
        isLink
      >
        View <Icon className="ml-1" icon="arrow-right" />
      </Button>
    )}
  </>
))
