import { ForwardedRef, forwardRef, ReactNode } from 'react'
import classNames from 'classnames'
import { find, indexOf, map } from 'lodash'
import { Link } from 'react-router-dom'
import { Icon } from '@cotiss/common/components/icon.component'
import { TasksRemainingChip } from '@cotiss/common/components/tasks-remaining-chip.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'

type Props<T> = {
  className?: string
  id: T
  label: ReactNode
  tasksRemaining?: number
  activeTab?: VerticalTabModel<T>
  setActiveTab: (activeTab: VerticalTabModel<T>) => void
  tabs: VerticalTabModel<T>[]
  isFull?: boolean
  isLocked?: boolean
  isDisabled?: boolean
  href?: string
}

function VerticalTabButtonInner<T>(props: Props<T>, ref: ForwardedRef<HTMLButtonElement | HTMLAnchorElement>) {
  const { className, id, label, tasksRemaining, activeTab, setActiveTab, tabs, isDisabled, isLocked, href } = props
  const tab = find(tabs, ({ id: idToCheck }) => idToCheck === id)

  if (!tab) {
    throw new Error(`The '${id}' tab does not exist in the tabs [${map(tabs, 'id').join(', ')}].`)
  }

  const isActive = activeTab?.id === id
  const classes = classNames(
    className,
    'relative flex items-center justify-start text-left text-sm w-full h-7 px-3 mt-1 z-1 hover:text-secondary-500 focus:outline-none',
    {
      'mb-2': indexOf(tabs, tab) !== tabs.length - 1,
      'text-secondary-500 is-active': isActive,
      'text-secondary-500 pointer-events-none opacity-30': isDisabled || isLocked,
      'text-gray-700': !isDisabled && !isLocked,
    }
  )

  const renderContent = () => {
    return (
      // Wrapping the content in a div, so we can get the exact width of the content without having to hard code the padding offset.
      <div className="flex whitespace-nowrap">
        {isLocked && (
          <div className="flex items-center justify-center shrink-0 bg-secondary-200 rounded h-5 w-5 mr-2 z-10">
            <Icon icon="lock" size={16} variant="secondary" />
          </div>
        )}
        {tasksRemaining !== undefined && !isLocked && !isDisabled && (
          <TasksRemainingChip className="mr-2 z-10" tasksRemaining={tasksRemaining} size="sm" state="translucent" />
        )}
        {label}
      </div>
    )
  }

  if (href && !isDisabled) {
    return (
      <Link className={classes} onClick={() => setActiveTab(tab)} type="button" to={href} ref={ref as ForwardedRef<HTMLAnchorElement>}>
        {renderContent()}
      </Link>
    )
  }

  return (
    <button className={classes} onClick={() => setActiveTab(tab)} type="button" disabled={isDisabled} ref={ref as ForwardedRef<HTMLButtonElement>}>
      {renderContent()}
    </button>
  )
}

export const VerticalTabButton = forwardRef(VerticalTabButtonInner) as <T>(
  props: Props<T> & { ref?: ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof VerticalTabButtonInner>
