import { memo, useMemo } from 'react'
import { filter, map } from 'lodash'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { utilService } from '@cotiss/common/services/util.service'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractSummaryExercisedTab = memo(({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const newExercisedPeriods = useMemo(() => {
    const contract = contractShell?.contracts.find((contract) => contract._id === contractId)

    // The exercised price durations from the current contract/variation (in DRAFTING)
    const currentExercisedPriceDurations = filter(contract?.priceDurations, (pd) => Boolean(pd.exercised))

    // The ids of the exercised price durations from the last approved contract/variation
    const latestApprovedContract = contractShell ? contractService.getContract(contractShell, ['PUBLISHED']) : null
    const approvedExercisedPriceDurationReferenceIds = map(
      filter(latestApprovedContract?.priceDurations, (pd) => Boolean(pd.exercised)),
      (pd) => pd.referenceId
    )

    const newExercisedPeriods = filter(currentExercisedPriceDurations, (pd) => !approvedExercisedPriceDurationReferenceIds.includes(pd.referenceId))

    return contractService.parseContractPriceDurations(newExercisedPeriods)
  }, [contractShell, contractId])

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Term',
      rows: map(newExercisedPeriods, ({ label }) => ({
        content: () => (
          <Text className="font-semibold truncate" variant="light">
            {label}
          </Text>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Dates',
      rows: map(newExercisedPeriods, ({ startDate, endDate }) => ({
        content: () => (
          <Text variant="light">
            {startDate && endDate ? `${datetimeService.format(startDate, 'do MMM yyyy')} - ${datetimeService.format(endDate, 'do MMM yyyy')}` : '--'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Duration',
      rows: map(newExercisedPeriods, ({ length }) => ({
        content: () => <Text variant="light">{`${length} months`}</Text>,
      })),
    },
    {
      heading: 'Total',
      rows: map(newExercisedPeriods, ({ value, variation }) => ({
        content: () => <Text variant="light">{utilService.formatAsCurrency(value + variation)}</Text>,
      })),
    },
  ]

  if (isLoading) {
    return (
      <div className="p-6">
        <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
      </div>
    )
  }

  if (!newExercisedPeriods.length) {
    return <NoDataPlaceholder label="No new periods exercised" />
  }

  return <ScrollableTable fixedColumnsWidth={350} fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
})
