import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, includes, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { ProcurementListPopulatedModel } from '@cotiss/procurement/procurement.models'
import { useListProcurement } from '@cotiss/procurement/resources/use-list-procurement.resource'
import { TenderModel, TenderStatus } from '@cotiss/tender/tender.models'
import { TenderTypeBadge } from '@cotiss/tender/components/tender-type-badge.component'
import { useListTender } from '@cotiss/tender/resources/use-list-tender.resource'
import { Button } from '@cotiss/common/components/button.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { useListProject } from '@cotiss/project/resources/use-list-project.resource'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type SortKey = 'title' | 'date'

export const EvaluationEventSubmissionImportFromLegacyTenderDrawer = memo(() => {
  const { openModal, closeDrawer } = useCallout()
  const { evaluationEvent } = useEvaluationEvent()
  const { step, transition, onTransition } = useTransition()
  const [currentTenderPage, setCurrentTenderPage] = useState(1)
  const { projects, isLoading: isProjectsLoading } = useListProject()
  const [currentProcurementPage, setCurrentProcurementPage] = useState(1)
  const { procurements, meta, isLoading: isProcurementsLoading } = useListProcurement({ pageSize: 2000 })
  const { queryEvaluationSubmissionList, mutateImportEvaluationSubmissionsFromLegacyTender } = useEvaluationSubmission()
  const { sortKey, sortDirection, onSort } = useSortTable<SortKey>({ initialKey: 'date' })
  const [selectedProcurement, setSelectedProcurement] = useState<ProcurementListPopulatedModel | null>(null)
  const { tenders, isLoading: isTendersLoading } = useListTender({ procurementId: selectedProcurement?._id })
  const isLoading = isProjectsLoading || isProcurementsLoading

  const { processedProcurements, pagination } = useMemo(() => {
    const result = procurements.sort((a, b) => {
      if (sortKey === 'title') {
        return sortService.sortString(a.title || '', b.title || '')
      } else if (sortKey === 'date') {
        return sortService.sortDate(a.createdAt, b.createdAt)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: processedProcurements, pagination } = paginationService.paginate(sortedResult, {
      currentPage: currentProcurementPage,
      pageSize: 15,
    })

    return { processedProcurements, pagination }
  }, [procurements, sortKey, sortDirection, currentProcurementPage])

  useEffect(() => {
    onTransition({ transition: selectedProcurement ? 'right' : 'left', step: selectedProcurement ? 2 : 1 })
  }, [selectedProcurement])

  const handleImport = async (tender: TenderModel) => {
    if (!evaluationEvent) {
      return
    }

    await mutateImportEvaluationSubmissionsFromLegacyTender({ evaluationEventId: evaluationEvent.id, tenderId: tender._id })
    await queryEvaluationSubmissionList({ filter: { evaluationEventId: evaluationEvent.id } })
    closeDrawer()
  }

  const renderProcurements = () => {
    if (!isLoading && !processedProcurements.length) {
      return <NoDataPlaceholder label="You haven't created any procurements yet" />
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Procurement name',
        onSort: () => onSort('title'),
        rows: map(processedProcurements, (procurement) => ({
          content: () => (
            <Text className="truncate" title={procurement.title}>
              {procurement.title}
            </Text>
          ),
          cta: includes(meta?.hasAccess, procurement._id) ? (
            <TableRowCta
              cta={{
                label: (
                  <>
                    Select <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                onClick: () => setSelectedProcurement(procurement),
              }}
            />
          ) : (
            <Tooltip tooltip="You do not have access to this procurement.">
              <Icon icon="lock" variant="light" />
            </Tooltip>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        onSort: () => onSort('date'),
        rows: map(processedProcurements, ({ createdAt }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Reference',
        rows: map(processedProcurements, ({ internalIdentifier }) => ({
          content: () => (
            <Text className="truncate" size="sm">
              {internalIdentifier}
            </Text>
          ),
        })),
      },
      {
        heading: 'Project',
        rows: map(processedProcurements, ({ project: projectId }) => {
          const project = find(projects, { _id: projectId })

          return {
            content: () => (
              <Text className="truncate max-w-[200px]" size="sm" title={project?.title || '--'}>
                {project?.title || '--'}
              </Text>
            ),
          }
        }),
      },
      {
        heading: 'Type',
        rows: map(processedProcurements, ({ tenders }) => ({
          content: () => (
            <>
              {map(tenders, (tender) => (
                <TenderTypeBadge key={tender._id} className="mr-1" tenderType={tender.tenderType} />
              ))}
            </>
          ),
        })),
      },
      {
        heading: 'Users',
        rows: map(processedProcurements, ({ accessControlUsers }) => ({
          content: () => <UserAvatarGroup users={accessControlUsers} />,
        })),
      },
    ]

    return (
      <>
        <TableHeader>
          <Text className="font-semibold">Select the procurement that contains the sourcing event.</Text>
          <Text className="mt-1" size="sm" variant="light">
            Hover over relevant procurement to select the sourcing event that you would like to import from.
          </Text>
        </TableHeader>
        <ScrollableTable
          fixedColumns={fixedColumns}
          columns={columns}
          pagination={pagination}
          onPageChange={setCurrentProcurementPage}
          isLoading={isLoading}
        />
      </>
    )
  }

  const renderTenders = () => {
    const IS_EXCLUDED_TENDER_STATUSES: TenderStatus[] = ['complete', 'cancelled', 'unsuccessful', 'withdrawn']
    const IS_IN_PROGRESS_TENDER_STATUSES: TenderStatus[] = ['planning', 'planned-approval', 'active', 'review']
    const filteredTenders = filter(tenders, ({ title, status }) => Boolean(title) && !includes(IS_EXCLUDED_TENDER_STATUSES, status))

    if (!isLoading && !filteredTenders.length) {
      return <NoDataPlaceholder label="This procurement does not have any tenders" />
    }

    const { items: processedTenders, pagination } = paginationService.paginate(filteredTenders, { currentPage: currentTenderPage, pageSize: 15 })

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Sourcing event',
        rows: map(processedTenders, (tender) => ({
          content: () => <Text className="truncate">{tender.title}</Text>,
          cta: includes(IS_IN_PROGRESS_TENDER_STATUSES, tender.status) ? (
            <Tooltip tooltip="Cannot import a sourcing event that is still in progress.">
              <Icon icon="lock" variant="light" />
            </Tooltip>
          ) : (
            <TableRowCta
              actions={[
                { label: 'View sourcing event', href: routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId: tender._id }) },
              ]}
              cta={{
                label: 'Import',
                onClick: () =>
                  openModal(
                    <ConfirmModal
                      heading="Import submissions to evaluation"
                      description={`Import submissions from ${tender.title}`}
                      onSubmit={() => handleImport(tender)}
                    />
                  ),
              }}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Type',
        rows: map(processedTenders, ({ tenderType }) => ({
          content: () => <TenderTypeBadge tenderType={tenderType} />,
        })),
      },
      {
        heading: 'Status',
        rows: map(processedTenders, ({ status }) => ({
          content: () =>
            includes(IS_IN_PROGRESS_TENDER_STATUSES, status) ? (
              <Badge variant="neutral" state="outline">
                In progress
              </Badge>
            ) : (
              <Badge variant="success" state="outline">
                Complete
              </Badge>
            ),
        })),
      },
      {
        heading: 'Close date',
        rows: map(processedTenders, ({ tenderPeriod }) => ({
          content: () => (
            <Text variant="light" size="sm">
              {tenderPeriod?.endDate ? datetimeService.format(tenderPeriod?.endDate, 'do MMM yyyy') : '--'}
            </Text>
          ),
        })),
      },
    ]

    return (
      <>
        <TableHeader>
          <Text className="font-semibold">
            <Text className="mr-1" variant="light" isInline>
              Procurement:
            </Text>
            {selectedProcurement?.title || ''}
          </Text>
          <Text className="mt-1" size="sm" variant="light">
            This is the list of sourcing events within this procurement. Submissions can only be imported from reviewed sourcing events.
          </Text>
        </TableHeader>
        <ScrollableTable
          fixedColumns={fixedColumns}
          columns={columns}
          pagination={pagination}
          onPageChange={setCurrentTenderPage}
          isLoading={isTendersLoading}
        />
      </>
    )
  }

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Import from sourcing event
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && renderProcurements()}
          {step === 2 && (
            <>
              <Button className="mb-4" onClick={() => setSelectedProcurement(null)} state="translucent" variant="secondary" size="xs" isLink>
                <Icon className="mr-1" icon="arrow-left" />
                Back
              </Button>
              {renderTenders()}
            </>
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
