import { memo, useMemo } from 'react'
import { map, upperFirst } from 'lodash'
import { Icon } from '@cotiss/common/components/icon.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ContractShellAuditHistoryField } from '@cotiss/contract/contract.model'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { userService } from '@cotiss/user/user.service'

type Props = {
  contractShellId: string
  field: ContractShellAuditHistoryField
}

export const ContractHistoryModal = memo(({ contractShellId, field }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { metafields } = useListMetafield({ entityType: 'CONTRACT' })
  const { metafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShell?.contracts, ({ _id }) => _id),
  })

  const history = useMemo(
    () => (contractShell ? contractService.getContractShellAuditHistory({ contractShell, field, metafields, metafieldValues }) : []),
    [field, contractShell]
  )

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />
          <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
          <Skeleton className="bg-primary-200 h-16 w-full" />
        </>
      )
    }

    if (!history.length) {
      return <NoDataPlaceholder label="No changes made" />
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Activity',
        rows: map(history, ({ type, value, fieldName }) => ({
          content: () => (
            <div className="flex items-center">
              <Icon className="mr-4" variant="secondary" icon={type === 'added' ? 'circle-add' : 'refresh-02'} />
              <div className="w-full truncate">
                <Text>{`${upperFirst(fieldName.replaceAll('-', ' '))} ${type}`}</Text>
                <Text variant="light" size="sm" className="w-full truncate" title={value}>
                  {value}
                </Text>
              </div>
            </div>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Actioned by',
        rows: map(history, ({ actionedBy }) => ({
          content: () => (
            <Text className="truncate w-48" title={userService.getFullName(actionedBy)}>
              {userService.getFullName(actionedBy)}
            </Text>
          ),
        })),
      },
      {
        heading: 'Timestamp',
        rows: map(history, ({ timestamp }) => ({
          content: () => <Text>{datetimeService.format(timestamp, 'd MMMM yyyy h:mm aaa')}</Text>,
        })),
      },
      {
        heading: 'Approved by',
        rows: map(history, ({ approvedBy }) => ({
          content: () => (
            <>
              {!approvedBy && <Text>--</Text>}
              {approvedBy && approvedBy.length <= 1 && (
                <Text title={userService.getFullName(approvedBy[0])} className="truncate w-48">
                  {userService.getFullName(approvedBy[0])}
                </Text>
              )}
              {approvedBy && approvedBy.length > 1 && <UserAvatarGroup users={approvedBy || []} />}
            </>
          ),
        })),
      },
    ]

    return <ScrollableTable fixedColumns={fixedColumns} columns={columns} state="split" variant="primary" />
  }

  return (
    <>
      <ModalHeader heading="View history" />
      <ModalContent>{renderContent()}</ModalContent>
    </>
  )
})
