import { FormEvent, memo, useState } from 'react'
import { isEmpty } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { MetafieldFormArrayString } from '@cotiss/metafield/components/metafield-form-array-string.component'
import { MetafieldFormDate } from '@cotiss/metafield/components/metafield-form-date.component'
import { MetafieldFormDatetime } from '@cotiss/metafield/components/metafield-form-datetime.component'
import { MetafieldFormHyperlink } from '@cotiss/metafield/components/metafield-form-hyperlink.component'
import { MetafieldFormLongString } from '@cotiss/metafield/components/metafield-form-long-string.component'
import { MetafieldFormMultiArrayString } from '@cotiss/metafield/components/metafield-form-multi-array-string.component'
import { MetafieldFormNumber } from '@cotiss/metafield/components/metafield-form-number.component'
import { MetafieldFormShortString } from '@cotiss/metafield/components/metafield-form-short-string.component'
import { MetafieldModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'
import { useMutateMetafieldValue } from '@cotiss/metafield-value/resources/use-mutate-metafield-value.resource'

type Props = {
  resourceId: string
  metafield: MetafieldModel
  metafieldValue?: MetafieldValueModel
}

export const MetafieldFormEditModal = memo(({ resourceId, metafield, metafieldValue }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState(metafieldValue?.fieldValue || {})
  const { createMetafieldValue, updateMetafieldValue } = useMutateMetafieldValue()
  const { track } = useAnalytics()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // If there is no formData, then close the modal, so we don't store empty object in the database.
    if (isEmpty(formData)) {
      closeModal()
      return
    }

    track('contract_wizard_metadata_update_submit')

    setIsSaving(true)
    try {
      !metafieldValue && (await createMetafieldValue({ metafieldId: metafield._id, resourceId, fieldValue: formData }))
      metafieldValue && (await updateMetafieldValue(metafieldValue._id, { fieldValue: formData }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={metafield.fieldLabel} isDisabled={isSaving} />
      <ModalContent>
        <Label>{metafield.fieldLabel}</Label>
        {metafield.fieldType === 'SHORT_STRING' && (
          <MetafieldFormShortString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'HYPERLINK' && (
          <MetafieldFormHyperlink metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'LONG_STRING' && (
          <MetafieldFormLongString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'ARRAY_STRING' && (
          <MetafieldFormArrayString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'MULTI_ARRAY_STRING' && (
          <MetafieldFormMultiArrayString metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />
        )}
        {metafield.fieldType === 'NUMBER' && <MetafieldFormNumber metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />}
        {metafield.fieldType === 'DATE' && <MetafieldFormDate metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />}
        {metafield.fieldType === 'DATETIME' && <MetafieldFormDatetime metafield={metafield} metafieldValue={metafieldValue} onChange={setFormData} />}
        {metafield.fieldDescription && (
          <Text className="mt-2" size="sm" variant="light">
            {metafield.fieldDescription}
          </Text>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
