import { useState, useEffect } from 'react'
import { supplierService } from '../supplier.service'
import { ProcessedUnspscSubcategory } from '@cotiss/supplier/supplier.models'

export const useGetUnspscCategoriesByCodes = (categoryCodes: string[]) => {
  const [categories, setCategories] = useState<ProcessedUnspscSubcategory[]>([])
  const [isLoadingCategories, setIsLoadingCategories] = useState(false)

  useEffect(() => {
    const loadCategories = async () => {
      setIsLoadingCategories(true)
      try {
        const fetchedCategories = await supplierService.getCategoriesFromCodes(categoryCodes)
        setCategories(fetchedCategories)
      } finally {
        setIsLoadingCategories(false)
      }
    }

    if (categoryCodes?.length) {
      loadCategories()
    }
  }, [categoryCodes])

  return {
    categories,
    isLoadingCategories,
  }
}
