import { memo, Suspense } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { AppSideNav } from '@cotiss/app/components/app-side-nav.component'
import { SupplierInvitationPage } from '@cotiss/supplier/pages/supplier-invitation.page'
import ApprovalLayout from '@cotiss/approval/approval.layout'
import ContractLayout from '@cotiss/contract/contract.layout'
import SsoConfigurationPage from '@cotiss/auth/pages/sso-configuration.page'
import BuyerLayout from '@cotiss/buyer/buyer.layout'
import PlanEventLayout from '@cotiss/plan-event/plan-event.layout'
import TenderFlowLayout from '@cotiss/tender-flow/tender-flow.layout'
import PerformanceLayout from '@cotiss/performance/performance.layout'
import ProcurementLayout from '@cotiss/procurement/procurement.layout'
import EvaluationEventLayout from '@cotiss/evaluation-event/evaluation-event.layout'
import TenderInvitationLayout from '@cotiss/tender-invitation/tender-invitation.layout'
import PreferredSupplierLayout from '@cotiss/preferred-supplier/preferred-supplier.layout'
import ConflictOfInterestLayout from '@cotiss/conflict-of-interest/conflict-of-interest.layout'
import DocumentLayout from '@cotiss/document/document.layout'
import EvaluationLayout from '@cotiss/evaluation/evaluation.layout'
import ProcurementResponseLayout from '@cotiss/procurement-response/procurement-response.layout'
import ProjectLayout from '@cotiss/project/project.layout'
import SettingsLayout from '@cotiss/settings/settings.layout'
import SupplierLayout from '@cotiss/supplier/supplier.layout'
import TaskLayout from '@cotiss/task/task.layout'

export const AppLayout = memo(() => (
  <div className="flex h-full">
    <AppSideNav />
    <main className="h-full w-full overflow-y-auto">
      <Suspense fallback={<div className="bg-primary-50 h-full w-full" />}>
        <Switch>
          <Redirect from="/" to="/settings" exact />

          {/* ! This is a legacy route that we want to support incase there are any old invitation emails hanging around. */}
          <Route path="/supplier-invitation" exact>
            <SupplierInvitationPage />
          </Route>

          <Route path="/tender-flow">
            <TenderFlowLayout />
          </Route>
          <Route path="/procurement-response">
            <ProcurementResponseLayout />
          </Route>
          <Route path="/project">
            <ProjectLayout />
          </Route>
          <Route path="/conflict-of-interest">
            <ConflictOfInterestLayout />
          </Route>
          <Route path="/contract">
            <ContractLayout />
          </Route>
          <Route path="/procurement">
            <ProcurementLayout />
          </Route>
          <Route path="/buyer">
            <BuyerLayout />
          </Route>
          <Route path="/supplier">
            <SupplierLayout />
          </Route>
          <Route path="/preferred-supplier">
            <PreferredSupplierLayout />
          </Route>
          <Route path="/task">
            <TaskLayout />
          </Route>
          <Route path="/evaluation">
            <EvaluationLayout />
          </Route>
          <Route path="/evaluation-event">
            <EvaluationEventLayout />
          </Route>
          <Route path="/performance">
            <PerformanceLayout />
          </Route>
          <Route path="/plan-event">
            <PlanEventLayout />
          </Route>
          <Route path="/approval">
            <ApprovalLayout />
          </Route>
          <Route path="/settings">
            <SettingsLayout />
          </Route>
          <Route path="/document">
            <DocumentLayout />
          </Route>
          <Route path="/tender-invitation">
            <TenderInvitationLayout />
          </Route>
          <Route path="/sso-configuration">
            <SsoConfigurationPage />
          </Route>
          <FourOhFourPage />
        </Switch>
      </Suspense>
    </main>
  </div>
))
