import { FormEvent, memo, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment, GqlEvaluationFieldsFragment, GqlEvaluationScoreFieldsFragment } from '@gql'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationScore } from '@cotiss/evaluation-event/hooks/use-evaluation-score.hook'
import { useEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'

type Props = {
  evaluationCriteria: GqlEvaluationCriteriaFieldsFragment
  evaluation?: GqlEvaluationFieldsFragment | null
  evaluationScore?: GqlEvaluationScoreFieldsFragment
  onChange: () => void
  isModeration?: boolean
}

export const EvaluationEventScoreCommentCreateUpdateModal = memo((props: Props) => {
  const { evaluation, evaluationScore, onChange, isModeration } = props
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { mutateUpdateEvaluation } = useEvaluation()
  const { mutateUpdateEvaluationScore } = useEvaluationScore()
  const [comment, setComment] = useState(isModeration ? evaluationScore?.moderationComment || '' : evaluationScore?.comment || '')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      if (!evaluationScore || !evaluation) {
        return
      }

      setIsSaving(true)
      if (isModeration) {
        await mutateUpdateEvaluationScore({
          evaluationScoreId: evaluationScore.id,
          moderationComment: comment,
        })
      } else {
        await mutateUpdateEvaluationScore({
          evaluationScoreId: evaluationScore.id,
          comment,
        })

        // This will update the status to in progress if it isn't already, and will also update the cached evaluation with the updated overview and
        // breakdown properties.
        await mutateUpdateEvaluation({ evaluationId: evaluation.id, status: 'inProgress' })
      }

      onChange()
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader
        heading={`${isModeration ? (evaluationScore?.moderationComment ? 'Edit' : 'Add') : evaluationScore?.comment ? 'Edit' : 'Add'} comment`}
      />
      <ModalContent>
        <Label>Comment</Label>
        <TextArea
          value={comment}
          onChange={({ target }) => setComment(target.value)}
          placeholder="Add comment"
          rows={4}
          isDisabled={isSaving}
          autoFocus
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
