import { memo } from 'react'
import classNames from 'classnames'
import { differenceInDays, formatDistanceToNowStrict, isBefore, parseISO } from 'date-fns'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'

export const ContractReminderCountDown = memo(({ reminderDate }: { reminderDate: string }) => {
  const today = new Date()
  const date = parseISO(reminderDate)
  const isPast = isBefore(date, today)
  const isUrgent = reminderDate && differenceInDays(parseISO(reminderDate), new Date()) < 10

  return (
    <div className={classNames('flex items-center space-x-1', { 'text-alert-error-400': isUrgent })}>
      <Icon icon="clock" size={12} />
      <Text size="sm">
        {formatDistanceToNowStrict(date)} {isPast ? 'ago' : ''}
      </Text>
    </div>
  )
})
