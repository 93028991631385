import { useMemo, useState } from 'react'

import { Text } from '@cotiss/common/components/text.component'

import { sentryService } from '@cotiss/common/services/sentry.service'
import { GqlPagination, GqlPerformanceScorecardUserFieldsFragment } from '@gql'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { userService } from '@cotiss/user/user.service'
import { map } from 'lodash'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'

type Props = {
  isArchived: boolean
  onSubmit: () => void | Promise<unknown>
  performanceScorecardId: string
}

export const PerformanceScorecardMetricArchiveStatusConfirmationModal = ({ isArchived, performanceScorecardId, onSubmit }: Props) => {
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()

  const [performanceScorecardUsers, setPerformanceScorecardUsers] = useState<GqlPerformanceScorecardUserFieldsFragment[]>([])
  const { performanceScorecardUserInSession, queryPerformanceScorecardUserList } = usePerformanceScorecardUser()

  const refreshPerformanceScorecardUsers = async () => {
    try {
      setIsLoading(true)
      const { items: performanceScorecardUsers, pagination } = await queryPerformanceScorecardUserList({
        filter: { performanceScorecardId, role: 'evaluator' },
        pagination: {
          page: currentPage,
          pageSize: 100,
        },
      })

      setPerformanceScorecardUsers(performanceScorecardUsers)
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }

  useAsyncEffect(async () => {
    refreshPerformanceScorecardUsers()
  }, [])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        rows: map(performanceScorecardUsers, (performanceScorecardUser) => {
          const fullName = userService.getFullName(performanceScorecardUser.user)

          return {
            content: () => (
              <div className="flex items-center truncate">
                <Text className="truncate" title={fullName}>
                  {fullName}
                </Text>
                {performanceScorecardUserInSession?.id === performanceScorecardUser.id && (
                  <Text className="ml-1" variant="light">
                    (you)
                  </Text>
                )}
              </div>
            ),
          }
        }),
      },
    ]
    return { columns }
  }, [performanceScorecardUsers, performanceScorecardUserInSession])

  return (
    <ConfirmModal
      size="md"
      heading={`${isArchived ? 'Unarchive' : 'Archive'} Metric`}
      description={
        <div>
          {isArchived ? (
            <Text>
              {`Scoring for this metric will now activate.${
                performanceScorecardUsers.length > 0
                  ? ' All evaluators that were previously assigned will be notified when the next cycle is generated.'
                  : ''
              }`}
            </Text>
          ) : (
            <div>
              <Text>
                {`Scoring for this metric will be paused while it is archived.${
                  performanceScorecardUsers.length > 0
                    ? ' Once archived, evaluators assigned to this metric will no longer be able to score it.'
                    : ' The metric can be unarchived at any time.'
                }`}
              </Text>
              {performanceScorecardUsers.length > 0 && <Text className="mt-4">The metric can be unarchived at any time. </Text>}
            </div>
          )}

          {!isError && performanceScorecardUsers.length > 0 && (
            <div className="mt-4">
              <TableHeader className="flex items-center justify-between" variant="white">
                <div>
                  <Text className="ml-1" variant="light" size="sm">
                    EVALUATORS ASSIGNED
                  </Text>
                </div>
              </TableHeader>
              <div>
                <Table columns={columns} isLoading={isLoading} pagination={pagination} onPageChange={setCurrentPage} />
              </div>
            </div>
          )}
        </div>
      }
      onSubmit={onSubmit}
    />
  )
}
