import { memo, useState } from 'react'
import { map } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TENDER_TYPES } from '@cotiss/tender/tender.constants'
import { TenderType } from '@cotiss/tender/tender.models'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TenderFlowCreateCard } from '@cotiss/tender-flow/components/tender-flow-create-card.component'

type Props = {
  procurementId: string
}

export const TenderCreateModal = memo(({ procurementId }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { createTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)

  const handleClick = async (tenderType: TenderType) => {
    try {
      setIsSaving(true)
      const tender = await createTender({ procurementId, tenderType })
      push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId: tender._id }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="min-w-[900px] max-w-[900px]">
      <ModalHeader heading="Select request type" isDisabled={isSaving} />
      <ModalContent className="grid grid-cols-3 grid-rows-2 gap-6">
        {map(TENDER_TYPES, (tenderType) => (
          <TenderFlowCreateCard key={tenderType} tenderType={tenderType} onClick={handleClick} isDisabled={isSaving} />
        ))}
      </ModalContent>
    </div>
  )
})
