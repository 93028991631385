import { memo } from 'react'
import { map } from 'lodash'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useListPriceItem } from '@cotiss/price-item/resources/use-list-price-item.resource'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  className?: string
  tenderId?: string
}

export const TenderPriceTable = memo(({ className, tenderId }: Props) => {
  const { priceItems, isLoading } = useListPriceItem({ tenderId })

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Category',
      rows: map(priceItems, ({ category }) => ({
        content: () => <Text size="sm">{category}</Text>,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Description',
      rows: map(priceItems, ({ description }) => ({
        content: () => <Text size="sm">{description}</Text>,
      })),
    },
    {
      heading: 'Quantity',
      rows: map(priceItems, ({ quantity }) => ({
        content: () => <Text size="sm">{quantity}</Text>,
      })),
    },
    {
      heading: 'Unit',
      rows: map(priceItems, ({ unit }) => ({
        content: () => <Text size="sm">{unit}</Text>,
      })),
    },
  ]

  return (
    <div className={className}>
      <div className="bg-primary-100 border-x border-t border-primary-200 rounded-t-lg px-4 py-3 mt-4">
        <Text className="font-semibold" variant="heading">
          Line items
        </Text>
      </div>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />
    </div>
  )
})
