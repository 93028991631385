import { memo } from 'react'
import { Banner } from '@cotiss/common/components/banner.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import {
  ORGANISATION_CANADA_SUBDIVISION_OPTIONS,
  ORGANISATION_COUNTRY_OPTIONS,
  ORGANISATION_ENTITY_TYPE_OPTIONS,
  ORGANISATION_US_SUBDIVISION_OPTIONS,
} from '@cotiss/organisation/organisation.constants'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { PreferredSupplierAddUsersTable } from '@cotiss/preferred-supplier/components/preferred-supplier-add-users-table.component'
import { PreferredSupplierAddFormData } from '@cotiss/preferred-supplier/drawers/preferred-supplier-add.drawer'

type Props = {
  breadcrumbs: BreadcrumbModel[]
  formData: PreferredSupplierAddFormData
  isDisabled: boolean
  isSaving: boolean
  isTenderInvitation?: boolean
  onBack: () => void
  setFormData: (formData: PreferredSupplierAddFormData) => void
  showWarningBanner?: boolean
}

export const PreferredSupplierAddDetailsStep = memo((props: Props) => {
  const { breadcrumbs, formData, isDisabled, isSaving, isTenderInvitation, onBack, setFormData, showWarningBanner } = props
  const processedCountryCode = formData.countryCode === 'CA' || formData.countryCode === 'US' ? formData.countryCodeSubdivision : formData.countryCode

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} onBack={onBack} />
      {showWarningBanner && (
        <Banner className="mt-4" icon="alert-circle" variant="light" iconVariant="danger">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Hmm we couldn&apos;t find a record of this contact
            </Text>
            <Text size="sm">Add additional information to add them to your list</Text>
          </div>
        </Banner>
      )}
      <Label className="mt-6">Entity type</Label>
      <Select_DEPRECATED
        value={formData.entityType}
        options={ORGANISATION_ENTITY_TYPE_OPTIONS}
        onChange={(entityType) => setFormData({ ...formData, entityType })}
        placeholder
        isRequired
      />
      <Label className="mt-6">Country</Label>
      <Select_DEPRECATED
        value={formData.countryCode}
        options={ORGANISATION_COUNTRY_OPTIONS}
        onChange={(countryCode) => setFormData({ ...formData, countryCode, countryCodeSubdivision: undefined })}
        placeholder
        isRequired
      />
      {(formData.countryCode === 'CA' || formData.countryCode === 'US') && (
        <>
          <Label className="mt-6">Region</Label>
          <Select_DEPRECATED
            value={formData.countryCodeSubdivision}
            options={formData.countryCode === 'CA' ? ORGANISATION_CANADA_SUBDIVISION_OPTIONS : ORGANISATION_US_SUBDIVISION_OPTIONS}
            onChange={(countryCodeSubdivision) => setFormData({ ...formData, countryCodeSubdivision })}
            placeholder
            isRequired
          />
        </>
      )}
      <Label className="mt-6">Contact name</Label>
      <Input
        value={formData.organisationName}
        onChange={({ target }) => setFormData({ ...formData, organisationName: target.value })}
        placeholder="Enter contact name"
        maxLength={100}
        isRequired
      />
      {processedCountryCode && (
        <>
          <Label className="mt-6">
            {organisationService.getRegistryNumberType(processedCountryCode)}
            {formData.entityType === 'Sole trader' ? ' (optional)' : ''}
          </Label>
          <Input
            value={formData.businessNumber}
            onChange={({ target }) => setFormData({ ...formData, businessNumber: target.value })}
            placeholder={`Enter ${organisationService.getRegistryNumberType(processedCountryCode)}`}
            maxLength={100}
            isRequired={formData.entityType !== 'Sole trader'}
          />
        </>
      )}
      <PreferredSupplierAddUsersTable
        className="mt-6"
        formData={formData}
        isDisabled={isDisabled}
        isSaving={isSaving}
        isTenderInvitation={isTenderInvitation}
        setFormData={setFormData}
      />
    </>
  )
})
