import { FormEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Label } from '@cotiss/common/components/label.component'
import { RegionMultiSelect } from '@cotiss/common/components/region-multi-select.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'

type Props = {
  regions: string[]
  onChange: (regions: string[]) => void
}

export const SupplierOnboardingRegionsModal = memo(({ regions, onChange }: Props) => {
  const { closeModal } = useCallout()
  const [stateRegions, setStateRegions] = useState<string[]>(regions)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    onChange(stateRegions)
    closeModal()
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square">
        <Icon icon="x-close" />
      </Button>
      <div className="py-6 px-10">
        <Text className="font-semibold" size="h5">
          Select Regions
        </Text>

        <Label>Regions</Label>
        <RegionMultiSelect value={stateRegions} onChange={setStateRegions} />
        <div className="flex justify-end mt-4">
          <Button type="submit" variant="secondary">
            Confirm Selection
          </Button>
        </div>
      </div>
    </Form>
  )
})
