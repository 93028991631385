import { memo } from 'react'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { SettingsGeneralFormEmail } from '@cotiss/settings/components/settings-general-form-email.component'
import { SettingsGeneralFormName } from '@cotiss/settings/components/settings-general-form-name.component'
import { SettingsGeneralFormPassword } from '@cotiss/settings/components/settings-general-form-password.component'
import { SettingsGeneralFormPhone } from '@cotiss/settings/components/settings-general-form-phone.component'
import { SettingsGeneralTwoFactorForm } from '@cotiss/settings/components/settings-general-form-two-factor-form.component'

export const SettingsGeneralTab = memo(() => (
  <Card>
    <SettingsGeneralFormEmail />
    <Hr className="my-4" />
    <SettingsGeneralFormName />
    <Hr className="my-4" />
    <SettingsGeneralFormPhone />
    <Hr className="my-4" />
    <SettingsGeneralFormPassword />
    <Hr className="my-4" />
    <SettingsGeneralTwoFactorForm />
  </Card>
))
