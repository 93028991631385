import { compact, map } from 'lodash'
import { ProcessedUnspscCategory, ProcessedUnspscSubcategory } from '@cotiss/supplier/supplier.models'

// When there is an update to the UNSPSC data, some transforms need to be applied to the data to get it in the format of these files.
// See this commit for copies of the scripts I used to transform the data: https://github.com/Cotiss/cotiss-app/commit/1b935448bf9040ff370d73d3ed7708d4a5bb3e29
// Note that the processed categories need to include the top level categories as a subcategory of themselves.

class SupplierService {
  private _categoryMap: Record<string, ProcessedUnspscSubcategory> | null = null
  private _processedCategories: ProcessedUnspscCategory[] | null = null

  getUnspscCategoryMap = async () => {
    if (this._categoryMap) return this._categoryMap

    const data = (await fetch('/data/full-unspsc-data.json').then((res) => res.json())) as Record<string, ProcessedUnspscSubcategory>
    this._categoryMap = data
    return this._categoryMap
  }

  getProcessedUnspscCategories = async () => {
    if (this._processedCategories) return this._processedCategories

    const data = (await fetch('/data/categorised-unspsc-data.json').then((res) => res.json())) as ProcessedUnspscCategory[]
    this._processedCategories = data
    return this._processedCategories
  }

  getCategoriesFromCodes = async (categoryCodes: string[]) => {
    const unspscCategoryMap = await this.getUnspscCategoryMap()
    return compact(map(categoryCodes, (categoryCode) => unspscCategoryMap[categoryCode]))
  }
}

export const supplierService = new SupplierService()
