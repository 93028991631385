import { ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children?: ReactNode
}

export const KanbanColumn = ({ className, children }: Props) => {
  const classes = classNames(className, 'relative overflow-y-auto shrink-0 h-full w-full max-w-[220px] mr-4 last:mr-0')

  return <div className={classes}>{children}</div>
}
