import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { InputSkeleton } from '@cotiss/common/components/input-skeleton.component'
import { Select } from '@cotiss/common/components/select.component'
import { Text } from '@cotiss/common/components/text.component'
import { FILTER_OPERATION_NAMES } from '@cotiss/common/constants/filters.constants'
import { AdvancedFiltersValueField } from '@cotiss/common/modals/advanced-filters/advanced-filters-value-field.component'
import { useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { PartialFilter } from '@cotiss/common/modals/advanced-filters/advanced-filters.reducer'
import { isFilterDirty } from '@cotiss/common/modals/advanced-filters/advanced-filters.utils'
import { FilterAllOperations, FilterFieldValues } from '@cotiss/common/models/filter.model'

type Props = {
  itemIndex: number
  itemsCount: number
  filter: PartialFilter
  filterTypeOptions: { value: string; label: string }[]
  filterFieldConfig?: FilterFieldValues
}

export const AdvancedFiltersItem = ({ itemIndex, filter, filterTypeOptions, filterFieldConfig, itemsCount }: Props) => {
  const { advancedFiltersDispatch, filterFields } = useAdvancedFiltersContext()
  const operatorOptions =
    filterFieldConfig?.supportedOperations.map((operation) => ({
      value: operation,
      label: FILTER_OPERATION_NAMES[operation],
    })) || []

  const canRemoveFilter = itemsCount > 1 || isFilterDirty(filter)

  return (
    <div className="flex items-start justify-around bg-gray-50 rounded-lg py-3 px-3 gap-3">
      <div className="shrink-0 h-11 flex items-center justify-center">
        <Text size="lg" className="text-center w-[70px]">
          {itemIndex === 0 ? 'Where:' : 'and:'}
        </Text>
      </div>
      <div className="flex flex-1 items-start justify-between gap-2">
        <div className="flex-basis-1/3 flex-1">
          <Select
            renderInPortal
            isRequired
            placeholder="Select filter type"
            options={filterTypeOptions}
            value={filter.field}
            onChange={(value) => advancedFiltersDispatch({ type: 'UPDATE_FILTER_FIELD', payload: { id: filter.id, field: value, filterFields } })}
          />
        </div>
        <div className="flex-basis-1/3 flex-1">
          {filterFieldConfig ? (
            <Select
              renderInPortal
              isRequired
              options={operatorOptions}
              placeholder="Select operator"
              value={filter.operation}
              onChange={(value) =>
                advancedFiltersDispatch({ type: 'UPDATE_FILTER_OPERATION', payload: { id: filter.id, operation: value as FilterAllOperations } })
              }
            />
          ) : (
            <InputSkeleton />
          )}
        </div>
        <div className="flex-basis-1/3 w-[230px]">
          {filterFieldConfig ? (
            <AdvancedFiltersValueField
              isDisabled={!filterFieldConfig}
              valueOptions={filterFieldConfig?.valueOptions || []}
              setValue={(value) => advancedFiltersDispatch({ type: 'UPDATE_FILTER_VALUE', payload: { id: filter.id, value } })}
              valueType={filterFieldConfig?.valueType}
              value={filter.value}
            />
          ) : (
            <InputSkeleton />
          )}
        </div>
      </div>
      <div className="shrink-0 h-11 flex items-center justify-center">
        <Button
          variant="danger"
          state="translucent"
          size="xs"
          isDisabled={!canRemoveFilter}
          onClick={() => advancedFiltersDispatch({ type: 'REMOVE_FILTER', payload: filter.id })}
          shape="square"
        >
          <Icon icon="trash-01" />
        </Button>
      </div>
    </div>
  )
}
