import { memo, useMemo, useState } from 'react'
import { find, map, meanBy } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { NumberAnimation } from '@cotiss/common/components/number-animation.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useListGroupEvaluation } from '@cotiss/group-evaluation/resources/use-list-group-evaluation.resource'
import { useMutateGroupEvaluation } from '@cotiss/group-evaluation/resources/use-mutate-group-evaluation.resource'
import { TenderPopulatedModel } from '@cotiss/tender/tender.models'
import { TenderQuestionReportScoreItemSkeleton } from '@cotiss/tender-question/components/tender-question-report-score-item-skeleton.component'
import { TenderQuestionReportScoreItem } from '@cotiss/tender-question/components/tender-question-report-score-item.component'
import { useListTenderQuestionScore } from '@cotiss/tender-question/resources/use-list-tender-question-score.resource'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  tender: TenderPopulatedModel
  tenderResponseId: string
  tenderQuestionId: string
  isEditable?: boolean
}

export const TenderQuestionReportScore = memo(({ tender, tenderResponseId, tenderQuestionId, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateGroupEvaluationBulk } = useMutateGroupEvaluation()
  const { groupEvaluations, isLoading: isGroupEvaluationsLoading } = useListGroupEvaluation({ tenderResponseId })
  const { tenderQuestionScores, isLoading: isTenderQuestionScoresLoading } = useListTenderQuestionScore({ tenderQuestionId, tenderResponseId })
  const { tenderQuestionScores: completedTenderQuestionScores, isLoading: isCompletedTenderQuestionScoresLoading } = useListTenderQuestionScore({
    tenderQuestionId,
    tenderResponseId,
    evaluationStatus: 'completed',
  })
  const isLoading = isTenderQuestionScoresLoading || isGroupEvaluationsLoading || isCompletedTenderQuestionScoresLoading

  const { groupEvaluation, groupScoreAverage } = useMemo(() => {
    const groupEvaluation = find(groupEvaluations, { tenderResponse: tenderResponseId, tenderQuestion: tenderQuestionId })
    const groupScoreAverage = meanBy(completedTenderQuestionScores, ({ groupScore }) => groupScore || 0) || 0

    return { groupEvaluation, groupScoreAverage }
  }, [completedTenderQuestionScores, groupEvaluations])

  useAsyncEffect(async () => {
    if (groupEvaluation?.status !== 'notStarted') {
      return
    }

    try {
      await updateGroupEvaluationBulk({
        tenderId: tender._id,
        updates: [{ tenderQuestionId, tenderResponseId, status: 'inProgress' }],
      })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }, [groupEvaluation])

  const handleMarkAsComplete = async () => {
    try {
      setIsSaving(true)
      await updateGroupEvaluationBulk({
        tenderId: tender._id,
        updates: [{ tenderQuestionId, tenderResponseId, status: 'completed' }],
      })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleEdit = async () => {
    try {
      setIsSaving(true)
      await updateGroupEvaluationBulk({
        tenderId: tender._id,
        updates: [{ tenderQuestionId, tenderResponseId, status: 'inProgress' }],
      })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <>
      {isEditable && groupEvaluation?.status === 'completed' && (
        <div className="flex items-center justify-between bg-gray-100 rounded-lg w-full p-2 mb-4">
          <div className="flex items-center">
            <Icon className="mr-2" icon="check-circle" variant="success" size={20} />
            <Text>This consensus score has been marked as complete.</Text>
          </div>
          <Button className="ml-4" onClick={handleEdit} state="text" variant="link">
            Edit
          </Button>
        </div>
      )}
      <div className="bg-primary-50 border border-secondary-400 rounded-lg p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Text className="font-semibold">Consensus score:</Text>
            <Text className="font-semibold bg-secondary-300 rounded px-3 py-0.5 ml-2" variant="secondary">
              <NumberAnimation value={groupScoreAverage} format={(value) => value.toFixed(1)} />
            </Text>
          </div>
          {isEditable && groupEvaluation?.status && groupEvaluation.status !== 'completed' && (
            <Button className="ml-2" onClick={handleMarkAsComplete} size="sm" isLoading={isSaving}>
              Mark as complete
            </Button>
          )}
          {groupEvaluation?.status === 'completed' && (
            <Badge state="translucent" variant="success">
              Completed
            </Badge>
          )}
        </div>
        <Hr className="my-4" />
        <table className="table-fixed w-full">
          <thead>
            <tr>
              <th />
              <th className="w-20" />
              <th className="w-24" />
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <>
                <TenderQuestionReportScoreItemSkeleton />
                <TenderQuestionReportScoreItemSkeleton />
              </>
            )}
            {!isLoading && (
              <>
                {map(tenderQuestionScores, (questionScore) => (
                  <TenderQuestionReportScoreItem
                    key={questionScore._id}
                    tender={tender}
                    questionScore={questionScore}
                    isDisabled={isSaving}
                    isEditable={
                      isEditable &&
                      groupEvaluation?.status &&
                      groupEvaluation.status !== 'completed' &&
                      questionScore.evaluation.status !== 'abstained'
                    }
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
})
