import { ReactNode, memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  variant?: 'primary'
}

export const Header = memo(({ className, children, variant }: Props) => {
  const classes = classNames(className, 'sticky top-0 bg-white shadow-sm px-10 py-4 z-20', {
    'flex items-center justify-between h-[72px]': variant === 'primary',
  })

  return <header className={classes}>{children}</header>
})
