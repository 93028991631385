import { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { map } from 'lodash'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { usePerformanceMetric } from '@cotiss/performance/hooks/use-performance-metric.hook'
import { SettingsModulesPerformanceMetricCreateDrawer } from '@cotiss/settings/drawers/settings-modules-performance-metric-create.drawer'
import { SettingsModulesPerformanceMetricUpdateDrawer } from '@cotiss/settings/drawers/settings-modules-performance-metric-update.drawer'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { userService } from '@cotiss/user/user.service'

type RefreshPerformanceMetricListParam = {
  currentPage: number
  tab: SettingsModulesPerformanceTabType
}

type SettingsModulesPerformanceTabType = 'archived' | 'active'

const SETTINGS_MODULES_PERFORMANCE_TABS: TabModel<SettingsModulesPerformanceTabType>[] = [
  { id: 'active', label: 'Active' },
  { id: 'archived', label: 'Archived' },
]

export const SettingsModulesPerformanceTab = memo(() => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { permissions } = useUserAccess()
  const { openDrawer, openModal } = useCallout()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const isPerformanceScorecardEnabled = useFeature('performance-scorecard')
  const [tab, setTab] = useState<SettingsModulesPerformanceTabType>('active')
  const { performanceMetrics, queryPerformanceMetricList, mutateUpdatePerformanceMetric } = usePerformanceMetric()

  const handleTabChange = (tab: SettingsModulesPerformanceTabType) => {
    setCurrentPage(1)
    setTab(tab)
    refreshPerformanceMetricList({ currentPage: 1, tab })
  }

  const handlePageChange = (currentPage: number) => {
    setCurrentPage(currentPage)
    refreshPerformanceMetricList({ currentPage, tab })
  }

  const handleArchive = async (performanceMetricId: string, isArchived: boolean) => {
    track(isArchived ? 'settings_modules_performance_metric_archive_submit' : 'settings_modules_performance_metric_unarchive_submit')

    await mutateUpdatePerformanceMetric({ performanceMetricId, isArchived })
    await refreshPerformanceMetricList({ currentPage, tab })
  }

  const refreshPerformanceMetricList = async ({ currentPage, tab }: RefreshPerformanceMetricListParam) => {
    try {
      setIsLoading(true)

      const { pagination } = await queryPerformanceMetricList({
        filter: { isArchived: tab === 'archived' },
        pagination: { page: currentPage, pageSize: 20 },
      })
      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsLoading(false)
  }

  useEffect(() => {
    refreshPerformanceMetricList({ currentPage: 1, tab })
  }, [])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceMetrics, (performanceMetric) => ({
          content: () => (
            <Text className="truncate" title={performanceMetric.name}>
              {performanceMetric.name}
            </Text>
          ),
          cta: (
            <TableRowCta
              cta={{
                label: (
                  <>
                    View <Icon className="ml-1" icon="arrow-right" />
                  </>
                ),
                onClick: () =>
                  openDrawer(
                    <SettingsModulesPerformanceMetricUpdateDrawer
                      performanceMetric={performanceMetric}
                      onSubmit={() => refreshPerformanceMetricList({ currentPage, tab })}
                      isEditable={tab === 'active'}
                    />
                  ),
              }}
              actions={
                permissions.isAdmin
                  ? [
                      ...(!performanceMetric.isArchived
                        ? [
                            {
                              label: 'Edit',
                              onClick: () =>
                                openDrawer(
                                  <SettingsModulesPerformanceMetricUpdateDrawer
                                    performanceMetric={performanceMetric}
                                    onSubmit={() => refreshPerformanceMetricList({ currentPage, tab })}
                                    isEditable={tab === 'active'}
                                    isEditing
                                  />
                                ),
                            },
                          ]
                        : []),
                      {
                        label: performanceMetric.isArchived ? 'Unarchive' : 'Archive',
                        onClick: () =>
                          openModal(
                            <ConfirmModal
                              size="md"
                              heading={`${performanceMetric.isArchived ? 'Unarchive' : 'Archive'} Metric`}
                              description={
                                performanceMetric.isArchived ? (
                                  <div>
                                    <Text>Unarchiving this metric will make it available to add to new scorecards.</Text>
                                  </div>
                                ) : (
                                  <div>
                                    <Text>
                                      Once archived, this metric can no longer be associated with future scorecards, but it will remain unchanged on
                                      active scorecards.
                                    </Text>
                                    <Text className="mt-4">This metric can be unarchived at anytime.</Text>
                                  </div>
                                )
                              }
                              onSubmit={() => handleArchive(performanceMetric.id, !performanceMetric.isArchived)}
                            />
                          ),
                      },
                    ]
                  : []
              }
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Group',
        rows: map(performanceMetrics, ({ group }) => ({
          content: () => <Text>{group}</Text>,
        })),
      },
      {
        heading: 'Description',
        isWrapped: true,
        rows: map(performanceMetrics, ({ description }) => ({
          content: () => <Text>{description}</Text>,
        })),
      },
      {
        heading: 'Created by',
        rows: map(performanceMetrics, ({ createdByUser }) => ({
          content: () => (createdByUser ? <Avatar>{userService.getInitials(createdByUser)}</Avatar> : '--'),
        })),
      },
      {
        heading: 'Created at',
        rows: map(performanceMetrics, ({ createdAt }) => ({
          content: () => <Text>{datetimeService.format(createdAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [performanceMetrics, permissions])

  if (!isPerformanceScorecardEnabled) {
    return null
  }

  return (
    <Card className="mb-8 flex-1">
      <Text className="font-medium" variant="heading" size="h5">
        Performance
      </Text>
      <Text className="mt-1" variant="light">
        Adjust settings specific to the performance module.
      </Text>
      <Hr className="my-4" />
      <TableHeader className="flex justify-between items-center">
        <div>
          <Text className="font-semibold">Metrics</Text>
          <Text variant="light" size="sm" className="mt-1">
            Add metrics to build scorecards to measure vendor performance.
          </Text>
        </div>
        {permissions.isAdmin && (
          <Button
            onClick={() =>
              openDrawer(<SettingsModulesPerformanceMetricCreateDrawer onSubmit={() => refreshPerformanceMetricList({ currentPage, tab })} />)
            }
            size="xs"
            variant="secondary"
          >
            <Icon icon="plus-01" /> Add metric
          </Button>
        )}
      </TableHeader>
      <TableHeader variant="white">
        <Tabs<SettingsModulesPerformanceTabType>
          tab={tab}
          tabs={SETTINGS_MODULES_PERFORMANCE_TABS}
          onChange={({ id }) => handleTabChange(id)}
          variant="raised"
        />
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={handlePageChange}
        emptyCta={
          permissions.isAdmin && (
            <Button
              onClick={() =>
                openDrawer(<SettingsModulesPerformanceMetricCreateDrawer onSubmit={() => refreshPerformanceMetricList({ currentPage, tab })} />)
              }
              size="sm"
              state="text"
              variant="secondary"
            >
              + Add metric
            </Button>
          )
        }
        isLoading={isLoading}
      />
    </Card>
  )
})
