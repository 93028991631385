import { memo, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { find, includes, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { usePerformanceScorecardMetricUser } from '@cotiss/performance/hooks/use-performance-scorecard-metric-user.hook'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { usePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { performanceService } from '@cotiss/performance/performance.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { userService } from '@cotiss/user/user.service'

type Props = {
  performanceScorecardId: string
  performanceScorecardUserId: string
}

export const PerformanceScorecardEvaluatorViewMetricsStep = memo(({ performanceScorecardId, performanceScorecardUserId }: Props) => {
  const { openToast } = useToast()
  const { openModal, closeDrawer } = useCallout()
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { performanceScorecardUser, performanceScorecardUserInSession } = usePerformanceScorecardUser()
  const { performanceScorecardMetrics, queryPerformanceScorecardMetricList } = usePerformanceScorecardMetric()
  const { performanceScorecardMetricUsers, queryPerformanceScorecardMetricUserList, mutateDeletePerformanceScorecardMetricUser } =
    usePerformanceScorecardMetricUser()

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { items, pagination } = await queryPerformanceScorecardMetricUserList({
        filter: {
          performanceScorecardId,
          performanceScorecardUserId,
        },
        pagination: {
          page: currentPage,
          pageSize: 20,
        },
      })

      await queryPerformanceScorecardMetricList({
        filter: {
          performanceScorecardId,
          performanceScorecardMetricIds: map(items, 'performanceScorecardMetricId'),
        },
      })
      setPagination(pagination)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [currentPage])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Metric',
        rows: map(performanceScorecardMetricUsers, ({ id: performanceScorecardMetricUserId, performanceScorecardMetricId }) => {
          const performanceScorecardMetric = find(performanceScorecardMetrics, { id: performanceScorecardMetricId })

          return {
            content: () => (
              <Text className="truncate" title={performanceScorecardMetric?.performanceMetric.name}>
                {performanceScorecardMetric?.performanceMetric.name}
              </Text>
            ),
            cta: performanceScorecardUser && includes(performanceScorecardUserInSession?.roles, 'owner') && (
              <TableRowCta
                actions={[
                  {
                    label: 'Delete',
                    onClick: () =>
                      openModal(
                        <ConfirmModal
                          heading="Delete metric"
                          description="Are you sure you want to delete this metric from this user?"
                          onSubmit={() => mutateDeletePerformanceScorecardMetricUser({ performanceScorecardMetricUserId })}
                        />
                      ),
                  },
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Group',
        rows: map(performanceScorecardMetricUsers, ({ performanceScorecardMetricId }) => {
          const performanceScorecardMetric = find(performanceScorecardMetrics, { id: performanceScorecardMetricId })

          return {
            content: () => <Text>{performanceScorecardMetric?.performanceMetric.group}</Text>,
          }
        }),
      },
      {
        heading: 'Target',
        rows: map(performanceScorecardMetricUsers, ({ performanceScorecardMetricId }) => {
          const performanceScorecardMetric = find(performanceScorecardMetrics, { id: performanceScorecardMetricId })

          return {
            content: () => (
              <Text>
                {performanceScorecardMetric
                  ? performanceService.formatPerformanceValue({
                      value: performanceScorecardMetric.target,
                      performanceMetric: performanceScorecardMetric.performanceMetric,
                    })
                  : '--'}
              </Text>
            ),
          }
        }),
      },
    ]
    return { columns }
  }, [performanceScorecardMetricUsers, performanceScorecardMetrics])

  return (
    <>
      {performanceScorecardUser && (
        <Text className="font-medium" size="lg">
          {userService.getFullName(performanceScorecardUser.user)}
        </Text>
      )}
      {performanceScorecardUser && (
        <Text className="mt-2" size="sm" variant="light">
          {performanceScorecardUser.user.email}
        </Text>
      )}
      <Hr className="my-4" />
      <TableHeader className="flex items-center justify-between">
        <div className="mr-4">
          <Text className="font-medium" size="lg">
            Metrics
          </Text>
          <Text variant="light">Below are all the metrics assigned to this user.</Text>
        </div>
        <Button className="ml-2 gap-1" type="submit" variant="secondary" state="filled" size="sm">
          <Icon icon="plus-01" /> Associate metric
        </Button>
      </TableHeader>
      <Table
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          <Button size="sm" state="text" variant="secondary" type="submit" isDisabled={isLoading}>
            + Associate metric
          </Button>
        }
      />
    </>
  )
})
