import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useContractSearchAndFiltersContext } from '@cotiss/contract/components/contract-search-and-filters-context.component'
import { ContractSearchAndFiltersPrimaryTabKeys } from '@cotiss/contract/contract.constants'

type Props = {
  tabs: TabModel<ContractSearchAndFiltersPrimaryTabKeys>[]
  tab: ContractSearchAndFiltersPrimaryTabKeys
}

export const ContractListTabs = ({ tabs, tab }: Props) => {
  const { track } = useAnalytics()
  const { queryStateDispatch } = useContractSearchAndFiltersContext()

  const handleTabChange = (_tab: ContractSearchAndFiltersPrimaryTabKeys) => {
    if (tab === 'active') {
      track('contract_list_active_view')
    }

    if (tab === 'archived') {
      track('contract_list_archived_view')
    }

    if (tab === 'mine') {
      track('contract_list_my_contracts_view')
    }
    queryStateDispatch({ type: 'UPDATE_PRIMARY_TAB', payload: _tab })
  }
  return <Tabs<ContractSearchAndFiltersPrimaryTabKeys> tabs={tabs} tab={tab} onChange={({ id }) => handleTabChange(id)} variant="raised" />
}
