import { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { filter, map } from 'lodash'
import { RemirrorJSON } from 'remirror'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Switch } from '@cotiss/common/components/switch.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentModel } from '@cotiss/document/document.models'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'
import { ForumQuestionModel } from '@cotiss/forum/forum.models'
import { ForumAddProtectionQuestionConfirmModal } from '@cotiss/forum/modals/forum-add-protection-question-confirm.modal'
import { ForumAddUpdateQuestionModal } from '@cotiss/forum/modals/forum-add-update-question.modal'
import { ForumDeleteQuestionConfirmModal } from '@cotiss/forum/modals/forum-delete-question-confirm.modal'
import { ForumRemoveProtectionQuestionConfirmModal } from '@cotiss/forum/modals/forum-remove-protection-question-confirm.modal'
import { ForumResponseDocumentUploadModal } from '@cotiss/forum/modals/forum-response-document-upload.modal'
import { useMutateForum } from '@cotiss/forum/resources/use-mutate-forum.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { userService } from '@cotiss/user/user.service'

type FormData = {
  response: RemirrorJSON
  responseAttachments: DocumentModel[]
  isProtected: boolean
  pinned: boolean
}

type Props = {
  className?: string
  forumQuestion: ForumQuestionModel
  canRespond?: boolean
  variant?: 'white' | 'primary'
}

export const ForumQuestion = memo(({ className, forumQuestion, canRespond, variant }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { updateForumQuestion } = useMutateForum()
  const [isReplying, setIsReplying] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const classes = classNames(className, 'relative rounded-lg p-4', {
    'bg-white': variant === 'white',
    'bg-primary-50': variant === 'primary',
  })
  const isUserForumQuestion = forumQuestion.createdBy.account.organisation._id === user?.account?.organisation?._id
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const [formData, setFormData] = useState<FormData>({
    response: forumQuestion.response || richTextService.getEmptyRemirrorJson(),
    responseAttachments: forumQuestion.responseAttachments || [],
    isProtected: forumQuestion.isProtected,
    pinned: forumQuestion.pinned,
  })
  const { track } = useAnalytics()
  const hasAttachments = Boolean(forumQuestion.attachments?.length)
  const hasResponseAttachments = Boolean(forumQuestion.responseAttachments?.length)

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const questionTableColumns = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Document name',
        rows: map(forumQuestion.attachments, ({ _id, fileName }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle max-w-full"
              onClick={() => setDocumentIdToDownload(_id)}
              state="raw"
            >
              <Text className="truncate">{fileName}</Text>
            </Button>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        rows: map(forumQuestion.attachments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(forumQuestion.attachments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    return { columns, fixedColumns }
  }, [forumQuestion.attachments])

  const responseTableColumns = useMemo(() => {
    const responseAttachments = isReplying ? formData.responseAttachments : forumQuestion.responseAttachments

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Document name',
        rows: map(responseAttachments, ({ _id, fileName }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle max-w-full"
              onClick={() => setDocumentIdToDownload(_id)}
              state="raw"
            >
              <Text className="truncate">{fileName}</Text>
            </Button>
          ),
          cta: isReplying && (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() =>
                          setFormData({
                            ...formData,
                            responseAttachments: filter(formData.responseAttachments, (attachment) => attachment._id !== _id),
                          })
                        }
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        rows: map(responseAttachments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(responseAttachments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    return { columns, fixedColumns }
  }, [formData.responseAttachments, forumQuestion.responseAttachments, isReplying])

  const handleUpdateForumQuestion = async () => {
    try {
      setIsSaving(true)
      forumQuestion.response ? track('forum_response_update_submit') : track('forum_response_create_submit')
      await updateForumQuestion(forumQuestion._id, {
        ...formData,
        responseAttachments: map(formData.responseAttachments, ({ _id }) => _id),
      })
      setIsReplying(false)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!forumQuestion.isProtected && formData.isProtected) {
      // Adding protection
      openModal(<ForumAddProtectionQuestionConfirmModal isListingOwner={canRespond} onSubmit={handleUpdateForumQuestion} />)
    } else if (forumQuestion.isProtected && !formData.isProtected) {
      // Removing protection
      openModal(<ForumRemoveProtectionQuestionConfirmModal onSubmit={handleUpdateForumQuestion} />)
    } else {
      // No protection changes
      await handleUpdateForumQuestion()
    }
  }

  const handlePin = async (pinned: boolean) => {
    try {
      setIsSaving(true)
      setIsDropdownOpen(false)
      await updateForumQuestion(forumQuestion._id, { pinned })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  return (
    <div key={forumQuestion._id} className={classes}>
      {!forumQuestion.response && (
        <Text className="inline-flex items-center mr-2" size="xs" isInline>
          <Icon className="mr-1" icon="alert-circle-deprecated" size={12} />
          Not answered
        </Text>
      )}
      {forumQuestion.pinned && (
        <Text className="inline-flex items-center mr-2" size="xs" isInline>
          <Icon className="mr-1" icon="push-pin" size={12} />
          Pinned
        </Text>
      )}
      {forumQuestion.isProtected && (
        <Text className="inline-flex items-center" size="xs" isInline>
          <Icon className="mr-1" icon="visible" size={12} />
          Private
        </Text>
      )}

      <div className="flex items-center justify-between mt-2">
        <div className="mr-10">
          {!canRespond && (
            <Text className="font-semibold" variant="link">
              {isUserForumQuestion && (
                <>
                  {forumQuestion.createdBy.account.organisation.name}: <Text isInline>{userService.getFullName(forumQuestion.createdBy)}</Text>
                </>
              )}
              {!isUserForumQuestion && 'Anonymous'}
            </Text>
          )}
          {canRespond && (
            <Button
              state="text"
              variant="link"
              className="font-semibold"
              isLink
              href={routerService.getHref('/supplier/view/:id/:tab?', { id: forumQuestion.createdBy.account.organisation._id })}
              isOpenNewTab
            >
              {forumQuestion.createdBy.account.organisation.name}: <Text isInline>{userService.getFullName(forumQuestion.createdBy)}</Text>
            </Button>
          )}
        </div>
        {(isUserForumQuestion || canRespond) && (
          <>
            <Button className="absolute top-2 right-4" onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost" isDisabled={isSaving}>
              <Icon icon="dots" variant="light" size={20} />
            </Button>
            <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              {isUserForumQuestion && (
                <DropdownContent
                  className="flex items-center"
                  onClick={() => openModal(<ForumAddUpdateQuestionModal forumQuestion={forumQuestion} />)}
                >
                  Edit
                </DropdownContent>
              )}
              {forumQuestion.response && canRespond && !isReplying && (
                <DropdownContent className="flex items-center" onClick={() => setIsReplying(true)}>
                  Edit response
                </DropdownContent>
              )}
              {!forumQuestion.isProtected && (
                <DropdownContent
                  className="flex items-center"
                  onClick={() =>
                    openModal(
                      <ForumAddProtectionQuestionConfirmModal
                        isListingOwner={canRespond}
                        onSubmit={() => updateForumQuestion(forumQuestion._id, { isProtected: true })}
                      />
                    )
                  }
                >
                  Protect
                </DropdownContent>
              )}
              {forumQuestion.isProtected && (
                <DropdownContent
                  className="flex items-center"
                  onClick={() =>
                    openModal(
                      <ForumRemoveProtectionQuestionConfirmModal onSubmit={() => updateForumQuestion(forumQuestion._id, { isProtected: false })} />
                    )
                  }
                >
                  Remove protection
                </DropdownContent>
              )}
              {canRespond && !forumQuestion.pinned && (
                <DropdownContent className="flex items-center" onClick={() => handlePin(true)}>
                  Pin to top
                </DropdownContent>
              )}
              {canRespond && forumQuestion.pinned && (
                <DropdownContent className="flex items-center" onClick={() => handlePin(false)}>
                  Unpin from top
                </DropdownContent>
              )}
              {canRespond && (
                <DropdownContent
                  className="flex items-center"
                  onClick={() => openModal(<ForumDeleteQuestionConfirmModal forumQuestion={forumQuestion} />)}
                >
                  Delete
                </DropdownContent>
              )}
            </Dropdown>
          </>
        )}
      </div>
      <Text className="font-medium mt-1" variant="light" size="sm">
        {datetimeService.format(forumQuestion.createdAt, 'd MMMM yyyy h:mm aaa')}
      </Text>
      <RichText className="mt-4" value={forumQuestion.content} />
      {hasAttachments && (
        <>
          <TableHeader className="mt-4" variant={variant === 'white' ? 'white' : 'default'}>
            <Text className="font-semibold">Attached documents</Text>
          </TableHeader>
          <ScrollableTable
            variant={variant}
            fixedColumnsWidth={400}
            fixedColumns={questionTableColumns.fixedColumns}
            columns={questionTableColumns.columns}
          />
        </>
      )}
      {forumQuestion.response && !isReplying && (
        <div className="border-l-2 border-secondary-500 pl-4 py-2 ml-4 mt-4">
          <RichText value={forumQuestion.response} />
          {hasResponseAttachments && (
            <>
              <TableHeader className="mt-4" variant={variant === 'white' ? 'white' : 'default'}>
                <Text className="font-semibold">Attached documents</Text>
              </TableHeader>
              <ScrollableTable
                variant={variant}
                fixedColumnsWidth={400}
                fixedColumns={responseTableColumns.fixedColumns}
                columns={responseTableColumns.columns}
              />
            </>
          )}
        </div>
      )}
      {!forumQuestion.response && canRespond && !isReplying && (
        <div className={classNames('text-right', { 'mt-4': hasAttachments })}>
          <Button onClick={() => setIsReplying(true)} variant="secondary" size="xs" isDisabled={isSaving}>
            Reply
          </Button>
        </div>
      )}
      {canRespond && isReplying && (
        <Form className="border-l-2 border-secondary-500 pl-4 ml-4 mt-4" onSubmit={handleSubmit}>
          <RichTextEditor
            value={formData.response}
            onChange={(response) => setFormData({ ...formData, response })}
            isDisabled={isSaving}
            isRequired
          />
          <TableHeader className="flex justify-between items-center mt-4" variant={variant === 'white' ? 'white' : 'default'}>
            <div>
              <Text className="font-semibold">Attached documents</Text>
            </div>
            <Button
              size="xs"
              state="translucent"
              variant="secondary-dark"
              onClick={() =>
                openModal(
                  <ForumResponseDocumentUploadModal
                    onSubmit={(documents) => setFormData({ ...formData, responseAttachments: [...formData.responseAttachments, ...documents] })}
                    forumQuestion={forumQuestion}
                  />
                )
              }
              isDisabled={isSaving}
            >
              + Upload documents
            </Button>
          </TableHeader>
          <ScrollableTable
            variant={variant}
            fixedColumnsWidth={400}
            fixedColumns={responseTableColumns.fixedColumns}
            columns={responseTableColumns.columns}
            emptyCta={
              <Button
                state="text"
                variant="secondary"
                size="sm"
                onClick={() =>
                  openModal(
                    <ForumResponseDocumentUploadModal
                      onSubmit={(documents) => setFormData({ ...formData, responseAttachments: [...formData.responseAttachments, ...documents] })}
                      forumQuestion={forumQuestion}
                    />
                  )
                }
              >
                + Upload documents
              </Button>
            }
          />
          <div className="flex items-center justify-between mt-4">
            <div className="flex items-center">
              <label className="flex items-center cursor-pointer mr-2" htmlFor={`forum-response-${forumQuestion._id}-is-protected`}>
                <Switch
                  id={`forum-response-${forumQuestion._id}-is-protected`}
                  isOn={formData.isProtected}
                  onClick={() => setFormData({ ...formData, isProtected: !formData.isProtected })}
                  size="sm"
                  isDisabled={isSaving}
                />
                <Text className="ml-2" size="sm">
                  Is private
                </Text>
              </label>
              <label className="flex items-center cursor-pointer" htmlFor={`forum-response-${forumQuestion._id}-pinned`}>
                <Switch
                  id={`forum-response-${forumQuestion._id}-pinned`}
                  isOn={formData.pinned}
                  onClick={() => setFormData({ ...formData, pinned: !formData.pinned })}
                  size="sm"
                  isDisabled={isSaving}
                />
                <Text className="ml-2" size="sm">
                  Pin to top
                </Text>
              </label>
            </div>
            <div className="flex items-center">
              <Button onClick={() => setIsReplying(false)} state="ghost" variant="link" size="xs" isDisabled={isSaving}>
                Close
              </Button>
              <Button variant="secondary" size="xs" type="submit" isLoading={isSaving}>
                Post
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  )
})
