import { memo } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { ProcurementPublicModel } from '@cotiss/procurement/procurement.models'
import { TenderTimeRemainingBadge } from '@cotiss/tender/components/tender-time-remaining-badge.component'
import { TENDER_TYPE_ABBREVIATION_MAP } from '@cotiss/tender/tender.constants'

type Props = {
  className?: string
  publicProcurement: ProcurementPublicModel
}

export const BuyerProcurementItem = memo(({ className, publicProcurement }: Props) => {
  const classes = classNames(className, 'bg-white shadow-lg rounded-lg p-4')
  const { _id: procurementId, procuringEntity, regions, title, topLevelClassification, tender } = publicProcurement

  if (!tender) {
    return null
  }

  return (
    <div className={classes}>
      <div className="flex items-start justify-between">
        <OrganisationLogo className="shrink-0 mr-4" organisation={procuringEntity} />
        <div className="w-full">
          <div className="flex items-start justify-between">
            <Text className="mr-4" variant="light" size="sm">
              {procuringEntity.name} • {regions.join(', ')}
            </Text>
            <TenderTimeRemainingBadge tender={tender} />
          </div>
          <Text className="font-medium mt-1" variant="heading">
            {title}
          </Text>
          {tender.tenderType && (
            <Text className="mt-1" variant="link" size="sm">
              {tender.title} ({TENDER_TYPE_ABBREVIATION_MAP[tender.tenderType]})
            </Text>
          )}
          <Text className="line-clamp-2 mt-1" size="xs">
            {tender.briefDescription}
          </Text>
        </div>
      </div>
      <Hr className="my-4" />
      <div className="flex items-center justify-between">
        <div className="mr-4">
          {map(topLevelClassification, ({ _id, description }) => (
            <Badge key={_id} className="truncate max-w-[140px] mr-1 mt-1" state="translucent" variant="secondary" size="sm">
              {description}
            </Badge>
          ))}
        </div>
        <Button
          onClick={() => window.open(routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId }), '_blank')}
          variant="secondary"
          size="sm"
        >
          View details
        </Button>
      </div>
    </div>
  )
})
