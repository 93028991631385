import { memo, useEffect, useMemo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { EvaluationEventScoringAccessList } from '@cotiss/evaluation-event/components/evaluation-event-scoring-access-list.component'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { EvaluationEventPanelUpdateSummaryPanelTab } from '@cotiss/evaluation-event/tabs/evaluation-event-panel-update-summary-panel.tab'

type EvaluationSummaryTab = 'panel' | 'scoring'
const TABS: TabModel<EvaluationSummaryTab>[] = [
  { id: 'panel', label: 'Panel' },
  { id: 'scoring', label: 'Scoring' },
]

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  onBack: () => void
}

export const EvaluationEventPanelUpdateSummaryStep = memo(({ evaluationEnvelope, onBack }: Props) => {
  const { closeFullModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const { step, transition, onTransition } = useTransition()
  const activeTabId = useMemo(() => TABS[step - 1].id, [step])

  useEffect(() => {
    track('evaluation_event_panel_update_summary_view')
  }, [])

  const handleTabChange = (tab: EvaluationSummaryTab) => {
    const newStep = findIndex(TABS, ({ id }) => id === tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Evaluation summary
        </Text>
        <div className="ml-4">
          <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
            Back
          </Button>
          <Button onClick={closeFullModal} variant="secondary" size="sm">
            Save and exit
          </Button>
        </div>
      </CardHeader>

      <Tabs<EvaluationSummaryTab>
        className="border-b border-gray-300 w-full mb-6"
        tab={activeTabId}
        tabs={TABS}
        onChange={({ id }) => handleTabChange(id)}
        variant="underline"
      />
      <AnimatePresence initial={false} mode="wait">
        <TransitionContainer key={step} transition={transition}>
          {activeTabId === 'panel' && <EvaluationEventPanelUpdateSummaryPanelTab evaluationEnvelope={evaluationEnvelope} />}
          {activeTabId === 'scoring' && <EvaluationEventScoringAccessList evaluationEnvelope={evaluationEnvelope} />}
        </TransitionContainer>
      </AnimatePresence>
    </Card>
  )
})
