import { memo } from 'react'
import { map } from 'lodash'
import { EvaluationEventScoringAccessList } from '@cotiss/evaluation-event/components/evaluation-event-scoring-access-list.component'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'

export const EvaluationEventSummaryScoring = memo(() => {
  const { evaluationEnvelopes } = useEvaluationEnvelope()

  return (
    <>
      {map(evaluationEnvelopes, (evaluationEnvelope) => (
        <EvaluationEventScoringAccessList key={evaluationEnvelope.id} className="mt-4" evaluationEnvelope={evaluationEnvelope} />
      ))}
    </>
  )
})
