import { MouseEvent as ReactMouseEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { AccountDeleteConfirmModal } from '@cotiss/account/modals/account-delete-confirm.modal'
import { BillingUpgradeFreeSupplierConfirmModal } from '@cotiss/billing/modals/billing-upgrade-free-supplier-confirm.modal'
import { useMutateBilling } from '@cotiss/billing/resources/use-mutate-billing.resource'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const SettingsSubscriptionTab = memo(() => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { user } = useGetLoggedInUser()
  const { permissions } = useUserAccess()
  const { billingPortal } = useMutateBilling()
  const [isLoading, setIsLoading] = useState(false)

  const renderSubscribed = () => (
    <div className="flex items-center">
      <Text className="mr-1" variant="link">
        Subscribed
      </Text>
      <div className="inline-flex items-center justify-center shrink-0 bg-secondary-500 rounded-full w-4 h-4">
        <Icon icon="check" variant="white" />
      </div>
    </div>
  )

  const handleClick = async (event: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    try {
      setIsLoading(true)
      if (user?.account) {
        const { url } = await billingPortal({ customerId: user.account.stripeId })
        window.open(url, '_blank')
      }
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsLoading(false)
    }
  }

  return (
    <Card>
      <div className="flex items-center justify-between">
        <div className="mr-8">
          <Text className="font-semibold">Free supplier</Text>
          <Text>Respond to invited listings and access public portals.</Text>
        </div>
        {!permissions.isSupplier && (
          <Button
            onClick={() => openModal(<BillingUpgradeFreeSupplierConfirmModal />)}
            size="sm"
            state="translucent"
            variant="secondary"
            isDisabled={!permissions.isAdmin}
          >
            Upgrade
          </Button>
        )}
        {permissions.isSupplier && renderSubscribed()}
      </div>
      {permissions.isAdmin && permissions.isBuyer && (
        <>
          <Hr className="my-4" />
          <div className="flex items-center justify-between">
            <div className="mr-8">
              <Text className="font-semibold">Billing information</Text>
              <Text>View account billing information.</Text>
            </div>

            <Button size="sm" state="translucent" variant="secondary" isLoading={isLoading} onClick={handleClick}>
              <Icon className="mr-1" icon="external-link" size={18} />
              View billing
            </Button>
          </div>
        </>
      )}
      {permissions.isAdmin && user?.account && (
        <>
          <Hr className="my-4" />
          <Text className="font-semibold">Account</Text>
          <Text>
            Click{' '}
            <Button onClick={() => openModal(<AccountDeleteConfirmModal isAdmin={permissions.isAdmin} />)} state="text" variant="link">
              here
            </Button>{' '}
            to cancel your account and revoke access.
          </Text>
        </>
      )}
    </Card>
  )
})
