import { FormEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ConflictOfInterestTerms } from '@cotiss/conflict-of-interest/components/conflict-of-interest-terms.component'
import { ConflictOfInterestModel } from '@cotiss/conflict-of-interest/conflict-of-interest.model'
import { useMutateConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-mutate-conflict-of-interest.resource'

type Props = {
  conflictOfInterest: ConflictOfInterestModel
  onSuccess: () => void
}

export const ConflictOfInterestViewAgreementTab = memo(({ conflictOfInterest, onSuccess }: Props) => {
  const [isSaving, setIsSaving] = useState(false)
  const { openToast } = useToast()
  const { updateConflictOfInterest } = useMutateConflictOfInterest()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateConflictOfInterest(conflictOfInterest._id, { step: 'declaration-form' })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }

    setIsSaving(false)
    onSuccess()
  }

  return (
    <Form className="bg-white rounded-lg shadow-lg w-full px-8 py-6 max-w-5xl" onSubmit={handleSubmit}>
      <Text variant="heading" size="h4">
        {conflictOfInterest.procurement.title}
      </Text>
      <Hr className="my-4" />
      <ConflictOfInterestTerms conflictOfInterestId={conflictOfInterest._id} isReviewing />
      <Hr className="my-4" />
      <div className="flex items-center justify-end">
        <Button type="submit" variant="secondary" isLoading={isSaving}>
          Continue
        </Button>
      </div>
    </Form>
  )
})
