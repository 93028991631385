import { forwardRef, ReactNode } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  isNarrow?: boolean
}

export const DrawerFooter = forwardRef<HTMLDivElement, Props>(({ className, children, isNarrow }, ref) => {
  const classes = classNames(className, 'shrink-0 bg-white w-full mt-auto')

  return (
    <div className={classes} style={{ maxWidth: isNarrow ? 460 : 900 }} ref={ref}>
      <div className="border-t border-gray-200 p-6">{children}</div>
    </div>
  )
})
