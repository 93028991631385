import { memo } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { AvatarSkeleton } from '@cotiss/common/components/avatar-skeleton.component'

type Props = {
  className?: string
}

export const AvatarGroupSkeleton = memo(({ className }: Props) => {
  const classes = classNames(className, 'inline-flex items-center')

  return (
    <div className={classes}>
      {map(Array(3), (_, index) => (
        <AvatarSkeleton key={index} className={index ? '-ml-4' : ''} style={{ zIndex: 3 - index }} />
      ))}
    </div>
  )
})
