import { memo } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ContractDocumentShellAttachmentStatusBadge } from '@cotiss/contract/components/contract-document-shell-attachment-status-badge.component'
import { ContractDocumentShellAttachmentStatus } from '@cotiss/contract/contract.model'
import { ContractDocumentShellHierarchy } from '@cotiss/contract/contract.service'
import { ContractDocumentDrawer } from '@cotiss/contract/drawers/contract-document.drawer'
import { DocumentModel } from '@cotiss/document/document.models'

export type ContractDocumentItem = {
  shellTitle: string
  index: string
  status: ContractDocumentShellAttachmentStatus
  hierarchy: ContractDocumentShellHierarchy
  document: DocumentModel
  signedDocument?: DocumentModel
  contractShellId: string
  contractId: string
  createdAt: string
}

type Props = {
  title: string
  documentItems: ContractDocumentItem[]
  className?: string
}

export const ContractDocumentTable = memo(({ title, documentItems, className = '' }: Props) => {
  const { openDrawer } = useCallout()
  const classes = classNames(className)

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: '#',
      thClassName: 'w-20',
      rows: map(documentItems, ({ index }) => ({
        content: () => <Text className="font-semibold">{index}</Text>,
      })),
    },
    {
      heading: 'File name',
      rows: map(documentItems, ({ document, signedDocument, shellTitle, contractId, contractShellId }) => ({
        content: () => (
          <div className="flex items-center truncate">
            {/* TODO: make this the correct icon */}
            <div className="shrink-0 mr-2 bg-secondary-100 rounded h-6 w-6 flex items-center justify-center">
              <Icon icon="document-check" className="text-secondary-500 " />
            </div>
            <Text variant="light" size="sm" className="truncate">
              {document.fileName || '--'}
            </Text>
          </div>
        ),
        cta: (
          <Button
            onClick={() =>
              openDrawer(
                <ContractDocumentDrawer
                  contractId={contractId}
                  contractShellId={contractShellId}
                  signedDocument={signedDocument}
                  approvedDocument={document}
                  title={shellTitle}
                />
              )
            }
            state="outline"
            variant="secondary"
            size="xs"
          >
            View <Icon className="ml-1" icon="arrow-right" />
          </Button>
        ),
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Hierarchy',
      rows: map(documentItems, ({ hierarchy }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {/* TODO: update styling here per hierarchy type */}
            {hierarchy === 'master' ? 'Legal Document' : 'Variation'}
          </Text>
        ),
      })),
    },
    {
      heading: 'Date',
      rows: map(documentItems, ({ document }) => ({
        content: () => <Text>{datetimeService.format(document.createdAt, 'do MMM yyyy')}</Text>,
      })),
    },
    {
      heading: 'Status',
      rows: map(documentItems, ({ status }) => ({
        content: () => <ContractDocumentShellAttachmentStatusBadge status={status} />,
      })),
    },
  ]

  return (
    <div className={classes}>
      <div className="border border-grey-200 border-b-0 rounded-t-md">
        <div className="bg-primary-50 p-4">
          <Text className="font-semibold">{title}</Text>
        </div>
      </div>
      <ScrollableTable fixedColumns={fixedColumns} columns={columns} fixedColumnsWidth={480} />
    </div>
  )
})
