import { memo } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { BuyerResourceItem } from '@cotiss/buyer/components/buyer-resource-item.component'
import { BuyerAddUpdateResourceModal } from '@cotiss/buyer/modals/buyer-add-update-resource.modal'
import { useGetOrganisation } from '@cotiss/organisation/resources/use-get-organisation.resource'

type Props = {
  isEditable?: boolean
}

export const BuyerViewResourcesTab = memo(({ isEditable }: Props) => {
  const { openModal } = useCallout()
  const { id } = useParams<{ id: string }>()
  const { organisation } = useGetOrganisation(id)

  // TODO: Loading state.
  if (!organisation) {
    return null
  }

  if (!organisation.resources?.length) {
    return (
      <>
        <Text className="font-medium">Add new resources to your portal.</Text>
        <Text variant="light">E.g. Procurement policy, marketing materials, notice of upcoming work.</Text>
        {isEditable && (
          <Button className="mt-4" onClick={() => openModal(<BuyerAddUpdateResourceModal organisation={organisation} />)} variant="secondary">
            + Add resource
          </Button>
        )}
      </>
    )
  }

  return (
    <>
      <div className="flex justify-end mb-3">
        {isEditable && (
          <Button
            onClick={() => openModal(<BuyerAddUpdateResourceModal organisation={organisation} />)}
            variant="secondary"
            state="translucent"
            size="xs"
          >
            + Add resource
          </Button>
        )}
      </div>
      {map(organisation.resources, (resource, index) => (
        <BuyerResourceItem
          key={resource._id}
          className={classNames({ 'mt-4': index })}
          organisation={organisation}
          resource={resource}
          isEditable={isEditable}
        />
      ))}
    </>
  )
})
