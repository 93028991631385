import { memo } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  className?: string
  onClick: () => void
  isActive?: boolean
}

export const TenderPriceTableButton = memo(({ className, onClick, isActive }: Props) => {
  const classes = classNames(className, 'flex items-stretch justify-between rounded-lg w-full', {
    'bg-primary-50': !isActive,
    'bg-white shadow-lg': isActive,
  })

  return (
    <div className={classes}>
      <Button className="flex items-center truncate p-3" onClick={onClick} state="raw">
        <div className="inline-flex items-center justify-center shrink-0 bg-gray-200 rounded w-6 h-6 mr-2">
          <Icon icon="table" variant="link" />
        </div>
        <Text className="truncate" size="sm" variant="link">
          Pricing table
        </Text>
      </Button>
    </div>
  )
})
