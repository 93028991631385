import { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { ContractCreateInitialStep } from '@cotiss/contract/steps/contract-create-initial.step'
import { ContractCreateProcurementStep } from '@cotiss/contract/steps/contract-create-procurement.step'
import { ProcurementListPopulatedModel } from '@cotiss/procurement/procurement.models'

export type ContractCreateMethod = 'with-procurement' | 'without-procurement'

export const ContractCreateDrawer = memo(() => {
  const { push } = useHistory()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [title, setTitle] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const { createContractShell } = useMutateContractShell()
  const { step, transition, isTransitioning, onTransition } = useTransition()
  const [createMethod, setCreateMethod] = useState<ContractCreateMethod>('with-procurement')
  const [selectedProcurement, setSelectedProcurement] = useState<ProcurementListPopulatedModel>()

  const handleCreateContract = async () => {
    track('contract_list_create_submit')

    try {
      setIsSaving(true)
      const { _id: contractShellId } = await createContractShell({
        title,
        ...(selectedProcurement && { procurementId: selectedProcurement._id }),
      })

      closeDrawer()
      push(routerService.getHref('/contract/view/:contractShellId/:tab?', { contractShellId }))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleSubmit = async () => {
    if (step === 1) {
      createMethod === 'without-procurement' ? handleCreateContract() : onTransition({ step: 2 })
    } else {
      handleCreateContract()
    }
  }

  const renderHeader = () => (
    <Text className="font-medium" size="h5" variant="heading">
      Create contract
    </Text>
  )

  const renderFooter = () => (
    <AnimatePresence mode="wait" initial={false}>
      <TransitionContainer key={step} transition={transition}>
        {step === 1 && (
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            {createMethod === 'with-procurement' ? (
              <>
                Continue <Icon icon="arrow-right" className="text-white ml-1" />
              </>
            ) : (
              'Confirm'
            )}
          </Button>
        )}
        {step === 2 && (
          <div className="flex items-center">
            <Button type="submit" variant="secondary" isLoading={isSaving}>
              Confirm
            </Button>
            <Text className="ml-2">{selectedProcurement ? '1' : '0'} selected</Text>
          </div>
        )}
      </TransitionContainer>
    </AnimatePresence>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <AnimatePresence mode="wait" initial={false}>
        <TransitionContainer key={step} transition={transition}>
          {step === 1 && (
            <ContractCreateInitialStep
              title={title}
              createMethod={createMethod}
              onCreateMethodChange={setCreateMethod}
              onTitleChange={setTitle}
              isDisabled={isTransitioning || isSaving}
            />
          )}
          {step === 2 && (
            <ContractCreateProcurementStep
              title={title}
              selectedProcurement={selectedProcurement}
              onProcurementChange={setSelectedProcurement}
              onBack={() => onTransition({ step: 1, transition: 'left' })}
              isDisabled={isTransitioning || isSaving}
            />
          )}
        </TransitionContainer>
      </AnimatePresence>
    </Drawer>
  )
})
