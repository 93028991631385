import { CSSProperties, memo } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
  isLoading?: boolean
  style?: CSSProperties
}

export const AvatarSkeleton = memo(({ className, isLoading }: Props) => {
  const classes = classNames(className, 'shrink-0 rounded-full h-8 w-8')

  return <Skeleton className={classes} isLoading={isLoading} isRounded />
})
