import { memo } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { createPortal } from 'react-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { TRANSITION_DURATION } from '@cotiss/common/constants/transition.constants'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

export const ToastNotification = memo(() => {
  const { toast, closeToast } = useToast()
  const { children, variant = 'success' } = toast || {}
  const classes = classNames(
    'absolute right-6 top-6 flex items-center max-w-xs w-full bg-primary-500 border overflow-hidden rounded-lg shadow-md p-2 z-toast',
    {
      'border-success': variant === 'success',
      'border-danger': variant === 'danger',
    }
  )

  const renderIcon = () => {
    switch (variant) {
      case 'success':
        return <Icon icon="check-circle" variant="link" />
      case 'danger':
        return <Icon icon="alert-circle-deprecated" variant="danger" size={20} />
    }
  }

  return createPortal(
    <AnimatePresence>
      {toast && (
        <motion.div
          className={classes}
          initial={{ y: -5, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: TRANSITION_DURATION.md }}
          exit={{ y: -5, opacity: 0 }}
        >
          <div className="inline-flex items-center shrink-0 mr-2">{renderIcon()}</div>
          <div className="w-full line-clamp-2">
            <Text variant="white">{children}</Text>
          </div>
          <Button className="ml-2 shrink-0" onClick={closeToast} state="ghost" shape="square" size="xs">
            <Icon icon="x-close" variant="light" />
          </Button>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  )
})
