import { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { ProcurementPopulatedModel } from '@cotiss/procurement/procurement.models'
import { useMutateProcurement } from '@cotiss/procurement/resources/use-mutate-procurement.resource'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'

type Props = {
  procurement: ProcurementPopulatedModel
}

export const ProcurementOverviewBackgroundDocumentUploadModal = memo(({ procurement }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateProcurement } = useMutateProcurement()
  const [attachments, setAttachments] = useState<DocumentModel[]>([])

  if (!user?.account?.organisation) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateProcurement(procurement._id, {
        attachments: [...map(attachments, ({ _id }) => _id), ...map(procurement.attachments, ({ _id }) => _id)],
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader
        heading="Upload internal documents"
        supplementary="These are read only documents not visible to suppliers."
        isDisabled={isSaving}
      />
      <ModalContent>
        <DocumentUpload documents={attachments} onChange={setAttachments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
