import { useReducer, createContext, useContext, useMemo, Dispatch, ReactNode, FC } from 'react'
import { Filter } from '@cotiss/common/models/filter.model'
import { CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB, ContractSearchAndFiltersPrimaryTabKeys } from '@cotiss/contract/contract.constants'

export type ContractSearchAndFiltersContextState = {
  searchQuery: string
  currentPage: number
  advancedFilters: Filter[]
  primaryTab: ContractSearchAndFiltersPrimaryTabKeys
  primaryTabFilters: Partial<Record<ContractSearchAndFiltersPrimaryTabKeys, { filters: Filter[] }>>
  statusCard: string | null
  statusCardFilters: Partial<Record<string, { filters: Filter[] }>>
}

type UpdateSearchQueryAction = {
  type: 'UPDATE_SEARCH_QUERY'
  payload: string
}

type UpdateCurrentPageAction = {
  type: 'UPDATE_CURRENT_PAGE'
  payload: number
}

type UpdateAdvancedFiltersAction = {
  type: 'UPDATE_ADVANCED_FILTERS'
  payload: Filter[]
}

type UpdatePrimaryTabAction = {
  type: 'UPDATE_PRIMARY_TAB'
  payload: ContractSearchAndFiltersPrimaryTabKeys
}

type UpdateStatusCardAction = {
  type: 'UPDATE_STATUS_CARD'
  payload: string | null
}

type ContractSearchAndFiltersContextAction =
  | UpdateSearchQueryAction
  | UpdateCurrentPageAction
  | UpdateAdvancedFiltersAction
  | UpdatePrimaryTabAction
  | UpdateStatusCardAction

export type ContractSearchAndFiltersContextValue = {
  processedFilters: Filter[]
  isQuerying: boolean
  queryState: ContractSearchAndFiltersContextState
  queryStateDispatch: Dispatch<ContractSearchAndFiltersContextAction>
}

const ContractSearchAndFiltersContext = createContext<ContractSearchAndFiltersContextValue | null>(null)

/**
 * Combines all filters from the state.
 * Note that advancedFilters are always applied,
 * and if a statusCard (and primaryTab) exists, its corresponding filters are added.
 */
function processFilters({
  advancedFilters,
  primaryTab,
  primaryTabFilters,
  statusCard,
  statusCardFilters,
}: ContractSearchAndFiltersContextState): Filter[] {
  return [
    ...advancedFilters,
    ...(statusCard ? statusCardFilters?.[statusCard]?.filters ?? [] : []),
    ...(primaryTab ? primaryTabFilters?.[primaryTab]?.filters ?? [] : []),
  ]
}

const searchAndFiltersReducer = (
  state: ContractSearchAndFiltersContextState,
  action: ContractSearchAndFiltersContextAction
): ContractSearchAndFiltersContextState => {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...state,
        currentPage: 1,
        searchQuery: action.payload,
      }
    case 'UPDATE_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'UPDATE_ADVANCED_FILTERS':
      return {
        ...state,
        currentPage: 1,
        advancedFilters: action.payload,
      }
    case 'UPDATE_PRIMARY_TAB':
      return {
        ...state,
        currentPage: 1,
        primaryTab: action.payload,
        statusCard: null,
      }
    case 'UPDATE_STATUS_CARD':
      return {
        ...state,
        currentPage: 1,
        statusCard: action.payload,
      }
    default:
      return state
  }
}

export const useContractSearchAndFiltersContext = () => {
  const context = useContext(ContractSearchAndFiltersContext)
  if (!context) {
    throw new Error('useContractSearchAndFiltersContext must be used within a ContractSearchAndFiltersProvider')
  }
  return context
}

const defaultInitialState: ContractSearchAndFiltersContextState = {
  searchQuery: '',
  currentPage: 1,
  advancedFilters: [],
  primaryTab: CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB,
  primaryTabFilters: {},
  statusCard: null,
  statusCardFilters: {},
}

export const ContractSearchAndFiltersContextProvider: FC<{
  children: ReactNode
  initialState: Partial<ContractSearchAndFiltersContextState>
}> = ({ children, initialState }) => {
  const [queryState, queryStateDispatch] = useReducer(searchAndFiltersReducer, { ...defaultInitialState, ...(initialState ?? {}) })

  const value = useMemo(
    () => ({
      queryState,
      isQuerying: Boolean(queryState.searchQuery?.length || queryState.advancedFilters?.length > 0 || queryState.statusCard),
      queryStateDispatch,
      processedFilters: processFilters(queryState),
    }),
    [queryState]
  )

  return <ContractSearchAndFiltersContext.Provider value={value}>{children}</ContractSearchAndFiltersContext.Provider>
}
