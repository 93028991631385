import { createElement, ComponentType, memo, useEffect, useState } from 'react'
import { Route, RouteProps, useHistory, useLocation } from 'react-router-dom'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { analyticsService } from '@cotiss/common/services/analytics.service'
import { featureService } from '@cotiss/common/services/feature.service'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAuth } from '@cotiss/auth/auth.provider'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useGetUserRoles } from '@cotiss/user/resources/use-get-user-roles.resource'
import { UserMeModel } from '@cotiss/user/user.models'

type Props = RouteProps & {
  component: ComponentType<any>
}

export const AppRoute = memo(({ component, ...rest }: Props) => {
  const location = useLocation()
  const { user } = useGetLoggedInUser()
  const { roles } = useGetUserRoles()
  const { push, replace } = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticating, isAuthenticated } = useAuth()
  const [isUserInitialised, setIsUserInitialised] = useState(false)

  useEffect(() => {
    if (isAuthenticating) {
      return
    }

    if (!isAuthenticated) {
      if (location.pathname !== '/') {
        localStorageService.setItem('auth-redirect-route', `${location.pathname}${location.search}`)
      }

      return push(routerService.getHref('/login'))
    }

    // If we make it this far, then we know that the user is authenticated. We can remove the redirect route from local storage.
    localStorageService.removeItem('auth-redirect-route')
  }, [isAuthenticating])

  // Pulling this out into it's own method, as we don't want to render the app until we've identified the user. However we want
  // render the app regardless on whether identifying the user has thrown an error or not, as we will fall back to our default
  // set of flags.
  const initialiseFeatureService = async (user: UserMeModel) => {
    try {
      await featureService.identify(user)
    } finally {
      setIsUserInitialised(true)
    }
  }

  useAsyncEffect(async () => {
    if (!user) {
      return
    }

    initialiseFeatureService(user)
    sentryService.setUser(user)
    analyticsService.identify(user)
  }, [user])

  useEffect(() => {
    if (!roles.length) {
      return
    }
    // Only go through redirection logic if the user navigates to the root path. Otherwise the user is navigating to a specific page.
    // TODO: Ensure that the user has permission to view the respective page. This needs to be done on a per-page basis.
    if (location.pathname !== '/') {
      setIsLoading(false)
      return
    }

    replace(routerService.getHref('/settings'))
    setIsLoading(false)
  }, [roles])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoading || !isUserInitialised) {
          return null
        }

        return createElement(component, props)
      }}
    />
  )
})
