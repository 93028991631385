import { memo, useEffect, useState } from 'react'
import AlertTriangleSvg from '@assets/svg/alert-triangle.svg'
import CotissIconSecondarySvg from '@assets/svg/cotiss-icon-secondary.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { useHistory, useParams } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAuth } from '@cotiss/auth/auth.provider'

export const SsoLoginPage = memo(() => {
  const { push } = useHistory()
  const { ssoLogin, logout } = useAuth()
  const { token } = useParams<{ token: string }>()
  const [errorMessage, setErrorMessage] = useState('')
  const isSsoConfigurationEnabled = useFeature('sso-configuration')

  useEffect(() => {
    ;(async () => {
      // Shouldn't ever be viewing this page without a token
      if (!token) {
        return
      }
      try {
        logout()
        await ssoLogin(token)
        push(localStorageService.getItem('auth-redirect-route') || routerService.getHref('/'))
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        // TODO: once BE gives more verbose error, parse and give useful message
        setErrorMessage('We encountered an error logging you in')
      }
    })()
  }, [token])

  if (!isSsoConfigurationEnabled) {
    return <FourOhThreePage />
  }

  return (
    <Page className="flex items-center justify-center bg-white ">
      <div className="w-1/2 h-full p-12">
        <div className="flex items-center justify-center w-full h-full">
          {errorMessage && (
            <div className="flex flex-col items-center">
              <AlertTriangleSvg />
              <Badge variant="secondary" state="translucent" shape="square">
                Error
              </Badge>
              <Text className="text-xl mt-6 font-semibold" size="h3">
                {errorMessage}
              </Text>
              <Text className="text-xs mt-2" variant="light">
                Please contact your systems administrator
              </Text>
            </div>
          )}
          {!errorMessage && (
            <div className="w-32 flex flex-col items-center">
              <CotissIconSecondarySvg />
              <Text className="mt-8">Redirecting you...</Text>
            </div>
          )}
        </div>
      </div>
      <div className=" w-1/2 h-full p-4">
        <div className="flex flex-col items-start justify-between bg-secondary-500 rounded-lg h-full w-full p-10">
          <CotissLogoLightSvg className="h-8" />
          <h1 className="text-white text-5xl leading-tight">
            Better decisions.
            <br />
            Simplified process.
          </h1>
        </div>
      </div>
    </Page>
  )
})
