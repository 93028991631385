import { memo } from 'react'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { OrganisationModel } from '@cotiss/organisation/organisation.models'
import { useGetPublicOrganisation } from '@cotiss/organisation/resources/use-get-public-organisation.resource'

type Props = {
  organisationId: string
  onChange: (publicOrganisation: OrganisationModel) => void
  isSelected?: boolean
  isDisabled?: boolean
}

export const SignUpRegisterOrganisationInterest = memo(({ organisationId, onChange, isSelected, isDisabled }: Props) => {
  // TODO: We should provide a way to bulk fetch the public organisations, otherwise we need to make a lot of individual requests, which could clog up
  // TODO: our network and server.
  const { publicOrganisation, isLoading } = useGetPublicOrganisation(organisationId)

  if (!publicOrganisation?.account?._id) {
    return null
  }

  return (
    <RadioCard
      className="p-3.5"
      value={publicOrganisation?.account._id}
      onChange={() => onChange(publicOrganisation)}
      variant="info"
      name="sign-up-register-organisation-interest"
      isSelected={isSelected}
      isDisabled={isDisabled}
      isRequired
    >
      <div className="w-full">
        <div className="flex justify-between">
          <OrganisationLogo className="-mt-9 mb-3" organisation={publicOrganisation} />
          <div className="relative">
            {isSelected && <div className="absolute top-1 left-1 w-2 h-2 rounded-full bg-primary-500" />}
            <div className="appearance-none form-radio shadow-sm rounded-full w-4 h-4 bg-white border border-gray-300 group-hover:border-gray-400" />
          </div>
        </div>
        {isLoading && <Skeleton className="h-2 w-2/3" />}
        {!isLoading && (
          <Text size="sm" className="font-medium">
            {publicOrganisation.name}
          </Text>
        )}
      </div>
      {isLoading && <Skeleton className="h-2 w-1/2" />}
      {!isLoading && (
        <Text className="mt-2" variant="light" size="sm">
          {publicOrganisation.account.location?.city} <span className="sr-only">, </span>
          <span className="not-sr-only px-2">&bull;</span> {publicOrganisation.account.location?.country}
        </Text>
      )}
    </RadioCard>
  )
})
