import { memo } from 'react'
import { map } from 'lodash'
import { ScrollContainerButton } from '@cotiss/common/components/scroll-container-button.component'
import { StatusCard } from '@cotiss/common/components/status-card.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useAutoScrollContainer } from '@cotiss/common/hooks/use-auto-scroll-container.hook'
import { useContractStatusCardFilterTotals } from '@cotiss/contract/hooks/use-contract-status-card-filter-totals.hook'
import { useContractSearchAndFiltersContext } from '@cotiss/contract/components/contract-search-and-filters-context.component'
import { CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS } from '@cotiss/contract/contract.constants'

type Props = {
  isDisabled?: boolean
  statusCardFilterTotals: ReturnType<typeof useContractStatusCardFilterTotals>
}

export const ContractListStatusCards = memo(({ isDisabled, statusCardFilterTotals }: Props) => {
  const { queryState, queryStateDispatch } = useContractSearchAndFiltersContext()
  const { track } = useAnalytics()

  const { scrollContainerRef, canScrollLeft, canScrollRight, handleScrollLeft, handleScrollRight } = useAutoScrollContainer()

  return (
    <div className="relative">
      {canScrollLeft ? <ScrollContainerButton inset={1} direction="left" onClick={handleScrollLeft} /> : null}
      <div ref={scrollContainerRef} className="flex overflow-x-auto bg-white scrollbar-hidden border">
        {map(CONTRACT_SEARCH_AND_FILTERS_STATUS_CARD_FILTERS, ({ label, icon, iconFill, iconStroke, tooltip }, key) => {
          const { filteredSubtotal, total } = statusCardFilterTotals?.totals[key] ?? {}

          const isActive = queryState.statusCard === key

          return (
            <StatusCard
              key={key}
              label={label}
              icon={icon}
              iconFill={iconFill}
              iconStroke={iconStroke}
              shouldShowTotal={Boolean(queryState.searchQuery || queryState.advancedFilters.length > 0)}
              filteredSubtotal={filteredSubtotal}
              total={total}
              onClick={() => {
                // Only fire the event if they are activating (not de-activating) the card
                if (!isActive) track('contract_list_status_card_click', { statusCard: key })
                queryStateDispatch({
                  type: 'UPDATE_STATUS_CARD',
                  payload: isActive ? null : key,
                })
              }}
              isActive={isActive}
              isDisabled={isDisabled}
              isLoading={statusCardFilterTotals?.isLoading}
              className="last:border-r-0 border-r"
              tooltip={tooltip}
            />
          )
        })}
      </div>
      {canScrollRight ? <ScrollContainerButton inset={1} direction="right" onClick={handleScrollRight} /> : null}
    </div>
  )
})
