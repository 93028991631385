import { memo, useEffect, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { indexOf } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationChairHeader } from '@cotiss/evaluation/components/evaluation-chair-header.component'
import { EVALUATION_CHAIR_STEPS, EvaluationChairStep } from '@cotiss/evaluation/evaluation.constants'
import { evaluationService } from '@cotiss/evaluation/evaluation.service'
import { EvaluationChairConsensusStep } from '@cotiss/evaluation/steps/evaluation-chair-consensus.step'
import { EvaluationChairIndividualStep } from '@cotiss/evaluation/steps/evaluation-chair-individual.step'
import { EvaluationChairPriceStep } from '@cotiss/evaluation/steps/evaluation-chair-price.step'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'

export const EvaluationChairPage = memo(() => {
  const { replace } = useHistory()
  const { tenderId, step: evaluationChairStep } = useParams<{ tenderId: string; step: EvaluationChairStep }>()
  const { tender } = useGetTender(tenderId)
  const { step, transition, onTransition } = useTransition({ initialStep: indexOf(EVALUATION_CHAIR_STEPS, evaluationChairStep) + 1 })

  const headerBreadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'chair' }),
      },
      {
        label: 'Chair evaluation',
      },
    ]
  }, [])

  useEffect(() => {
    if (!tender) {
      return
    }

    if (evaluationChairStep) {
      return replace(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step: evaluationChairStep }))
    }

    const activeStep = evaluationService.getActiveChairStep(tender)
    replace(routerService.getHref('/evaluation/chair/:tenderId/:step?', { tenderId, step: activeStep || 'individual-evaluation' }))
  }, [tender])

  useEffect(() => {
    const newStep = indexOf(EVALUATION_CHAIR_STEPS, evaluationChairStep) + 1

    onTransition({ step: newStep || 1, transition: newStep > step ? 'right' : 'left' })
  }, [evaluationChairStep])

  return (
    <Page>
      <EvaluationChairHeader breadcrumbs={headerBreadcrumbs} />
      <PageContent>
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {step === 1 && <EvaluationChairIndividualStep key={1} />}
            {step === 2 && <EvaluationChairConsensusStep key={2} />}
            {step === 3 && <EvaluationChairPriceStep key={3} />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
