import { ChangeEvent, memo, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { ACCOUNT_INDUSTRY_OPTIONS } from '@cotiss/account/account.constants'
import { authResource } from '@cotiss/auth/auth.resource'
import { SignUpSupplierFormData } from '@cotiss/auth/pages/sign-up-supplier.page'

type Props = {
  formData: SignUpSupplierFormData
  transition: TransitionType
  setFormData: (formData: SignUpSupplierFormData) => void
  isDisabled?: boolean
}

export const SignUpSupplierStepCompany = memo(({ formData, transition, setFormData, isDisabled }: Props) => {
  const [isEmailInUse, setIsEmailInUse] = useState(false)

  // NOTE: Make sure to check email on page load in case of prefill from query params
  useEffect(() => {
    if (formData.email) {
      handleCheckDomain(formData.email)
    }
  }, [])

  const handleCheckDomain = debounce(async (email: string) => {
    const domain = await authResource.verifyDomain(email)
    setIsEmailInUse(!domain?.message)
  }, 300)

  const handleEmailChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, email: target.value })
    handleCheckDomain(target.value)
  }

  return (
    <TransitionContainer transition={transition}>
      <Label>Email address</Label>
      <Input
        value={formData.email}
        onChange={handleEmailChange}
        placeholder="Enter your email address"
        type="email"
        maxLength={320}
        autoComplete="username"
        isDisabled={isDisabled}
        isRequired
      />
      {isEmailInUse && (
        <Text size="sm" className="mt-2">
          It appears your organisation already has an account.{' '}
          <Button size="sm" state="text" variant="link" href={routerService.getHref('/join-account/:email', { email: formData.email })} isLink>
            Register to join account
          </Button>
        </Text>
      )}
      <Label className="mt-6">Company name</Label>
      <Input
        value={formData.companyName}
        onChange={({ target }) => setFormData({ ...formData, companyName: target.value })}
        name="Company name"
        placeholder="Enter company name"
        maxLength={100}
        isDisabled={isDisabled || isEmailInUse}
        isRequired
      />
      <Label className="mt-6">Industry</Label>
      <Select_DEPRECATED
        value={formData.industry}
        options={ACCOUNT_INDUSTRY_OPTIONS}
        onChange={(industry) => setFormData({ ...formData, industry })}
        isDisabled={isDisabled || isEmailInUse}
        placeholder
        isRequired
      />
      <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled || isEmailInUse}>
        Next
      </Button>
    </TransitionContainer>
  )
})
