import { memo, useMemo, useState } from 'react'
import { compact, filter, map, some } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'
import { Button } from '@cotiss/common/components/button.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { TenderResponseSubmissionUpdateDrawer } from '@cotiss/tender-response/drawers/tender-response-submission-update.drawer'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import {
  PreferredSupplierSearchAndFiltersProvider,
  usePreferredSupplierSearchAndFilters,
} from '@cotiss/preferred-supplier/components/preferred-supplier-search-and-filters'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { useHistory } from 'react-router-dom'
import { routerService } from '@cotiss/common/services/router.service'
import { PreferredSupplierListSearchInput } from '@cotiss/preferred-supplier/components/preferred-supplier-list-search-input.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { AdvancedFiltersDropdownButton } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-button.component'
import { AdvancedFiltersPillList } from '@cotiss/common/modals/advanced-filters/advanced-filters-pill-list.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import HighlightedText from '@cotiss/common/components/highlighted-text.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'
import { PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS } from '@cotiss/preferred-supplier/preferred-supplier.constants'

type Props = {
  tenderId: string
}

const TenderResponseSubmissionCreateDrawerInternal = memo(({ tenderId }: Props) => {
  const { openToast } = useToast()
  const { push } = useHistory()
  const { track } = useAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { createTenderResponse } = useMutateTenderResponse()
  const { closeDrawer, openDrawer, closeNarrowDrawer } = useCallout()
  const { tenderResponses } = useListTenderResponse({ tenderId, status: 'submitted' })
  const [selectedPreferredSupplier, setSelectedPreferredSupplier] = useState<PreferredSupplierPopulatedModel>()

  const { isQuerying, queryState, preferredSuppliers, pagination, isLoadingPreferredSuppliers, handlePageChange, onSort } =
    usePreferredSupplierSearchAndFilters()

  const handleSubmit = async () => {
    if (!tenderId || !selectedPreferredSupplier?.supplierOrganisation) {
      return
    }

    try {
      setIsSaving(true)
      closeNarrowDrawer()

      const tenderResponse = await createTenderResponse({ tenderId, supplierOrganisationId: selectedPreferredSupplier.supplierOrganisation._id })

      openDrawer(<TenderResponseSubmissionUpdateDrawer tenderResponseId={tenderResponse._id} />)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleAddContact = () => {
    closeDrawer()
    push(routerService.getHref('/preferred-supplier/list/:tab?'))
  }

  const { fixedColumns, columns } = useMemo(() => {
    const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
      if (!preferredSupplier.tags?.length) {
        return <Text size="sm">--</Text>
      }

      const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

      return (
        <>
          <Badge className="mr-1" state="outline" variant="secondary">
            {firstTag}
          </Badge>
          {Boolean(rest.length) && (
            <Tooltip tooltip={rest.join(', ')}>
              <Text className="font-medium" variant="link" size="sm">
                +{rest.length}
              </Text>
            </Tooltip>
          )}
        </>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(preferredSuppliers, (preferredSupplier) => {
          const isAlreadySubmitted = some(
            tenderResponses,
            (tenderResponse) => tenderResponse.procurementResponse.supplier._id === preferredSupplier.supplierOrganisation?._id
          )

          return {
            content: () => (
              <Radio
                value={preferredSupplier._id}
                name="evaluation-event-submission-contact"
                onChange={() => setSelectedPreferredSupplier(preferredSupplier)}
                isChecked={preferredSupplier._id === selectedPreferredSupplier?._id}
                isDisabled={isSaving || isAlreadySubmitted}
                isRequired
              />
            ),
          }
        }),
      },
      {
        heading: 'Organisation name',
        onSort: () => onSort('organisationName'),
        rows: map(preferredSuppliers, (preferredSupplier) => {
          const isAlreadySubmitted = some(
            tenderResponses,
            (tenderResponse) => tenderResponse.procurementResponse.supplier._id === preferredSupplier.supplierOrganisation?._id
          )

          return {
            content: () => (
              <Text className="truncate" title={preferredSupplier.supplierOrganisation?.name || '--'}>
                <HighlightedText text={preferredSupplier.supplierOrganisation?.name || '--'} highlightText={queryState.searchQuery} />
              </Text>
            ),
            cta: isAlreadySubmitted && (
              <Tooltip tooltip="This contact has already been submitted.">
                <Icon icon="lock" />
              </Tooltip>
            ),
          }
        }),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Organisation status',
        rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
          content: () =>
            supplierOrganisation?.claimedStatus ? (
              <Badge
                state="outline"
                variant={supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the contacts associated with this account has created a Cotiss account'
                    : 'None of the contacts associated with this account have created a Cotiss account'
                }
              >
                {supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(supplierOrganisation.claimedStatus)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
      {
        heading: 'Contacts',
        rows: map(preferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstName: contact.email, lastName: '' })),
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(preferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [preferredSuppliers, selectedPreferredSupplier, isSaving])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Associate contact
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button variant="secondary" type="submit" isLoading={isSaving} isDisabled={!selectedPreferredSupplier}>
        Continue <Icon className="ml-1" icon="arrow-right" />
      </Button>
      <Text className="ml-2">{selectedPreferredSupplier ? '1' : '0'} selected</Text>
    </div>
  )

  const renderContent = () => {
    const emptyState = isQuerying ? (
      <Text size="md" variant="light">
        No results
      </Text>
    ) : (
      <NoDataPlaceholder
        illustration="dot-list"
        variant="transparent"
        label="You have not added any contacts yet."
        ctaLabel="+ Add contact"
        onCtaClick={handleAddContact}
      />
    )

    return (
      <>
        <TableHeader className="flex items-center justify-between">
          <Text className="font-medium" size="lg">
            Select contact
          </Text>
        </TableHeader>
        <div className="w-full h-13 px-4 border-t flex items-center justify-between gap-4 border-l border-r">
          <PreferredSupplierListSearchInput className="flex-1" />
          <VerticalDivider className="h-7" />
          <AdvancedFiltersDropdownButton title="Filter contacts" onOpen={() => track('preferred_supplier_list_filters_dropdown_open')} />
        </div>
        {queryState.advancedFilters?.length ? (
          <div className="w-full px-4 py-3 border-x border-t">
            <AdvancedFiltersPillList />
          </div>
        ) : null}
        {!isLoadingPreferredSuppliers && !preferredSuppliers.length ? (
          <div className="flex items-center justify-center h-60 bg-white border">{emptyState}</div>
        ) : (
          <ScrollableTable
            fixedColumns={fixedColumns}
            columns={columns}
            pagination={pagination}
            onPageChange={handlePageChange}
            isLoading={isLoadingPreferredSuppliers}
          />
        )}
      </>
    )
  }

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      {renderContent()}
    </Drawer>
  )
})

export const TenderResponseSubmissionCreateDrawer = ({ tenderId }: Props) => {
  const initialState = {
    primaryTabFilters: PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS,
  }

  return (
    <PreferredSupplierSearchAndFiltersProvider initialState={initialState}>
      <TenderResponseSubmissionCreateDrawerInternal tenderId={tenderId} />
    </PreferredSupplierSearchAndFiltersProvider>
  )
}
