import { memo, useEffect, useState } from 'react'
import { clamp } from 'lodash'
import classNames from 'classnames'
import { GqlEvaluationCriteriaRatingScale } from '@gql'
import { Input } from '@cotiss/common/components/input.component'
import { Select } from '@cotiss/common/components/select.component'
import {
  EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP,
  EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS,
} from '@cotiss/evaluation-event/evaluation-event.constants'
import { Text } from '@cotiss/common/components/text.component'

type Props = {
  className?: string
  ratingScale: GqlEvaluationCriteriaRatingScale
  value?: string
  onSubmit: (value: string) => void
  isDisabled?: boolean
}

export const EvaluationEventRatingScaleValueField = memo(({ className, ratingScale, value, onSubmit, isDisabled }: Props) => {
  const [stateValue, setStateValue] = useState(value || '')
  const classes = classNames(className, 'flex items-center w-full')

  useEffect(() => {
    setStateValue(value || '')
  }, [value])

  const renderContent = () => {
    if (ratingScale === 'zeroToOneHundredWithStaggeredDefinitions' || ratingScale === 'percentage') {
      return (
        <Input
          className="w-full"
          value={stateValue}
          onChange={({ target }) => setStateValue((clamp(Number(target.value), 0, 100) || 0).toString())}
          onBlur={() => onSubmit(stateValue)}
          placeholder="--"
          isDisabled={isDisabled}
        />
      )
    }

    return (
      <Select
        className="w-full"
        value={value}
        options={EVALUATION_EVENT_SCORE_VALUE_BY_RATING_SCALE_OPTIONS[ratingScale]}
        onChange={onSubmit}
        placeholder="--"
        isDisabled={isDisabled}
        isRequired
      />
    )
  }

  return (
    <div className={classes}>
      {renderContent()}
      <Text className="flex items-center shrink-0 w-10 ml-2">
        {ratingScale === 'percentage' ? '%' : `/ ${EVALUATION_EVENT_RATING_SCALE_DENOMINATOR_MAP[ratingScale]}`}
      </Text>
    </div>
  )
})
