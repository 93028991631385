import { memo } from 'react'
import classNames from 'classnames'
import { Text } from '@cotiss/common/components/text.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

type Props = {
  state?: 'full' | 'simple'
}

export const AppSideNavOrganisation = memo(({ state }: Props) => {
  const { user } = useGetLoggedInUser()
  const nameParts = user?.account?.organisation?.name.split(' ')
  const [firstNamePart, secondNamePart] = nameParts || []

  return (
    <div className="flex items-center">
      <div
        className={classNames('flex items-center justify-center bg-primary-500 flex-shrink-0 text-white rounded w-8 h-8', {
          'mr-2': state === 'full',
        })}
      >
        {firstNamePart?.[0]}
        {secondNamePart?.[0]}
      </div>
      {state === 'full' && <Text className="font-medium line-clamp-2">{user?.account?.organisation?.name}</Text>}
    </div>
  )
})
1
