import { ReactNode, memo } from 'react'
import classNames from 'classnames'
import { Icon } from '@cotiss/common/components/icon.component'
import { PaginationButton } from '@cotiss/common/components/pagination-button.component'

export type PaginationModel = {
  currentPage: number
  itemCount: number
  pageCount: number
  pageSize: number
  totalCount: number
}

type Props = {
  className?: string
  pagination: PaginationModel
  onPageChange: (page: number) => void
  isDisabled?: true
}

export const Pagination = memo(({ className, pagination, onPageChange, isDisabled }: Props) => {
  const { currentPage, pageCount } = pagination

  if (!pageCount || pageCount === 1) {
    return null
  }

  const renderButtons = () => {
    let isOutOfRange = false
    const buttons: ReactNode[] = []

    for (let i = 1; i <= pageCount; i += 1) {
      if (Math.abs(currentPage - i) <= 2) {
        isOutOfRange = false

        buttons.push(
          <PaginationButton key={i} className="ml-2" onClick={() => onPageChange(i)} isActive={i === currentPage} isDisabled={isDisabled}>
            {i}
          </PaginationButton>
        )
      } else {
        if (!isOutOfRange) {
          buttons.push(
            <PaginationButton key={i} className="ml-2" isDisabled>
              ...
            </PaginationButton>
          )
        }

        isOutOfRange = true
      }
    }

    return buttons
  }

  return (
    <div className={classNames(className, 'flex items-center justify-center w-full')}>
      <PaginationButton className="ml-2" onClick={() => onPageChange(currentPage - 1)} isDisabled={isDisabled || currentPage === 1}>
        <Icon icon="chevron-left" />
      </PaginationButton>

      {renderButtons()}

      <PaginationButton className="ml-2" onClick={() => onPageChange(currentPage + 1)} isDisabled={isDisabled || currentPage === pageCount}>
        <Icon icon="chevron-right" />
      </PaginationButton>
    </div>
  )
})
