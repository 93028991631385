import ReactSelect from 'react-select'
import ReactCreatableSelect from 'react-select/creatable'
import { MultiSelectGroupedOption, MultiSelectOption } from '@cotiss/common/models/form.model'

type Props<T = string> = {
  className?: string
  value?: MultiSelectOption<T>[]
  options?: MultiSelectOption<T>[] | MultiSelectGroupedOption<T>[]
  placeholder?: string | true
  onChange: (value: MultiSelectOption<T>[]) => void
  onCreate?: (value: string) => void
  onInputChange?: (value: string) => void
  isRequired?: boolean
  isDisabled?: boolean
}

export function MultiSelect_DEPRECATED<T extends string>({
  className,
  value,
  options,
  placeholder,
  onChange,
  onCreate,
  onInputChange,
  isRequired,
  isDisabled,
}: Props<T>) {
  const handleCreate = (value: string) => {
    onChange([{ value: value as T, label: value }])
    onCreate && onCreate(value)
  }

  return (
    <>
      {!onCreate && (
        <ReactSelect
          className={className}
          value={value}
          options={options}
          onChange={(value) => onChange(value as MultiSelectOption<T>[])}
          onInputChange={onInputChange}
          isClearable={false}
          isDisabled={isDisabled}
          required={isRequired}
          isMulti
          components={{ IndicatorSeparator: null }}
          placeholder={placeholder === true ? '- Select an option -' : placeholder}
        />
      )}
      {onCreate && (
        <ReactCreatableSelect
          className={className}
          value={value}
          options={options}
          onChange={(value) => onChange(value as MultiSelectOption<T>[])}
          onCreateOption={handleCreate}
          onInputChange={onInputChange}
          required={isRequired}
          isClearable={false}
          isDisabled={isDisabled}
          isMulti
          components={{ IndicatorSeparator: null }}
          placeholder={placeholder === true ? '- Select an option -' : placeholder}
        />
      )}
    </>
  )
}
