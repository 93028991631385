import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { Switch, useHistory } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { StagingBanner } from '@cotiss/common/components/staging-banner.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { envService } from '@cotiss/common/services/env.service'
import { AppLayout } from '@cotiss/app/app.layout'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'
import { AppRoute } from '@cotiss/app/components/app-route.component'
import { ForgotPasswordPage } from '@cotiss/auth/pages/forgot-password.page'
import { JoinAccountPage } from '@cotiss/auth/pages/join-account.page'
import { LoginPage } from '@cotiss/auth/pages/login.page'
import { LogoutPage } from '@cotiss/auth/pages/logout.page'
import { MasqueradePage } from '@cotiss/auth/pages/masquerade.page'
import { ResetPasswordPage } from '@cotiss/auth/pages/reset-password.page'
import { SignUpBuyerPage } from '@cotiss/auth/pages/sign-up-buyer.page'
import { SignUpSupplierPage } from '@cotiss/auth/pages/sign-up-supplier.page'
import { SignUpUserPage } from '@cotiss/auth/pages/sign-up-user.page'
import { SignUpPage } from '@cotiss/auth/pages/sign-up.page'
import { SsoErrorPage } from '@cotiss/auth/pages/sso-error.page'
import { SsoLoginPage } from '@cotiss/auth/pages/sso-login.page'

export const App = Sentry.withProfiler(() => {
  const history = useHistory()
  const { init: initAnalytics } = useAnalytics()
  const classes = classNames('relative', {
    'h-full': !envService.isStaging(),
    'top-[68px] h-[calc(100%-68px)]': envService.isStaging(),
  })

  useEffect(() => {
    initAnalytics()
    history.listen(() => {
      window.scroll(0, 0)
    })
  }, [])

  return (
    <Sentry.ErrorBoundary>
      {envService.isStaging() && <StagingBanner />}
      <div className={classes}>
        <Switch>
          {/* ! This is a legacy route that we want to support incase there are any old invitation emails hanging around. */}
          <Route path="/user-signup" exact>
            <SignUpUserPage />
          </Route>

          <Route path="/error" exact>
            <AppErrorPage />
          </Route>
          <Route path="/sign-up" exact>
            <SignUpPage />
          </Route>
          <Route path="/sign-up/supplier" exact>
            <SignUpSupplierPage />
          </Route>
          <Route path="/sign-up/buyer" exact>
            <SignUpBuyerPage />
          </Route>
          <Route path="/sign-up/user/:invitationCode" exact>
            <SignUpUserPage />
          </Route>
          <Route path="/join-account/:email" exact>
            <JoinAccountPage />
          </Route>
          <Route path="/forgot-password" exact>
            <ForgotPasswordPage />
          </Route>
          <Route path="/reset-password/:token" exact>
            <ResetPasswordPage />
          </Route>
          <Route path="/masquerade/:token" exact>
            <MasqueradePage />
          </Route>
          <Route path="/login" exact>
            <LoginPage />
          </Route>
          <Route path="/sso-login/:token" exact>
            <SsoLoginPage />
          </Route>
          <Route path="/sso-error" exact>
            <SsoErrorPage />
          </Route>
          <Route path="/logout" exact>
            <LogoutPage />
          </Route>
          <AppRoute path="/" component={AppLayout} />
        </Switch>
      </div>
    </Sentry.ErrorBoundary>
  )
})
