import { memo, useState } from 'react'
import { Input, InputProps } from '@cotiss/common/components/input.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'

type Props = InputProps & {
  onSubmit: () => Promise<void>
}

export const AsyncInput = memo(({ onSubmit, value, isDisabled, ...rest }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)

  const handleBlur = async () => {
    try {
      setIsSaving(true)
      await onSubmit()
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return <Input {...rest} value={value} onBlur={handleBlur} isDisabled={isDisabled || isSaving} />
})
