import { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { RemirrorJSON } from 'remirror'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { useMutateTenderUpdate } from '@cotiss/tender-update/resources/use-mutate-tender-update.resource'
import { TenderUpdatePopulatedModel } from '@cotiss/tender-update/tender-update.models'

type FormData = {
  title: string
  content: RemirrorJSON
  attachments: DocumentModel[]
}

type Props = {
  tenderId?: string
  tenderUpdate?: TenderUpdatePopulatedModel
}

export const TenderUpdateCreateUpdateModal = memo(({ tenderId, tenderUpdate }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createTenderUpdate, updateTenderUpdate } = useMutateTenderUpdate()
  const [formData, setFormData] = useState<FormData>({
    title: tenderUpdate?.title || '',
    content: tenderUpdate?.content || richTextService.getEmptyRemirrorJson(),
    attachments: tenderUpdate?.attachments || [],
  })
  const { track } = useAnalytics()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!tenderId && !tenderUpdate) {
      return
    }

    try {
      setIsSaving(true)
      const formDataToPost = { ...formData, attachments: map(formData.attachments, ({ _id }) => _id) }

      if (tenderId && !tenderUpdate) {
        track('forum_update_create_submit')
        await createTenderUpdate({ tenderId, ...formDataToPost })
      }

      if (tenderUpdate) {
        track('forum_update_update_submit')
        await updateTenderUpdate(tenderUpdate._id, formDataToPost)
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="h-full min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${tenderUpdate ? 'Edit' : 'Create'} forum update`} isDisabled={isSaving} />
      <ModalContent isScrollable>
        <Label>Title</Label>
        <Input
          value={formData.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          placeholder="Title"
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-4">Content</Label>
        <RichTextEditor value={formData.content} onChange={(content) => setFormData({ ...formData, content })} isDisabled={isSaving} isRequired />

        <Label className="mt-4">Attachments (optional)</Label>
        <DocumentUpload documents={formData.attachments} onChange={(attachments) => setFormData({ ...formData, attachments })} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
