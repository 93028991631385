import { memo, useMemo, useState } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment, GqlEvaluationEnvelopeOverviewFieldsFragment, GqlEvaluationEventFieldsFragment } from '@gql'
import { includes, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationEventEnvelopeStatusBadge } from '@cotiss/evaluation-event/components/evaluation-event-envelope-status-badge.component'
import { mutateRegressEvaluationEnvelope } from '@cotiss/evaluation-event/graphql/evaluation-envelope/mutate-regress-evaluation-envelope.graphql'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  className?: string
  evaluationEvent: GqlEvaluationEventFieldsFragment
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  evaluationEnvelopeOverview: GqlEvaluationEnvelopeOverviewFieldsFragment
  fixedColumns: ScrollableTableColumn[]
  columns: ScrollableTableColumn[]
  isLoading?: boolean
}

export const EvaluationEventViewTrackingEnvelope = memo((props: Props) => {
  const { className, evaluationEvent, evaluationEnvelope, evaluationEnvelopeOverview, fixedColumns, columns, isLoading } = props
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { evaluationUserInSession } = useEvaluationUser()
  const { queryEvaluationEventView } = useEvaluationEvent()
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { queryEvaluationEnvelopeList, queryEvaluationEnvelopeOverviewList, queryEvaluationEnvelopeDownloadCsv, mutateProgressEvaluationEnvelope } =
    useEvaluationEnvelope()

  const { isUserInSessionOwner, isUserInSessionModerator, isUserInSessionEvaluator, hasTable } = useMemo(() => {
    const isUserInSessionOwner = evaluationUserInSession?.role === 'owner'
    const baseAccessControl = { resourceType: 'envelope', resourceId: evaluationEnvelope.id }
    const isUserInSessionModerator = some(evaluationUserInSession?.accessControls, { ...baseAccessControl, access: 'moderate' })
    const isUserInSessionEvaluator = some(evaluationUserInSession?.accessControls, { ...baseAccessControl, access: 'evaluate' })

    const hasTable = evaluationEvent.status === 'draft' || evaluationEnvelope.status !== 'locked'

    return { isUserInSessionOwner, isUserInSessionEvaluator, isUserInSessionModerator, hasTable }
  }, [evaluationUserInSession, evaluationEnvelope])

  const handleProgressEnvelope = async () => {
    const updatedEvaluationEnvelope = await mutateProgressEvaluationEnvelope({ evaluationEnvelopeId: evaluationEnvelope.id })

    const [updatedEvaluationEvent] = await Promise.all([
      queryEvaluationEventView({ evaluationEventId: evaluationEvent.id }),
      queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } }),
      queryEvaluationEnvelopeOverviewList({ filter: { evaluationEventId: evaluationEvent.id } }),
    ])

    if (updatedEvaluationEnvelope.status === 'moderate' && isUserInSessionModerator) {
      push(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId', {
          evaluationEventId: evaluationEvent.id,
          evaluationEnvelopeId: evaluationEnvelope.id,
        })
      )
    } else if (updatedEvaluationEvent.status === 'complete') {
      push(
        routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId: evaluationEvent.id, tab: 'result' })
      )
    }
  }

  const handleRegressEnvelope = async () => {
    await mutateRegressEvaluationEnvelope({ evaluationEnvelopeId: evaluationEnvelope.id })

    await Promise.all([
      queryEvaluationEventView({ evaluationEventId: evaluationEvent.id }),
      queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } }),
      queryEvaluationEnvelopeOverviewList({ filter: { evaluationEventId: evaluationEvent.id } }),
    ])
  }

  const handleDownloadCsv = async () => {
    try {
      setIsDownloading(true)
      const csv = await queryEvaluationEnvelopeDownloadCsv({ evaluationEnvelopeId: evaluationEnvelope.id })

      const link = document.createElement('a')
      link.setAttribute('href', `data:text/csv;charset=utf-8,${csv}`)
      link.setAttribute('download', `${evaluationEnvelope.name}.csv`)
      document.body.appendChild(link)

      link.click()
      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  const renderEnvelopeCta = () => {
    // There is quite a bit of logic to decide what CTA appears at the top of the envelope based on the roles and access the user in session has, and
    // whether the envelope has a moderation phase. Here is the table of what to show:
    //
    // ENVELOPE WITH MODERATION PHASE:
    //
    // | Accesses & Role               | Evaluation Phase CTA               | Moderation Phase                                             |
    // | ----------------------------- | ---------------------------------- | ------------------------------------------------------------ |
    // | Evaluator                     | View evaluation                    | N/A                                                          |
    // | Moderator                     | Start moderation                   | Back to evaluate + Review scores + Submit reviewed scores |
    // | Evaluator + Moderator         | View evaluation + Start moderation | Back to evaluate + Review scores + Submit reviewed scores |
    // | Owner                         | Start moderation                   | Back to evaluate + Submit reviewed scores                   |
    // | Owner + Evaluator             | View evaluation + Start moderation | Back to evaluate + Submit reviewed scores                   |
    // | Owner + Moderator             | View evaluation + Start moderation | Back to evaluate + Review scores + Submit reviewed scores |
    // | Owner + Evaluator + Moderator | View evaluation + Start moderation | Back to evaluate + Review scores + Submit reviewed scores |
    //
    // ENVELOPE WITHOUT MODERATION PHASE:
    //
    // | Accesses & Role   | Evaluation Phase CTA            |
    // | ----------------- | ------------------------------- |
    // | Evaluator         | View evaluation                 |
    // | Owner             | Submit scores                   |
    // | Owner + Evaluator | View evaluation + Submit scores |

    if (!evaluationUserInSession || evaluationEvent.status !== 'active' || !includes(['evaluate', 'moderate'], evaluationEnvelope.status)) {
      return
    }

    if (evaluationEnvelopeOverview.hasModerationPhase) {
      if (evaluationEnvelope.status === 'evaluate') {
        return (
          <>
            {isUserInSessionEvaluator && (
              <Button
                className="whitespace-nowrap"
                variant="secondary-dark"
                state="translucent"
                size="sm"
                href={routerService.getHref(
                  '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
                  {
                    evaluationEventId: evaluationEvent.id,
                    evaluationEnvelopeId: evaluationEnvelope.id,
                    evaluationUserId: evaluationUserInSession.id,
                  }
                )}
                isLink
              >
                View evaluation <Icon className="ml-1" icon="arrow-right" />
              </Button>
            )}
            {(isUserInSessionModerator || isUserInSessionOwner) && (
              <Tooltip
                className="ml-2"
                tooltip="Moderation cannot begin while evaluations in progress"
                isEnabled={evaluationEnvelopeOverview.nextStatus !== 'moderate'}
              >
                <Button
                  className="whitespace-nowrap"
                  variant="secondary"
                  size="sm"
                  isDisabled={evaluationEnvelopeOverview.nextStatus !== 'moderate'}
                  onClick={() =>
                    openModal(
                      <ConfirmModal
                        heading="Start moderation"
                        description="Are you sure you want to start the moderation phase of this envelope?"
                        onSubmit={async () => await handleProgressEnvelope()}
                      />
                    )
                  }
                >
                  Start moderation
                </Button>
              </Tooltip>
            )}
          </>
        )
      } else if (evaluationEnvelope.status === 'moderate') {
        return (
          <div className="flex items-center relative">
            {(isUserInSessionModerator || isUserInSessionOwner) && (
              <>
                {isDownloading && <Spinner colour={COLOUR.primary[500]} />}
                <Button onClick={() => setIsDropdownOpen(true)} state="ghost" size="sm" isDisabled={isLoading}>
                  <Icon icon="dots" variant="light" size={20} />
                </Button>
                <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                  {evaluationEnvelopeOverview.previousStatus === 'evaluate' && (
                    <DropdownContent
                      onClick={() =>
                        openModal(
                          <ConfirmModal
                            heading="Go back to evaluation"
                            description="Are you sure you want to move this envelope back to the evaluation phase?"
                            onSubmit={() => handleRegressEnvelope()}
                          />
                        )
                      }
                    >
                      Go back to evaluation
                    </DropdownContent>
                  )}
                  <Tooltip
                    tooltip="All moderation scores must be marked as reviewed before you can submit"
                    isEnabled={!evaluationEnvelopeOverview?.nextStatus}
                  >
                    <DropdownContent
                      onClick={() =>
                        openModal(
                          <ConfirmModal
                            heading="Submit reviewed scores"
                            description="Are you sure you want to submit the reviewed scores of this envelope? This action cannot be undone."
                            onSubmit={async () => await handleProgressEnvelope()}
                          />
                        )
                      }
                      isDisabled={!evaluationEnvelopeOverview?.nextStatus}
                    >
                      Submit reviewed scores
                    </DropdownContent>
                  </Tooltip>
                  <DropdownContent onClick={handleDownloadCsv} isDisabled={isDownloading}>
                    Export evaluation results
                  </DropdownContent>
                </Dropdown>
              </>
            )}
            {isUserInSessionModerator && (
              <Button
                className="whitespace-nowrap ml-2"
                variant="secondary-dark"
                state="translucent"
                size="sm"
                href={routerService.getHref('/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId', {
                  evaluationEventId: evaluationEvent.id,
                  evaluationEnvelopeId: evaluationEnvelope.id,
                })}
                isLink
              >
                Review scores <Icon className="ml-1" icon="arrow-right" />
              </Button>
            )}
          </div>
        )
      }
    } else {
      return (
        <>
          {isUserInSessionEvaluator && (
            <Button
              variant="secondary-dark"
              state="translucent"
              size="sm"
              href={routerService.getHref(
                '/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?',
                {
                  evaluationEventId: evaluationEvent.id,
                  evaluationEnvelopeId: evaluationEnvelope.id,
                  evaluationUserId: evaluationUserInSession.id,
                }
              )}
              isLink
            >
              View evaluation <Icon className="ml-1" icon="arrow-right" />
            </Button>
          )}
          {isUserInSessionOwner && (
            <Tooltip tooltip="Moderation cannot begin while evaluations in progress" isEnabled={evaluationEnvelopeOverview.nextStatus !== 'complete'}>
              <Button
                className="ml-2"
                variant="primary"
                size="sm"
                isDisabled={evaluationEnvelopeOverview.nextStatus !== 'complete'}
                onClick={() =>
                  openModal(
                    <ConfirmModal
                      heading="Submit scores"
                      description="Are you sure you want to submit the scores of this envelope? This action cannot be undone."
                      onSubmit={async () => await handleProgressEnvelope()}
                    />
                  )
                }
              >
                Submit scores
              </Button>
            </Tooltip>
          )}
        </>
      )
    }
  }

  return (
    <div className={className}>
      <TableHeader className="relative flex items-center justify-between" variant="white" hasTable={hasTable}>
        <div className="mr-2">
          <Text size="sm" variant="light">
            Envelope {evaluationEnvelope.order}
          </Text>
          <div className="flex items-center">
            <Text className="font-medium" size="lg">
              {evaluationEnvelope.name}
            </Text>
            <EvaluationEventEnvelopeStatusBadge className="ml-2" evaluationEnvelope={evaluationEnvelope} />
          </div>
        </div>
        <div>{renderEnvelopeCta()}</div>
      </TableHeader>
      {hasTable && <ScrollableTable fixedColumns={fixedColumns} columns={columns} isLoading={isLoading} />}
    </div>
  )
})
