import { memo, useState } from 'react'
import folderDocumentsPng from '@assets/images/folder-documents.png'
import { every, filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ApprovalApprover } from '@cotiss/approval/components/approval-approver.component'
import { useListApproval } from '@cotiss/approval/resources/use-list-approval.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'

type Props = {
  tenderId: string
}

export const TenderFlowSelectionApprovalGate = memo(({ tenderId }: Props) => {
  const { openToast } = useToast()
  const { progressTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { approvals } = useListApproval({ tenderId })
  const filteredApprovals = filter(approvals, { approvalType: 'recommendation' })
  const { tender } = useGetTender(tenderId)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  const handleMoveToSelection = async () => {
    try {
      setIsSaving(true)
      await progressTender(tenderId)
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (!isOldEvaluationsEnabled) {
    return <FourOhThreePage />
  }

  return (
    <div className="flex items-start justify-center max-w-[600px] mx-auto mt-36">
      <img className="mr-8" src={folderDocumentsPng} alt="Folder documents" />
      <div>
        <Text className="font-semibold" size="h5">
          Great! Your{' '}
          <Text className="font-semibold" variant="link" size="h5" isInline>
            Recommendation Report
          </Text>{' '}
          has been sent for approval
        </Text>
        <Text className="mt-4" variant="light">
          Report stage will be unlocked when your live tender is approved
        </Text>
        {map(filteredApprovals, (approval) => (
          <ApprovalApprover key={approval._id} className="mt-6" approval={approval} />
        ))}

        <Button className="mt-8" onClick={handleMoveToSelection} isDisabled={!every(filteredApprovals, { status: 'approved' })} isLoading={isSaving}>
          Go to report
        </Button>
      </div>
    </div>
  )
})
