import { useEffect, useState, ReactNode } from 'react'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useDeepMemo } from '@cotiss/common/hooks/use-deep-memo.hook'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { AdvancedFiltersProvider, useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { sentryService } from '@cotiss/common/services/sentry.service'
import {
  PreferredSupplierSearchFiltersContextProvider,
  PreferredSupplierSearchFiltersContextState,
  usePreferredSupplierSearchFiltersContext,
} from '@cotiss/preferred-supplier/components/preferred-supplier-search-filters-context.component'
import { PREFERRED_SUPPLIER_FILTER_FIELDS_WHITELIST } from '@cotiss/preferred-supplier/preferred-supplier.constants'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'
import {
  FilterPreferredSupplierSortKey,
  useMutatePreferredSupplier,
} from '@cotiss/preferred-supplier/resources/use-mutate-preferred-supplier.resource'
import { pickBy, map } from 'lodash'
import { PaginationModel } from '@cotiss/common/components/pagination.component'
import { Filter } from '@cotiss/common/models/filter.model'

export const usePreferredSupplierSearchAndFilters = () => {
  const { openToast } = useToast()

  const { queryState, processedFilters, queryStateDispatch, isQuerying } = usePreferredSupplierSearchFiltersContext()
  const { searchQuery, currentPage } = queryState

  const { advancedFiltersDispatch, validAdvancedFilters } = useAdvancedFiltersContext()
  const { filterPreferredSupplier } = useMutatePreferredSupplier()
  const [isLoadingPreferredSuppliers, setIsLoadingPreferredSuppliers] = useState(false)
  const [preferredSuppliers, setPreferredSuppliers] = useState<PreferredSupplierPopulatedModel[]>([])
  const [pagination, setPagination] = useState<PaginationModel>()
  const { sortKey, sortDirection, onSort } = useSortTable<FilterPreferredSupplierSortKey>({ initialKey: 'organisationName' })

  const handlePageChange = (page: number) => {
    queryStateDispatch({ type: 'UPDATE_CURRENT_PAGE', payload: page })
  }

  useEffect(() => {
    queryStateDispatch({
      type: 'UPDATE_ADVANCED_FILTERS',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      payload: map(validAdvancedFilters, ({ id, ...rest }) => ({ ...rest } as Filter)),
    })
  }, [validAdvancedFilters])

  const refreshPreferredSuppliers = async () => {
    try {
      setIsLoadingPreferredSuppliers(true)

      const { preferredSuppliers, meta, pagination } = await filterPreferredSupplier({
        filters: processedFilters,
        searchQuery,
        currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setPreferredSuppliers(preferredSuppliers)
      setPagination(pagination)

      // Whitelist only filter fields that appear in the whitelist OR custom metafields
      const filterFields = pickBy(meta, (_, key) => PREFERRED_SUPPLIER_FILTER_FIELDS_WHITELIST.includes(key) || key.startsWith('metafield'))
      advancedFiltersDispatch({ type: 'SET_FILTER_FIELDS', payload: filterFields })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoadingPreferredSuppliers(false)
  }

  const preferredSupplierQuery = useDeepMemo(
    () => ({
      queryState,
      sortKey,
      sortDirection,
    }),
    [queryState, sortKey, sortDirection]
  )

  useEffect(() => {
    refreshPreferredSuppliers()
  }, [preferredSupplierQuery])

  return {
    isQuerying,
    queryState,
    queryStateDispatch,
    preferredSuppliers,
    isLoadingPreferredSuppliers,
    pagination,
    handlePageChange,
    onSort,
    processedFilters,
    refreshPreferredSuppliers,
  }
}

export const PreferredSupplierSearchAndFiltersProvider = ({
  children,
  initialState,
}: {
  children: ReactNode
  initialState: Partial<PreferredSupplierSearchFiltersContextState>
}) => {
  const initialAdvancedFilters = initialState?.advancedFilters

  return (
    <PreferredSupplierSearchFiltersContextProvider initialState={initialState}>
      <AdvancedFiltersProvider initialFilters={initialAdvancedFilters}>{children}</AdvancedFiltersProvider>
    </PreferredSupplierSearchFiltersContextProvider>
  )
}
