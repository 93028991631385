import { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useHistory, useParams } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { RemainingTasksButton } from '@cotiss/common/components/remaining-tasks-button.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { utilService } from '@cotiss/common/services/util.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'
import { TenderResponseReviewList } from '@cotiss/tender-response/components/tender-response-review-list.component'
import { TenderResponseReview } from '@cotiss/tender-response/components/tender-response-review.component'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { TenderResponsePopulatedModel } from '@cotiss/tender-response/tender-response.models'

export const TenderFlowReviewStep = memo(() => {
  const { push } = useHistory()
  const { openModal } = useCallout()
  const { progressTender } = useMutateTender()
  const { tenderId } = useParams<{ tenderId: string; step: TenderFlowStep }>()
  const { tender } = useGetTender(tenderId)
  const { tenderResponses: excludedTenderResponses } = useListTenderResponse({ tenderId, reviewStatus: 'excluded' })
  const isEditable = tender?.status === 'review'
  const [transition, setTransition] = useState<TransitionType>('right')
  const [activeTenderResponseId, setActiveTenderResponseId] = useState('')
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)

  const handleProgressProcurement = async () => {
    await progressTender(tenderId)
    let nextStep = 'shortlist'

    if (isOldEvaluationsEnabled) {
      nextStep = tender?.evaluationPanelType === 'none' ? 'selection' : 'evaluate'
    }

    push(
      routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', {
        tenderId,
        step: nextStep,
      })
    )
  }

  const handleSetActiveTenderResponse = (tenderResponse: TenderResponsePopulatedModel | null) => {
    setTransition(tenderResponse ? 'right' : 'left')
    setTimeout(() => setActiveTenderResponseId(tenderResponse?._id || ''), 0)
  }

  const handleBannerCtaClick = () => {
    const heading = isOldEvaluationsEnabled ? 'Start evaluation' : 'Submit shortlist'
    const description = isOldEvaluationsEnabled ? (
      'Are you sure you want to start the evaluation process?'
    ) : (
      <>
        <Text size="sm" variant="light">
          Confirming will package the included submissions in preparation for evaluation
        </Text>
        {Boolean(excludedTenderResponses.length) && (
          <div className="flex items-baseline mt-4">
            <Badge variant="danger" className="mr-2" state="translucent">
              1
            </Badge>
            <Text size="sm" variant="light">
              <Text size="sm" className="font-medium mr-1" isInline>
                Excluded
              </Text>
              {utilService.pluralize(excludedTenderResponses.length, 'Submission')} will not progress to evaluation
            </Text>
          </div>
        )}
      </>
    )

    openModal(<ConfirmModal heading={heading} description={description} onSubmit={handleProgressProcurement} />)
  }

  const getCtaBannerText = () => {
    if (!isEditable) {
      return null
    }

    if (isOldEvaluationsEnabled) {
      return 'You will be able to begin the evaluation process once the review is complete'
    }

    return 'You will be able to proceed to shortlist once the review is complete'
  }

  const getCtaBannerButtonText = () => {
    if (!isEditable) {
      return null
    }

    if (isOldEvaluationsEnabled) {
      return tender.evaluationPanelType === 'none' ? 'Proceed to selection' : 'Proceed to evaluation'
    }

    return 'Submit'
  }

  return (
    <AnimatePresence initial={false} mode="wait">
      {!activeTenderResponseId && (
        <TransitionContainer key={1} transition={transition}>
          {isEditable && (
            <Banner className="mb-6" icon="clipboard-check" variant="secondary">
              <div className="mr-6">
                <Text className="font-semibold" variant="heading">
                  Complete review of submissions
                </Text>
                <Text size="sm">{getCtaBannerText()}</Text>
              </div>
              <div className="flex items-center justify-center shrink-0">
                <RemainingTasksButton errors={tender.validation.validationErrors} />
                <Button className="ml-2" onClick={handleBannerCtaClick} size="sm" isDisabled={Boolean(tender.validation.validationErrors.length)}>
                  {getCtaBannerButtonText()}
                </Button>
              </div>
            </Banner>
          )}
          <TenderResponseReviewList tenderId={tenderId} onView={handleSetActiveTenderResponse} />
        </TransitionContainer>
      )}
      {activeTenderResponseId && (
        <TransitionContainer key={2} transition={transition}>
          <TenderResponseReview
            tenderResponseId={activeTenderResponseId}
            onBack={() => handleSetActiveTenderResponse(null)}
            isEditable={isEditable}
          />
        </TransitionContainer>
      )}
    </AnimatePresence>
  )
})
