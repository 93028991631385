import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { every, filter, map, sortBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { EvaluationEventSubCriteriaWeightList } from '@cotiss/evaluation-event/components/evaluation-event-sub-criteria-weight-list.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardSubWeightingStep = memo(({ onNext, onBack }: Props) => {
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const [criteriaWeightById, setCriteriaWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: evaluationCriteria })
  )

  const { weightedPercentageById, overallWeightedPercentageById } = useMemo(() => {
    return evaluationEventService.getWeightedPercentagesById({ evaluationEnvelopes, evaluationCriteria })
  }, [evaluationEnvelopes, evaluationCriteria])

  useEffect(() => {
    track('evaluation_event_wizard_sub_weight_view')
  }, [])

  useEffect(() => {
    setErrorText('')
  }, [evaluationCriteria])

  const handleClickContinue = () => {
    const parentCriteria = sortBy(filter(evaluationCriteria, { parentEvaluationCriteriaId: null, isScored: false }), 'index')

    if (!every(parentCriteria, ({ id }) => every(filter(evaluationCriteria, { parentEvaluationCriteriaId: id }), ({ weight }) => weight))) {
      setErrorText('Every sub-criteria must have a weight assigned.')
      return
    }

    onNext()
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Sub-weight
        </Text>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
              Back
            </Button>
            <Button onClick={handleClickContinue} variant="secondary" size="sm">
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>

      {map(evaluationEnvelopes, (envelope) => {
        const parentCriteria = sortBy(filter(evaluationCriteria, { evaluationEnvelopeId: envelope.id, parentEvaluationCriteriaId: null }), 'index')

        return (
          <div className="mt-8 first:mt-0" key={envelope.id}>
            <div className="flex items-center justify-between">
              <div>
                <Text size="sm" variant="light">
                  Envelope {envelope.order}.
                </Text>
                <Text className="font-medium" size="lg">
                  {envelope.name}
                </Text>
              </div>
              <div className="text-right whitespace-nowrap ml-4">
                <Text size="sm" variant="light">
                  Envelope weight
                </Text>
                <Text className="ml-1" variant="secondary">
                  {utilService.formatAsPercentage(Number(weightedPercentageById[envelope.id]) * 100)}
                </Text>
              </div>
            </div>
            <Hr className="my-4" />
            {map(parentCriteria, (parentCriteriaItem) => {
              const evaluationSubCriteria = filter(evaluationCriteria, { parentEvaluationCriteriaId: parentCriteriaItem.id })

              return (
                <Fragment key={parentCriteriaItem.id}>
                  <TableHeader className="mt-8" hasTable={Boolean(evaluationSubCriteria.length)}>
                    <Text size="sm" variant="light">
                      Criteria {parentCriteriaItem.index}
                    </Text>
                    <Text className="font-semibold">{parentCriteriaItem.content}</Text>
                  </TableHeader>
                  {Boolean(evaluationSubCriteria.length) && (
                    <EvaluationEventSubCriteriaWeightList
                      evaluationEnvelope={envelope}
                      parentEvaluationCriteriaItem={parentCriteriaItem}
                      evaluationSubCriteria={evaluationSubCriteria}
                      criteriaWeightById={criteriaWeightById}
                      setCriteriaWeightById={setCriteriaWeightById}
                      weightedPercentageById={weightedPercentageById}
                      overallWeightedPercentageById={overallWeightedPercentageById}
                      isEditable
                    />
                  )}
                </Fragment>
              )
            })}
          </div>
        )
      })}
    </Card>
  )
})
