import { memo } from 'react'
import { map } from 'lodash'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Text } from '@cotiss/common/components/text.component'
import { TenderCriteriaReportAccordion } from '@cotiss/tender-criteria/components/tender-criteria-report-accordion.component'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria/tender-criteria.models'
import { TenderQuestionReportModel } from '@cotiss/tender-question/tender-question.models'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type Props = {
  tenderResponseReport: TenderResponseReportModel
  onView?: (tenderCriterionReport: TenderCriterionReportModel, tenderQuestionReport: TenderQuestionReportModel) => void
}

export const TenderResponseReportView = memo(({ tenderResponseReport, onView }: Props) => (
  <Card>
    <CardHeader>
      <Text className="font-semibold" size="h7">
        {tenderResponseReport.procurementResponse.supplier.name}
      </Text>
    </CardHeader>
    <Text className="font-semibold" size="h7">
      Consensus score the criteria below
    </Text>
    <Text className="mt-1" variant="light" size="sm">
      Progress through each of the non-price criteria for this supplier
    </Text>
    {map(tenderResponseReport.tenderCriteria, (tenderCriterionReport) => (
      <TenderCriteriaReportAccordion key={tenderCriterionReport._id} className="mt-4" tenderCriterionReport={tenderCriterionReport} onView={onView} />
    ))}
  </Card>
))
