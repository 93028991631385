import { memo } from 'react'
import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { TenderSummaryCardSkeleton } from '@cotiss/tender/components/tender-summary-card-skeleton.component'
import { TenderSummaryCard } from '@cotiss/tender/components/tender-summary-card.component'
import { TenderCreateModal } from '@cotiss/tender/modals/tender-create.modal'
import { TenderFlowRequestDraftCard } from '@cotiss/tender-flow/components/tender-flow-request-draft-card.component'

export const ProcurementOverviewAllRequestsTab = memo(() => {
  const { openModal } = useCallout()
  const { procurementId } = useParams<{ procurementId: string }>()
  const { procurement, isLoading } = useGetProcurement(procurementId)

  return (
    <div className="flex flex-wrap">
      {isLoading && <TenderSummaryCardSkeleton />}
      {map(procurement?.tenders, (tender) => tender.tenderType && <TenderSummaryCard key={tender._id} className="mr-4 mb-4" tender={tender} />)}
      {procurement && (
        <TenderFlowRequestDraftCard className="mr-4 mb-4" onCreateRequest={() => openModal(<TenderCreateModal procurementId={procurementId} />)} />
      )}
    </div>
  )
})
