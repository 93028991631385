import { memo, useMemo } from 'react'
import { compact, filter, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { PerformanceScorecardCreateFormData } from '@cotiss/performance/drawers/performance-scorecard-create.drawer'
import { PreferredSupplierPopulatedModel } from '@cotiss/preferred-supplier/preferred-supplier.models'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { sortService } from '@cotiss/common/services/sort.service'
import { Banner } from '@cotiss/common/components/banner.component'
import { routerService } from '@cotiss/common/services/router.service'
import {
  PreferredSupplierSearchAndFiltersProvider,
  usePreferredSupplierSearchAndFilters,
} from '@cotiss/preferred-supplier/components/preferred-supplier-search-and-filters'
import { AdvancedFiltersPillList } from '@cotiss/common/modals/advanced-filters/advanced-filters-pill-list.component'
import { AdvancedFiltersDropdownButton } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-button.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { PreferredSupplierListSearchInput } from '@cotiss/preferred-supplier/components/preferred-supplier-list-search-input.component'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useHistory } from 'react-router-dom'
import HighlightedText from '@cotiss/common/components/highlighted-text.component'
import { Tooltip } from '@cotiss/common/components/tooltip.component'
import { PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS } from '@cotiss/preferred-supplier/preferred-supplier.constants'

type Props = {
  formData: PerformanceScorecardCreateFormData
  setFormData: (formData: PerformanceScorecardCreateFormData) => void
  onBack: () => void
  isDisabled?: boolean
}

const PerformanceScorecardCreateContactStepInternal = memo(({ formData, setFormData, onBack, isDisabled }: Props) => {
  const { track } = useAnalytics()
  const { push } = useHistory()
  const { isQuerying, queryState, preferredSuppliers, pagination, isLoadingPreferredSuppliers, handlePageChange, onSort } =
    usePreferredSupplierSearchAndFilters()

  const { closeDrawer } = useCallout()

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: formData.name,
      onClick: onBack,
    },
    {
      label: 'Select vendor',
    },
  ]

  const handleAddContact = () => {
    closeDrawer()
    push(routerService.getHref('/preferred-supplier/list/:tab?'))
  }

  const { fixedColumns, columns } = useMemo(() => {
    const renderTags = (preferredSupplier: PreferredSupplierPopulatedModel) => {
      if (!preferredSupplier.tags?.length) {
        return <Text size="sm">--</Text>
      }

      const [firstTag, ...rest] = preferredSupplier.tags.sort((a, b) => sortService.sortString(a, b))

      return (
        <>
          <Badge className="mr-1" state="outline" variant="secondary">
            {firstTag}
          </Badge>
          {Boolean(rest.length) && (
            <Tooltip tooltip={rest.join(', ')}>
              <Text className="font-medium" variant="link" size="sm">
                +{rest.length}
              </Text>
            </Tooltip>
          )}
        </>
      )
    }

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(preferredSuppliers, ({ _id, supplierOrganisation }) => ({
          content: () => (
            <Radio
              value={_id}
              name="performance-scorecard-contact"
              onChange={() => setFormData({ ...formData, contactId: _id, supplierId: supplierOrganisation?._id || '' })}
              isChecked={_id === formData.contactId}
              isDisabled={isDisabled}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Organisation name',
        onSort: () => onSort('organisationName'),
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => (
            <Text className="truncate" title={preferredSupplier.supplierOrganisation?.name || '--'}>
              <HighlightedText text={preferredSupplier.supplierOrganisation?.name || '--'} highlightText={queryState.searchQuery} />
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Organisation status',
        rows: map(preferredSuppliers, ({ supplierOrganisation }) => ({
          content: () =>
            supplierOrganisation?.claimedStatus ? (
              <Badge
                state="outline"
                variant={supplierOrganisation.claimedStatus === 'CLAIMED' ? 'secondary' : 'neutral'}
                title={
                  supplierOrganisation.claimedStatus === 'CLAIMED'
                    ? 'One of the vendors associated with this account has created a Cotiss account'
                    : 'None of the vendors associated with this account have created a Cotiss account'
                }
              >
                {supplierOrganisation.claimedStatus === 'CLAIMED' && <Icon className="mr-1" icon="check-verified-02" size={10} />}
                {organisationService.getClaimedStatusText(supplierOrganisation.claimedStatus)}
              </Badge>
            ) : (
              <Text size="sm" variant="light">
                --
              </Text>
            ),
        })),
      },
      {
        heading: 'Contacts',
        rows: map(preferredSuppliers, ({ supplierOrganisation, contacts }) => {
          const accountUserEmails = compact(supplierOrganisation?.account?.accountUser.map(({ email }) => email))
          const uniqueContacts = filter(contacts, (contact) => !accountUserEmails.includes(contact.email))

          return {
            content: () => (
              <UserAvatarGroup
                users={[
                  ...compact(supplierOrganisation?.account?.accountUser),
                  ...map(uniqueContacts, (contact) => ({ firstName: contact.email, lastName: '' })),
                ]}
              />
            ),
          }
        }),
      },
      {
        heading: 'Tags',
        rows: map(preferredSuppliers, (preferredSupplier) => ({
          content: () => renderTags(preferredSupplier),
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(preferredSuppliers, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [preferredSuppliers, formData, isDisabled])

  const emptyState = isQuerying ? (
    <Text size="md" variant="light">
      No results
    </Text>
  ) : (
    <NoDataPlaceholder
      illustration="dot-list"
      variant="transparent"
      label="You have not added any contacts yet."
      ctaLabel="+ Add contact"
      onCtaClick={handleAddContact}
    />
  )

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <Banner className="flex items-center justify-between" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Select a vendor
          </Text>
          <Text size="sm">Select an option below to continue</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 2 of {formData.withContract ? '3' : '2'}
        </Badge>
      </Banner>
      <TableHeader className="flex items-center justify-between mt-8">
        <Text className="font-medium" size="lg">
          Select vendor
        </Text>
      </TableHeader>
      <div className="w-full h-13 px-4 border-t flex items-center justify-between gap-4 border-l border-r">
        <PreferredSupplierListSearchInput className="flex-1" />
        <VerticalDivider className="h-7" />
        <AdvancedFiltersDropdownButton title="Filter contacts" onOpen={() => track('preferred_supplier_list_filters_dropdown_open')} />
      </div>
      {queryState.advancedFilters?.length ? (
        <div className="w-full px-4 py-3 border-x border-t">
          <AdvancedFiltersPillList />
        </div>
      ) : null}
      {!isLoadingPreferredSuppliers && !preferredSuppliers.length ? (
        <div className="flex items-center justify-center h-60 bg-white border">{emptyState}</div>
      ) : (
        <ScrollableTable
          fixedColumns={fixedColumns}
          columns={columns}
          pagination={pagination}
          onPageChange={handlePageChange}
          isLoading={isLoadingPreferredSuppliers}
        />
      )}
    </>
  )
})

export const PerformanceScorecardCreateContactStep = ({ formData, setFormData, onBack, isDisabled }: Props) => {
  const initialState = {
    primaryTabFilters: PREFERRED_SUPPLIER_PRIMARY_TAB_FILTERS,
  }

  return (
    <PreferredSupplierSearchAndFiltersProvider initialState={initialState}>
      <PerformanceScorecardCreateContactStepInternal formData={formData} setFormData={setFormData} onBack={onBack} isDisabled={isDisabled} />
    </PreferredSupplierSearchAndFiltersProvider>
  )
}
