import { memo, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { findIndex } from 'lodash'
import { useParams } from 'react-router-dom'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria/tender-criteria.models'
import { TenderQuestionReport } from '@cotiss/tender-question/components/tender-question-report.component'
import { TenderQuestionReportModel } from '@cotiss/tender-question/tender-question.models'
import { TenderResponseReportView } from '@cotiss/tender-response/components/tender-response-report-view.component'
import { useListTenderResponseReport } from '@cotiss/tender-response/resources/use-list-tender-response-report.resource'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type ActiveEntity =
  | {
      type: 'consensus'
      tenderResponseReport: TenderResponseReportModel
    }
  | {
      type: 'consensus-question'
      tenderResponseReport: TenderResponseReportModel
      tenderCriterionReport: TenderCriterionReportModel
      tenderQuestionReport: TenderQuestionReportModel
    }

type Props = {
  initialTenderResponseReport: TenderResponseReportModel
  breadcrumbs: BreadcrumbModel[]
}

// TODO: This probably belongs either in the evaluation domain, or the tender response domain.

export const TenderFlowEvaluateStepPanelConsensusScoring = memo(({ initialTenderResponseReport, breadcrumbs: rootBreadcrumbs }: Props) => {
  const { tenderId } = useParams<{ tenderId: string }>()
  const { tender } = useGetTender(tenderId)
  const [transition, setTransition] = useState<TransitionType>('right')
  const { tenderResponseReports } = useListTenderResponseReport(tenderId)
  const [activeEntity, setActiveEntity] = useState<ActiveEntity>({ type: 'consensus', tenderResponseReport: initialTenderResponseReport })
  const breadcrumbs = useMemo(() => {
    if (activeEntity.type === 'consensus') {
      return [...rootBreadcrumbs, { label: activeEntity.tenderResponseReport.procurementResponse.supplier.name }]
    }

    if (activeEntity.type === 'consensus-question') {
      return [
        ...rootBreadcrumbs,
        {
          label: activeEntity.tenderResponseReport.procurementResponse.supplier.name,
          onClick: () => handleSetEntity({ type: 'consensus', tenderResponseReport: activeEntity.tenderResponseReport }, 'left'),
        },
        { label: activeEntity.tenderQuestionReport.question },
      ]
    }

    return rootBreadcrumbs
  }, [activeEntity, rootBreadcrumbs])

  const handleSetEntity = (entity: ActiveEntity, transition: TransitionType = 'right') => {
    setTransition(transition)
    setTimeout(() => setActiveEntity(entity), 0)
  }

  const handlePreviousQuestion = () => {
    if (activeEntity?.type !== 'consensus-question') {
      return
    }

    const { tenderResponseReport, tenderCriterionReport, tenderQuestionReport } = activeEntity
    const tenderQuestionReportIndex = findIndex(tenderCriterionReport.tenderQuestions, { _id: tenderQuestionReport._id })

    if (tenderQuestionReportIndex) {
      handleSetEntity({
        type: 'consensus-question',
        tenderResponseReport,
        tenderCriterionReport,
        tenderQuestionReport: tenderCriterionReport.tenderQuestions[tenderQuestionReportIndex - 1],
      })
      return
    }

    const tenderCriterionReportIndex = findIndex(tenderResponseReport.tenderCriteria, { _id: tenderCriterionReport._id })

    if (tenderCriterionReportIndex) {
      const newTenderCriterionReport = tenderResponseReport.tenderCriteria[tenderCriterionReportIndex - 1]

      handleSetEntity({
        type: 'consensus-question',
        tenderResponseReport,
        tenderCriterionReport: newTenderCriterionReport,
        tenderQuestionReport: newTenderCriterionReport.tenderQuestions[newTenderCriterionReport.tenderQuestions.length - 1],
      })
      return
    }

    const newTenderResponseReport = tenderResponseReports[tenderResponseReports.length - 1]
    const newTenderCriterionReport = newTenderResponseReport.tenderCriteria[newTenderResponseReport.tenderCriteria.length - 1]

    handleSetEntity({
      type: 'consensus-question',
      tenderResponseReport: newTenderResponseReport,
      tenderCriterionReport: newTenderCriterionReport,
      tenderQuestionReport: newTenderCriterionReport.tenderQuestions[newTenderCriterionReport.tenderQuestions.length - 1],
    })
  }

  const handleNextQuestion = () => {
    if (activeEntity?.type !== 'consensus-question') {
      return
    }

    const { tenderResponseReport, tenderCriterionReport, tenderQuestionReport } = activeEntity
    const tenderQuestionReportIndex = findIndex(tenderCriterionReport.tenderQuestions, { _id: tenderQuestionReport._id })

    if (tenderQuestionReportIndex < tenderCriterionReport.tenderQuestions.length - 1) {
      handleSetEntity({
        type: 'consensus-question',
        tenderResponseReport,
        tenderCriterionReport,
        tenderQuestionReport: tenderCriterionReport.tenderQuestions[tenderQuestionReportIndex + 1],
      })
      return
    }

    const tenderCriterionReportIndex = findIndex(tenderResponseReport.tenderCriteria, { _id: tenderCriterionReport._id })

    if (tenderCriterionReportIndex < tenderResponseReport.tenderCriteria.length - 1) {
      const newTenderCriterionReport = tenderResponseReport.tenderCriteria[tenderCriterionReportIndex + 1]

      handleSetEntity({
        type: 'consensus-question',
        tenderResponseReport,
        tenderCriterionReport: newTenderCriterionReport,
        tenderQuestionReport: newTenderCriterionReport.tenderQuestions[0],
      })
      return
    }

    const newTenderResponseReport = tenderResponseReports[0]
    const newTenderCriterionReport = newTenderResponseReport.tenderCriteria[0]

    handleSetEntity({
      type: 'consensus-question',
      tenderResponseReport,
      tenderCriterionReport: newTenderCriterionReport,
      tenderQuestionReport: newTenderCriterionReport.tenderQuestions[0],
    })
  }

  return (
    <AnimatePresence mode="wait" initial={false}>
      {activeEntity?.type === 'consensus' && (
        <TransitionContainer key={1} transition={transition}>
          <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
          <TenderResponseReportView
            tenderResponseReport={activeEntity.tenderResponseReport}
            onView={(tenderCriterionReport, tenderQuestionReport) => {
              handleSetEntity({
                type: 'consensus-question',
                tenderResponseReport: activeEntity.tenderResponseReport,
                tenderCriterionReport,
                tenderQuestionReport,
              })
            }}
          />
        </TransitionContainer>
      )}
      {activeEntity?.type === 'consensus-question' && tender && (
        <TransitionContainer key={2} transition={transition}>
          <Breadcrumb className="mb-2" breadcrumbs={breadcrumbs} />
          <TenderQuestionReport
            tender={tender}
            tenderResponseReport={activeEntity.tenderResponseReport}
            tenderCriterionReport={activeEntity.tenderCriterionReport}
            tenderQuestionReport={activeEntity.tenderQuestionReport}
            onPreviousQuestion={handlePreviousQuestion}
            onNextQuestion={handleNextQuestion}
          />
        </TransitionContainer>
      )}
    </AnimatePresence>
  )
})
