import { useEffect, useRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { UnregisterCallback } from 'history'
import { useHistory } from 'react-router-dom'
import { TRANSITION_DURATION } from '@cotiss/common/constants/transition.constants'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'

export const DrawerContainer = () => {
  const history = useHistory()
  let unregisterListener: UnregisterCallback | undefined
  const drawerContainerRef = useRef<HTMLDivElement>(null)
  const { modal, drawer, drawerConfig, closeDrawer } = useCallout()
  const { willCloseOnClickOutside = false } = drawerConfig || {}
  const modalRef = useRef(modal)
  const willCloseOnClickOutsideRef = useRef(willCloseOnClickOutside)

  const handleClickOutside = (event: MouseEvent | TouchEvent) => {
    if (
      !willCloseOnClickOutsideRef.current ||
      modalRef.current ||
      !drawerContainerRef.current ||
      drawerContainerRef.current.contains(event.target as any)
    ) {
      return
    }

    closeDrawer()
  }

  const handleKeydown = ({ code }: KeyboardEvent) => {
    // If a modal is open, we don't want to also close the drawer.
    if (code !== 'Escape' || modalRef.current) {
      return
    }

    closeDrawer()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)
    window.addEventListener('keydown', handleKeydown)
  }, [])

  useEffect(() => {
    if (drawer) {
      document.body.style.overflow = 'hidden'
      window.scroll(0, 0)
      unregisterListener = history.listen(() => closeDrawer())
    } else {
      document.body.style.overflow = 'auto'
      unregisterListener && unregisterListener()
    }
  }, [drawer])

  useEffect(() => {
    modalRef.current = modal
    willCloseOnClickOutsideRef.current = willCloseOnClickOutside
  }, [modal, willCloseOnClickOutside])

  return (
    <AnimatePresence>
      {Boolean(drawer) && (
        <motion.div
          className="fixed top-0 right-0 bg-white shadow-xl h-full w-full overflow-y-auto z-drawer"
          initial={{ x: 900, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: TRANSITION_DURATION.lg }}
          exit={{ x: 900, opacity: 0 }}
          style={{ maxWidth: 900 }}
          ref={drawerContainerRef}
        >
          {drawer}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
