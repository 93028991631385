import { Fragment, memo, useEffect, useState } from 'react'
import { GqlEvaluationCriteriaFieldsFragment } from '@gql'
import { every, filter, map, some, sortBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Label } from '@cotiss/common/components/label.component'
import { Switch } from '@cotiss/common/components/switch.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventParentTerminalCriteria } from '@cotiss/evaluation-event/components/evaluation-event-parent-terminal-criteria.component'
import { EvaluationEventSubCriteriaList } from '@cotiss/evaluation-event/components/evaluation-event-sub-criteria-list.component'
import { EvaluationEventEnvelopeRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-envelope-rating-scale.drawer'
import { EvaluationEventSubCriteriaRatingScaleDrawer } from '@cotiss/evaluation-event/drawers/evaluation-event-sub-criteria-create-update.drawer'
import { EVALUATION_EVENT_RATING_SCALE_NAME_MAP } from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardRatingScaleStep = memo(({ onNext, onBack }: Props) => {
  const { openToast } = useToast()
  const { openModal, openDrawer } = useCallout()
  const [errorText, setErrorText] = useState('')
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { evaluationEvent, queryEvaluationEventView } = useEvaluationEvent()
  const [isSavingMap, setIsSavingMap] = useState<Record<string, boolean | undefined>>({})
  const { evaluationCriteria, mutateUpdateEvaluationCriteria, queryEvaluationCriteriaList } = useEvaluationCriteria()

  useEffect(() => {
    track('evaluation_event_wizard_sub_criteria_view')
  }, [])

  useEffect(() => {
    setErrorText('')
  }, [evaluationCriteria])

  const handleClickContinue = () => {
    if (
      !every(evaluationEnvelopes, ({ id: evaluationEnvelopeId }) => {
        const parentCriteriaWithSubCriteria = filter(evaluationCriteria, {
          evaluationEnvelopeId,
          parentEvaluationCriteriaId: null,
          isScored: false,
        })

        return every(parentCriteriaWithSubCriteria, ({ id }) => some(evaluationCriteria, { parentEvaluationCriteriaId: id }))
      })
    ) {
      setErrorText('Every criteria that has sub-criteria enabled must have at least one sub-criteria.')
      return
    }

    onNext()
  }

  const handleMutateCriteria = async (evaluationCriteriaId: string, isScored: boolean) => {
    if (!evaluationEvent) {
      return
    }

    try {
      setIsSavingMap({ ...isSavingMap, [evaluationCriteriaId]: true })

      track('evaluation_event_wizard_criteria_update_submit')
      await mutateUpdateEvaluationCriteria({
        evaluationCriteriaId,
        isScored,
        // Unset supplementary if it is not a leaf node
        supplementary: !isScored ? '' : undefined,
      })

      // We need to refresh the evaluation event as updating the envelopes may update the steps of the wizard (E.g. if there is sub criteria.)
      // If the parent criteria has been updated from having sub-criteria (not isScored) to not having sub-criteria (isScored), any sub-criteria that
      // have been created for this criteria will be deleted. Then we need to refresh the evaluation criteria to remove any sub-criteria that have
      // been created against this criteria.
      await Promise.all([
        queryEvaluationEventView({ evaluationEventId: evaluationEvent.id }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id } }),
      ])
      setIsSavingMap({ ...isSavingMap, [evaluationCriteriaId]: false })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSavingMap({ ...isSavingMap, [evaluationCriteriaId]: false })
    }
  }

  const handleParentCriteriaToggle = (parentEvaluationCriteriaItem: GqlEvaluationCriteriaFieldsFragment) => {
    if (!parentEvaluationCriteriaItem.isScored && some(evaluationCriteria, { parentEvaluationCriteriaId: parentEvaluationCriteriaItem.id })) {
      openModal(
        <ConfirmModal
          heading="Are you sure you want to switch off sub-criteria?"
          description="Confirming this will delete any existing sub-criteria that have already been created under this criteria."
          onSubmit={() => handleMutateCriteria(parentEvaluationCriteriaItem.id, !parentEvaluationCriteriaItem.isScored)}
        />
      )
    } else if (parentEvaluationCriteriaItem.isScored && parentEvaluationCriteriaItem.supplementary) {
      openModal(
        <ConfirmModal
          heading="Are you sure you want to switch on sub-criteria?"
          description="Confirming this will delete any scoring requirements that have already been created for this criteria."
          onSubmit={() => handleMutateCriteria(parentEvaluationCriteriaItem.id, !parentEvaluationCriteriaItem.isScored)}
        />
      )
    } else {
      handleMutateCriteria(parentEvaluationCriteriaItem.id, !parentEvaluationCriteriaItem.isScored)
    }
  }

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Rating scale
        </Text>
        <div className="flex flex-col items-end justify-center ml-4">
          <div>
            <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
              Back
            </Button>

            <Button onClick={handleClickContinue} variant="secondary" size="sm">
              Continue
            </Button>
          </div>
          {errorText && (
            <Text className="mt-1" size="sm" variant="danger">
              {errorText}
            </Text>
          )}
        </div>
      </CardHeader>
      {map(evaluationEnvelopes, (evaluationEnvelope) => {
        const parentCriteria = sortBy(
          filter(evaluationCriteria, { evaluationEnvelopeId: evaluationEnvelope.id, parentEvaluationCriteriaId: null }),
          'index'
        )

        return (
          <div className="mt-12 first:mt-0" key={evaluationEnvelope.id}>
            <div className="grid grid-cols-12 gap-x-6 justify-between items-center">
              <div className="col-span-6 xl:col-span-7">
                <Text size="sm" variant="light">
                  Envelope {evaluationEnvelope.order}.
                </Text>
                <Text className="font-medium" size="lg">
                  {evaluationEnvelope.name}
                </Text>
              </div>
              <div className="col-span-6 xl:col-span-5 flex items-center space-x-6">
                <div>
                  <Label>Rating scale template</Label>
                  <Text className="mt-1" variant="light" size="sm">
                    This will not apply to updated scales
                  </Text>
                </div>
                <Button
                  className="flex-1 rounded-md group hover:bg-primary-50 p-3"
                  state="text"
                  onClick={() => openDrawer(<EvaluationEventEnvelopeRatingScaleDrawer evaluationEnvelope={evaluationEnvelope} />)}
                >
                  <div className="flex items-center justify-between group-hover:text-secondary-500">
                    <Text className="max-w-md flex group-hover:text-secondary-500 whitespace-pre-wrap">
                      {EVALUATION_EVENT_RATING_SCALE_NAME_MAP[evaluationEnvelope.defaultRatingScale]}
                    </Text>
                    <Icon icon="edit" />
                  </div>
                </Button>
              </div>
            </div>
            <Hr className="my-4" />
            {map(parentCriteria, (parentEvaluationCriteriaItem) => {
              return (
                <Fragment key={parentEvaluationCriteriaItem.id}>
                  <TableHeader className="flex items-center justify-between w-full mt-8">
                    <div>
                      <Text size="sm" variant="light">
                        Criteria {parentEvaluationCriteriaItem.index}
                      </Text>

                      <Text className="font-semibold">{parentEvaluationCriteriaItem.content}</Text>
                    </div>
                    <div className="flex items-center">
                      <Switch
                        className="mr-2"
                        variant="secondary"
                        size="md"
                        isOn={!parentEvaluationCriteriaItem.isScored}
                        onClick={() => handleParentCriteriaToggle(parentEvaluationCriteriaItem)}
                        isDisabled={isSavingMap[parentEvaluationCriteriaItem.id]}
                      />
                      <Text className="mr-6">This criteria has sub-criteria</Text>
                      <Button
                        size="xs"
                        state="translucent"
                        variant="secondary-dark"
                        onClick={() =>
                          openDrawer(
                            <EvaluationEventSubCriteriaRatingScaleDrawer
                              evaluationEnvelope={evaluationEnvelope}
                              parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                              isEditable
                            />
                          )
                        }
                        isDisabled={isSavingMap[parentEvaluationCriteriaItem.id] || parentEvaluationCriteriaItem.isScored}
                      >
                        + Add sub-criteria
                      </Button>
                    </div>
                  </TableHeader>
                  {parentEvaluationCriteriaItem.isScored ? (
                    <EvaluationEventParentTerminalCriteria
                      evaluationEnvelope={evaluationEnvelope}
                      evaluationCriteriaItem={parentEvaluationCriteriaItem}
                      isEditable
                    />
                  ) : (
                    <EvaluationEventSubCriteriaList
                      evaluationEnvelope={evaluationEnvelope}
                      parentEvaluationCriteriaItem={parentEvaluationCriteriaItem}
                      isEditable
                    />
                  )}
                </Fragment>
              )
            })}
          </div>
        )
      })}
    </Card>
  )
})
