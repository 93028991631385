import { memo } from 'react'
import { GqlPerformanceScorecardStatus } from '@gql'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize, BadgeState } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

const getVariant = (status: GqlPerformanceScorecardStatus): BaseVariant => {
  switch (status) {
    case 'published':
      return 'success'
    case 'draft':
      return 'warning'
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status: GqlPerformanceScorecardStatus
  size?: BadgeSize
  state?: BadgeState
}

export const PerformanceScorecardStatusBadge = memo(({ className, status, size = 'md', state = 'outline' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state={state}>
    {upperFirst(lowerCase(status))}
  </Badge>
))
