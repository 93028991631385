import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { TenderResponseReviewForms } from '@cotiss/tender-response/components/tender-response-review-forms.component'
import { TenderResponseReviewStatusBadge } from '@cotiss/tender-response/components/tender-response-review-status-badge.component'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'

type Props = {
  tenderResponseId: string
  onBack: () => void
  isEditable?: boolean
}

export const TenderResponsePreview = memo(({ tenderResponseId, onBack, isEditable }: Props) => {
  const { tenderResponse, isLoading } = useGetTenderResponse(tenderResponseId)

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center mr-4">
          <Button className="mr-2" onClick={onBack} state="translucent" variant="secondary" size="sm">
            <Icon icon="arrow-left" size={20} />
            Back
          </Button>
          {isLoading && <Skeleton className="h-4 w-1/3" />}
          {!isLoading && tenderResponse && (
            <Text className="font-semibold" size="h4">
              {tenderResponse.procurementResponse.supplier.name}
            </Text>
          )}
        </div>
        {!isLoading && tenderResponse && <TenderResponseReviewStatusBadge className="ml-3" status={tenderResponse.reviewStatus} size="md" />}
      </CardHeader>
      <TenderResponseReviewForms tenderResponseId={tenderResponseId} isEditable={isEditable} />
    </Card>
  )
})
