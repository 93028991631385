import { memo } from 'react'
import { upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { TenderStatus } from '@cotiss/tender/tender.models'

const getVariant = (status: TenderStatus | 'shortlist'): BaseVariant => {
  switch (status) {
    case 'planning':
    case 'planned-approval':
      return 'warning'
    case 'active':
      return 'success'
    case 'review':
    case 'evaluate':
    case 'shortlist':
    case 'consensus':
    case 'price':
    case 'selection':
    case 'selection-approval':
    case 'report':
      return 'info'
    case 'cancelled':
    case 'unsuccessful':
    case 'withdrawn':
      return 'warning'
    case 'complete':
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status: TenderStatus | 'shortlist'
  size?: BadgeSize
}

export const TenderStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(status)}
  </Badge>
))
