import { useReducer, createContext, useContext, useMemo, Dispatch, ReactNode, FC } from 'react'
import { Filter } from '@cotiss/common/models/filter.model'
import { PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB, PreferredSupplierPrimaryTabKeys } from '@cotiss/preferred-supplier/preferred-supplier.constants'

export type PreferredSupplierSearchFiltersContextState = {
  searchQuery: string
  currentPage: number
  advancedFilters: Filter[]
  primaryTab: PreferredSupplierPrimaryTabKeys
  primaryTabFilters: Partial<Record<PreferredSupplierPrimaryTabKeys, { filters: Filter[] }>>
}

type UpdateSearchQueryAction = {
  type: 'UPDATE_SEARCH_QUERY'
  payload: string
}

type UpdateCurrentPageAction = {
  type: 'UPDATE_CURRENT_PAGE'
  payload: number
}

type UpdateAdvancedFiltersAction = {
  type: 'UPDATE_ADVANCED_FILTERS'
  payload: Filter[]
}

type UpdatePrimaryTabAction = {
  type: 'UPDATE_PRIMARY_TAB'
  payload: PreferredSupplierPrimaryTabKeys
}

type PreferredSupplierSearchFiltersContextAction =
  | UpdateSearchQueryAction
  | UpdateCurrentPageAction
  | UpdateAdvancedFiltersAction
  | UpdatePrimaryTabAction

const defaultInitialState: PreferredSupplierSearchFiltersContextState = {
  searchQuery: '',
  currentPage: 1,
  advancedFilters: [],
  primaryTab: PREFERRED_SUPPLIER_DEFAULT_PRIMARY_TAB,
  primaryTabFilters: {},
}

type PreferredSupplierSearchFiltersContextValue = {
  processedFilters: Filter[]
  isQuerying: boolean
  queryState: PreferredSupplierSearchFiltersContextState
  queryStateDispatch: Dispatch<PreferredSupplierSearchFiltersContextAction>
}

const PreferredSupplierSearchFiltersContext = createContext<PreferredSupplierSearchFiltersContextValue | null>(null)

function processFilters({ advancedFilters, primaryTab, primaryTabFilters }: PreferredSupplierSearchFiltersContextState): Filter[] {
  return [...advancedFilters, ...(primaryTab ? primaryTabFilters?.[primaryTab]?.filters ?? [] : [])]
}

const searchFiltersReducer = (
  state: PreferredSupplierSearchFiltersContextState,
  action: PreferredSupplierSearchFiltersContextAction
): PreferredSupplierSearchFiltersContextState => {
  switch (action.type) {
    case 'UPDATE_SEARCH_QUERY':
      return {
        ...state,
        currentPage: 1,
        searchQuery: action.payload,
      }
    case 'UPDATE_CURRENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      }
    case 'UPDATE_ADVANCED_FILTERS':
      return {
        ...state,
        currentPage: 1,
        advancedFilters: action.payload,
      }
    case 'UPDATE_PRIMARY_TAB':
      return {
        ...state,
        currentPage: 1,
        primaryTab: action.payload,
      }

    default:
      return state
  }
}

export const usePreferredSupplierSearchFiltersContext = () => {
  const context = useContext(PreferredSupplierSearchFiltersContext)
  if (!context) {
    throw new Error('usePreferredSupplierSearchFiltersContext must be used within a PreferredSupplierSearchFiltersProvider')
  }
  return context
}

export const PreferredSupplierSearchFiltersContextProvider: FC<{
  children: ReactNode
  initialState: Partial<PreferredSupplierSearchFiltersContextState>
}> = ({ children, initialState }) => {
  const [queryState, queryStateDispatch] = useReducer(searchFiltersReducer, { ...defaultInitialState, ...(initialState ?? {}) })

  const value = useMemo(
    () => ({
      queryState,
      isQuerying: Boolean(queryState.searchQuery?.length || queryState.advancedFilters?.length > 0),
      queryStateDispatch,
      processedFilters: processFilters(queryState),
    }),
    [queryState]
  )

  return <PreferredSupplierSearchFiltersContext.Provider value={value}>{children}</PreferredSupplierSearchFiltersContext.Provider>
}
