import { memo, useMemo } from 'react'
import { map, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { Header } from '@cotiss/common/components/header.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { routerService } from '@cotiss/common/services/router.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { useGetOrganisation } from '@cotiss/organisation/resources/use-get-organisation.resource'
import { useGetProcurement } from '@cotiss/procurement/resources/use-get-procurement.resource'
import { useGetProcurementResponse } from '@cotiss/procurement-response/resources/use-get-procurement-response.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Tab = 'procurement' | 'procurement-team'

type Props = {
  activeTab: Tab
  procurementId?: string
  procurementResponseId?: string
}

export const ProcurementViewHeader = memo(({ activeTab, procurementId, procurementResponseId }: Props) => {
  const { push } = useHistory()
  const { procurement, isLoading: isProcurementLoading } = useGetProcurement(procurementId)
  const { organisation, isLoading: isOrganisationLoading } = useGetOrganisation(procurement?.procuringEntity)
  const { procurementResponse, isLoading: isProcurementResponseLoading } = useGetProcurementResponse(procurementResponseId)
  const { user: loggedInUser, isLoading: isLoggedInUserLoading } = useGetLoggedInUser()
  const isLoading =
    isProcurementLoading || isOrganisationLoading || isLoggedInUserLoading || Boolean(procurementResponseId && isProcurementResponseLoading)
  const isUserProcuringEntity = loggedInUser?.account.organisation?._id === procurement?.procuringEntity

  const isUserInProcurementResponse = useMemo(() => {
    if (!procurementResponse || !loggedInUser) {
      return false
    }

    return some(procurementResponse.procurementResponseUsers, ({ user }) => loggedInUser._id === user._id)
  }, [procurementResponse, loggedInUser])

  const isProcurementTeamTabDisabled = useMemo(() => {
    if (isUserProcuringEntity) {
      return true
    }

    if (!procurementResponse) {
      return 'This tab will become unlocked once you subscribe.'
    }

    if (!isUserInProcurementResponse) {
      return 'To view this tab, you must join the team.'
    }

    return false
  }, [isUserProcuringEntity, procurementResponse, isUserInProcurementResponse])

  const tabs: TabModel<Tab>[] = [
    { id: 'procurement', label: 'Requests' },
    {
      id: 'procurement-team',
      label: 'Team',
      isHidden: isUserProcuringEntity,
      isDisabled: isProcurementTeamTabDisabled,
    },
  ]

  const renderTags = () => {
    if (!procurement?.topLevelClassification.length) {
      return <Text>--</Text>
    }

    const [firstTag, secondTag, ...rest] = procurement.topLevelClassification.sort((a, b) => sortService.sortString(a.description, b.description))

    return (
      <>
        <Badge className="truncate max-w-[140px] mr-1" state="outline" variant="neutral" size="sm">
          {firstTag.description}
        </Badge>
        {secondTag && (
          <Badge className="truncate max-w-[140px] mr-1" state="outline" variant="neutral" size="sm">
            {secondTag.description}
          </Badge>
        )}
        {Boolean(rest.length) && (
          <Tooltip className="mr-1" tooltip={map(rest, ({ description }) => description).join(', ')}>
            <Text className="font-medium" variant="link" size="sm">
              +{rest.length}
            </Text>
          </Tooltip>
        )}
      </>
    )
  }

  const handleTabChange = ({ id: tab }: TabModel<Tab>) => {
    if (tab === activeTab) {
      return
    }

    if (tab === 'procurement' && procurementId) {
      push(routerService.getHref('/procurement/view/:procurementId/:tab?/:nestedTab?', { procurementId: procurementId }))
    }

    if (tab === 'procurement-team' && procurementResponseId) {
      push(routerService.getHref('/procurement-response/view/:procurementResponseId/team', { procurementResponseId: procurementResponseId }))
    }
  }

  return (
    <Header className="flex flex-col pb-0">
      <div className="flex items-center w-full">
        <OrganisationLogo className="shrink-0 mr-4" organisation={organisation} />

        <div className="truncate w-full">
          {isLoading && <Skeleton className="h-2 w-48 mb-2" />}
          {!isLoading && (
            <Text className="truncate" variant="light" size="sm">
              {organisation?.name}
            </Text>
          )}
          <div className="flex items-center w-full">
            {isLoading && (
              <>
                <Skeleton className="h-3 w-32 mr-4" />
                <div className="inline-block bg-gray-300 h-5 w-px mr-4" />
                <Skeleton className="h-2 w-20 mr-4" />
                <div className="inline-block bg-gray-300 h-5 w-px mr-4" />
                <Skeleton className="h-4 w-20" />
              </>
            )}
            {!isLoading && (
              <>
                <Text className="font-semibold truncate mr-4" size="h5" variant="heading">
                  {procurement?.title}
                </Text>
                <div className="inline-block bg-gray-300 h-5 w-px mr-4" />
                <Text className="truncate mr-4">{procurement?.internalIdentifier}</Text>
                <div className="inline-block bg-gray-300 h-5 w-px mr-4" />
              </>
            )}
          </div>
          {isLoading && (
            <div className="flex items-center mt-1">
              <Skeleton className="h-4 w-20 mr-1" />
              <Skeleton className="h-2 w-16" />
            </div>
          )}
          {!isLoading && (
            <div className="flex items-center">
              {renderTags()}
              {procurement?.regions && <Text className="truncate">{procurement.regions[0]}</Text>}
            </div>
          )}
        </div>
      </div>

      <Tabs<Tab> className="w-full mt-4" tab={activeTab} tabs={tabs} onChange={handleTabChange} variant="underline" />
    </Header>
  )
})
