import { memo, useMemo, useState } from 'react'
import { GqlPerformanceScorecardUserFieldsFragment } from '@gql'
import { includes, map, some, startCase, toLower } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { Radio } from '@cotiss/common/components/radio.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { PerformanceScorecardEvaluatorAddFormData } from '@cotiss/performance/drawers/performance-scorecard-evaluator-add.drawer'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { userService } from '@cotiss/user/user.service'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  performanceScorecardId: string
  formData: PerformanceScorecardEvaluatorAddFormData
  setFormData: (formData: PerformanceScorecardEvaluatorAddFormData) => void
}

export const PerformanceScorecardEvaluatorAddUserStep = memo(({ performanceScorecardId, formData, setFormData }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isLoading, setIsLoading] = useState(true)
  const { users, isLoading: isUsersLoading } = useListUser()
  const { queryPerformanceScorecardUserList } = usePerformanceScorecardUser()
  const [performanceScorecardUsers, setPerformanceScorecardUsers] = useState<GqlPerformanceScorecardUserFieldsFragment[]>([])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { items: performanceScorecardUsers } = await queryPerformanceScorecardUserList({
        filter: { performanceScorecardId, role: 'evaluator' },
      })

      setPerformanceScorecardUsers(performanceScorecardUsers)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(users, (user) => {
          const isAdded = some(performanceScorecardUsers, { userId: user._id })

          return {
            content: () => (
              <Radio
                value={user._id}
                name="performance-scorecard-evaluator"
                onChange={() => setFormData({ ...formData, evaluatorUser: user })}
                isDisabled={isAdded || !includes(user.permissions, 'EVALUATOR')}
                isChecked={user._id === formData.evaluatorUser?._id}
                isRequired
              />
            ),
          }
        }),
      },
      {
        heading: 'Name',
        rows: map(users, (user) => {
          const isAdded = some(performanceScorecardUsers, { userId: user._id })
          const hasPermission = includes(user.permissions, 'EVALUATOR')

          return {
            content: () => <Text>{userService.getFullName(user)}</Text>,
            cta: (isAdded || !hasPermission) && (
              <Tooltip tooltip={isAdded ? 'Already added' : 'User needs the "evaluator" permission to be assigned as an evaluator.'}>
                <Icon icon="lock" />
              </Tooltip>
            ),
          }
        }),
      },
      {
        heading: 'Email',
        rows: map(users, ({ email }) => ({
          content: () => <Text>{email}</Text>,
        })),
      },
      {
        heading: 'Roles',
        rows: map(users, ({ permissions }) => ({
          content: () => (
            <div>
              {!permissions.length && '--'}
              {map(permissions, (permission) => (
                <Badge key={permission} className="mr-1" state="translucent" variant="secondary">
                  {startCase(toLower(permission))}
                </Badge>
              ))}
            </div>
          ),
        })),
      },
    ]

    return { columns }
  }, [formData, users, performanceScorecardUsers])

  return (
    <>
      <TableHeader>
        <Text className="font-medium" size="lg">
          Select user
        </Text>
        <Text variant="light">Select a user below to continue.</Text>
      </TableHeader>
      <Table columns={columns} isLoading={isLoading || isUsersLoading} />
    </>
  )
})
