import { memo, useMemo, useState } from 'react'
import { filter, map } from 'lodash'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { approvalService } from '@cotiss/approval/approval.service'
import { useListApproval } from '@cotiss/approval/resources/use-list-approval.resource'
import { TENDER_TYPE_NAME_MAP } from '@cotiss/tender/tender.constants'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { TaskStatusBadge } from '@cotiss/task/components/task-status-badge.component'
import { TaskListApprovalCta } from '@cotiss/task/components/task-list-approval-cta.component'

export const ApprovalTaskTab = memo(() => {
  const { user, isLoading: isLoggedInUserLoading, isError: isLoggedInUserError } = useGetLoggedInUser()
  const { approvals, isLoading: isApprovalsLoading, isError: isApprovalsError } = useListApproval({ userId: user?._id, invalidated: true })
  const [currentPage, setCurrentPage] = useState(1)

  const { processedApprovals, pagination } = useMemo(() => {
    const _approvals = map(approvals, (approval) => ({ type: 'approval', item: approval } as const))
    const { items: preProcessedApprovals, pagination } = paginationService.paginate(_approvals, { currentPage })
    const processedApprovals = filter(preProcessedApprovals, ({ item }) => item.status !== 'revoked')
    return { processedApprovals, pagination }
  }, [approvals, currentPage])

  if (isLoggedInUserError || isApprovalsError) {
    return (
      <div className="h-64 flex justify-center items-center">
        <Text>Whoops, something went wrong. Please try again.</Text>
      </div>
    )
  }

  if (!(isLoggedInUserLoading || isApprovalsLoading) && !processedApprovals.length) {
    return <NoDataPlaceholder label="No approvals" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Tender',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => <Text className="truncate">{item.tender.title || TENDER_TYPE_NAME_MAP[item.tender.tenderType]}</Text>,
        cta: <TaskListApprovalCta approval={item} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => <TaskStatusBadge status={item.status} />,
      })),
    },
    {
      heading: 'Task Type',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text size="sm" variant="light">
            {approvalService.getDisplayApprovalType(item)}
          </Text>
        ),
      })),
    },
    {
      heading: 'Procurement',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text className="truncate max-w-xs" size="sm">
            {item.procurement.title}
          </Text>
        ),
      })),
    },
    {
      heading: 'Date Created',
      rows: map(processedApprovals, ({ item }) => ({
        content: () => (
          <Text variant="light" size="sm">
            {datetimeService.format(item.createdAt, 'd MMMM yyyy h:mm aaa')}
          </Text>
        ),
      })),
    },
  ]

  return (
    <ScrollableTable
      fixedColumns={fixedColumns}
      columns={columns}
      pagination={pagination}
      onPageChange={setCurrentPage}
      isLoading={isLoggedInUserLoading || isApprovalsLoading}
    />
  )
})
