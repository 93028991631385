import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { ConflictOfInterestViewPage } from '@cotiss/conflict-of-interest/pages/conflict-of-interest-view.page'
import { ProcurementOverviewPage } from '@cotiss/procurement/pages/procurement-overview.page'
import { ProcurementViewPage } from '@cotiss/procurement/pages/procurement-view.page'

const ProcurementLayout = memo(() => (
  <Switch>
    <Route path="/procurement/view/:procurementId/:tab?/:nestedTab?" exact>
      <ProcurementViewPage />
    </Route>
    <Route path="/procurement/overview/:procurementId/:tab?/:nestedTab?" exact>
      <ProcurementOverviewPage />
    </Route>
    <Route path="/procurement/overview/:procurementId/conflict-of-interest/view/:conflictOfInterestId" exact>
      <ConflictOfInterestViewPage />
    </Route>
  </Switch>
))

export default ProcurementLayout
