import { memo } from 'react'
import classNames from 'classnames'
import ReactDatePicker from 'react-datepicker'
import { DatetimeFormat } from '@cotiss/common/services/datetime.service'

type Props = {
  className?: string
  onChange: (value: Date | null) => void
  size?: 'sm' | 'md' | 'lg'
  placeholder?: string
  value?: Date | null
  dateFormat?: DatetimeFormat
  autoFocus?: boolean
  isFuture?: boolean
  isPast?: boolean
  isTimeVisible?: boolean
  isError?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  maxDate?: Date
  minDate?: Date
}

export const DatetimeInput = memo((props: Props) => {
  const {
    className,
    onChange,
    value,
    placeholder,
    size = 'md',
    isFuture,
    isPast,
    isError,
    isRequired,
    isDisabled,
    dateFormat = 'd MMMM yyyy h:mm aaa',
    isTimeVisible = true,
    maxDate,
    minDate,
  } = props
  const classes = classNames(
    className,
    'block bg-white border text-primary-500 placeholder-gray-400 rounded w-full focus:shadow-none focus:outline-none',
    {
      'border-gray-300 focus:border-secondary-600': !isError,
      'border-pink-500 focus:border-pink-500': isError,
      'px-2 py-0.5': size === 'sm',
      'h-11 px-3 py-2': size === 'md',
      'px-4 py-4': size === 'lg',
      'pointer-events-none opacity-50': isDisabled,
    }
  )

  return (
    <ReactDatePicker
      className={classes}
      selected={value}
      onChange={onChange}
      placeholderText={placeholder}
      dateFormat={dateFormat}
      required={isRequired}
      maxDate={isPast ? new Date() : maxDate}
      minDate={isFuture ? new Date() : minDate}
      showTimeSelect={isTimeVisible}
    />
  )
})
