import { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { Text } from '@cotiss/common/components/text.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { ContractVariationStatus } from '@cotiss/contract/contract.model'

const getVariant = (status: ContractVariationStatus): BaseVariant => {
  switch (status) {
    case 'DRAFTING':
      return 'warning'
    case 'PENDING_APPROVAL':
    case 'PENDING_SIGNATURES':
      return 'info'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ContractVariationStatus
  size?: BadgeSize
}

export const ContractVariationStatusBadge = memo(({ className, status, size = 'sm' }: Props) => {
  if (status === 'ACTIVE' || status === 'NONE') {
    return <Text>--</Text>
  }
  return (
    <Badge className={className} size={size} variant={getVariant(status)} state="dot">
      {upperFirst(lowerCase(status))}
    </Badge>
  )
})
