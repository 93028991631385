import { memo, useMemo } from 'react'
import { filter, map } from 'lodash'
import { AvatarSkeleton } from '@cotiss/common/components/avatar-skeleton.component'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { useListTenderQuestionScore } from '@cotiss/tender-question/resources/use-list-tender-question-score.resource'
import { userService } from '@cotiss/user/user.service'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type Props = {
  tenderQuestionId: string
  tenderResponseId: string
}

export const TenderQuestionReportEvaluationComments = memo(({ tenderQuestionId, tenderResponseId }: Props) => {
  const { tenderQuestionScores, isLoading } = useListTenderQuestionScore({ tenderQuestionId, tenderResponseId, evaluationStatus: 'completed' })
  const tenderQuestionScoresWithComments = useMemo(
    () => filter(tenderQuestionScores, ({ commentary }) => Boolean(commentary)),
    [tenderQuestionScores, tenderQuestionId]
  )

  if (!isLoading && !tenderQuestionScoresWithComments.length) {
    return null
  }

  const renderSkeletonItem = () => (
    <div className="flex items-start mt-4">
      <AvatarSkeleton className="mr-2" />
      <div className="w-full">
        <div className="flex items-center justify-between mt-2">
          <Skeleton className="h-2 w-1/3" />
          <Skeleton className="h-2 w-1/3" />
        </div>
        <Skeleton className="h-2 w-full mt-3" />
        <Skeleton className="h-2 w-full mt-1" />
        <Skeleton className="h-2 w-2/3 mt-1" />
      </div>
    </div>
  )

  return (
    <>
      <Text className="uppercase mt-4" variant="light" size="sm">
        Evaluation comments
      </Text>
      {isLoading && (
        <>
          {renderSkeletonItem()}
          {renderSkeletonItem()}
        </>
      )}
      {!isLoading && (
        <>
          {map(tenderQuestionScoresWithComments, ({ _id, evaluation: { user }, createdAt, commentary }) => (
            <div key={_id} className="flex items-start mt-4">
              <Avatar className="mr-2">{userService.getInitials(user)}</Avatar>
              <div>
                <div className="flex items-center justify-between mt-2">
                  <Text size="sm" variant="secondary">
                    {userService.getFullName(user)}
                  </Text>
                  <Text variant="light" size="sm">
                    {datetimeService.format(createdAt, 'd MMMM yyyy h:mm aaa')}
                  </Text>
                </div>
                <Text className="mt-2" size="sm">
                  {commentary}
                </Text>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  )
})
