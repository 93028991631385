import { memo, useEffect, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'

type Props = {
  className?: string
  document: DocumentModel
}

export const DocumentLink = memo(({ className, document }: Props) => {
  const classes = classNames(className, 'flex items-center w-full')
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  return (
    <Button className={classes} onClick={() => setDocumentIdToDownload(document._id)} state="raw">
      <div className="inline-flex items-center justify-center bg-gray-200 rounded w-6 h-6 mr-2">
        <Icon icon="file-04" variant="link" />
      </div>
      <Text className="mr-1" size="sm">
        {document.fileName}
      </Text>
      <Text size="sm" variant="light">
        ({documentService.formatSize(document.fileSize)})
      </Text>
    </Button>
  )
})
