import { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { filter, map, orderBy, toLower, upperFirst } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Card } from '@cotiss/common/components/card.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { utilService } from '@cotiss/common/services/util.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { ContractScheduledRateCta } from '@cotiss/contract/components/contract-scheduled-rate-cta.component'
import { ContractStepCardSkeletonLoading } from '@cotiss/contract/components/contract-step-card-skeleton-loading.component'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractWizardScheduleRatesModal } from '@cotiss/contract/modals/contract-wizard-schedule-rates.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  onNext: () => void
  onBack: (() => void) | null
  contractShellId: string
}

export const ContractWizardScheduleRatesStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { track } = useAnalytics()
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { updateScheduledRatesBulk } = useMutateContractShell()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)

  const { scheduledRates, contract, isVariation } = useMemo(() => {
    if (!contractShell) {
      return { scheduledRates: [], contract: null, isVariation: false }
    }
    const contract = contractService.getContract(contractShell, ['DRAFTING'])
    const isVariation = Boolean(contract?.variationTypes.length)

    return { scheduledRates: orderBy(contract?.scheduledRates, 'index'), contract, isVariation }
  }, [contractShell?.contracts])

  const [areScheduleRatesRequired, setAreScheduleRatesRequired] = useState<boolean>(Boolean(scheduledRates.length))
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    track('contract_wizard_schedule_rates_view')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // If it's a variation we don't use areScheduleRatesRequired
    // So we never need to clear the rates
    if (isVariation) {
      onNext()
      return
    }

    // If we have scheduled rates saved, but they are no longer required
    if (!areScheduleRatesRequired && contract?.scheduledRates.length) {
      // Remove saved values
      try {
        setIsSaving(true)
        await updateScheduledRatesBulk(contractShellId, contract._id, { items: [] })
        setIsSaving(false)
      } catch (error: any) {
        sentryService.captureException({ exception: error })
        openToast(error.message, 'danger')
        setIsSaving(false)
      }
    }

    onNext()
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Item',
      rows: map(scheduledRates, (rate) => ({
        content: (isHighlighted) => (
          <Text
            className={classNames('font-medium break-words w-full', { truncate: isHighlighted, 'whitespace-normal mt-[2px]': !isHighlighted })}
            title={rate.title}
          >
            {rate.title}
          </Text>
        ),
        cta: (
          <ContractScheduledRateCta
            scheduledRate={rate}
            onDelete={async () => {
              if (!contract) {
                sentryService.captureException({
                  exception: `Tried updating scheduled rates bulk but couldn't find contract`,
                  extras: { contractShellId },
                })
                openToast('Something went wrong. Please try again.', 'danger')
                return
              }
              await updateScheduledRatesBulk(contractShellId, contract._id, {
                items: filter(scheduledRates, (_rate) => _rate._id !== rate._id),
              })
            }}
            contractShellId={contractShellId}
          />
        ),
        tdClassName: 'w-60 align-top py-4',
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Tag',
      rows: map(scheduledRates, ({ tag }) => ({
        content: () => (
          <>
            {tag ? (
              <Badge variant="neutral" state="outline">
                {upperFirst(toLower(tag))}
              </Badge>
            ) : (
              '--'
            )}
          </>
        ),
        tdClassName: 'align-top py-4',
      })),
    },
    {
      heading: 'Unit price',
      rows: map(scheduledRates, ({ amount, unit }) => ({
        content: () => (
          <div className="flex items-center">
            <Text variant="secondary">{utilService.formatAsCurrency(amount, contract?.metadata.currency)}</Text>
            <Text variant="light">/{unit}</Text>
          </div>
        ),
        tdClassName: 'align-top mt-4 py-4',
      })),
    },
    {
      heading: 'Description',
      rows: map(scheduledRates, ({ description }) => ({
        content: () => <Text className="whitespace-pre-wrap">{description}</Text>,
        tdClassName: 'w-3/4 align-top py-4',
      })),
    },
  ]

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="p-6">
          <Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />
          <Skeleton className="bg-primary-200 h-16 w-full mb-4" />
          <Skeleton className="bg-primary-200 h-16 w-full" />
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contractShell) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell?.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5">
              Schedule of rates
            </Text>
          </div>
          <div className="ml-4">
            {onBack && (
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
                Back
              </Button>
            )}
            <Button type="submit" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
              Continue
            </Button>
          </div>
        </CardHeader>
        <div className="p-6">
          {!isVariation && (
            <Field label="Does this contract require a schedule of rates?" className="mb-6">
              <div className="flex items-start">
                <Radio<boolean>
                  className="mr-4"
                  value={true}
                  onChange={() => setAreScheduleRatesRequired(true)}
                  name="contract-documents-required"
                  isChecked={areScheduleRatesRequired}
                  isDisabled={isLoading || isSaving}
                >
                  Yes
                </Radio>
                <Radio<boolean>
                  value={false}
                  onChange={() => setAreScheduleRatesRequired(false)}
                  name="contract-documents-required"
                  isChecked={areScheduleRatesRequired === false}
                  isDisabled={isLoading || isSaving}
                >
                  No
                </Radio>
              </div>
            </Field>
          )}
          {(areScheduleRatesRequired || isVariation) && (
            <>
              <TableHeader className="flex justify-between items-center">
                <div>
                  <Text className="font-semibold">Schedule of rates table</Text>
                  <Text variant="light" size="sm">
                    Agreed rates charged for various goods or services by the counterparty
                  </Text>
                </div>
                <Button
                  size="xs"
                  variant="secondary"
                  onClick={() => openModal(<ContractWizardScheduleRatesModal contractShellId={contractShell._id} />)}
                  isDisabled={isLoading || isSaving}
                >
                  + Add row {/* TODO: Replace this with descriptive CTA label */}
                </Button>
              </TableHeader>

              <ScrollableTable
                fixedColumnsWidth={240}
                fixedColumns={fixedColumns}
                columns={columns}
                emptyCta={
                  <Button
                    state="text"
                    variant="secondary"
                    size="sm"
                    onClick={() => openModal(<ContractWizardScheduleRatesModal contractShellId={contractShell._id} />)}
                    isDisabled={isLoading || isSaving}
                  >
                    + Add row {/* TODO: Replace this with descriptive CTA label */}
                  </Button>
                }
              />
            </>
          )}
        </div>
      </Card>
    </Form>
  )
})
