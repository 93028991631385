import { FormEvent, memo, useMemo, useState } from 'react'
import { GqlCreateEvaluationAccessControlItemInput, GqlEvaluationEnvelopeFieldsFragment, GqlEvaluationUserFieldsFragment } from '@gql'
import { filter, find, forEach, includes, map, some } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationAccessControl } from '@cotiss/evaluation-event/hooks/use-evaluation-access-control.hook'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'
import { userService } from '@cotiss/user/user.service'

type Props = {
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  evaluationUser: GqlEvaluationUserFieldsFragment
}

export const EvaluationEventScoringAccessUpdateModal = memo(({ evaluationEnvelope, evaluationUser }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { track } = useEvaluationEventAnalytics()
  const [isSaving, setIsSaving] = useState(false)
  const { evaluationCriteria } = useEvaluationCriteria()
  const { queryEvaluationUserView } = useEvaluationUser()
  const { mutateCreateEvaluationAccessControls, mutateDeleteEvaluationAccessControls } = useEvaluationAccessControl()

  const parentCriteria = useMemo(
    () => filter(evaluationCriteria, { evaluationEnvelopeId: evaluationEnvelope.id, parentEvaluationCriteriaId: null }),
    [evaluationEnvelope, evaluationCriteria]
  )

  const formDataToSet = useMemo(() => {
    const formData: Record<string, boolean> = {}

    forEach(parentCriteria, (criteria) => {
      formData[criteria.id] = some(evaluationUser.accessControls, { resourceType: 'criteria', resourceId: criteria.id })
    })

    return formData
  }, [])

  const [formData, setFormData] = useState<Record<string, boolean>>(formDataToSet)

  const showWarningBanner = useMemo(() => {
    // Show warning banner if the evaluation user has had their access to a criteria revoked
    return some(map(formData, (isEnabled, resourceId) => !isEnabled && formDataToSet[resourceId]))
  }, [formData, formDataToSet])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const allAccessControls = filter(evaluationUser.accessControls, ({ resourceType, resourceId }) => {
        return resourceType === 'criteria' && includes(map(parentCriteria, 'id'), resourceId)
      })

      const accessControlIdsToDelete: string[] = []
      forEach(allAccessControls, (accessControl) => {
        if (!formData[accessControl.resourceId]) {
          accessControlIdsToDelete.push(accessControl.id)
        }
      })

      if (accessControlIdsToDelete.length) {
        track('evaluation_event_wizard_scoring_access_update_delete_submit')
        await mutateDeleteEvaluationAccessControls({ evaluationAccessControlIds: accessControlIdsToDelete })
      }

      const accessControlsToCreate: GqlCreateEvaluationAccessControlItemInput[] = []
      forEach(formData, (isEnabled, resourceId) => {
        if (isEnabled && !find(allAccessControls, { resourceId })) {
          accessControlsToCreate.push({ evaluationUserId: evaluationUser.id, resourceType: 'criteria', resourceId, access: 'score' })
        }
      })

      if (accessControlsToCreate.length) {
        track('evaluation_event_wizard_scoring_access_update_create_submit')
        await mutateCreateEvaluationAccessControls({ accessControls: accessControlsToCreate })
      }

      await queryEvaluationUserView({ evaluationUserId: evaluationUser.id })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[600px] max-w-[600px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Edit scoring access" />
      <ModalContent isScrollable>
        {showWarningBanner && (
          <Banner className="mb-6" icon="alert-circle" variant="danger" iconVariant="danger">
            <div>
              <Text className="font-semibold">Warning</Text>
              <Text size="sm">
                By removing this user&apos;s access to a criteria, you will be deleting any data they have scored in that criteria. This action cannot
                be undone once saved.
              </Text>
            </div>
          </Banner>
        )}
        <div className="flex">
          <div className="border-r border-gray-200 w-2/5 mr-4 pr-4">
            <Label>{userService.getFullName(evaluationUser.user)}</Label>
            <Text size="sm" variant="light">
              Revoke scoring access to specific criteria within this evaluation envelope. If access is revoked, the user will be able to view but not
              score the criteria.
            </Text>
          </div>
          <div className="w-3/5">
            {map(parentCriteria, ({ id, content }) => (
              <label
                key={id}
                className="flex items-center bg-secondary-100 cursor-pointer rounded p-2 mb-2"
                htmlFor={`evaluation-scoring-access-${id}`}
              >
                <Checkbox
                  id={`evaluation-scoring-access-${id}`}
                  className="mr-1"
                  onChange={() => setFormData({ ...formData, [id]: !formData[id] })}
                  isChecked={formData[id]}
                  isDisabled={isSaving}
                />
                <Text className="font-semibold" size="sm">
                  {content}
                </Text>
              </label>
            ))}
          </div>
        </div>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
