import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const SettingsGeneralFormEmail = memo(() => {
  const { user } = useGetLoggedInUser()

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">Email address</Text>
        <Text>{user?.email || '–'}</Text>
      </div>

      <Button
        size="sm"
        state="translucent"
        variant="secondary"
        onClick={() => {
          window.open('https://www.cotiss.com/support', '_blank')
        }}
      >
        <Icon className="mr-1" icon="external-link" size={18} />
        Contact Support
      </Button>
    </div>
  )
})
