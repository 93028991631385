import { FormEvent, memo, useState } from 'react'
import { parseISO } from 'date-fns'
import { map } from 'lodash'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { MultiSelect_DEPRECATED } from '@cotiss/common/components/deprecated/multi-select.component'
import { Form } from '@cotiss/common/components/form.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { ContractReminderModel } from '@cotiss/contract/contract.model'
import { CreateContractReminderBody, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type FormData = Pick<CreateContractReminderBody, 'title' | 'description'> & { date: Date | null; users: string[] }

type Props = {
  contractShellId: string
  reminder?: ContractReminderModel
}

export const ContractReminderAddModal = memo(({ contractShellId, reminder }: Props) => {
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { openToast } = useToast()
  const { users } = useListUser({ permissions: ['ADMIN', 'CONTRACT_MANAGER'] })
  const { createContractReminder, updateContractReminder } = useMutateContractShell()
  const [formData, setFormData] = useState<FormData>({
    description: reminder?.description || '',
    title: reminder?.title || '',
    users: map(reminder?.users, (user) => user._id) || [],
    date: reminder?.date ? parseISO(reminder?.date) : null,
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const body = { ...formData, date: formData.date?.toISOString() }

      if (reminder) {
        await updateContractReminder(reminder._id, contractShellId, body)
      } else if (body.date) {
        await createContractReminder(contractShellId, { ...body, date: body.date })
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${reminder ? 'Edit' : 'Add'} reminder`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Title</Label>
        <Input
          value={formData?.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          maxLength={100}
          isDisabled={isSaving}
          placeholder="Enter title"
          isRequired
        />
        <Label className="mt-4">Description</Label>
        <TextArea
          value={formData?.description}
          maxLength={500}
          isDisabled={isSaving}
          rows={4}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
        />
        <Label className="mt-4">Notification date</Label>
        <DatetimeInput
          value={formData?.date}
          placeholder="Select date and time"
          isFuture
          isDisabled={isSaving}
          onChange={(date) => setFormData({ ...formData, date })}
          isRequired
        />
        <Label className="mt-4">Send reminder to</Label>
        <MultiSelect_DEPRECATED
          value={formData.users.map((user) => {
            const userWithNames = users.find((u) => u._id === user)

            return {
              label: userWithNames ? `${userWithNames.firstname} ${userWithNames.surname}` : '',
              value: user,
            }
          })}
          options={map(users, (user) => ({ label: `${user.firstname} ${user.surname}`, value: user._id }))}
          onChange={(users) => setFormData({ ...formData, users: users.map((user) => user.value) })}
          isDisabled={isSaving}
          isRequired
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
