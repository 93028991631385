import type { ReactNode } from 'react'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  children?: ReactNode
}

export const ContractStepCardSkeletonLoading = ({ children }: Props) => {
  return (
    <>
      <Card>
        <CardHeader className="flex justify-between">
          <div className="w-full flex-grow">
            <Skeleton className="bg-primary-200 h-4 w-1/3" />
            <Skeleton className="bg-primary-200 h-6 w-1/2 mt-1" />
          </div>
          <div className="ml-4 flex-shrink flex">
            <Skeleton className="bg-primary-200 h-8 w-12 mr-2" />
            <Skeleton className="bg-primary-200 h-8 w-16" />
          </div>
        </CardHeader>
        {children}
      </Card>
    </>
  )
}
