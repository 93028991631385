import classNames from 'classnames'
import { Icon } from '@cotiss/common/components/icon.component'

type Props = {
  direction: 'left' | 'right'
  onClick: () => void
  inset?: number
}

export const ScrollContainerButton = ({ direction, onClick, inset = 0 }: Props) => {
  return (
    <div
      style={{ top: inset, bottom: inset }}
      className={classNames(`absolute w-12 bg-white flex items-center justify-center`, direction === 'left' ? 'left-0' : 'right-0')}
    >
      <div
        className={classNames(
          'absolute top-0 bottom-0 w-8',
          direction === 'left' ? ['left-12', 'bg-gradient-to-r'] : ['right-12', 'bg-gradient-to-l'],
          'from-white to-transparent'
        )}
      />
      <button className="p-2 rounded-full text-secondary-500 appearance-none" onClick={onClick}>
        <Icon icon={`arrow-${direction}`} size={24} />
      </button>
    </div>
  )
}
