import { memo, useMemo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { authService } from '@cotiss/auth/auth.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { TenderResponseInvitationLinkRegenerateConfirmationModal } from '@cotiss/tender-response/modals/tender-response-invitation-link-regenerate-confirmation.modal'

type Props = {
  tenderId: string
  isEditable?: boolean
  willRegenerateLinkOnOpen?: boolean
}

export const TenderResponseInvitationLinkModal = memo(({ tenderId, isEditable, willRegenerateLinkOnOpen }: Props) => {
  const { openModal } = useCallout()
  const { openToast } = useToast()
  const { tender } = useGetTender(tenderId)
  const { generateTenderInvitationToken } = useMutateTender()
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const [isCopyingLink, setIsCopyingLink] = useState(false)
  const [isGeneratingLink, setIsGeneratingLink] = useState(false)
  // This will stop the modal getting in an infinite loop if `willRegenerateLinkOnOpen` is true
  const [willRegenerateLink, setWillRegenerateLink] = useState(willRegenerateLinkOnOpen)

  useAsyncEffect(async () => {
    if (!tender || (tender.invitationToken && !willRegenerateLink)) {
      return
    }

    setIsGeneratingLink(true)
    try {
      await generateTenderInvitationToken(tenderId)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsGeneratingLink(false)
    setWillRegenerateLink(false)
  }, [tender, willRegenerateLink])

  const tenderInviteLink = useMemo(() => {
    if (!tender?.invitationToken) {
      return
    }

    const urlBase = routerService.getHref('/tender-invitation')
    const queryParams = utilService.generateUrlSearchParams({
      'invitation-token': tender.invitationToken,
    })
    const url = `${import.meta.env.VITE_APP_DOMAIN}${urlBase}${queryParams}`
    return url
  }, [tender])

  const isTenderInviteLinkExpired = useMemo(() => {
    if (!tender?.invitationToken) {
      return
    }

    return !authService.isTokenValid(tender.invitationToken)
  }, [tender])

  const resetIsLinkCopied = () => {
    setTimeout(() => {
      setIsLinkCopied(false)
    }, 3000)
  }

  const handleCopyToClipboard = async () => {
    if (!tenderInviteLink) {
      // Copy link button will be disabled
      return
    }

    setIsCopyingLink(true)
    try {
      await navigator.clipboard.writeText(tenderInviteLink)
      setIsLinkCopied(true)
      openToast('Link copied to clipboard', 'success')
      resetIsLinkCopied()
    } catch (error) {
      setIsLinkCopied(false)
      openToast('Failed to copy to clipboard', 'danger')
    }
    setIsCopyingLink(false)
  }

  return (
    <div className="relative min-w-[450px] max-w-[450px]">
      <ModalHeader heading="Copy invite link" supplementary="Invite link will be active for 6 months" />
      <ModalContent>
        <Label>Invite link</Label>
        <Input
          value={tenderInviteLink}
          placeholder={tender?.invitationToken || 'Generating link...'}
          isReadOnly={true}
          isError={isTenderInviteLinkExpired}
        />
        {isTenderInviteLinkExpired && (
          <Text className="mt-1" variant="danger" size="sm">
            Invite link has expired, regenerate to create a new link
          </Text>
        )}
      </ModalContent>
      <ModalFooter className="flex justify-end">
        <Button
          className="mr-4"
          variant="secondary"
          state="translucent"
          onClick={() => openModal(<TenderResponseInvitationLinkRegenerateConfirmationModal tenderId={tenderId} isEditable={isEditable} />)}
          isDisabled={!tenderInviteLink}
          isLoading={Boolean(tenderInviteLink && isGeneratingLink)}
        >
          <Icon icon="refresh-cw-03" className="mr-1" /> Regenerate link
        </Button>
        <Button variant="secondary" onClick={handleCopyToClipboard} isDisabled={!tenderInviteLink || isLinkCopied} isLoading={isCopyingLink}>
          <Icon icon="link-03" className="mr-1" /> Copy link
        </Button>
      </ModalFooter>
    </div>
  )
})
