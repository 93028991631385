import { ReactNode } from 'react'
import classNames from 'classnames'
import { BaseVariant } from '@cotiss/common/models/common.models'

export type BadgeSize = 'sm' | 'md'
export type BadgeState = 'filled' | 'translucent' | 'outline' | 'dot'
type Props = {
  className?: string
  children: ReactNode
  state?: BadgeState
  shape?: 'rect' | 'square' | 'rounded' | 'flag' // TODO: 'flag' is not yet supported on 'outline' state
  variant?: BaseVariant
  size?: BadgeSize
  title?: string
}

export const Badge = ({ className, size = 'sm', children, state = 'filled', variant = 'primary', shape = 'rounded', title }: Props) => {
  let classes = classNames(className, 'font-medium ', {
    'text-xs': size === 'sm',
    'text-sm': size === 'md',
  })

  let flagClasses = 'border-t-[10px] border-b-[10px] border-l-[12px] w-0 h-0 border-t-transparent border-b-transparent right-0'

  if (state !== 'dot') {
    classes = classNames(classes, 'inline-block py-0.5', {
      'px-1': shape === 'square',
      'px-2.5': shape !== 'square',
      'pr-1 whitespace-nowrap': shape === 'flag',
      'rounded-full': shape === 'rounded',
      rounded: shape !== 'rounded',
    })
  }

  if (state === 'filled') {
    classes = classNames(classes, 'text-white', {
      'bg-primary-500': variant === 'primary',
      'bg-secondary-500': variant === 'secondary',
      'bg-pink-500': variant === 'danger',
      'bg-blue-500': variant === 'info',
      'bg-orange-500': variant === 'warning',
      'bg-green-500': variant === 'success',
      'bg-gray-500': variant === 'neutral',
      'rounded-r-none': shape === 'flag',
    })
    flagClasses = classNames(flagClasses, {
      'border-l-primary-500': variant === 'primary',
      'border-l-secondary-500': variant === 'secondary',
      'border-l-pink-500': variant === 'danger',
      'border-l-blue-500': variant === 'info',
      'border-l-orange-500': variant === 'warning',
      'border-l-green-500': variant === 'success',
      'border-l-gray-500': variant === 'neutral',
    })
  }

  if (state === 'translucent') {
    classes = classNames(classes, {
      'bg-primary-200 text-primary-500': variant === 'secondary',
      'bg-secondary-200 text-secondary-500': variant === 'secondary',
      'bg-pink-200 text-pink-600': variant === 'danger',
      'bg-blue-200 text-blue-700': variant === 'info',
      'bg-orange-300 text-orange-500': variant === 'warning',
      'bg-green-200 text-green-700': variant === 'success',
      'bg-gray-200 text-gray-600': variant === 'neutral',
      'rounded-r-none': shape === 'flag',
    })
    flagClasses = classNames(flagClasses, {
      'border-l-primary-200': variant === 'primary',
      'border-l-secondary-200': variant === 'secondary',
      'border-l-pink-200': variant === 'danger',
      'border-l-blue-200': variant === 'info',
      'border-l-orange-300': variant === 'warning',
      'border-l-green-200': variant === 'success',
      'border-l-gray-200': variant === 'neutral',
    })
  }

  if (state === 'outline') {
    classes = classNames(classes, 'border', {
      'border-primary-500 text-primary-500': variant === 'primary',
      'border-secondary-500 text-secondary-500': variant === 'secondary',
      'border-pink-500 text-pink-500': variant === 'danger',
      'border-blue-500 text-blue-500': variant === 'info',
      'border-orange-500 text-orange-500': variant === 'warning',
      'border-green-500 text-green-500': variant === 'success',
      'border-gray-200 text-gray-600': variant === 'neutral', // TODO: Look into changing the neutral variant to match DS
    })

    // TODO: support flag shape
  }

  if (state === 'dot') {
    classes = classNames(classes, 'inline-flex items-center', {
      'text-primary-500': variant === 'primary',
      'text-secondary-500': variant === 'secondary',
      'text-pink-500': variant === 'danger',
      'text-blue-500': variant === 'info',
      'text-orange-500': variant === 'warning',
      'text-green-500': variant === 'success',
      'text-gray-600': variant === 'neutral',
    })
  }

  const renderDot = () => {
    const dotClasses = classNames('w-2 h-2 rounded-full mr-2', {
      'bg-primary-500': variant === 'primary',
      'bg-secondary-500': variant === 'secondary',
      'bg-pink-500': variant === 'danger',
      'bg-blue-500': variant === 'info',
      'bg-orange-500': variant === 'warning',
      'bg-green-500': variant === 'success',
      'bg-gray-500': variant === 'neutral',
    })

    return <div className={dotClasses} />
  }
  const renderContent = () => {
    return (
      <div className={classes} title={title}>
        {state === 'dot' && renderDot()}
        {children}
      </div>
    )
  }

  if (shape === 'flag') {
    return (
      <div className="flex">
        {renderContent()}
        <div className={flagClasses} />
      </div>
    )
  }

  return <>{renderContent()}</>
}
