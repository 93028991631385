import { memo } from 'react'
import classNames from 'classnames'
import { Skeleton } from '@cotiss/common/components/skeleton.component'

type Props = {
  className?: string
  hasAvatar?: boolean
}

export const UserLineItemSkeleton = memo(({ className, hasAvatar = true }: Props) => {
  const classes = classNames(className, 'flex items-center justify-between')

  return (
    <div className={classes}>
      <div className="flex items-center">
        {hasAvatar && <Skeleton className="h-10 w-10 mr-2" variant="secondary" isRounded />}
        <div className="mr-2">
          <div className="flex items-center">
            <Skeleton className="h-3 w-24 mr-1" variant="gray" />
            <Skeleton className="h-3 w-12" variant="gray" />
          </div>
          <Skeleton className="h-2 w-20 mt-2" variant="gray" />
        </div>
      </div>
    </div>
  )
})
