import { useState, memo, useMemo } from 'react'
import { filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { contractService } from '@cotiss/contract/contract.service'
import { ContractWizardPriceDurationModal } from '@cotiss/contract/modals/contract-wizard-price-duration.modal'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  priceDurationId: string
  contractShellId: string
}

export const ContractPriceDurationItemCta = memo(({ priceDurationId, contractShellId }: Props) => {
  const { openModal } = useCallout()
  const { updateContractPriceDurationBulk } = useMutateContractShell()
  const { openToast } = useToast()

  const { contractShell } = useGetContractShell(contractShellId)
  const contract = useMemo(() => (contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null), [contractShell])

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const handleDelete = async (id: string) => {
    if (!contract) {
      openToast('Something went wrong trying to delete contract duration', 'danger')
      return
    }

    const existingPriceDurations = contract?.priceDurations.map((priceDuration) => {
      return {
        ...priceDuration,
        startDate: priceDuration.startDate ? new Date(priceDuration.startDate) : undefined,
        endDate: priceDuration.endDate ? new Date(priceDuration.endDate) : undefined,
        variation: priceDuration.variation || 0,
      }
    })

    try {
      setIsSaving(true)
      await updateContractPriceDurationBulk(contractShellId, contract._id, {
        items: map(
          filter(existingPriceDurations, (pd) => pd._id !== id),
          (pd, count) => {
            return { ...pd, index: count }
          }
        ),
      })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="flex items-center">
      <Button
        className="mr-2"
        onClick={() => setIsDropdownOpen(true)}
        shape="square"
        state="translucent"
        variant="secondary-dark"
        size="xs"
        isRounded
        isDisabled={isSaving}
      >
        <Icon icon="dots" variant="secondary" />
      </Button>
      <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent
          isDisabled={isSaving}
          className="flex items-center"
          onClick={() => openModal(<ContractWizardPriceDurationModal priceDurationId={priceDurationId} contractShellId={contractShellId} />)}
        >
          Edit
        </DropdownContent>
        <DropdownContent
          isDisabled={isSaving}
          className="flex items-center"
          onClick={() =>
            openModal(
              <ConfirmModal
                heading="Delete"
                description="Are you sure you want to delete this contract period?"
                onSubmit={() => handleDelete(priceDurationId)}
              />
            )
          }
        >
          Delete
        </DropdownContent>
      </Dropdown>
    </div>
  )
})
