import { memo, useState } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { AccordionIcon } from '@cotiss/common/components/accordion-icon.component'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { GroupEvaluationStatusBadge } from '@cotiss/group-evaluation/components/group-evaluation-status-badge.component'
import { TenderCriterionReportModel } from '@cotiss/tender-criteria/tender-criteria.models'
import { TenderQuestionReportModel } from '@cotiss/tender-question/tender-question.models'

type Props = {
  className?: string
  tenderCriterionReport: TenderCriterionReportModel
  onView?: (tenderCriterionReport: TenderCriterionReportModel, tenderQuestionReport: TenderQuestionReportModel) => void
}

export const TenderCriteriaReportAccordion = memo(({ className, tenderCriterionReport, onView }: Props) => {
  const classes = classNames(className, 'table-fixed w-full')
  const [isExpanded, setIsExpanded] = useState(false)
  const thClasses = classNames('bg-secondary-50 border-t border-gray-200 px-4 py-2', {
    'border-b': !isExpanded,
  })
  const total = tenderCriterionReport.consensusUnscoredQuestions + tenderCriterionReport.consensusScoredQuestions
  const isComplete = total === tenderCriterionReport.consensusScoredQuestions

  // TODO: Swap this out with the AccordionToggle and AccordionContent components.

  return (
    <table className={classes}>
      <thead>
        <tr className="cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
          <th className="w-10 pr-2 py-2">
            <AccordionIcon isExpanded={isExpanded} />
          </th>
          <th className={classNames(thClasses, 'border-l')}>
            <Text className="text-left font-medium mr-4">{tenderCriterionReport.name}</Text>
          </th>
          <th className={classNames(thClasses, 'w-24')}>
            <Badge variant={isComplete ? 'success' : 'neutral'} state="translucent">
              {isComplete ? 'Complete' : `${tenderCriterionReport.consensusScoredQuestions}/${total}`}
            </Badge>
          </th>
          <th className={classNames(thClasses, 'w-24')}>
            <Text className="text-blue-500" variant="none">
              {tenderCriterionReport.avgScore.toFixed(2)}
            </Text>
          </th>
          <th className={classNames(thClasses, 'w-28')}>
            <Text variant="secondary">{tenderCriterionReport.groupScore.toFixed(2)}</Text>
          </th>
          <th className={classNames(thClasses, 'border-r w-24')} />
        </tr>
      </thead>
      {isExpanded && (
        <tbody>
          <tr>
            <td className="px-4 py-2" />
            <td className="border-l border-t border-gray-200 px-4 py-2">
              <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                Criteria
              </Text>
            </td>
            <td className="border-t border-gray-200 px-4 py-2">
              <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                Progress
              </Text>
            </td>
            <td className="border-t border-gray-200 px-4 py-2">
              <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                Avg score
              </Text>
            </td>
            <td className="border-t border-gray-200 px-4 py-2">
              <Text className="font-normal text-left whitespace-nowrap uppercase" size="xs" variant="light">
                Group score
              </Text>
            </td>

            <td className="border-t border-r border-gray-200" />
          </tr>
          {map(tenderCriterionReport.tenderQuestions, (tenderQuestionReport, index) => {
            const tdClasses = classNames('border-t border-gray-200 px-4 py-2', {
              'border-b': index === tenderCriterionReport.tenderQuestions.length - 1,
            })

            return (
              <tr key={tenderQuestionReport._id}>
                <td className="px-4 py-2" />
                <td className={classNames(tdClasses, 'border-l')}>
                  <Text className="truncate" size="sm">
                    {tenderQuestionReport.question}
                  </Text>
                </td>
                <td className={classNames(tdClasses)}>
                  <GroupEvaluationStatusBadge status={tenderQuestionReport.groupEvaluation.status} />
                </td>
                <td className={classNames(tdClasses)}>
                  <Text className="font-medium text-blue-500 text-center" variant="none" size="sm">
                    {tenderQuestionReport.avgScore.toFixed(2)}
                  </Text>
                </td>
                <td className={classNames(tdClasses)}>
                  <Text className="font-medium text-center" variant="secondary" size="sm">
                    {tenderQuestionReport.groupScore.toFixed(2)}
                  </Text>
                </td>

                <td className={classNames(tdClasses, 'border-r')}>
                  <Button
                    onClick={() => onView && onView(tenderCriterionReport, tenderQuestionReport)}
                    state="translucent"
                    variant="secondary"
                    size="sm"
                    isDisabled={!onView}
                  >
                    View
                    <Icon icon="arrow-right" size={20} />
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      )}
    </table>
  )
})
