import { memo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { BannerSkeleton } from '@cotiss/common/components/banner-skeleton.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RemainingTasksButton } from '@cotiss/common/components/remaining-tasks-button.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType, useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useListApproval } from '@cotiss/approval/resources/use-list-approval.resource'
import { TenderTimeRemainingBadge } from '@cotiss/tender/components/tender-time-remaining-badge.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'
import { tenderService } from '@cotiss/tender/tender.service'
import { TenderFlowPlannedApprovalGate } from '@cotiss/tender-flow/components/tender-flow-planned-approval-gate.component'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { useListTenderInvitation } from '@cotiss/tender-invitation/resources/use-list-tender-invitation.resource'
import { TenderResponsePreview } from '@cotiss/tender-response/components/tender-response-preview.component'
import { TenderResponseInviteModal } from '@cotiss/tender-response/modals/tender-response-invite.modal'
import { TenderResponseContactsTab } from '@cotiss/tender-response/tabs/tender-response-contacts.tab'
import { TenderResponseForumTab } from '@cotiss/tender-response/tabs/tender-response-forum.tab'
import { TenderResponseResponsesTab } from '@cotiss/tender-response/tabs/tender-response-responses.tab'
import { TenderUpdateCreateUpdateModal } from '@cotiss/tender-update/modals/tender-update-create-update.modal'

export type TenderFlowActiveTab = 'responses' | 'forum' | 'contacts'

export const TenderFlowActiveStep = memo(() => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { push, replace } = useHistory()
  const { progressTender, regressTender } = useMutateTender()

  const [transition, setTransition] = useState<TransitionType>('right')
  const [activeTenderResponseId, setActiveTenderResponseId] = useState('')
  const { tenderId, step: tenderFlowStep, tab } = useParams<{ tenderId: string; step: TenderFlowStep; tab: TenderFlowActiveTab }>()
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { tenderInvitations, isLoading: isTenderInvitationsLoading } = useListTenderInvitation({ tenderId })
  const isLoading = isTenderLoading || isTenderInvitationsLoading
  const isEditable = tender?.status === 'active'
  const { approvals } = useListApproval({ tenderId })
  const rejectedApprovals = filter(approvals, { approvalType: 'go-to-market', status: 'rejected' })
  const tabs: TabModel<TenderFlowActiveTab>[] = [
    {
      id: 'responses',
      label: 'Responses',
    },
    {
      id: 'forum',
      label: 'Forum',
    },
    {
      id: 'contacts',
      label: `Contacts${tenderInvitations?.length ? ` (${tenderInvitations.length} invited)` : ''}`,
    },
  ]

  const { step, transition: nestedTransition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tab) + 1 })

  const handleProgressProcurement = async () => {
    try {
      await progressTender(tenderId)
      push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: 'review' }))
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleRevokeContract = async () => {
    openModal(
      <ConfirmModal
        heading="Are you sure you would like to revoke this request?"
        description={
          <>
            <Text className="mb-6 text-left" size="sm" variant="light">
              This will allow you to edit the listing and revoke current approvals. Once updated, it can then be re-sent for approval.
            </Text>
          </>
        }
        onSubmit={async () => {
          await regressTender(tenderId)
          push(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, step: 'plan' }))
        }}
      />
    )
  }

  const handleTabChange = (tab: TenderFlowActiveTab) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1

    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    replace(routerService.getHref('/tender-flow/:tenderId/:step?/:tab?', { tenderId, tab, step: tenderFlowStep }))
  }

  const handleSetActiveTenderResponseId = (tenderResponseId: string) => {
    setTransition(tenderResponseId ? 'right' : 'left')

    setTimeout(() => {
      setActiveTenderResponseId(tenderResponseId)
    }, 0)
  }

  const renderPrimaryAction = () => {
    if (!tender) {
      return null
    }

    const handleClick = () => {
      openModal(
        <ConfirmModal
          heading={tenderService.isTenderClosed(tender, false) ? 'Start review' : 'Close listing'}
          description={
            tenderService.isTenderClosed(tender, false)
              ? 'Are you sure you want to start the review process?'
              : 'Are you sure you want to close your procurement listing?'
          }
          onSubmit={handleProgressProcurement}
        />
      )
    }

    if (tenderService.isTenderClosed(tender, false)) {
      return (
        <Button className="ml-2" onClick={handleClick} size="sm" isDisabled={Boolean(tender.validation.validationErrors.length)}>
          Start review
        </Button>
      )
    }

    if (!tenderService.isTenderClosed(tender)) {
      return (
        <Button className="ml-2" onClick={handleClick} isDisabled={Boolean(tender.validation.validationErrors.length)} size="sm">
          Close listing
        </Button>
      )
    }
  }

  if (tender?.status === 'planned-approval' && tender) {
    return (
      <>
        {Boolean(!rejectedApprovals.length) && (
          <Banner className="mb-6" icon="clock" variant="primary">
            <div className="mr-6">
              <Text className="font-semibold" variant="heading">
                Waiting for approval
              </Text>
              <Text size="sm">Once approved you will be able to publish the request to the live market.</Text>
            </div>
            <Button type="submit" variant="secondary" state="text" onClick={handleRevokeContract}>
              Revoke request
            </Button>
          </Banner>
        )}
        <TenderFlowPlannedApprovalGate tenderId={tenderId} />
      </>
    )
  }

  const renderInitial = () => (
    <>
      {isLoading && <BannerSkeleton className="mb-6" hasIcon />}
      {!isLoading && isEditable && (
        <Banner className="mb-6" icon="lightning-01" variant="secondary">
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Invite Suppliers to your Active Listing
            </Text>
            <Text size="sm">Once all tasks are completed you can progress to the next step</Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            <RemainingTasksButton errors={tender.validation.validationErrors} />
            {renderPrimaryAction()}
          </div>
        </Banner>
      )}

      <div className="flex items-center justify-between">
        <div className="flex items-center mr-8">
          <Text className="font-semibold" size="h5" variant="heading">
            Manage responses
          </Text>
          <div className="bg-gray-300 h-4 w-px mx-4" />
          <Text size="sm">All submissions must be finalised by:</Text>
          {isTenderLoading && <Skeleton className="h-4 w-20 ml-2" isRounded />}
          {!isTenderLoading && tender && <TenderTimeRemainingBadge className="ml-2" tender={tender} />}
        </div>
        <div className="flex space-x-2">
          <Button
            onClick={() => {
              handleTabChange('forum')
              openModal(<TenderUpdateCreateUpdateModal tenderId={tenderId} />)
            }}
            size="sm"
            variant="secondary"
            state="translucent"
            isDisabled={!isEditable || isLoading}
          >
            <Icon icon="edit" className="mr-2" />
            Post forum update
          </Button>
          <Button
            onClick={() => openModal(<TenderResponseInviteModal tenderId={tenderId} isEditable={isEditable} />)}
            size="sm"
            variant="secondary"
            isDisabled={!isEditable || isLoading}
          >
            + Invite
          </Button>
        </div>
      </div>
      <>
        <Tabs<TenderFlowActiveTab>
          className="border-b border-gray-300 w-full mt-4 mb-8"
          tab={tab}
          tabs={tabs}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} className="w-full" transition={nestedTransition}>
            {step === 1 && (
              <TenderResponseResponsesTab tenderId={tenderId} isEditable={isEditable} onPreviewTenderResponse={handleSetActiveTenderResponseId} />
            )}
            {step === 2 && <TenderResponseForumTab tenderId={tenderId} />}
            {step === 3 && <TenderResponseContactsTab tenderId={tenderId} isEditable={isEditable} />}
          </TransitionContainer>
        </AnimatePresence>
      </>
    </>
  )

  return (
    <AnimatePresence mode="wait" initial={false}>
      {!activeTenderResponseId && (
        <TransitionContainer key={1} transition={transition}>
          {renderInitial()}
        </TransitionContainer>
      )}
      {activeTenderResponseId && (
        <TransitionContainer key={2} transition={transition}>
          <TenderResponsePreview
            tenderResponseId={activeTenderResponseId}
            onBack={() => handleSetActiveTenderResponseId('')}
            isEditable={tender?.status === 'review' || tender?.status === 'active'}
          />
        </TransitionContainer>
      )}
    </AnimatePresence>
  )
})
