import { memo, useMemo, useState } from 'react'
import { GqlCurrencyCode, GqlPerformanceScorecardMetricFrequency } from '@gql'
import { map, upperFirst } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Banner } from '@cotiss/common/components/banner.component'
import { Breadcrumb, BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { SelectOption_DEPRECATED, Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { CURRENCY_DISPLAY_NAME_MAP } from '@cotiss/common/constants/currency.constants'
import { MONTH_CONFIG, MONTH_OPTIONS, WEEKDAY_OPTIONS, Weekday } from '@cotiss/common/constants/datetime.constants'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { PerformanceScorecardMetricCycleExplanation } from '@cotiss/performance/components/performance-scorecard-metric-cycle-explanation.component'
import { PerformanceScorecardMetricCreateFormData } from '@cotiss/performance/drawers/performance-scorecard-metric-create.drawer'
import { usePerformanceMetric } from '@cotiss/performance/hooks/use-performance-metric.hook'
import { PERFORMANCE_METRIC_FREQUENCY_MONTH_REPEAT_OPTIONS, PERFORMANCE_METRIC_FREQUENCY_OPTIONS } from '@cotiss/performance/performance.constants'
import { PerformanceScorecardMetricNumberInput } from '@cotiss/performance/components/performance-scorecard-metric-number-input.component'

type Props = {
  formData: PerformanceScorecardMetricCreateFormData
  setFormData: (formData: PerformanceScorecardMetricCreateFormData) => void
  onBack: () => void
  isDisabled?: boolean
}

export const PerformanceScorecardMetricCreateConfigureStep = memo(({ formData, setFormData, onBack, isDisabled }: Props) => {
  const { openToast } = useToast()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { performanceMetric, queryPerformanceMetricView } = usePerformanceMetric()

  const frequencyEndOnDayOptions: SelectOption_DEPRECATED[] = useMemo(() => {
    if (formData.frequency === 'annually') {
      return map(Array(MONTH_CONFIG[formData.frequencyEndOnMonth].numberOfDays), (_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
    } else {
      return map(Array(31), (_, index) => ({ label: `${index + 1}`, value: `${index + 1}` }))
    }
  }, [formData.frequency, formData.frequencyEndOnMonth])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      await queryPerformanceMetricView({ performanceMetricId: formData.performanceMetricId })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message)
      setIsError(true)
    }

    setIsLoading(false)
  }, [formData.performanceMetricId])

  const breadcrumbs: BreadcrumbModel[] = [
    {
      label: 'Select metric',
      onClick: onBack,
    },
    {
      label: performanceMetric?.name || '',
      isLoading,
    },
  ]

  if (isError) {
    return (
      <>
        <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
        <ErrorPanel />
      </>
    )
  }

  return (
    <>
      <Breadcrumb className="mb-4" breadcrumbs={breadcrumbs} onBack={onBack} />
      <Banner className="flex items-center justify-between mb-6" variant="light">
        <div className="mr-6">
          <Text className="font-semibold" variant="heading">
            Enter metric requirements
          </Text>
          <Text size="sm">Assign a target value, frequency and start date to the metric.</Text>
        </div>
        <Badge variant="secondary" state="translucent">
          Step 2 of 2
        </Badge>
      </Banner>
      {performanceMetric && (
        <Text size="sm" variant="light">
          {performanceMetric.group}
        </Text>
      )}
      {performanceMetric && (
        <Text className="font-medium mt-2" size="lg">
          {performanceMetric.name}
        </Text>
      )}
      <Hr className="my-4" />
      <Field label="Description" supplementary="Outlines this metric in more detail.">
        {performanceMetric && <Text>{performanceMetric.description}</Text>}
      </Field>
      <Field className="mt-8" label="Methodology" supplementary="Outlines how to gather the value of this metric.">
        {performanceMetric && <Text>{performanceMetric.methodology}</Text>}
      </Field>
      <Field className="mt-8" label="Metric type (unit)">
        {performanceMetric && (
          <div className="flex items-center">
            <Text>
              {upperFirst(performanceMetric.metricUnit)}
              {performanceMetric.metricUnit === 'currency' && ': '}
            </Text>
            {performanceMetric.metricUnit === 'currency' && performanceMetric.metricUnitType && (
              <Text className="ml-1" variant="light">
                {performanceMetric.metricUnitType} ({CURRENCY_DISPLAY_NAME_MAP[performanceMetric.metricUnitType as GqlCurrencyCode]})
              </Text>
            )}
          </div>
        )}
      </Field>
      <Field className="mt-8" label="Target value" supplementary="Set a target value for this metric.">
        {performanceMetric && (
          <PerformanceScorecardMetricNumberInput
            metricUnit={performanceMetric.metricUnit}
            metricUnitType={performanceMetric.metricUnitType}
            onValueChange={({ value }) => setFormData({ ...formData, target: value })}
            value={formData.target}
            isDisabled={isDisabled}
            isRequired
            placeholder="Enter the scorecard target value"
          />
        )}
      </Field>
      <Hr className="my-4" />
      <Field className="mt-8" label="Frequency" supplementary="Set a recurring frequency for this metric.">
        <Select_DEPRECATED<GqlPerformanceScorecardMetricFrequency>
          value={formData.frequency}
          options={PERFORMANCE_METRIC_FREQUENCY_OPTIONS}
          onChange={(frequency) => setFormData({ ...formData, frequency })}
          isDisabled={isDisabled}
          isRequired
        />
      </Field>
      {formData.frequency === 'annually' && (
        <Field className="mt-8" label="Due day" supplementary="Set the day that the metric is due to be collected.">
          <div className="flex items-center">
            <Select_DEPRECATED
              className="mr-4"
              value={formData.frequencyEndOnDay}
              options={frequencyEndOnDayOptions}
              onChange={(frequencyEndOnDay) => setFormData({ ...formData, frequencyEndOnDay })}
              isDisabled={isDisabled}
              isRequired
            />
            <Select_DEPRECATED
              value={formData.frequencyEndOnMonth}
              options={MONTH_OPTIONS}
              onChange={(frequencyEndOnMonth) => setFormData({ ...formData, frequencyEndOnMonth })}
              isDisabled={isDisabled}
              isRequired
            />
          </div>
        </Field>
      )}
      {formData.frequency === 'monthly' && (
        <>
          <Field className="mt-8" label="Repeats every" supplementary="Set cadence for the monthly frequency.">
            <Select_DEPRECATED
              value={formData.frequencyMonthRepeat}
              options={PERFORMANCE_METRIC_FREQUENCY_MONTH_REPEAT_OPTIONS}
              onChange={(frequencyMonthRepeat) => setFormData({ ...formData, frequencyMonthRepeat })}
              isDisabled={isDisabled}
              isRequired
            />
          </Field>
          <Field className="mt-8" label="Due day" supplementary="Set the day that the metric is due to be collected.">
            <Select_DEPRECATED
              value={formData.frequencyEndOnDay}
              options={frequencyEndOnDayOptions}
              onChange={(frequencyEndOnDay) => setFormData({ ...formData, frequencyEndOnDay })}
              isDisabled={isDisabled}
              isRequired
            />
          </Field>
        </>
      )}
      {formData.frequency === 'weekly' && (
        <Field className="mt-8" label="Due day" supplementary="Set the day that the metric is due to be collected.">
          <Select_DEPRECATED<Weekday>
            value={formData.frequencyEndOnWeekday}
            options={WEEKDAY_OPTIONS}
            onChange={(frequencyEndOnWeekday) => setFormData({ ...formData, frequencyEndOnWeekday })}
            isDisabled={isDisabled}
            isRequired
          />
        </Field>
      )}
      <Field className="mt-8" label="Start date" supplementary="Select a date for the first frequency cycle to open.">
        <DatetimeInput
          value={formData.startDate}
          onChange={(startDate) => setFormData({ ...formData, startDate })}
          placeholder="Select start date..."
          dateFormat="do MMM yyyy"
          isDisabled={isDisabled}
          isTimeVisible={false}
          isFuture
          isRequired
        />
      </Field>
      <PerformanceScorecardMetricCycleExplanation className="mt-6" {...formData} />
    </>
  )
})
