import { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { RemirrorJSON } from 'remirror'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { RichTextEditor } from '@cotiss/common/components/rich-text-editor.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { richTextService } from '@cotiss/common/services/rich-text.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { ForumQuestionModel } from '@cotiss/forum/forum.models'
import { useMutateForum } from '@cotiss/forum/resources/use-mutate-forum.resource'

type FormData = {
  content: RemirrorJSON
  isProtected: boolean
  attachments: DocumentModel[]
}

type Props = {
  tenderId?: string
  forumQuestion?: ForumQuestionModel
}

export const ForumAddUpdateQuestionModal = memo(({ tenderId, forumQuestion }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { createForumQuestion, updateForumQuestion } = useMutateForum()
  const [formData, setFormData] = useState<FormData>({
    content: forumQuestion?.content ?? richTextService.getEmptyRemirrorJson(),
    isProtected: forumQuestion?.isProtected ?? false,
    attachments: forumQuestion?.attachments ?? [],
  })
  const { track } = useAnalytics()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!tenderId && !forumQuestion) {
      return
    }

    try {
      setIsSaving(true)

      if (tenderId && !forumQuestion) {
        track('forum_question_create_submit')
        await createForumQuestion({ tenderId, ...formData, attachments: map(formData.attachments, ({ _id }) => _id) })
      }

      if (forumQuestion) {
        track('forum_question_update_submit')
        await updateForumQuestion(forumQuestion._id, { ...formData, attachments: map(formData.attachments, ({ _id }) => _id) })
      }

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader
        heading={`${forumQuestion ? 'Update' : 'Ask a'} question`}
        isDisabled={isSaving}
        supplementary="This question will be posted on the forum"
      />
      <ModalContent isScrollable>
        <RichTextEditor value={formData.content} onChange={(content) => setFormData({ ...formData, content })} isRequired />
        <label className="flex items-center cursor-pointer mt-2" htmlFor="cotiss-forum-question-protected">
          <Checkbox
            id="cotiss-forum-question-protected"
            className="mr-1"
            isChecked={formData.isProtected}
            onChange={() => setFormData({ ...formData, isProtected: !formData.isProtected })}
          />
          <Text>Contains sensitive information</Text>
        </label>
        <Label className="mt-4">Attachments</Label>
        <DocumentUpload documents={formData.attachments} onChange={(attachments) => setFormData({ ...formData, attachments })} />
      </ModalContent>
      <ModalFooter className="flex justify-end" isSaving={isSaving}>
        <Button className="mr-2" onClick={() => closeModal()} state="ghost" variant="link" isDisabled={isSaving}>
          Cancel
        </Button>
        <Button type="submit" variant="secondary" isLoading={isSaving}>
          {forumQuestion ? 'Update' : 'Post'} question
        </Button>
      </ModalFooter>
    </Form>
  )
})
