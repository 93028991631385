import { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { TenderResponseStatus } from '@cotiss/tender-response/tender-response.models'
import { tenderResponseService } from '@cotiss/tender-response/tender-response.service'

type Props = {
  className?: string
  status: TenderResponseStatus
  size?: BadgeSize
}

export const TenderResponseStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={tenderResponseService.getStatusBaseVariant(status)} state="dot">
    {upperFirst(lowerCase(status))}
  </Badge>
))
