import { memo, useMemo } from 'react'
import { find, map, orderBy } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Field } from '@cotiss/common/components/field.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ContractApproverStatusBadge } from '@cotiss/contract/components/contract-approver-status-badge.component'
import { userService } from '@cotiss/user/user.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { COLOUR } from '@cotiss/common/constants/colour.constants'

type Props = {
  contractShellId: string
  contractId: string
  isLoading?: boolean
  approvalId: string | undefined
  size?: 'small' | 'large'
  isCommentVisible?: boolean
}

export const ContractApproversTable = memo(({ contractId, contractShellId, approvalId, size = 'large', isCommentVisible = false }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const approvers = useMemo(() => {
    const contract = find(contractShell?.contracts, (contract) => contract._id === contractId)
    return orderBy(find(contract?.approvals, ({ _id }) => _id === approvalId)?.approvers, 'order')
  }, [contractShell, contractId])

  const approval = useMemo(() => {
    const contract = find(contractShell?.contracts, (contract) => contract._id === contractId)
    return find(contract?.approvals, ({ _id }) => _id === approvalId)
  }, [contractShell, contractId])

  if (isLoading) {
    return (
      <div className="flex items-center h-64">
        <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
      </div>
    )
  }

  if (!approvers.length) {
    return <NoDataPlaceholder label="No approvers selected" />
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Order',
      rows: map(approvers, ({ order }) => ({
        content: () => <Text>{order}</Text>,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Name',
      rows: map(approvers, ({ assigned }) => ({
        tdClassName: 'w-64',
        content: () => (
          <>
            <Text className="w-64 truncate font-medium" title={userService.getFullName(assigned)}>
              {userService.getFullName(assigned)}
            </Text>
            <Text variant="light" size="sm" className="mt-1">
              {assigned.email}
            </Text>
          </>
        ),
      })),
    },
    {
      heading: 'Role',
      rows: map(approvers, ({ role }) => ({
        content: () => (
          <Badge variant="neutral" shape="square" state="translucent">
            {role}
          </Badge>
        ),
      })),
    },
    ...(size === 'large'
      ? [
          {
            heading: 'Date sent',
            rows: map(approvers, ({ sentAt }) => ({
              content: () => <Text variant="light">{sentAt ? datetimeService.format(sentAt, 'do MMM yyyy') : '--'}</Text>,
            })),
          },
          {
            heading: 'Approval status',
            rows: map(approvers, ({ status, state }) => ({
              content: () =>
                state === 'PENDING' ? <Text variant="light">--</Text> : <ContractApproverStatusBadge status={status}></ContractApproverStatusBadge>,
            })),
          },
          {
            heading: 'Date approved',
            rows: map(approvers, ({ actionedAt, status }) => ({
              content: () => (
                <Text variant="light">{actionedAt && status === 'APPROVED' ? datetimeService.format(actionedAt, 'do MMM yyyy') : '--'}</Text>
              ),
            })),
          },
          {
            heading: 'Comments',
            rows: map(approvers, ({ comment }) => ({
              content: () => (
                <Text className="w-60 truncate" variant="light" title={comment}>
                  {comment || '--'}
                </Text>
              ),
            })),
          },
        ]
      : []),
  ]

  return (
    <div>
      {isCommentVisible && (
        <div className="mb-6 py-6">
          <Field label="Requester comment" supplementary="Comments that you would like the approvers to read while they complete their approval">
            <Text className="break-words whitespace-pre-wrap"> {approval?.comment || '--'}</Text>
          </Field>
        </div>
      )}
      <ScrollableTable fixedColumnsWidth={80} fixedColumns={fixedColumns} columns={columns} />{' '}
    </div>
  )
})
