import { FormEvent, memo, useEffect, useState } from 'react'
import { GqlEvaluationEventMethodology } from '@gql'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { RadioCard } from '@cotiss/common/components/radio-card.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EVALUATION_EVENT_METHODOLOGY_OPTIONS } from '@cotiss/evaluation-event/evaluation-event.constants'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type FormData = {
  name?: string
  methodology?: GqlEvaluationEventMethodology | null
}

type Props = {
  onNext: () => void
}

export const EvaluationEventWizardGeneralStep = memo(({ onNext }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent, mutateUpdateEvaluationEvent } = useEvaluationEvent()
  const [formData, setFormData] = useState<FormData>({
    name: evaluationEvent?.name,
    methodology: evaluationEvent?.methodology,
  })
  const [errorText, setErrorText] = useState('')

  useEffect(() => {
    track('evaluation_event_wizard_general_view')
  }, [])

  useEffect(() => {
    setErrorText('')
  }, [formData])

  if (!evaluationEvent) {
    return null
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.name) {
      setErrorText('You must enter a title for this evaluation event.')
      return
    }

    if (!formData.methodology) {
      setErrorText('You must select a methodology.')
      return
    }

    try {
      setIsSaving(true)
      await mutateUpdateEvaluationEvent({ evaluationEventId: evaluationEvent.id, name: formData.name, methodology: formData.methodology })
      onNext()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <Text className="font-semibold" variant="heading" size="h5">
            General
          </Text>
          <div className="flex flex-col items-end">
            <Button className="ml-4" type="submit" variant="secondary" size="sm" isLoading={isSaving}>
              Continue
            </Button>
            {errorText && (
              <Text className="mt-1" size="sm" variant="danger">
                {errorText}
              </Text>
            )}
          </div>
        </CardHeader>
        <Field label="Evaluation title">
          <Input
            value={formData.name}
            onChange={({ target }) => setFormData({ ...formData, name: target.value })}
            placeholder="Enter a unique title for this evaluation event"
            isDisabled={isSaving}
            isRequired
          />
        </Field>
        <Field
          label="Methodology type"
          supplementary="Select the Methodology that best fits with this specific procurement or your organisational policies."
          className="mt-6"
        >
          <div className="grid grid-cols-2 grid-rows-1 gap-4">
            {map(EVALUATION_EVENT_METHODOLOGY_OPTIONS, ({ value, name, description, isDisabled }) => (
              <RadioCard
                key={value}
                className="min-h-[80px] border border-gray-100 p-3.5"
                value={value}
                name="evaluation-methodology"
                onChange={() => setFormData({ ...formData, methodology: value })}
                isDisabled={isDisabled || isSaving}
                isSelected={formData.methodology === value}
              >
                <div className="flex justify-between">
                  <Text className="font-medium">{name}</Text>
                  <Radio className="ml-2" isChecked={formData.methodology === value} />
                </div>
                <Text className="mt-1" size="sm" variant="light">
                  {description}
                </Text>
              </RadioCard>
            ))}
          </div>
        </Field>
      </Card>
    </Form>
  )
})
