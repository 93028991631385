import { memo, useMemo, useState } from 'react'
import { map, reverse } from 'lodash'
import { useParams } from 'react-router-dom'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderFlowStep } from '@cotiss/tender-flow/tender-flow.constants'
import { useListTenderResponse } from '@cotiss/tender-response/resources/use-list-tender-response.resource'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type ReviewTableSortKey = 'name' | 'updatedAt' | 'reviewStatus'

type Props = {
  className?: string
}

export const TenderFlowShortlistStep = memo(({ className }: Props) => {
  const { tenderId } = useParams<{ tenderId: string; step: TenderFlowStep }>()
  const { tender } = useGetTender(tenderId)
  const [currentPage, setCurrentPage] = useState(1)
  const { tenderResponses, isLoading: isTenderResponsesLoading } = useListTenderResponse({ tenderId, status: 'submitted', reviewStatus: 'included' })
  const { exportTenderResponse } = useMutateTenderResponse()
  const [isDownloading, setIsDownloading] = useState(false)
  const { openToast } = useToast()
  const { sortKey, sortDirection, onSort } = useSortTable<ReviewTableSortKey>({ initialKey: 'name' })

  const { processedTenderResponses, pagination } = useMemo(() => {
    const result = tenderResponses.sort((a, b) => {
      if (sortKey === 'name') {
        return sortService.sortString(a.procurementResponse.supplier.name, b.procurementResponse.supplier.name)
      } else if (sortKey === 'updatedAt') {
        return sortService.sortDate(a.updatedAt, b.updatedAt)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : reverse(result)
    const { items: paginatedTenderResponses, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { processedTenderResponses: paginatedTenderResponses, pagination }
  }, [tenderResponses, sortKey, sortDirection, currentPage])

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Supplier',
        onSort: () => onSort('name'),
        rows: map(processedTenderResponses, (tenderResponse) => ({
          content: () => (
            <Text className="truncate" title={tenderResponse.procurementResponse.supplier.name}>
              {tenderResponse.procurementResponse.supplier.name}
            </Text>
          ),
        })),
      },
      {
        heading: 'Date Submitted',
        onSort: () => onSort('updatedAt'),
        rows: map(processedTenderResponses, (tenderResponse) => ({
          content: () => <Text size="sm">{datetimeService.format(tenderResponse.updatedAt, 'do MMM yyyy')}</Text>,
        })),
      },
    ]

    return { columns }
  }, [processedTenderResponses, onSort])

  const handleExport = async () => {
    try {
      setIsDownloading(true)
      const data = await exportTenderResponse({ tenderId })

      if (data.isGenerating) {
        openToast('Generating submissions download. Please check back in a few minutes.', 'success')
      } else if (data.isGenerating === false && data.documentId) {
        window.open(routerService.getHref('/document/view/:id', { id: data.documentId }), '_blank')
      }

      setIsDownloading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsDownloading(false)
    }
  }

  return (
    <div className={className}>
      <Banner className="mb-4" variant="success" icon="check">
        <div>
          <Text className="font-semibold" variant="heading">
            Sourcing event complete! The submissions are ready to be evaluated.
          </Text>
          <Text size="sm" variant="light">
            Download submissions or import them straight into an evaluation
          </Text>
        </div>
      </Banner>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" size="lg">
          Submissions{' '}
          {pagination && (
            <Text className="ml-1" variant="light" size="lg" isInline>
              ({pagination.totalCount})
            </Text>
          )}
        </Text>
        <div className="flex flex-col items-end ml-4">
          <Tooltip
            tooltip="Generating submissions download. Please check back in a few minutes."
            isEnabled={Boolean(tender?.isGeneratingResponsesArchive)}
          >
            <Button
              onClick={handleExport}
              state="translucent"
              variant="secondary-dark"
              size="xs"
              isDisabled={isDownloading || tender?.isGeneratingResponsesArchive}
            >
              <Icon icon="download-01" className="mr-1" /> {tender?.responsesArchive ? 'Download' : 'Generate'} Submissions
            </Button>
          </Tooltip>
        </div>
      </TableHeader>
      <Table columns={columns} isLoading={isTenderResponsesLoading} pagination={pagination} onPageChange={setCurrentPage} />
    </div>
  )
})
