import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { ContractSummaryCessationDetails } from '@cotiss/contract/components/contract-summary-cessation-details.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

export const ContractViewCessationTab = () => {
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const contract = useMemo(() => (contractShell ? contractService.getContract(contractShell) : null), [])

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              Cessation
            </Text>
          </div>
        </CardHeader>
        <Skeleton className="h-8 w-full" />
      </Card>
    )
  }

  if (!contract) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>
          <Text className="font-semibold" size="h5">
            Cessation
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>
      <ContractSummaryCessationDetails contractId={contract?._id} contractShellId={contractShellId} />
    </Card>
  )
}
