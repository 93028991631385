import { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { filter, find, map, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { UserSelect } from '@cotiss/user/components/user-select.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'

export type Props = {
  contractShellId: string
  contractId: string
}

type FormData = {
  userId: string
}

export const ContractOwnerAddModal = memo(({ contractShellId, contractId }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const { track } = useAnalytics()
  const { closeModal, closeFullModal } = useCallout()
  const [formData, setFormData] = useState<FormData>({ userId: '' })
  const { users, isLoading } = useListUser({ permissions: ['CONTRACT_MANAGER'] })
  const { updateContractMetadata } = useMutateContractShell()
  const { contractShell } = useGetContractShell(contractShellId)

  const contract = useMemo(() => find(contractShell?.contracts, ({ _id }) => _id === contractId), [contractShell, contractId])

  const userOptions = useMemo(() => {
    return filter(users, (user) => !some(contract?.metadata.owners, (owner) => owner._id === user._id))
  }, [contract?.metadata.owners, users])

  useEffect(() => {
    track('contract_wizard_metadata_add_contract_owner_view')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formData.userId || !contract?.metadata._id) {
      return
    }

    track('contract_wizard_metadata_add_contract_owner_submit')

    try {
      setIsSaving(true)
      updateContractMetadata(contractShellId, contractId, contract.metadata._id, {
        owners: map(contract.metadata.owners, (owner) => owner._id).concat(formData.userId),
      })

      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast('Whoops, something went wrong. Please try again.', 'danger')
      setIsSaving(false)
    }
  }

  const handleClickOpenSettings = () => {
    push(routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' }))
    closeModal()
    closeFullModal()
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add owner" />
      <ModalContent>
        <Label className="mb-0">User</Label>
        <Text size="sm" variant="light" isInline>
          Users must be added to your organisation in your
          <Button className="ml-1" state="text" variant="link" size="sm" onClick={handleClickOpenSettings} isDisabled={isSaving}>
            Settings.
          </Button>
        </Text>
        <UserSelect
          value={formData.userId}
          options={userOptions}
          onChange={(userId) => setFormData({ ...formData, userId })}
          isDisabled={isSaving || isLoading}
          isRequired
          className="mb-1"
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
