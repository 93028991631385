import { FormEvent, memo, useMemo, useState } from 'react'
import classNames from 'classnames'
import { filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { accessControlService } from '@cotiss/access-control/access-control.service'
import { UserLineItem } from '@cotiss/user/components/user-line-item.component'
import { UserMultiSelect } from '@cotiss/user/components/user-multi-select.component'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { useListAccessControl } from '@cotiss/access-control/resources/use-list-access-control.resource'
import { useMutateAccessControl } from '@cotiss/access-control/resources/use-mutate-access-control.resource'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { Text } from '@cotiss/common/components/text.component'
import { tenderFlowService } from '@cotiss/tender-flow/tender-flow.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'

type FormData = {
  goToMarketApprovers: string[]
  recommendationApprovers: string[]
}

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderPlanApprovalsTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const { users } = useListUser({ permissions: ['APPROVER'] })
  const { accessControls } = useListAccessControl({ resourceId: tenderId })
  const { createAccessControlBulk, removeAccessControlBulk } = useMutateAccessControl()
  const [formData, setFormData] = useState<FormData>({ goToMarketApprovers: [], recommendationApprovers: [] })
  const { tender } = useGetTender(tenderId)
  const isOldEvaluationsEnabled = tenderFlowService.isOldEvaluationsEnabled(tender)
  const { goToMarketApprovers, recommendationApprovers } = useMemo(() => {
    return {
      goToMarketApprovers: filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:approval:go-to-market')),
      recommendationApprovers: filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:approval:recommendation')),
    }
  }, [accessControls])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)

      // Go to market approvers
      const goToMarketApproversToCreate = accessControlService.getCreateAccessControlBulkBody({
        userIdsToAdd: formData.goToMarketApprovers,
        existingAccessControls: goToMarketApprovers,
        roles: ['tender:approval:go-to-market'],
      })
      const goToMarketApproversToRemove = accessControlService.getRemoveAccessControlBulkBody({
        userIdsToAdd: formData.goToMarketApprovers,
        existingAccessControls: goToMarketApprovers,
        role: 'tender:approval:go-to-market',
      })
      goToMarketApproversToCreate && (await createAccessControlBulk(tenderId, 'TENDER', goToMarketApproversToCreate))
      goToMarketApproversToRemove && (await removeAccessControlBulk(tenderId, 'TENDER', goToMarketApproversToRemove))

      // Recommendation approvers
      const recommendationApproversToCreate = accessControlService.getCreateAccessControlBulkBody({
        userIdsToAdd: formData.recommendationApprovers,
        existingAccessControls: recommendationApprovers,
        roles: ['tender:approval:recommendation'],
      })
      const recommendationApproversToRemove = accessControlService.getRemoveAccessControlBulkBody({
        userIdsToAdd: formData.recommendationApprovers,
        existingAccessControls: recommendationApprovers,
        role: 'tender:approval:recommendation',
      })
      recommendationApproversToCreate && (await createAccessControlBulk(tenderId, 'TENDER', recommendationApproversToCreate))
      recommendationApproversToRemove && (await removeAccessControlBulk(tenderId, 'TENDER', recommendationApproversToRemove))

      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleToggleEdit = (isEditing: boolean) => {
    const goToMarketApprovers = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:approval:go-to-market'))
    const recommendationApprovers = filter(accessControls, ({ roles }) => accessControlService.includesRole(roles, 'tender:approval:recommendation'))

    setFormData({
      goToMarketApprovers: map(goToMarketApprovers, ({ user }) => user._id),
      recommendationApprovers: map(recommendationApprovers, ({ user }) => user._id),
    })
    setIsEditing(isEditing)
  }

  const renderToggleLink = (label: string) => (
    <Button onClick={() => handleToggleEdit(true)} state="text" variant="link">
      {label}
    </Button>
  )

  const renderContent = () => {
    if (isEditing) {
      return (
        <>
          <Field className="mt-8" label="Go to market approvers" supplementary="This approval will be required before going live to market.">
            <UserMultiSelect
              value={formData.goToMarketApprovers}
              options={users}
              onChange={(goToMarketApprovers) => setFormData({ ...formData, goToMarketApprovers })}
            />
          </Field>
          {isOldEvaluationsEnabled && (
            <>
              <Hr className="my-4" />
              <Field
                className="mt-8"
                label="Recommendation report approvers"
                supplementary="This approval will be required before submitting your recommendation."
              >
                <UserMultiSelect
                  value={formData.recommendationApprovers}
                  options={users}
                  onChange={(recommendationApprovers) => setFormData({ ...formData, recommendationApprovers })}
                />
              </Field>
            </>
          )}
          <Text className="mt-8" variant="light" size="sm">
            Tip: Adding new users to your account can be done in{' '}
            <Button
              size="sm"
              state="text"
              variant="link"
              href={routerService.getHref('/settings/:tab?/:nestedTab?/:subNestedTab?', { tab: 'organisation', nestedTab: 'account-members' })}
              isLink
            >
              Settings
            </Button>
            . Make sure that the user you are adding has the &ldquo;Approver&ldquo; permission
          </Text>
        </>
      )
    }

    return (
      <>
        <Field label="Go to market approvers" supplementary="This approval will be required before going live to market.">
          {!goToMarketApprovers.length && isEditable && renderToggleLink('+ Add go to market approvers')}
          {map(goToMarketApprovers, ({ _id, user }, index) => (
            <UserLineItem key={_id} className={classNames({ 'mt-4': index })} {...user} />
          ))}
        </Field>
        {isOldEvaluationsEnabled && (
          <>
            <Hr className="my-4" />
            <Field label="Recommendation report approvers" supplementary="This approval will be required before submitting your recommendation.">
              {!recommendationApprovers.length && isEditable && renderToggleLink('+ Add recommendation report approvers')}
              {map(recommendationApprovers, ({ _id, user }, index) => (
                <UserLineItem key={_id} className={classNames({ 'mt-4': index })} {...user} />
              ))}
            </Field>
          </>
        )}
      </>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card className="max-w-5xl">
        <CardFormHeader heading="Approvals" onToggleEdit={handleToggleEdit} isEditable={isEditable} isEditing={isEditing} isSaving={isSaving} />
        {renderContent()}

        {isEditing && (
          <>
            <Hr className="my-4" />
            <div className="flex items-center justify-end">
              <Button className="mr-2" onClick={() => handleToggleEdit(false)} state="ghost" variant="link" size="sm" isDisabled={isSaving}>
                Cancel
              </Button>
              <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
                Save
              </Button>
            </div>
          </>
        )}
      </Card>
    </Form>
  )
})
