import { memo, useMemo } from 'react'
import { some } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { Banner } from '@cotiss/common/components/banner.component'
import { BreadcrumbModel } from '@cotiss/common/components/breadcrumb.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { RemainingTasksButton } from '@cotiss/common/components/remaining-tasks-button.component'
import { Text } from '@cotiss/common/components/text.component'
import { routerService } from '@cotiss/common/services/router.service'
import { EvaluationListTable } from '@cotiss/evaluation/components/evaluation-list-table.component'
import { EvaluationViewHeader } from '@cotiss/evaluation/components/evaluation-view-header.component'
import { useListEvaluation } from '@cotiss/evaluation/resources/use-list-evaluation.resource'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const EvaluationProcurementPage = memo(() => {
  const { push } = useHistory()
  const { user } = useGetLoggedInUser()
  const { tenderId } = useParams<{ tenderId: string }>()
  const { evaluations } = useListEvaluation({ tenderId, userId: user?._id })

  const isEvaluationInProgress = some(evaluations, ({ status }) => status === 'inProgress' || status === 'notStarted')
  const breadcrumbs: BreadcrumbModel[] = useMemo(() => {
    return [
      {
        label: 'Tasks',
        href: routerService.getHref('/task/list/:tab?', { tab: 'evaluation' }),
      },
      {
        label: 'Individual evaluation',
      },
    ]
  }, [])

  return (
    <Page>
      <EvaluationViewHeader tenderId={tenderId} breadcrumbs={breadcrumbs} />
      <PageContent>
        {/* TODO: This banner copy doesn't make sense if completed */}
        <Banner
          className="mb-6"
          icon={isEvaluationInProgress ? 'lightning-01' : 'check-circle'}
          variant={isEvaluationInProgress ? 'secondary' : 'success'}
        >
          <div className="mr-6">
            <Text className="font-semibold" variant="heading">
              Complete your individual evaluation
            </Text>
            <Text size="sm">Score each response that has been submitted</Text>
          </div>
          <div className="flex items-center justify-center shrink-0">
            {isEvaluationInProgress && (
              <RemainingTasksButton
                errors={
                  isEvaluationInProgress
                    ? [
                        {
                          name: 'Individual Evaluation',
                          reason: 'Please mark all your individual evaluations as complete or abstained',
                        },
                      ]
                    : []
                }
              />
            )}
          </div>
        </Banner>
        <EvaluationListTable
          tenderId={tenderId}
          onView={(evaluation) => push(routerService.getHref('/evaluation/view/:id', { id: evaluation._id }))}
        />
      </PageContent>
    </Page>
  )
})
