import { memo, useMemo } from 'react'
import { filter, map, some, uniqBy } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { PreferredSupplierAddFormData } from '@cotiss/preferred-supplier/drawers/preferred-supplier-add.drawer'
import { PreferredSupplierAddUserModal } from '@cotiss/preferred-supplier/modals/preferred-supplier-add-user.modal'
import { PreferredSupplierContactModel } from '@cotiss/preferred-supplier/resources/use-mutate-preferred-supplier.resource'
import { userService } from '@cotiss/user/user.service'

type Props = {
  className?: string
  formData: PreferredSupplierAddFormData
  isDisabled: boolean
  isSaving: boolean
  isTenderInvitation?: boolean
  setFormData: (formData: PreferredSupplierAddFormData) => void
}

export const PreferredSupplierAddUsersTable = memo(({ className, formData, isDisabled, isSaving, isTenderInvitation, setFormData }: Props) => {
  const { openModal } = useCallout()

  const handleAddUserModalSubmit = async (newContactFormData: PreferredSupplierContactModel, oldContact?: PreferredSupplierContactModel) => {
    if (!oldContact && some(formData.contacts, { email: newContactFormData.email })) {
      // This will be caught by the onSubmit in the modal and displayed as a toast
      throw new Error(`${newContactFormData.email} already exists in the list of users`)
    }

    const newContacts = map(formData.contacts, (contact) => {
      if (contact.email === oldContact?.email) {
        return newContactFormData
      }

      return contact
    })

    setFormData({ ...formData, contacts: uniqBy([...newContacts, newContactFormData], 'email') })
  }

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: 'Email',
        rows: map(formData.contacts, (contact) => ({
          content: () => <Text className="truncate">{contact.email}</Text>,
          cta: (
            <TableRowCta
              cta={{
                label: 'Edit',
                onClick: () =>
                  openModal(
                    <PreferredSupplierAddUserModal
                      isTenderInvitation={isTenderInvitation}
                      contact={contact}
                      onSubmit={(formData) => handleAddUserModalSubmit(formData, contact)}
                    />
                  ),
                isDisabled: isDisabled,
              }}
              actions={[
                {
                  label: 'Delete',
                  onClick: () => setFormData({ ...formData, contacts: filter(formData.contacts, (c) => c.email !== contact.email) }),
                },
              ]}
            />
          ),
        })),
      },
      {
        heading: 'Name',
        rows: map(formData.contacts, (contact) => ({
          content: () => <Text>{userService.getFullName(contact) || '--'}</Text>,
        })),
      },
      {
        heading: 'Phone number',
        rows: map(formData.contacts, (contact) => ({
          content: () => <Text>{contact.phoneNumber || '--'}</Text>,
        })),
      },
      ...(!isTenderInvitation
        ? [
            {
              heading: 'Send Cotiss invite',
              rows: map(formData.contacts, (contact) => ({
                content: () => <Text>{contact.sendInvitationEmail ? 'Yes' : 'No'}</Text>,
              })),
            },
          ]
        : []),
    ]

    return { columns }
  }, [formData.contacts])

  return (
    <div className={className}>
      <TableHeader className="flex justify-between items-center space-x-4">
        <div>
          <Text className="font-medium" variant="heading" size="md">
            Add users to this contact{!isTenderInvitation ? ' (optional)' : ''}
          </Text>
          {!isTenderInvitation && (
            <Text className="mt-1" variant="light" size="sm">
              Select whether users receive an invitation to make a Cotiss account
            </Text>
          )}
        </div>
        <Button
          onClick={() => openModal(<PreferredSupplierAddUserModal isTenderInvitation={isTenderInvitation} onSubmit={handleAddUserModalSubmit} />)}
          state="translucent"
          variant="secondary-dark"
          size="xs"
          isDisabled={isDisabled}
        >
          + Add user
        </Button>
      </TableHeader>
      <Table
        columns={columns}
        emptyCta={
          !isDisabled && (
            <Button
              size="sm"
              variant="secondary"
              state="text"
              onClick={() => openModal(<PreferredSupplierAddUserModal isTenderInvitation={isTenderInvitation} onSubmit={handleAddUserModalSubmit} />)}
            >
              + Add user
            </Button>
          )
        }
        isLoading={isSaving}
      />
    </div>
  )
})
