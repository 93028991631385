import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { SettingsOrganisationUploadLogoModal } from '@cotiss/settings/modals/settings-organisation-upload-logo.modal'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export const SettingsOrganisationFormLogo = memo(() => {
  const { user } = useGetLoggedInUser()
  const { openModal } = useCallout()

  return (
    <div className="flex items-start justify-between">
      <div>
        <Text className="font-medium" variant="heading">
          Organisation logo
        </Text>
        <Text className="mt-1" variant="light">
          This will be shown on all posted listings.
        </Text>
        <OrganisationLogo className="mt-2" organisation={user?.account?.organisation} />
      </div>
      <Button onClick={() => openModal(<SettingsOrganisationUploadLogoModal />)} state="translucent" variant="secondary" size="sm">
        <Icon className="mr-2" icon="upload" size={20} />
        Upload image
      </Button>
    </div>
  )
})
