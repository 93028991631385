import { filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Pill } from '@cotiss/common/components/pill.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'

type Props = {
  regions: string[]
  onOpenModal: () => void
  onChange: (regions: string[]) => void
  isSaving?: boolean
}

export const SupplierOnboardingStepRegions = ({ regions, onOpenModal, onChange, isSaving }: Props) => (
  <TransitionContainer transition="right">
    <Text size="h4">Which regions would you prefer to hear about new opportunities from?</Text>
    <Text variant="light">Click the plus icon to make selection</Text>

    <div className="flex justify-between border-b border-gray-300 cursor-pointer p-4" onClick={() => onOpenModal()}>
      <div>
        {map(regions, (region) => (
          <Pill key={region} className="mr-2" onRemove={() => onChange(filter(regions, (r) => r !== region))}>
            {region}
          </Pill>
        ))}
      </div>
      <Button state="ghost" shape="square" isDisabled={isSaving}>
        <Icon icon="circle-add" />
      </Button>
    </div>
  </TransitionContainer>
)
