import { useEffect, useState, memo, ReactNode, RefObject } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'

type Props = {
  // TODO: possibly in the future make HTMLDivElement type dynamic
  readMoreRef: RefObject<HTMLDivElement>
  modalContent: ReactNode
  modalTitle: string
}

export const ButtonReadMore = memo(({ readMoreRef, modalContent, modalTitle }: Props) => {
  const { openModal } = useCallout()
  const [scrollHeight, setScrollHeight] = useState(readMoreRef.current?.scrollHeight || 0)
  const [clientHeight, setClientHeight] = useState(readMoreRef.current?.clientHeight || 0)

  useEffect(() => {
    if (!readMoreRef.current) {
      return
    }

    const observer = new ResizeObserver(([entry]) => {
      setScrollHeight(entry.target.scrollHeight)
      setClientHeight(entry.target.clientHeight)
    })

    observer.observe(readMoreRef.current)

    return () => observer.disconnect()
  }, [])

  if (scrollHeight <= clientHeight) {
    return null
  }

  return (
    <Button
      state="text"
      className="font-medium"
      size="sm"
      variant="secondary"
      onClick={() => {
        openModal(
          <div className="min-w-[450px] max-w-[450px]">
            <ModalHeader heading={modalTitle} />
            <ModalContent isScrollable>
              <Text className="whitespace-pre-wrap">{modalContent}</Text>
            </ModalContent>
          </div>
        )
      }}
    >
      Read more
    </Button>
  )
})
