import { memo, useState } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { ExternalLink } from '@cotiss/common/components/external-link.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { BuyerAddUpdateResourceModal } from '@cotiss/buyer/modals/buyer-add-update-resource.modal'
import { BuyerDeleteResourceConfirmModal } from '@cotiss/buyer/modals/buyer-delete-resource-confirm.modal'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { OrganisationBuyerResourceModel, OrganisationModel } from '@cotiss/organisation/organisation.models'

type Props = {
  className?: string
  organisation: OrganisationModel
  resource: OrganisationBuyerResourceModel
  isEditable?: boolean
}

export const BuyerResourceItem = memo(({ className, organisation, resource, isEditable }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const classes = classNames(className, 'bg-white shadow-lg rounded-lg w-full p-4')

  return (
    <div className={classes}>
      <div className="relative flex items-start justify-between">
        <Text className="font-semibold" size="h7">
          {resource.title}
        </Text>
        {isEditable && (
          <>
            <Button onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
              <Icon icon="dots" variant="light" size={20} />
            </Button>
            <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent
                className="flex items-center"
                onClick={() => openModal(<BuyerAddUpdateResourceModal organisation={organisation} resource={resource} />)}
              >
                Edit
              </DropdownContent>
              <DropdownContent
                className="flex items-center"
                onClick={() => openModal(<BuyerDeleteResourceConfirmModal organisation={organisation} resource={resource} />)}
              >
                Delete
              </DropdownContent>
            </Dropdown>
          </>
        )}
      </div>

      <RichText className="line-clamp-2 mt-2" value={resource.description} />
      {resource.link && (
        <ExternalLink className="mt-2" href={resource.link}>
          {resource.link}
        </ExternalLink>
      )}
      {Boolean(resource.attachments.length) && <DocumentList className="mt-4" documents={resource.attachments} />}
    </div>
  )
})
