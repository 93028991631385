import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { FormHiddenInput } from '@cotiss/common/components/form-hidden-input.component'
import { Label } from '@cotiss/common/components/label.component'
import { RegionMultiSelect } from '@cotiss/common/components/region-multi-select.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { ACCOUNT_EMPLOYEE_NUMBER_RANGE_OPTIONS, ACCOUNT_TENDER_EXPERIENCE_OPTIONS } from '@cotiss/account/account.constants'
import { accountService } from '@cotiss/account/account.service'
import { SignUpBuyerFormData } from '@cotiss/auth/pages/sign-up-buyer.page'

type Props = {
  formData: SignUpBuyerFormData
  transition: TransitionType
  setFormData: (formData: SignUpBuyerFormData) => void
  isDisabled?: boolean
}

export const SignUpBuyerStepAccount = memo(({ formData, transition, setFormData, isDisabled }: Props) => (
  <TransitionContainer transition={transition}>
    <Label>What regions do you operate in?</Label>
    <RegionMultiSelect value={formData.regions} onChange={(regions) => setFormData({ ...formData, regions })} />
    <Label className="mt-6">How many employees do you have?</Label>
    <Select_DEPRECATED
      value={formData.employeeNoRange}
      options={ACCOUNT_EMPLOYEE_NUMBER_RANGE_OPTIONS}
      onChange={(employeeNoRange) => setFormData({ ...formData, employeeNoRange })}
      isDisabled={isDisabled}
      placeholder
      isRequired
    />
    <Label className="mt-6">How often do you tender?</Label>
    <Select_DEPRECATED
      value={formData.experience}
      options={ACCOUNT_TENDER_EXPERIENCE_OPTIONS}
      onChange={(experience) => setFormData({ ...formData, experience })}
      isDisabled={isDisabled}
      placeholder
      isRequired
    />
    <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled}>
      Next
    </Button>

    {/* Hidden for elements, so HubSpot data is captured, and linked to the user signing up */}
    <FormHiddenInput value={formData.email} type="email" />
    <FormHiddenInput value={formData.firstname} name="First name" />
    <FormHiddenInput value={formData.surname} name="Last name" />
    <FormHiddenInput value={formData.phone} name="Phone number" />
    <FormHiddenInput value={formData.companyName} name="Company name" />
    <FormHiddenInput value={accountService.formatLocation(formData.location)} name="Address" />
  </TransitionContainer>
))
