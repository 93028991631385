import { memo } from 'react'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { ApprovalStatus } from '@cotiss/approval/approval.models'

const getVariant = (status: ApprovalStatus): BaseVariant => {
  switch (status) {
    case 'approved':
      return 'success'
    case 'rejected':
      return 'danger'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: ApprovalStatus
  size?: BadgeSize
}

export const TaskStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(lowerCase(status))}
  </Badge>
))
