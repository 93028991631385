import { memo, useState } from 'react'
import { upperFirst, toLower, map } from 'lodash'
import { AvatarGroupSkeleton } from '@cotiss/common/components/avatar-group-skeleton.component'
import { Button } from '@cotiss/common/components/button.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { ExternalLink } from '@cotiss/common/components/external-link.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Switch } from '@cotiss/common/components/switch.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { accountService } from '@cotiss/account/account.service'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { useGetOrganisation } from '@cotiss/organisation/resources/use-get-organisation.resource'
import { useListOrganisationUser } from '@cotiss/organisation/resources/use-list-organisation-user.resource'
import { SupplierUpdateProfileModal } from '@cotiss/supplier/modals/supplier-update-profile.modal'
import { SupplierViewTab } from '@cotiss/supplier/pages/supplier-view.page'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'

type Props = {
  organisationId?: string
  onTabChange: (tab: SupplierViewTab) => void
  setIsEditable?: (isEditable: boolean) => void
  isUserProfile?: boolean
  isEditable?: boolean
}

export const SupplierViewDetails = memo(({ organisationId, onTabChange, setIsEditable, isUserProfile, isEditable }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { organisation, isLoading: isOrganisationLoading } = useGetOrganisation(organisationId)
  const hasExternalLinks = Boolean(organisation?.website || organisation?.linkedin || organisation?.otherLink)
  const { organisationUsers, isLoading: isOrganisationUsersLoading } = useListOrganisationUser({ organisationId })

  return (
    <div className="relative w-1/3 min-w-[300px] mr-8">
      <div className="relative bg-gradient-to-br from-blue-200 bg-secondary-300 rounded-t-xl h-32 p-5">
        {isUserProfile && (
          <label className="absolute top-4 right-4 flex items-center cursor-pointer" htmlFor="#cotiss-supplier-view-public-switch">
            <Switch
              id="#cotiss-supplier-view-public-switch"
              className="mr-1"
              isOn={!isEditable}
              onClick={() => setIsEditable && setIsEditable(!isEditable)}
            />
            <Text>Public view</Text>
          </label>
        )}
      </div>
      <OrganisationLogo className="absolute left-6 top-20 z-1" organisation={organisation} />
      <div className="flex min-h-[calc(100%-128px)]">
        <div className="relative bg-white rounded-b-xl w-full py-16 px-6">
          {isEditable && (
            <>
              <Button className="absolute top-2 right-4" onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
                <Icon icon="dots" variant="light" size={20} />
              </Button>
              <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
                <DropdownContent className="flex items-center" onClick={() => openModal(<SupplierUpdateProfileModal />)}>
                  Edit
                </DropdownContent>
              </Dropdown>
            </>
          )}

          {/* Organisation business number */}
          {isOrganisationLoading && <Skeleton className="h-3 w-1/3" />}
          {!isOrganisationLoading && organisation?.businessNumber && (
            <Text className="mt-2" size="sm" variant="light">
              BN: {organisation?.businessNumber}
            </Text>
          )}

          {/* Organisation name */}
          {isOrganisationLoading && <Skeleton className="h-5 w-2/3 mt-1" />}
          {!isOrganisationLoading && (
            <Text className="font-semibold line-clamp-2" size="h7">
              {upperFirst(toLower(organisation?.name))}
            </Text>
          )}

          {/* Organisation location */}
          {isOrganisationLoading && (
            <>
              <Skeleton className="h-3 w-full mt-2" />
              <Skeleton className="h-3 w-2/3 mt-1" />
            </>
          )}
          {!isOrganisationLoading && organisation?.account?.location && (
            <Text className="mt-2" size="sm" variant="light">
              {accountService.formatLocation(organisation?.account?.location)}
            </Text>
          )}

          {/* Organisation users */}
          {isOrganisationUsersLoading && <AvatarGroupSkeleton className="mt-4" />}
          {!isOrganisationUsersLoading && Boolean(organisationUsers?.length) && <UserAvatarGroup className="mt-4" users={map(organisationUsers)} />}

          {/* Organisation links */}
          {isOrganisationLoading && (
            <>
              <Hr className="my-4" />
              <div className="flex items-center">
                <Skeleton className="w-5 h-5 mr-2" />
                <Skeleton className="h-3 w-1/2" />
              </div>
              <div className="flex items-center mt-2">
                <Skeleton className="w-5 h-5 mr-2" />
                <Skeleton className="h-3 w-1/2" />
              </div>
              <div className="flex items-center mt-2">
                <Skeleton className="w-5 h-5 mr-2" />
                <Skeleton className="h-3 w-1/2" />
              </div>
            </>
          )}
          {!isOrganisationLoading && hasExternalLinks && (
            <>
              <Hr className="my-4" />
              {Boolean(organisation?.website) && (
                <ExternalLink className="break-words" href={organisation?.website} variant="dark" size="sm">
                  <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                    <Icon icon="globe-01" variant="link" size={12} />
                  </div>
                  {organisation?.website}
                </ExternalLink>
              )}
              {Boolean(organisation?.linkedin) && (
                <ExternalLink className="break-words mt-2" href={organisation?.linkedin} variant="dark" size="sm">
                  <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                    <Icon icon="linkedin" variant="link" size={12} />
                  </div>
                  {organisation?.linkedin}
                </ExternalLink>
              )}
              {Boolean(organisation?.otherLink) && (
                <ExternalLink className="break-words mt-2" href={organisation?.otherLink} variant="dark" size="sm">
                  <div className="inline-flex items-center justify-center bg-gray-100 rounded w-5 h-5 mr-2">
                    <Icon icon="link-external-01" variant="link" size={12} />
                  </div>
                  {organisation?.otherLink}
                </ExternalLink>
              )}
            </>
          )}

          {/* Organisation certifications */}
          {isOrganisationLoading && (
            <Skeleton className="rounded-lg p-4 mt-8">
              <Skeleton className="h-2 w-2/3" />
              <Skeleton className="h-2 w-full mt-3" />
              <Skeleton className="h-2 w-2/3 mt-1" />
            </Skeleton>
          )}
          {!isOrganisationLoading && Boolean(organisation?.certifications?.length) && (
            <div className="bg-orange-200 border border-orange-400 rounded-lg p-4 mt-8">
              <Text className="font-semibold" size="sm">
                Certifications
              </Text>

              {map(organisation?.certifications, (certification) => (
                <div key={certification._id} className="mt-3">
                  <Text size="sm">{certification.title}</Text>
                  <Text size="sm" variant="light">
                    {certification.type}
                  </Text>
                </div>
              ))}

              <Button className="mt-4" onClick={() => onTabChange('certifications')} variant="link" size="sm" state="text">
                View all
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
