import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { TransitionType } from '@cotiss/common/hooks/use-transition.hook'
import { SignUpUserFormData } from '@cotiss/auth/pages/sign-up-user.page'

type Props = {
  formData: SignUpUserFormData
  transition: TransitionType
  setFormData: (formData: SignUpUserFormData) => void
  isDisabled?: boolean
  isError?: boolean
}

export const SignUpUserStepAccount = memo(({ formData, transition, setFormData, isDisabled, isError }: Props) => (
  <TransitionContainer transition={transition}>
    <Label>First name</Label>
    <Input
      value={formData.firstname}
      onChange={({ target }) => setFormData({ ...formData, firstname: target.value })}
      name="First name"
      placeholder="Enter your First name"
      maxLength={100}
      isDisabled={isDisabled}
      isRequired
    />
    <Label className="mt-6">Last name</Label>
    <Input
      className="mb-4"
      value={formData.surname}
      onChange={({ target }) => setFormData({ ...formData, surname: target.value })}
      name="Last name"
      placeholder="Enter your last name"
      maxLength={100}
      isDisabled={isDisabled}
      isRequired
    />
    <Label>Email address</Label>
    <Input
      value={formData.email}
      className="mb-4"
      onChange={({ target }) => setFormData({ ...formData, email: target.value })}
      placeholder="Enter your email address"
      type="email"
      maxLength={320}
      autoComplete="username"
      isDisabled={!isError}
      isRequired
    />
    <Label>Contact number (optional)</Label>
    <Input
      value={formData.phone}
      onChange={({ target }) => setFormData({ ...formData, phone: target.value })}
      name="Phone number"
      placeholder="Enter your contact number"
      maxLength={100}
      isDisabled={isDisabled}
    />
    <Button className="mt-6 w-full" type="submit" isDisabled={isDisabled || isError}>
      Next
    </Button>
  </TransitionContainer>
))
