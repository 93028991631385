import { memo } from 'react'
import classNames from 'classnames'
import { lowerCase, upperFirst } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'
import { GroupEvaluationStatus } from '@cotiss/group-evaluation/group-evaluation.models'

const getVariant = (status: GroupEvaluationStatus): BaseVariant => {
  switch (status) {
    case 'notStarted':
      return 'info'
    case 'inProgress':
      return 'warning'
    case 'completed':
      return 'success'
    default:
      return 'warning'
  }
}

type Props = {
  className?: string
  status: GroupEvaluationStatus
  size?: BadgeSize
}

export const GroupEvaluationStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={classNames(className, 'whitespace-nowrap')} size={size} variant={getVariant(status)} state="translucent">
    {upperFirst(lowerCase(status))}
  </Badge>
))
