import { memo, useEffect, useMemo, useState } from 'react'
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js'
// NOTE: This is part of a DEPRECATED and UNUSED flow.
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { map, max, mean } from 'lodash'
import { Bar } from 'react-chartjs-2'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { TenderQuestionScoreModel } from '@cotiss/tender-question/tender-question.models'
import { userService } from '@cotiss/user/user.service'

type Props = {
  className?: string
  tenderQuestionScores: TenderQuestionScoreModel[]
}

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)
ChartJS.register(annotationPlugin)
ChartJS.defaults.font.family = 'Inter, sans-serif'

export const TenderQuestionScoreGraph = memo(({ className, tenderQuestionScores }: Props) => {
  const [xAxisLabels, setXAxisLabels] = useState<string[]>([])
  const [groupScores, setGroupScores] = useState<number[]>([])
  const { maxValue, averageValue } = useMemo(() => ({ maxValue: max(groupScores) || 0, averageValue: mean(groupScores) }), [groupScores])

  useEffect(() => {
    const newXAxisLabels = map(tenderQuestionScores, ({ evaluation: { user } }) => userService.getFullName(user))
    const newGroupScores = map(tenderQuestionScores, ({ groupScore }) => groupScore || 0) || []

    setXAxisLabels(newXAxisLabels)
    setGroupScores(newGroupScores)
  }, [tenderQuestionScores])

  return (
    <Bar
      className={className}
      data={{
        datasets: [
          {
            label: 'Scores',
            data: groupScores,
            backgroundColor: map(groupScores, (_, index) => (index % 2 ? COLOUR.secondary[400] : COLOUR.secondary[500])),
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: false,
          },
          annotation: {
            annotations: {
              average: {
                type: 'line',
                borderColor: COLOUR.blue[600],
                borderDash: [6, 6],
                borderDashOffset: 0,
                borderWidth: 3,
                scaleID: 'y',
                value: () => averageValue,
                label: {
                  display: true,
                  content: () => `Average: ${averageValue}`,
                  position: 'end',
                  backgroundColor: COLOUR.blue[600],
                  color: COLOUR.white,
                },
              },
            },
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
            labels: xAxisLabels,
            ticks: {
              callback(value) {
                if (typeof value === 'number') {
                  const label = this.getLabelForValue(value)

                  return label.length > 10 ? `${label.slice(0, 10)}...` : label
                }
              },
            },
          },
          y: {
            stacked: true,
            min: 0,
            max: maxValue > 5 ? 12 : 6,
            ticks: {
              precision: 0,
              callback(tickValue: string | number) {
                if (typeof tickValue !== 'number') {
                  return tickValue
                }

                return maxValue > 5 ? (tickValue > 10 ? '' : tickValue) : tickValue > 5 ? '' : tickValue
              },
            },
          },
        },
      }}
    />
  )
})
