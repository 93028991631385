import { FormEvent, memo, useMemo, useState } from 'react'
import { parseISO } from 'date-fns'
import { compact, forEach, map, uniq } from 'lodash'
import CreatableSelect from 'react-select/creatable'
import { Badge } from '@cotiss/common/components/badge.component'
import { DatetimeInput } from '@cotiss/common/components/datetime-input.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { METRIC_UNIT_OPTIONS } from '@cotiss/common/constants/units.constants'
import { MetricUnit } from '@cotiss/common/models/units.model'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { OCDS_CURRENCY_MAP } from '@cotiss/common/constants/ocds.constants'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { PriceItemResponsePopulatedModel } from '@cotiss/price-item-response/price-item-response.models'
import { useListPriceItemResponse } from '@cotiss/price-item-response/resources/use-list-price-item-response.resource'
import {
  UpsertPriceItemResponseBulkBodyItem,
  useMutatePriceItemResponse,
} from '@cotiss/price-item-response/resources/use-mutate-price-item-response.resource'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'
// NOTE: This is part of a DEPRECATED and UNUSED flow.

type FormData = {
  category: string
  description: string
  unit?: MetricUnit
  quantity: string
  paymentDate?: string
  unitPrice: string
}

type Props = {
  tenderResponseId: string
  priceItemResponse?: PriceItemResponsePopulatedModel
}

export const PriceItemResponseAddUpdateModal = memo(({ tenderResponseId, priceItemResponse }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { tenderResponse } = useGetTenderResponse(tenderResponseId)
  const { upsertPriceItemResponseBulk } = useMutatePriceItemResponse()
  const { priceItemResponses } = useListPriceItemResponse({ tenderResponseId })

  // ! We have to prioritise the properties off the price item response, as this is set by the supplier. If the supplier hasn't set a value, we then
  // ! have to fall back to the buyer's value that are set on the nested price item.
  const initialQuantityToUse = priceItemResponse?.quantity ?? priceItemResponse?.priceItem?.quantity
  const [formData, setFormData] = useState<FormData>({
    category: priceItemResponse?.category || priceItemResponse?.priceItem?.category || '',
    description: priceItemResponse?.description || priceItemResponse?.priceItem?.description || '',
    quantity: initialQuantityToUse?.toString() ?? '',
    unit: priceItemResponse?.unit || priceItemResponse?.priceItem?.unit,
    unitPrice: priceItemResponse?.unitPrice?.toString() ?? '',
    paymentDate: priceItemResponse?.paymentDate,
  })

  // ! If there is a price item attached to the price item response, then we know it was created as a result of a price item being added to the tender
  // ! by the buyer. If this is the case, the supplier can only update specific properties. I.e. the unit price, payment date, and quantity if the
  // ! buyer allows the supplier to update the quantity via the priceTableAllowQuantityChanges property.
  const isBuyerPriceItem = Boolean(priceItemResponse?.priceItem)

  const categoryOptions = useMemo(() => {
    const categories = uniq(compact(map(priceItemResponses, ({ priceItem, category }) => priceItem?.category || category)))

    return map(categories, (category) => ({ label: category, value: category }))
  }, [priceItemResponses])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!tenderResponse) {
      return
    }

    try {
      setIsSaving(true)
      const items: UpsertPriceItemResponseBulkBodyItem[] = []

      forEach(priceItemResponses, ({ _id, unitPrice, paymentDate, description, category, unit, quantity }, index) => {
        if (priceItemResponse?._id === _id) {
          items.push({
            priceItemResponseId: _id,
            ...formData,
            unit: formData.unit || 'number',
            quantity: Number(formData.quantity),
            unitPrice: Number(formData.unitPrice),
            index,
          })
        } else {
          items.push({
            priceItemResponseId: _id,
            category,
            description,
            quantity,
            unit,
            paymentDate,
            unitPrice,
            index,
          })
        }
      })

      if (!priceItemResponse) {
        items.push({
          ...formData,
          unit: formData.unit || 'number',
          quantity: Number(formData.quantity),
          unitPrice: Number(formData.unitPrice),
          index: priceItemResponses.length,
        })
      }

      // ! We shouldn't have to send the tenderId, because there is a 1:1 mapping between the tender response and the tender.
      await upsertPriceItemResponseBulk({ tenderId: tenderResponse.tender._id, tenderResponseId, items })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add price item" isDisabled={isSaving} />
      <ModalContent>
        <Label>Category</Label>
        <CreatableSelect
          value={formData.category ? { value: formData.category, label: formData.category } : undefined}
          options={categoryOptions}
          onChange={(category) => setFormData({ ...formData, category: category?.value || '' })}
          components={{ IndicatorSeparator: null }}
          noOptionsMessage={() => 'Type to create category...'}
          placeholder="Add category"
          isDisabled={isBuyerPriceItem || isSaving}
          isMulti={false}
          required
          isClearable
        />
        <Label className="mt-4">Description</Label>
        <Input
          value={formData.description}
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          placeholder="Description"
          isDisabled={isBuyerPriceItem || isSaving}
          isRequired
        />
        <div className="flex items-start justify-between mt-4">
          <div className="mr-4">
            <Label>Quantity</Label>
            <Input
              value={formData.quantity}
              onChange={({ target }) => setFormData({ ...formData, quantity: target.value })}
              placeholder="Quantity"
              type="number"
              isDisabled={(isBuyerPriceItem && !tenderResponse?.tender.priceTableAllowQuantityChanges) || isSaving}
              isRequired
            />
          </div>
          <div>
            <Label>Unit</Label>
            <Select_DEPRECATED
              value={formData.unit}
              options={METRIC_UNIT_OPTIONS}
              onChange={(unit) => setFormData({ ...formData, unit })}
              isDisabled={isBuyerPriceItem || isSaving}
              placeholder
              isRequired
            />
          </div>
        </div>
        {tenderResponse?.tender.priceTableRequirePaymentDate && (
          <>
            <Label className="mt-4">Payment date</Label>
            <DatetimeInput
              value={formData.paymentDate ? parseISO(formData.paymentDate) : null}
              onChange={(paymentDate) => setFormData({ ...formData, paymentDate: paymentDate?.toISOString() })}
              isDisabled={isSaving}
              placeholder="--"
              isFuture
              isRequired
            />
          </>
        )}
        <Label className="mt-4">Unit price</Label>
        <div className="relative">
          {tenderResponse && (
            <Badge
              className="absolute top-1/2 left-4 -translate-y-1/2"
              state="translucent"
              shape="rect"
              variant="neutral"
              title={tenderResponse.tender.currency ? OCDS_CURRENCY_MAP[tenderResponse.tender.currency] : ''}
            >
              {tenderResponse.tender.currency}
            </Badge>
          )}
          <Input
            className={tenderResponse?.tender.currency ? 'pl-20' : undefined}
            value={formData.unitPrice}
            onChange={({ target }) => setFormData({ ...formData, unitPrice: target.value })}
            type="number"
            step=".01"
            isDisabled={isSaving}
            isRequired
            placeholder="--"
          />
        </div>
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
