import { memo, useState } from 'react'
import { intervalToDuration, parseISO } from 'date-fns'
import { map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { ExternalLink } from '@cotiss/common/components/external-link.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { DocumentLink } from '@cotiss/document/components/document-link.component'
import { OrganisationJobModel, OrganisationModel } from '@cotiss/organisation/organisation.models'
import { SupplierAddUpdateJobModal } from '@cotiss/supplier/modals/supplier-add-update-job.modal'
import { SupplierDeleteJobConfirmModal } from '@cotiss/supplier/modals/supplier-delete-job-confirm.modal'

type Props = {
  className?: string
  organisation: OrganisationModel
  job: OrganisationJobModel
  isEditable?: boolean
}

export const SupplierViewJobItem = memo(({ className, organisation, job, isEditable }: Props) => {
  const { openModal } = useCallout()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const renderDates = () => {
    const { years, months } = intervalToDuration({ start: parseISO(job.startDate), end: parseISO(job.endDate) })
    const displayDates = `${datetimeService.format(job.startDate, 'MMM yyyy')} – ${datetimeService.format(job.endDate, 'MMM yyyy')}`
    const displayInterval = `${years ? `${years} yrs ` : ''}${months ? ` ${months} mos` : ''}`

    return `${displayDates}${displayInterval && ` • ${displayInterval}`}`
  }

  return (
    <div className={className}>
      <div className="relative flex items-center justify-between">
        <Text className="font-medium mr-2">{job.title}</Text>
        {isEditable && (
          <>
            <Button onClick={() => setIsDropdownOpen(true)} shape="square" state="ghost">
              <Icon icon="dots" variant="light" size={20} />
            </Button>
            <Dropdown className="top-8 right-4" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
              <DropdownContent
                className="flex items-center"
                onClick={() => openModal(<SupplierAddUpdateJobModal organisation={organisation} job={job} />)}
              >
                Edit
              </DropdownContent>
              <DropdownContent
                className="flex items-center"
                onClick={() => openModal(<SupplierDeleteJobConfirmModal organisation={organisation} job={job} />)}
              >
                Delete
              </DropdownContent>
            </Dropdown>
          </>
        )}
      </div>
      <Text>{job.description}</Text>
      <Text className="mt-1" variant="light" size="sm">
        {renderDates()}
      </Text>
      {job.link && (
        <ExternalLink className="mt-1" href={job.link} size="sm">
          {job.link}
        </ExternalLink>
      )}
      {Boolean(job.attachments.length) && (
        <>
          <Text className="font-medium mt-2" size="sm">
            Documents
          </Text>
          {map(job.attachments, (attachment) => (
            <DocumentLink key={attachment._id} className="mt-2" document={attachment} />
          ))}
        </>
      )}
    </div>
  )
})
