import { memo, useState, useMemo, useEffect } from 'react'
import { find } from 'lodash'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ContractModel } from '@cotiss/contract/contract.model'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  contractShellId: string
}

export const ContractWizardStepDocumentUploadModal = memo(({ contractShellId }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { createContractDocumentShell, uploadContractDocumentShellAttachment } = useMutateContractShell()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { track } = useAnalytics()

  const [isSaving, setIsSaving] = useState(false)
  const contract = useMemo(() => find(contractShell?.contracts, { status: 'DRAFTING' }), [contractShell])

  useEffect(() => {
    if (isLoading) {
      return
    }

    if (!user?.account?.organisation || !contract) {
      sentryService.captureException({ exception: 'Failed to load contract wizard upload document modal.', extras: { contractShellId } })
      openToast(<>Couldn&apos;t load {contract ? 'user information' : 'contract'}. Please try again.</>, 'danger')
      closeModal()
    }
  }, [isLoading, user, contract])

  const handleUpload = async (file: File) => {
    if (!contract) {
      return
    }

    try {
      setIsSaving(true)

      let _contract: ContractModel = contract

      if (!find(contract?.documentShells, { type: 'CONTRACT' })) {
        const updatedContract = find(
          (await createContractDocumentShell(contractShellId, contract?._id, { name: 'Unnamed document', type: 'CONTRACT' })).contracts,
          { status: 'DRAFTING' }
        ) as ContractModel // Can assume if this request succeeded, the contract exists

        _contract = updatedContract
      }

      const documentShell = find(_contract?.documentShells, { type: 'CONTRACT' })

      if (!documentShell) {
        setIsSaving(false)
        return
      }

      await uploadContractDocumentShellAttachment(contractShellId, _contract?._id, documentShell._id, file)

      setIsSaving(false)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <div className="min-w-[450px] max-w-[450px]">
      <ModalHeader heading="Upload contract" isDisabled={isSaving} />
      <ModalContent>
        <DocumentUpload
          maxMb={24}
          onUpload={handleUpload}
          className="my-6"
          isDisabled={isLoading}
          isSingle
          onRejectedSupportedError={(errorCode) => errorCode === 'file-too-large' && track('contract_wizard_contract_update_error_file_too_large')}
        />
      </ModalContent>
    </div>
  )
})
