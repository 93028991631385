import { FormEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { authService } from '@cotiss/auth/auth.service'
import { useMutateUser } from '@cotiss/user/resources/use-mutate-user.resource'

export const SettingsGeneralFormPassword = memo(() => {
  const { openToast } = useToast()
  const { updateUserPassword } = useMutateUser()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [formError, setFormError] = useState('')
  const [formData, setFormData] = useState({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
  const isError = Boolean(formError)

  const handleReset = () => {
    setFormData({ currentPassword: '', newPassword: '', confirmNewPassword: '' })
    setIsEditing(false)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    setFormError('')

    if (formData.newPassword !== formData.confirmNewPassword) {
      setFormError('Passwords do not match')
      return
    }

    if (!authService.isPasswordValid(formData.newPassword)) {
      setFormError('Password must be at least 8 characters long and contain at least one letter and one number')
      return
    }

    try {
      setIsSaving(true)
      const { currentPassword, newPassword } = formData
      await updateUserPassword({ currentPassword, newPassword })
      setIsSaving(false)
      setIsEditing(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  if (isEditing) {
    return (
      <Form onSubmit={handleSubmit}>
        <Label>Old password</Label>
        <Input
          className="mb-4"
          value={formData.currentPassword}
          onChange={({ target }) => setFormData({ ...formData, currentPassword: target.value })}
          type="password"
          placeholder="Enter old password"
          autoComplete="current-password"
          isDisabled={isSaving}
          isRequired
        />
        <Label>New password</Label>
        <Input
          className="mb-4"
          value={formData.newPassword}
          onChange={({ target }) => setFormData({ ...formData, newPassword: target.value })}
          type="password"
          placeholder="Enter new password"
          autoComplete="new-password"
          isDisabled={isSaving}
          isError={isError}
          isRequired
        />
        <Label>Confirm new password</Label>
        <div className="mb-4">
          <Input
            value={formData.confirmNewPassword}
            onChange={({ target }) => setFormData({ ...formData, confirmNewPassword: target.value })}
            type="password"
            placeholder="Confirm new password"
            autoComplete="new-password"
            isDisabled={isSaving}
            isError={isError}
            isRequired
          />
          {isError && (
            <Text className="mt-1" variant="danger" size="sm">
              {formError}
            </Text>
          )}
        </div>

        <div className="flex items-center justify-end">
          <Button className="mr-2" onClick={handleReset} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" size="sm" isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Form>
    )
  }

  return (
    <div className="flex items-center justify-between">
      <div>
        <Text className="font-medium">Password</Text>
        <Text>********</Text>
      </div>

      <Button onClick={() => setIsEditing(true)} variant="secondary" state="translucent" size="sm">
        Update
      </Button>
    </div>
  )
})
