import { memo } from 'react'
import { GqlEvaluationEnvelopeSubmissionStatus } from '@gql'
import { upperFirst, lowerCase } from 'lodash'
import { Badge, BadgeSize } from '@cotiss/common/components/badge.component'
import { BaseVariant } from '@cotiss/common/models/common.models'

const getVariant = (status?: GqlEvaluationEnvelopeSubmissionStatus | null): BaseVariant => {
  switch (status) {
    case 'reviewed':
      return 'success'
    default:
      return 'neutral'
  }
}

type Props = {
  className?: string
  status?: GqlEvaluationEnvelopeSubmissionStatus | null
  size?: BadgeSize
}

export const EvaluationEventEnvelopeSubmissionStatusBadge = memo(({ className, status, size = 'sm' }: Props) => (
  <Badge className={className} size={size} variant={getVariant(status)} state="translucent">
    {status ? upperFirst(lowerCase(status)) : 'Not started'}
  </Badge>
))
