import { ReactNode, memo } from 'react'
import classNames from 'classnames'

type Props = {
  className?: string
  children: ReactNode
  variant?: 'default' | 'white'
  hasTable?: boolean
}

export const TableHeader = memo(({ className, children, variant = 'default', hasTable = true }: Props) => {
  const classes = classNames(className, 'border border-grey-200 rounded-t-md px-4 py-[10px] w-full', {
    'bg-primary-50': variant === 'default',
    'bg-white': variant === 'white',
    'border-b-0': hasTable,
    'rounded-b-md': !hasTable,
  })

  return <div className={classes}>{children}</div>
})
