import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { ContractApprovalViewPage } from '@cotiss/contract/pages/contract-approval-view.page'
import { ContractApprovalPage } from '@cotiss/contract/pages/contract-approval.page'
import { ContractListPage } from '@cotiss/contract/pages/contract-list.page'
import { ContractViewPage } from '@cotiss/contract/pages/contract-view.page'

const ContractLayout = memo(() => {
  return (
    <Switch>
      <Route path="/contract/list" exact>
        <ContractListPage />
      </Route>
      <Route path="/contract/list/:tab?" exact>
        <ContractListPage />
      </Route>
      <Route path="/contract/view/:contractShellId/:tab?" exact>
        <ContractViewPage />
      </Route>
      <Route path="/contract/approval/:contractShellId/:contractId/:approvalId?" exact>
        <ContractApprovalPage />
      </Route>
      <Route path="/contract/approval/:contractShellId/:contractId/:approvalId?/view/:tab?" exact>
        <ContractApprovalViewPage />
      </Route>
      <Redirect from="/contract" to="/contract/list" />
    </Switch>
  )
})

export default ContractLayout
