import { memo, useMemo, useState } from 'react'
import { map } from 'lodash'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { paginationService } from '@cotiss/common/services/pagination.service'
import { sortService } from '@cotiss/common/services/sort.service'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { TenderResponsePriceScoreConsensusStatusBadge } from '@cotiss/tender-response/components/tender-response-price-score-consensus-status-badge.component'
import { TenderResponseReportPriceListCta } from '@cotiss/tender-response/components/tender-response-report-price-list-cta.component'
import { useListTenderResponseReport } from '@cotiss/tender-response/resources/use-list-tender-response-report.resource'
import { TenderResponseReportModel } from '@cotiss/tender-response/tender-response.models'

type EvaluationChairPriceSortKey = 'name' | 'progress' | 'avg-score' | 'price-score' | 'total-score' | 'price'

type Props = {
  className?: string
  tenderId: string
  onView?: (tenderResponseReport: TenderResponseReportModel) => void
}

export const TenderResponseReportPriceList = memo(({ tenderId, onView }: Props) => {
  const { tenderResponseReports, isLoading: isTenderResponseReportsLoading } = useListTenderResponseReport(tenderId)
  const { sortKey, sortDirection, onSort } = useSortTable<EvaluationChairPriceSortKey>({ initialKey: 'name' })
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const [currentPage, setCurrentPage] = useState(1)
  const isLoading = isTenderResponseReportsLoading || isTenderLoading

  const { sortedTenderResponseReports, pagination } = useMemo(() => {
    const result = tenderResponseReports.sort((a, b) => {
      if (sortKey === 'name') {
        return sortService.sortString(a.procurementResponse.supplier.name, b.procurementResponse.supplier.name)
      } else if (sortKey === 'progress') {
        return sortService.sortNumber(
          a.consensusScoredQuestions / a.consensusUnscoredQuestions,
          b.consensusScoredQuestions / b.consensusUnscoredQuestions
        )
      } else if (sortKey === 'avg-score') {
        return sortService.sortNumber(a.avgScore, b.avgScore)
      } else if (sortKey === 'price-score') {
        return sortService.sortNumber(a.priceScore || 0, b.priceScore || 0)
      } else if (sortKey === 'total-score') {
        return sortService.sortNumber(a.totalScore || 0, b.totalScore || 0)
      }

      return 0
    })

    const sortedResult = sortDirection === 'asc' ? result : result.reverse()
    const { items: sortedTenderResponseReports, pagination } = paginationService.paginate(sortedResult, { currentPage })

    return { sortedTenderResponseReports, pagination }
  }, [tenderResponseReports, sortKey, sortDirection])

  if (!isLoading && !sortedTenderResponseReports.length) {
    return (
      <div className="flex items-center justify-center h-[calc(100%-64px)]">
        <NoDataPlaceholder label="Something wrong has occurred, please contract support for assistance." />
      </div>
    )
  }

  const fixedColumns: ScrollableTableColumn[] = [
    {
      heading: 'Supplier',
      onSort: () => onSort('name'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => <Text className="truncate">{tenderResponseReport.procurementResponse.supplier.name}</Text>,
        cta: <TenderResponseReportPriceListCta tenderResponseReport={tenderResponseReport} onView={onView} />,
      })),
    },
  ]

  const columns: ScrollableTableColumn[] = [
    {
      heading: 'Status',
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () =>
          tenderResponseReport.priceScoreConsensusStatus ? (
            <TenderResponsePriceScoreConsensusStatusBadge status={tenderResponseReport.priceScoreConsensusStatus} />
          ) : (
            '-'
          ),
      })),
    },
    {
      heading: 'Consensus score',
      onSort: () => onSort('avg-score'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => (
          <Text variant="light" size="sm">
            {tenderResponseReport.groupScore.toFixed(2)}
          </Text>
        ),
      })),
    },
  ]

  if (tender?.isPriceWeighted) {
    columns.push({
      heading: 'Price score',
      onSort: () => onSort('price-score'),
      rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
        content: () => (
          <Text variant="light" size="sm">
            {tenderResponseReport.priceScore?.toFixed(2) || '--'}
          </Text>
        ),
      })),
    })
  }

  columns.push({
    heading: 'Total score',
    onSort: () => onSort('total-score'),
    rows: map(sortedTenderResponseReports, (tenderResponseReport) => ({
      content: () => (
        <Text variant="light" size="sm">
          {tenderResponseReport.totalScore?.toFixed(2) || '--'}
        </Text>
      ),
    })),
  })

  return <ScrollableTable fixedColumns={fixedColumns} columns={columns} pagination={pagination} onPageChange={setCurrentPage} isLoading={isLoading} />
})
