import { memo, useMemo } from 'react'
import { filter, find, forEach, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { UserAvatarGroup } from '@cotiss/user/components/user-avatar-group.component'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'
import { metafieldValueService } from '@cotiss/metafield-value/metafield-value.service'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { ContractVariationStatusBadge } from '@cotiss/contract/components/contract-variation-status-badge.component'
import { contractService } from '@cotiss/contract/contract.service'
import { Button } from '@cotiss/common/components/button.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { utilService } from '@cotiss/common/services/util.service'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { ContractShellFilterPopulatedModel } from '@cotiss/contract/contract.model'
import { ContractCreateDrawer } from '@cotiss/contract/drawers/contract-create.drawer'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import {
  CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB,
  CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS,
  ContractSearchAndFiltersPrimaryTabKeys,
} from '@cotiss/contract/contract.constants'
import { filterService } from '@cotiss/common/services/filter.service'
import { useParams } from 'react-router-dom'
import { ContractSearchInput } from '@cotiss/contract/components/contract-search-input.component'
import { VerticalDivider } from '@cotiss/common/components/vertical-divider.component'
import { AdvancedFiltersDropdownButton } from '@cotiss/common/modals/advanced-filters/advanced-filters-dropdown-button.component'
import { ContractSearchAndFiltersProvider, useContractSearchAndFilters } from '@cotiss/contract/components/contract-search-and-filters'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'

type Props = {
  contractShellId: string
  selectedContractShell?: ContractShellFilterPopulatedModel
  onContractShellChange: (contractShell: ContractShellFilterPopulatedModel) => void
  isDisabled?: boolean
}

export const ContractLinkContractStepInternal = memo(({ contractShellId, selectedContractShell, onContractShellChange, isDisabled }: Props) => {
  const { user } = useGetLoggedInUser()
  const { openDrawer } = useCallout()
  const { track } = useAnalytics()
  const isContractManagementListViewMetafieldsEnabled = useFeature('contract-management-list-view-metafields')

  const { isQuerying, contractShells, pagination, isLoadingContractShells, handlePageChange, onSort } = useContractSearchAndFilters()

  const { metafields, isFetching: isLoadingMetafields } = useListMetafield({
    entityType: 'CONTRACT',
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const { metafieldValues, isFetching: isLoadingMetafieldValues } = useListMetafieldValue({
    resourceIds: map(contractShells, (contractShell) => contractShell.contracts._id),
    isEnabled: isContractManagementListViewMetafieldsEnabled,
  })
  const isLoading = isLoadingContractShells || isLoadingMetafields || isLoadingMetafieldValues

  const { fixedColumns, columns } = useMemo(() => {
    // Don't allow user to link contract shell to itself.
    const filteredContractShell = filter(contractShells, ({ _id }) => _id !== contractShellId)

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(filteredContractShell, (contractShell) => ({
          content: () => (
            <Radio
              value={contractShell._id}
              name="contract-link-contract"
              onChange={() => onContractShellChange(contractShell)}
              isChecked={contractShell._id === selectedContractShell?._id}
              isDisabled={isDisabled}
              isRequired
            />
          ),
        })),
      },
      {
        heading: 'Title',
        onSort: () => onSort('title'),
        rows: map(filteredContractShell, ({ title }) => ({
          content: () => (
            <Text className="truncate" title={title}>
              {title}
            </Text>
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Contract status',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => <ContractStatusBadge status={contracts?.status} />,
        })),
      },
      {
        heading: 'Owners',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => <UserAvatarGroup users={contracts.metadata.owners} />,
        })),
      },
      {
        heading: 'Variation status',
        rows: map(filteredContractShell, ({ variationStatus }) => ({
          content: () => {
            return <ContractVariationStatusBadge status={variationStatus} />
          },
        })),
      },
      {
        heading: 'Total exercised',
        onSort: () => onSort('totalExercised'),
        rows: map(
          filteredContractShell,
          ({
            totalExercised,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalExercised, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Total value',
        onSort: () => onSort('totalValue'),
        rows: map(
          filteredContractShell,
          ({
            totalValue,
            contracts: {
              metadata: { currency },
            },
          }) => ({
            content: () => (
              <Text variant="light" className="truncate" size="sm">
                {currency && `${utilService.formatAsCurrency(totalValue, currency)}`}
              </Text>
            ),
            tdClassName: 'max-w-[350px]',
          })
        ),
      },
      {
        heading: 'Next expiry',
        rows: map(filteredContractShell, ({ contracts: { priceDurations } }) => ({
          content: () => {
            const nextExpiry = contractService.getNextExpirationDate(priceDurations)
            return (
              <Text size="sm" variant="light">
                {nextExpiry ? datetimeService.format(nextExpiry, 'do MMM yyyy') : '--'}
              </Text>
            )
          },
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Procurement',
        onSort: () => onSort('procurementTitle'),
        rows: map(filteredContractShell, ({ procurement }) => ({
          content: () => (
            <Text variant="light" className="truncate" size="sm">
              {procurement?.title || '--'}
            </Text>
          ),
          tdClassName: 'max-w-[350px]',
        })),
      },
      {
        heading: 'Date created',
        onSort: () => onSort('createdAt'),
        rows: map(filteredContractShell, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contract ID',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.internalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Internal reference',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.externalReference || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Counterparties',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.suppliers.map((counterparty) => counterparty.name).join(', ') || '--'}
            </Text>
          ),
        })),
      },
      {
        heading: 'Contracting entity',
        rows: map(filteredContractShell, ({ contracts }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {contracts.metadata.contractingEntity?.name || '--'}
            </Text>
          ),
        })),
      },
    ]

    // Add metafields columns
    if (isContractManagementListViewMetafieldsEnabled) {
      forEach(metafields, (metafield) => {
        columns.push({
          heading: metafield.fieldLabel,
          rows: map(filteredContractShell, ({ contracts: { _id: contractId } }) => ({
            tdClassName: 'max-w-[350px]',
            content: () => {
              const metafieldValue = find(metafieldValues, { metafield: metafield._id, resourceId: contractId })
              const processedMetafieldValue = metafieldValueService.renderFieldValue({ metafield, metafieldValue })

              if (metafield.fieldType === 'HYPERLINK' && metafieldValue) {
                return (
                  <Button isExternalLink isTruncated href={processedMetafieldValue} variant="secondary" state="text">
                    {processedMetafieldValue}
                  </Button>
                )
              }

              return (
                <Text className="whitespace-pre-wrap line-clamp-3" size="sm" variant="light" title={processedMetafieldValue}>
                  {processedMetafieldValue}
                </Text>
              )
            },
          })),
        })
      })
    }

    return { fixedColumns, columns }
  }, [selectedContractShell, contractShellId, contractShells, user, metafields, metafieldValues, isContractManagementListViewMetafieldsEnabled])

  const emptyState = isQuerying ? (
    <Text size="md" variant="light">
      No results
    </Text>
  ) : (
    <NoDataPlaceholder
      illustration="dot-list"
      variant="transparent"
      label="You haven't created any contracts yet. Once created, you can track your contracts here"
      ctaLabel="+ Create contract"
      onCtaClick={() => openDrawer(<ContractCreateDrawer />)}
    />
  )

  return (
    <>
      <TableHeader className="flex items-center justify-between">
        <Text className="font-semibold">Select the contract.</Text>
        <Badge variant="secondary" state="translucent">
          Step 1 of 2
        </Badge>
      </TableHeader>
      <div className="w-full h-13 px-4 border-t flex items-center justify-between gap-4 border-l border-r">
        <ContractSearchInput className="flex-1" />
        <VerticalDivider className="h-7" />
        <AdvancedFiltersDropdownButton title="Filter contracts" onOpen={() => track('contract_list_filters_dropdown_open')} />
      </div>
      {!isLoadingContractShells && !contractShells.length ? (
        <div className="flex items-center justify-center h-60 bg-white border">{emptyState}</div>
      ) : (
        <ScrollableTable
          fixedColumns={fixedColumns}
          columns={columns}
          pagination={pagination}
          onPageChange={handlePageChange}
          isLoading={isLoading}
        />
      )}
    </>
  )
})

export const ContractLinkContractStep = memo(({ contractShellId, selectedContractShell, onContractShellChange, isDisabled }: Props) => {
  const { tab: initialPrimaryTab } = useParams<{ tab?: ContractSearchAndFiltersPrimaryTabKeys }>()
  const { q: initialSearchQuery } = utilService.getUrlSearchParams({ params: ['q'] })
  const initialAdvancedFilters = filterService.getFiltersFromUrl()

  const initialState = {
    searchQuery: initialSearchQuery,
    advancedFilters: initialAdvancedFilters,
    primaryTab: initialPrimaryTab ?? CONTRACT_SEARCH_AND_FILTERS_DEFAULT_PRIMARY_TAB,
    primaryTabFilters: CONTRACT_SEARCH_AND_FILTERS_PRIMARY_TAB_FILTERS,
  }

  return (
    <ContractSearchAndFiltersProvider initialState={initialState}>
      <ContractLinkContractStepInternal
        contractShellId={contractShellId}
        selectedContractShell={selectedContractShell}
        onContractShellChange={onContractShellChange}
        isDisabled={isDisabled}
      />
    </ContractSearchAndFiltersProvider>
  )
})
