import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { apolloService } from '@cotiss/common/services/apollo.service'
import { localStorageService } from '@cotiss/common/services/local-storage.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { clearReactiveApprovalTemplate } from '@cotiss/approval-template/hooks/use-approval-template.hook'
import { clearReactiveApprovalTemplateGroup } from '@cotiss/approval-template-group/hooks/use-approval-template-group.hook'
import { JwtDataModel } from '@cotiss/auth/auth.models'
import { SessionResponse, SessionType, authResource } from '@cotiss/auth/auth.resource'
import { authService } from '@cotiss/auth/auth.service'
import { clearReactiveEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { clearReactiveEvaluationEnvelopeDocument } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope-document.hook'
import { clearReactiveEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { clearReactiveEvaluationEventDocument } from '@cotiss/evaluation-event/hooks/use-evaluation-event-document.hook'
import { clearReactiveEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { clearReactiveEvaluationSubmissionDocument } from '@cotiss/evaluation-event/hooks/use-evaluation-submission-document.hook'
import { clearReactiveEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { clearReactiveEvaluation } from '@cotiss/evaluation-event/hooks/use-evaluation.hook'
import { clearReactivePerformanceMetric } from '@cotiss/performance/hooks/use-performance-metric.hook'
import { clearReactivePerformanceScorecard } from '@cotiss/performance/hooks/use-performance-scorecard.hook'
import { clearReactivePerformanceScorecardDocument } from '@cotiss/performance/hooks/use-performance-scorecard-document.hook'
import { clearReactivePerformanceScorecardMetric } from '@cotiss/performance/hooks/use-performance-scorecard-metric.hook'
import { clearReactivePerformanceScorecardMetricCycle } from '@cotiss/performance/hooks/use-performance-scorecard-metric-cycle.hook'
import { clearReactivePerformanceScorecardMetricUser } from '@cotiss/performance/hooks/use-performance-scorecard-metric-user.hook'
import { clearReactivePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { clearReactiveEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

export type AuthContextType = {
  isAuthenticated: boolean
  isAuthenticating: boolean
  accessToken: string | null
  refreshToken: string | null
  accessTokenData: JwtDataModel | null
  login: (email: string, password: string, oneTimePasswordToken?: string) => Promise<boolean | string>
  logout: () => void
  refreshSession: () => Promise<void>
  masquerade: (token: string) => Promise<boolean>
  ssoLogin: (token: string) => Promise<boolean>
}

export const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [refreshToken, setRefreshToken] = useState<string | null>(null)
  const [accessTokenData, setAccessTokenData] = useState<JwtDataModel | null>(null)

  useEffect(() => {
    ;(async () => {
      try {
        await refreshSession()
        setIsAuthenticating(false)
      } catch (error: any) {
        setIsAuthenticating(false)
      }
    })()
  }, [])

  useEffect(() => {
    const refreshTokenTimer = setInterval(() => {
      if (refreshToken) {
        refreshSession()
      }
    }, 15 * 60 * 1000) // Fifteen minutes

    return () => {
      clearInterval(refreshTokenTimer)
    }
  }, [refreshToken])

  const login = async (email: string, password: string, oneTimePasswordToken?: string) => {
    const session = await authResource.login(email, password, oneTimePasswordToken)

    if (session?.status === 'OTP') {
      return 'OTP'
    }

    return validateAndSetSessionTokens(session, 'public')
  }

  const ssoLogin = async (token: string) => {
    try {
      const session = await authResource.ssoTokenExchange(token)
      if (!session?.accessToken || !session?.refreshToken) {
        return false
      }

      return validateAndSetSessionTokens({ accessToken: session.accessToken, refreshToken: session.refreshToken, status: undefined }, 'sso')
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      return false
    }
  }

  const logout = async () => {
    const accessToken = localStorageService.getItem('access-token')
    const refreshToken = localStorageService.getItem('refresh-token')

    if (accessToken && refreshToken) {
      await authResource.logout({ accessToken, refreshToken })
    }

    queryClient.clear()
    setAccessToken(null)
    setRefreshToken(null)
    localStorageService.removeItem('access-token')
    localStorageService.removeItem('refresh-token')
    await clearApolloCache()
  }

  const refreshSession = async () => {
    try {
      const refreshToken = localStorageService.getItem('refresh-token')

      if (refreshToken && authService.isTokenValid(refreshToken)) {
        const session = await authResource.refreshToken(refreshToken)

        validateAndSetSessionTokens(session, 'public')
      }
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      // TODO: What should we do in the case we can't refresh the session? Show a modal to re-login?
    }
  }

  const masquerade = async (token: string) => {
    const session = await authResource.masqueradeTokenExchange(token)
    if (!session?.accessToken) {
      return false
    }

    return validateAndSetSessionTokens({ accessToken: session.accessToken, refreshToken: 'dummy-refresh-token', status: undefined }, 'masquerade')
  }

  const validateAndSetSessionTokens = (session?: SessionResponse, type?: SessionType) => {
    if (!session) {
      return false
    }

    const { accessToken, refreshToken } = session

    if (authService.isTokenValid(accessToken)) {
      if (type === 'public' && !authService.isTokenValid(refreshToken)) {
        return false
      }

      setAccessToken(accessToken)
      setRefreshToken(refreshToken)
      setAccessTokenData(authService.decodeToken(accessToken) || null)
      localStorageService.setItem('access-token', accessToken)
      localStorageService.setItem('refresh-token', refreshToken)

      return true
    }

    return false
  }

  const value = useMemo(
    () => ({
      isAuthenticated: Boolean(accessToken) && Boolean(refreshToken),
      isAuthenticating,
      accessToken,
      refreshToken,
      accessTokenData,
      login,
      ssoLogin,
      logout,
      refreshSession,
      masquerade,
    }),
    [isAuthenticating, accessToken, accessTokenData, refreshToken]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('This component must be used within a <AuthProvider> component.')
  }

  return context
}

export const clearApolloCache = async () => {
  await Promise.all([
    apolloService.clearStore(),
    clearReactiveApprovalTemplate(),
    clearReactiveApprovalTemplateGroup(),
    clearReactiveEvaluation(),
    clearReactiveEvaluationCriteria(),
    clearReactiveEvaluationEnvelope(),
    clearReactiveEvaluationEnvelopeDocument(),
    clearReactiveEvaluationEvent(),
    clearReactiveEvaluationEventDocument(),
    clearReactiveEvaluationSubmission(),
    clearReactiveEvaluationSubmissionDocument(),
    clearReactiveEvaluationUser(),
    clearReactivePerformanceMetric(),
    clearReactivePerformanceScorecard(),
    clearReactivePerformanceScorecardDocument(),
    clearReactivePerformanceScorecardMetric(),
    clearReactivePerformanceScorecardMetricCycle(),
    clearReactivePerformanceScorecardMetricUser(),
    clearReactivePerformanceScorecardUser(),
  ])
}
