import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { FourOhFourPage } from '@cotiss/common/pages/four-oh-four.page'
import { EvaluationEventEvaluateEnvelopeUserPage } from '@cotiss/evaluation-event/pages/evaluation-event-evaluate-envelope-user.page'
import { EvaluationEventEvaluateViewPage } from '@cotiss/evaluation-event/pages/evaluation-event-evaluate-view.page'
import { EvaluationEventListPage } from '@cotiss/evaluation-event/pages/evaluation-event-list.page'
import { EvaluationEventModerateEnvelopeSubmissionPage } from '@cotiss/evaluation-event/pages/evaluation-event-moderate-envelope-submission.page'
import { EvaluationEventModerateEnvelopePage } from '@cotiss/evaluation-event/pages/evaluation-event-moderate-envelope.page'
import { EvaluationEventViewPage } from '@cotiss/evaluation-event/pages/evaluation-event-view.page'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

const EvaluationEventLayout = memo(() => {
  const { permissions } = useUserAccess()

  if (!permissions.hasEvaluationAccess) {
    return <FourOhFourPage />
  }

  return (
    <Switch>
      <Route path="/evaluation-event/list/:tab?" exact>
        <EvaluationEventListPage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/evaluate/envelope/:evaluationEnvelopeId/user/:evaluationUserId/:tab?" exact>
        <EvaluationEventEvaluateEnvelopeUserPage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/evaluate/view/:evaluationId" exact>
        <EvaluationEventEvaluateViewPage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId" exact>
        <EvaluationEventModerateEnvelopePage />
      </Route>
      <Route path="/evaluation-event/view/:evaluationEventId/moderate/envelope/:evaluationEnvelopeId/submission/:evaluationSubmissionId/:tab?" exact>
        <EvaluationEventModerateEnvelopeSubmissionPage />
      </Route>
      {/* This route should be last, just incase there is a clash in pattern. */}
      <Route path="/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?" exact>
        <EvaluationEventViewPage />
      </Route>
      <Redirect from="/evaluation-event" to="/evaluation-event/list/:tab?" exact />
    </Switch>
  )
})

export default EvaluationEventLayout
