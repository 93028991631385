import { memo } from 'react'
import { isBefore, parseISO } from 'date-fns'
import { map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { ForumQuestionSkeleton } from '@cotiss/forum/components/forum-question-skeleton.component'
import { ForumQuestion } from '@cotiss/forum/components/forum-question.component'
import { useListForumQuestion } from '@cotiss/forum/resources/use-list-forum-questions.resource'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'

type Props = {
  tenderId?: string
}

export const ProcurementViewTenderForumTab = memo(({ tenderId }: Props) => {
  const { tender, isLoading: isTenderLoading } = useGetTender(tenderId)
  const { forumQuestions, isLoading: isForumQuestionsLoading } = useListForumQuestion({ tenderId })
  const isLoading = isForumQuestionsLoading || isTenderLoading
  const isForumClosed = Boolean(tender?.forumCloseDate?.endDate && isBefore(parseISO(tender.forumCloseDate.endDate), new Date()))

  const renderContent = () => {
    if (isLoading) {
      return (
        <>
          {map(Array(3), (_, index) => (
            <ForumQuestionSkeleton key={index} className="mt-4" />
          ))}
        </>
      )
    }

    if (!forumQuestions.length) {
      return (
        <Text className="text-center" variant="light">
          No questions have been asked.
        </Text>
      )
    }

    return (
      <>
        {map(forumQuestions, (forumQuestion) => (
          <ForumQuestion key={forumQuestion._id} className="mt-4" forumQuestion={forumQuestion} variant="primary" />
        ))}
      </>
    )
  }

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <Text className="font-semibold mr-4" variant="heading" size="h5">
            Questions and answers
            {Boolean(forumQuestions.length) && (
              <Text className="ml-2" variant="light" isInline>
                ({forumQuestions.length})
              </Text>
            )}
          </Text>
          {isForumClosed && (
            <Badge state="outline" variant="neutral">
              Closed
            </Badge>
          )}
          {!isForumClosed && tender?.forumCloseDate?.endDate && (
            <Badge state="outline" variant="secondary">
              Closes: {datetimeService.format(tender?.forumCloseDate.endDate, 'do MMM yyyy')}
            </Badge>
          )}
        </div>
      </div>
      {renderContent()}
    </>
  )
})
