import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { routerService } from '@cotiss/common/services/router.service'
import { ContractModel } from '@cotiss/contract/contract.model'

type Props = {
  contract: ContractModel
}

export const TaskListContractCta = memo(({ contract }: Props) => {
  return (
    <Button
      href={routerService.getHref('/contract/approval/:contractShellId/:contractId/:approvalId?', {
        contractShellId: contract?.contractShell,
        contractId: contract?._id || '',
      })}
      state="outline"
      variant="secondary"
      size="xs"
      isLink
    >
      View <Icon className="ml-1" icon="arrow-right" />
    </Button>
  )
})
