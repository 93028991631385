import { FormEvent, memo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { Form } from '@cotiss/common/components/form.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalConfirmationButton } from '@cotiss/common/containers/callout/modal/modal-confirmation-button.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ApprovalModel } from '@cotiss/approval/approval.models'
import { useMutateApproval } from '@cotiss/approval/resources/use-mutate-approval.resource'

type Props = {
  approval: ApprovalModel
}

export const ApprovalRejectConfirmModal = memo(({ approval }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const { updateApproval } = useMutateApproval()
  const [isSaving, setIsSaving] = useState(false)
  const [feedback, setFeedback] = useState('')

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await updateApproval(approval._id, { status: 'rejected', feedback })
      push(routerService.getHref('/approval/view/:id', { id: approval._id }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="relative min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalContent>
        <Button className="absolute top-0 right-0" onClick={closeModal} state="ghost" shape="square" isDisabled={isSaving}>
          <Icon icon="x-close" />
        </Button>

        <Text className="font-semibold" size="h5">
          {approval.approvalType === 'go-to-market' ? 'Reject go to market' : 'Reject recommendation report'}
        </Text>

        <Text className="mt-4">Please provide a reason for rejecting.</Text>
        <TextArea className="mt-1" value={feedback} rows={4} onChange={({ target }) => setFeedback(target.value)} isDisabled={isSaving} isRequired />
      </ModalContent>
      <ModalConfirmationButton type="submit" isLoading={isSaving}>
        Reject
      </ModalConfirmationButton>
    </Form>
  )
})
