import { memo, useMemo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { clamp, map, sortBy } from 'lodash'
import { AsyncInput } from '@cotiss/common/components/async-input.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { utilService } from '@cotiss/common/services/util.service'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  className?: string
  envelopes: GqlEvaluationEnvelopeFieldsFragment[]
  envelopeWeightById: Record<string, number>
  envelopeWeightPercentageById: Record<string, number>
  setEnvelopeWeightById?: (value: Record<string, number>) => void
  isEditable?: boolean
}

export const EvaluationEventEnvelopeWeightList = memo((props: Props) => {
  const { className, envelopes, envelopeWeightById, envelopeWeightPercentageById, setEnvelopeWeightById, isEditable } = props
  const { track } = useEvaluationEventAnalytics()
  const { mutateUpdateEvaluationEnvelope } = useEvaluationEnvelope()

  const handleUpdateWeight = async (evaluationEnvelopeId: string) => {
    track('evaluation_event_wizard_weight_envelope_update_submit')

    const clampedWeight = clamp(Number(envelopeWeightById[evaluationEnvelopeId]) || 0, 1, 100)
    setEnvelopeWeightById && setEnvelopeWeightById({ ...envelopeWeightById, [evaluationEnvelopeId]: clampedWeight })

    await mutateUpdateEvaluationEnvelope({ evaluationEnvelopeId, weight: clampedWeight })
  }

  const { columns } = useMemo(() => {
    const sortedEnvelopes = sortBy(envelopes, 'order')
    const columns: TableColumn[] = [
      {
        heading: 'Envelope',
        thClassName: 'w-1/2',
        rows: map(sortedEnvelopes, ({ name, order }) => ({
          content: () => (
            <Text>
              {order}. {name}
            </Text>
          ),
        })),
      },
      {
        heading: 'Value (1 - 100)',
        thClassName: 'w-1/6',
        rows: map(sortedEnvelopes, ({ id, weight }) => ({
          content: () =>
            isEditable && setEnvelopeWeightById ? (
              <div className="relative">
                <AsyncInput
                  className="w-20"
                  value={envelopeWeightById[id]}
                  onChange={({ target }) => setEnvelopeWeightById({ ...envelopeWeightById, [id]: Number(target.value) || 0 })}
                  onSubmit={() => handleUpdateWeight(id)}
                  onFocus={({ target }) => target.select()}
                  min={0}
                  max={100}
                  placeholder="--"
                  isFull={false}
                  isDisabled={sortedEnvelopes.length < 2}
                  rightSlot={
                    sortedEnvelopes.length < 2 && (
                      <Tooltip tooltip="You cannot edit the weight when you only have 1 envelope">
                        <Icon icon="lock" variant="light" />
                      </Tooltip>
                    )
                  }
                />
              </div>
            ) : (
              <Text className="text-right">{weight}</Text>
            ),
        })),
      },
      {
        heading: 'Weight',
        thClassName: 'text-right w-1/3',
        rows: map(sortedEnvelopes, ({ id }) => ({
          tdClassName: 'text-right',
          content: () => (
            <>
              <Text variant="secondary" isInline>
                {envelopeWeightPercentageById[id] ? utilService.formatAsPercentage(Number(envelopeWeightPercentageById[id]) * 100) : '--'}
              </Text>
              <Text className="mt-1" variant="light" size="sm">
                ({envelopeWeightById[id]}/{evaluationEventService.getTotalWeight({ weightById: envelopeWeightById })})
              </Text>
            </>
          ),
        })),
      },
    ]

    return { columns }
  }, [envelopes, envelopeWeightPercentageById, envelopeWeightById, isEditable])

  return <Table className={className} columns={columns} state="ghost" />
})
