import { memo, useMemo, useRef } from 'react'
import { filter, flatMap, map } from 'lodash'
import { useParams } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { MetafieldForm } from '@cotiss/metafield/components/metafield-form.component'
import { useListMetafieldValue } from '@cotiss/metafield-value/resources/use-list-metafield-value.resource'
import { SupplierViewDrawer } from '@cotiss/supplier/drawers/supplier-view.drawer'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { ContractSupportingDocumentsTable } from '@cotiss/contract/components/contract-supporting-documents-table.component'
import { ContractWizardMilestoneTable } from '@cotiss/contract/components/contract-wizard-milestone-table.component'
import { ContractWizardPriceDurationTable } from '@cotiss/contract/components/contract-wizard-price-duration-table.component'
import { ContractWizardStepMetadataEditModal } from '@cotiss/contract/modals/contract-wizard-step-metadata-edit.modal'
import { PriceDurationsOrMilestonesHistoryModal } from '@cotiss/contract/modals/contract-price-durations-or-milestones-history.modal'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { Banner } from '@cotiss/common/components/banner.component'
import { Button } from '@cotiss/common/components/button.component'
import { ButtonReadMore } from '@cotiss/common/components/button-read-more.component'
import { COLOUR } from '@cotiss/common/constants/colour.constants'
import { Card } from '@cotiss/common/components/card.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { EditableField } from '@cotiss/common/components/editable-field.component'
import { Field } from '@cotiss/common/components/field.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { OCDS_CURRENCY_MAP } from '@cotiss/common/constants/ocds.constants'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Spinner } from '@cotiss/common/components/spinner.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { regionService } from '@cotiss/common/services/region.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { ContractStatusBadge } from '@cotiss/contract/components/contract-status-badge.component'
import { ContractHistoryModal } from '@cotiss/contract/modals/contract-history.modal'

export const ContractViewGeneralTab = memo(() => {
  const { openModal, openNarrowDrawer } = useCallout()
  const shortDescriptionRef = useRef<HTMLDivElement>(null)
  const { metafields } = useListMetafield({ entityType: 'CONTRACT' })
  const { contractShellId } = useParams<{ contractShellId: string }>()
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const isEsCoreFieldDescriptorsEnabled = useFeature('es-core-field-descriptors')
  const { contract, hasMultipleContractPeriodVariations, supportingDocumentShells } = useMemo(() => {
    const contract = contractShell && contractService.getContract(contractShell)
    const hasMultipleContractPeriodVariations =
      filter(
        contractService.getAllApprovedContracts(contractShell),
        (contract) =>
          !contract.variationTypes.length ||
          contract.variationTypes.includes('price_duration') ||
          contract.variationTypes.includes('contract_renewal') ||
          contract.variationTypes.includes('milestone')
      ).length > 1

    // Get supporting documents from all approved contracts
    const supportingDocumentShells = filter(
      flatMap(contractService.getAllApprovedContracts(contractShell), (contract) => contract.documentShells),
      { type: 'SUPPORTING' }
    )

    return {
      contract,
      hasMultipleContractPeriodVariations,
      supportingDocumentShells,
    }
  }, [contractShell])

  // This is to display the current metafield values
  const { metafieldValues } = useListMetafieldValue({
    resourceId: contract?._id,
  })

  // This is to display the history of all metafield values
  const { metafieldValues: metafieldValuesHistory } = useListMetafieldValue({
    resourceIds: map(contractShell?.contracts, ({ _id }) => _id),
  })

  const metadataHistory = useMemo(
    () => (contractShell ? contractService.getContractShellAuditHistory({ contractShell, metafields, metafieldValues: metafieldValuesHistory }) : []),
    [contractShell, metafieldValuesHistory, metafields]
  )

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <div>
            <Skeleton className="h-2 w-1/3 mb-1" />
            <Text className="font-semibold" size="h5">
              General
            </Text>
          </div>
        </CardHeader>
        {/* TODO: move to skeleton */}
        <div className="flex items-center h-36">
          <Spinner className="mb-2 mx-auto" colour={COLOUR.primary[500]} />
        </div>
      </Card>
    )
  }

  if (!contractShell || !contract || !(contract.status === 'PUBLISHED' || contract.status === 'CEASED')) {
    return (
      <Card>
        <NoDataPlaceholder label="No active contract to view" />
      </Card>
    )
  }

  return (
    <Card>
      <CardHeader>
        <div>
          <Text className="mb-1" size="sm" variant="light">
            {contractShell?.title || ''}
          </Text>

          <Text className="font-semibold" size="h5">
            General
          </Text>
        </div>
        <ContractStatusBadge status={contract.status} />
      </CardHeader>
      <Banner variant="light">
        <Text className="font-medium mb-1">Contract metadata</Text>
        <Text size="sm">
          Adding more details will help track the performance of this contract. All changes will need to be signed off by your approval panel before
          the contract can be sent.
        </Text>
      </Banner>

      <div className="p-6">
        <EditableField
          label="Contract shell title"
          textValue={contractShell.title}
          supplementary={
            isEsCoreFieldDescriptorsEnabled && 'Contact Name, Execution date: YYYYMMDD, Agreement type (SoW, Contract, Variation), Activity Name'
          }
          onClick={() =>
            openModal(
              <ContractWizardStepMetadataEditModal
                contractId={contract._id}
                label="Contract shell title"
                field="contractShellTitle"
                supplementary={
                  isEsCoreFieldDescriptorsEnabled &&
                  'Contact Name, Execution date: YYYYMMDD, Agreement type (SoW, Contract, Variation), Activity Name'
                }
                contractShell={contractShell}
              />
            )
          }
          isTruncated
        />
        <EditableField
          label="Short description"
          className="mt-2"
          onClick={() =>
            openModal(
              <ContractWizardStepMetadataEditModal
                contractId={contract._id}
                label="Short description"
                field="shortDescription"
                contractShell={contractShell}
              />
            )
          }
        >
          {contractShell.description ? (
            <div ref={shortDescriptionRef} className="whitespace-pre-wrap line-clamp-2 overflow-hidden">
              {contractShell.description}
            </div>
          ) : null}
        </EditableField>
        {/* If the short description is long, this button will appear to help the user read all the text */}
        <Field label="" className="ml-4">
          <ButtonReadMore
            modalContent={<Text className="whitespace-pre-wrap">{contractShell.description}</Text>}
            modalTitle="Short description"
            readMoreRef={shortDescriptionRef}
          />
        </Field>
        {contractShell.procurement && (
          <EditableField isDisabled label="Procurement title" className="mt-2" textValue={contractShell.procurement.title || '--'} />
        )}
      </div>

      {!contract.priceDurations.length && !contract.milestones.length && hasMultipleContractPeriodVariations && (
        <NoDataPlaceholder
          illustration="dot-list"
          label="No contract periods"
          ctaLabel="View changes"
          ctaState="text"
          onCtaClick={() => openModal(<PriceDurationsOrMilestonesHistoryModal contractShellId={contractShellId} />)}
        />
      )}

      {!contract.priceDurations.length && !contract.milestones.length && !hasMultipleContractPeriodVariations && (
        <NoDataPlaceholder
          illustration="dot-list"
          label="No contract periods created yet. Create a contract variation to add initial and renewal periods."
        />
      )}

      {Boolean(contract.priceDurations.length || contract.milestones.length) && (
        <>
          <TableHeader className="flex justify-between items-center">
            <Text className="font-semibold">Contract overview</Text>
            {hasMultipleContractPeriodVariations && (
              <Button
                state="text"
                variant="secondary"
                onClick={() => openModal(<PriceDurationsOrMilestonesHistoryModal contractShellId={contractShellId} />)}
              >
                View changes
              </Button>
            )}
          </TableHeader>

          {contractShell.type !== 'MILESTONE' && (
            <ContractWizardPriceDurationTable
              priceDurations={contract.priceDurations || []}
              isVariation={Boolean(contract.priceDurations.length)}
              contractShellId={contractShellId}
              currency={contract?.metadata.currency}
            />
          )}
          {contractShell.type === 'MILESTONE' && (
            <ContractWizardMilestoneTable
              milestones={contract.milestones || []}
              isVariation={Boolean(contract.priceDurations.length)}
              contractShellId={contractShellId}
              currency={contract?.metadata.currency}
            />
          )}
        </>
      )}

      <div className="px-6 py-8">
        {/* This field doesn't have history as counterparties are only set on the initial contract */}
        <Field label="Counterparties">
          {!contract?.metadata.suppliers?.length && '--'}
          {map(contract?.metadata.suppliers, ({ name, _id }) => (
            <Button state="text" variant="link" key={_id} onClick={() => openNarrowDrawer(<SupplierViewDrawer organisationId={_id} />)}>
              {name}
            </Button>
          ))}
        </Field>
        {/* This field doesn't have history as contracting entity is only set on the initial contract */}
        <Field label="Contracting entity" className="mt-8">
          {!contract?.metadata.contractingEntity && '--'}
          {contract?.metadata.contractingEntity && (
            <Button
              state="text"
              variant="link"
              onClick={() =>
                contract?.metadata.contractingEntity &&
                openNarrowDrawer(<SupplierViewDrawer organisationId={contract?.metadata.contractingEntity._id} />)
              }
            >
              {contract?.metadata.contractingEntity?.name}
            </Button>
          )}
        </Field>
        <Field
          label="Currency"
          className="mt-8"
          supplementary={
            metadataHistory?.filter((entry) => entry.fieldName === 'currency').length > 1 && (
              <Button
                variant="secondary"
                state="text"
                size="sm"
                onClick={() => openModal(<ContractHistoryModal contractShellId={contractShellId} field="currency" />)}
              >
                View history
              </Button>
            )
          }
        >
          <Text>{contract?.metadata.currency ? OCDS_CURRENCY_MAP[contract?.metadata.currency] : '--'}</Text>
        </Field>
        <Field
          className="mt-8"
          label="Contract category"
          supplementary={
            metadataHistory?.filter((entry) => entry.fieldName === 'contract-category').length > 1 && (
              <Button
                variant="secondary"
                state="text"
                size="sm"
                onClick={() => openModal(<ContractHistoryModal contractShellId={contractShellId} field="contract-category" />)}
              >
                View history
              </Button>
            )
          }
        >
          <div>
            {!contract?.metadata.categories?.length && <Text>--</Text>}
            {map(contract?.metadata.categories, (category) => (
              <Badge
                title={category.description}
                key={category._id}
                className="truncate max-w-[140px] mr-2"
                state="translucent"
                variant="secondary"
                size="sm"
              >
                {category.description}
              </Badge>
            ))}
          </div>
        </Field>
        <Field
          className="mt-8"
          label="Region(s)"
          supplementary={
            metadataHistory?.filter((entry) => entry.fieldName === 'region').length > 1 && (
              <Button
                variant="secondary"
                state="text"
                size="sm"
                onClick={() => openModal(<ContractHistoryModal contractShellId={contractShellId} field="region" />)}
              >
                View history
              </Button>
            )
          }
        >
          <Text>
            {!contract?.metadata.regions?.length && '--'}
            {map(regionService.processRegions(contract.metadata.regions), (region) => (
              <Badge
                title={region.label}
                key={region.value}
                className="truncate max-w-[140px] mr-2"
                state="translucent"
                variant="secondary"
                size="sm"
              >
                {region.label}
              </Badge>
            ))}
          </Text>
        </Field>
        <Hr className="mt-8" />
        {/* This field doesn't have history as contract id cannot be changed by the user */}
        <Field className="mt-8" label="Contract ID">
          <Text>{contract?.metadata.internalReference || '--'}</Text>
        </Field>
        <Field
          className="mt-8"
          label="Internal reference"
          supplementary={
            <>
              {isEsCoreFieldDescriptorsEnabled && (
                <Text className="mb-1" variant="light" size="sm">
                  Objective URL
                </Text>
              )}
              {metadataHistory?.filter((entry) => entry.fieldName === 'internal-reference').length > 1 && (
                <Button
                  variant="secondary"
                  state="text"
                  size="sm"
                  onClick={() => openModal(<ContractHistoryModal contractShellId={contractShellId} field="internal-reference" />)}
                >
                  View history
                </Button>
              )}
            </>
          }
        >
          <Text>{contract?.metadata.externalReference || '--'}</Text>
        </Field>

        <Hr className="mt-8" />
        <div className="mt-8">
          {!supportingDocumentShells.length && <NoDataPlaceholder label="No supporting documents added" illustration="dot-list" />}
          {Boolean(supportingDocumentShells.length) && (
            <ContractSupportingDocumentsTable
              documentShells={supportingDocumentShells}
              contractShellId={contractShellId}
              contractId={contract._id}
              previewAction="drawer"
            />
          )}
        </div>

        {Boolean(metafields.length) && (
          <>
            <Hr className="mt-8" />
            {map(metafields, (metafield) => (
              <div key={metafield._id}>
                <MetafieldForm className="mt-8" resourceId={contract._id} metafield={metafield} metafieldValues={metafieldValues} />
                {metadataHistory?.filter((entry) => entry.fieldName === metafield.fieldLabel).length > 1 && (
                  <Button
                    variant="secondary"
                    state="text"
                    size="sm"
                    onClick={() => openModal(<ContractHistoryModal contractShellId={contractShellId} field={metafield.fieldLabel} />)}
                  >
                    View history
                  </Button>
                )}
              </div>
            ))}
          </>
        )}
      </div>
    </Card>
  )
})
