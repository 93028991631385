import { memo, useMemo } from 'react'
import classNames from 'classnames'
import { map } from 'lodash'
import { Text } from '@cotiss/common/components/text.component'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { tenderService } from '@cotiss/tender/tender.service'
import { useListTenderCriteria } from '@cotiss/tender-criteria/resources/use-list-tender-criteria.resource'

type Props = {
  className?: string
  tenderId: string
  isSupplierView?: boolean
}

export const TenderCriteriaTable = memo(({ className, tenderId, isSupplierView }: Props) => {
  const classes = classNames(className, 'table-auto w-full text-left')
  const { tender } = useGetTender(tenderId)
  const { tenderCriteria } = useListTenderCriteria({ tenderId })
  const { nonPriceCriteria, price } = useMemo(() => {
    return tenderService.processCriteria({
      nonPriceCriteria: tenderCriteria,
      nonPriceWeight: tender?.envelope.weight,
      priceWeight: tender?.priceEnvelope.weight,
    })
  }, [tenderCriteria, tender])

  return (
    <table className={classes}>
      <thead>
        <tr className="bg-secondary-50">
          <th>
            <Text className="font-semibold uppercase" variant="light" size="xs">
              Name
            </Text>
          </th>
          {(tender?.criteriaWeightingsVisible || !isSupplierView) && (
            <th>
              <Text className="font-semibold text-right uppercase" variant="light" size="xs">
                Weighting
              </Text>
            </th>
          )}
        </tr>
      </thead>
      <tbody>
        {tender?.isPriceWeighted && (
          <tr className="border-t border-gray-100">
            <td className="py-2">
              <Text className="mr-4" size="sm">
                Price
              </Text>
            </td>
            {(tender?.criteriaWeightingsVisible || !isSupplierView) && (
              <td className="py-2">
                <Text className="text-right" size="sm">
                  {price.percentage}
                </Text>
              </td>
            )}
          </tr>
        )}
        {Boolean(nonPriceCriteria.length) && (
          <>
            {map(tenderCriteria, (criteria, index) => (
              <tr key={criteria._id} className="border-t border-gray-100">
                <td className="py-2">
                  <Text className="mr-4" size="sm">
                    {criteria.name}
                  </Text>
                </td>
                {(tender?.criteriaWeightingsVisible || !isSupplierView) && (
                  <td className="py-2">
                    <Text className="text-right" size="sm">
                      {nonPriceCriteria[index].overallPercentage}
                    </Text>
                  </td>
                )}
              </tr>
            ))}
          </>
        )}
      </tbody>
    </table>
  )
})
