import { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { forEach, keyBy, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { utilService } from '@cotiss/common/services/util.service'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationSubmission } from '@cotiss/evaluation-event/hooks/use-evaluation-submission.hook'
import { EvaluationEventResultExportModal } from '@cotiss/evaluation-event/modals/evaluation-event-result-export.modal'

export const EvaluationEventViewResultTab = memo(() => {
  const { openModal } = useCallout()
  const [isError, setIsError] = useState(false)
  const { track } = useEvaluationEventAnalytics()
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagination, setPagination] = useState<GqlPagination>()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationSubmissions, queryEvaluationSubmissionList } = useEvaluationSubmission()
  const { evaluationEnvelopes, evaluationEnvelopeOverviews, queryEvaluationEnvelopeList, queryEvaluationEnvelopeOverviewList } =
    useEvaluationEnvelope()

  useEffect(() => {
    track('evaluation_event_view_results_tab_view')
  }, [])

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      setIsLoading(true)
      const [{ pagination }] = await Promise.all([
        queryEvaluationSubmissionList({
          filter: { evaluationEventId: evaluationEvent.id },
          pagination: { page: currentPage, pageSize: 20 },
          sort: { sortBy: 'rank', direction: 'asc' },
        }),
        queryEvaluationEnvelopeList({ filter: { evaluationEventId: evaluationEvent.id } }),
        queryEvaluationEnvelopeOverviewList({ filter: { evaluationEventId: evaluationEvent.id } }),
      ])

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  const { fixedColumns, columns } = useMemo(() => {
    const evaluationEnvelopeOverviewMap = keyBy(evaluationEnvelopeOverviews, 'evaluationEnvelopeId')

    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Rank',
        thClassName: 'w-20',
        rows: map(evaluationSubmissions, (submission) => ({
          content: () => <Text className="truncate">{submission.rank ?? '--'}</Text>,
        })),
      },
      {
        heading: 'Submission',
        rows: map(evaluationSubmissions, (submission) => ({
          content: () => <Text className="truncate">{submission.name}</Text>,
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Overall score',
        rows: map(evaluationSubmissions, ({ overallPercentageScore }) => ({
          content: () => (
            <Text className="truncate">{overallPercentageScore !== null ? utilService.formatAsPercentage(overallPercentageScore * 100) : '--'}</Text>
          ),
        })),
      },
    ]

    forEach(evaluationEnvelopes, ({ id: evaluationEnvelopeId, name, order }) => {
      const envelopeOverview = evaluationEnvelopeOverviewMap[evaluationEnvelopeId]
      const submissionBreakdownMap = keyBy(envelopeOverview?.submissionBreakdown, 'evaluationSubmissionId')

      columns.push({
        heading: (
          <div className="normal-case">
            <Text size="sm" variant="light">
              Envelope {order}.
            </Text>
            <Text className="font-semibold">{name}</Text>
          </div>
        ),
        rows: map(evaluationSubmissions, ({ id: evaluationSubmissionId }) => {
          const submissionBreakdown = submissionBreakdownMap[evaluationSubmissionId]

          return {
            content: () => (
              <Text>
                {submissionBreakdown
                  ? utilService.formatAsPercentage(submissionBreakdown.moderatedScoreSummary.averageEnvelopeWeightedPercentageScore * 100)
                  : '--'}
              </Text>
            ),
          }
        }),
      })
    })

    return { fixedColumns, columns }
  }, [evaluationSubmissions, evaluationEnvelopes, evaluationEnvelopeOverviews])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  return (
    <>
      <TableHeader className="flex items-center justify-between" variant="white">
        <Text className="font-medium" size="lg">
          Results
        </Text>
        <Button size="xs" state="translucent" variant="secondary" onClick={() => openModal(<EvaluationEventResultExportModal />)}>
          <Icon className="mr-2" icon="download-01" /> Download CSV
        </Button>
      </TableHeader>
      <ScrollableTable
        fixedColumnsWidth={400}
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        isLoading={isLoading}
      />
    </>
  )
})
