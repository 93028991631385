import { memo, useState } from 'react'
import { find, map } from 'lodash'
import { Table_DEPRECATED } from '@cotiss/common/components/deprecated/table.component'
import { Tbody_DEPRECATED } from '@cotiss/common/components/deprecated/tbody.component'
import { Td_DEPRECATED } from '@cotiss/common/components/deprecated/td.component'
import { Th_DEPRECATED } from '@cotiss/common/components/deprecated/th.component'
import { Thead_DEPRECATED } from '@cotiss/common/components/deprecated/thead.component'
import { Tr_DEPRECATED } from '@cotiss/common/components/deprecated/tr.component'
import { Radio } from '@cotiss/common/components/radio.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useListTenderMandatoryCriteriaScore } from '@cotiss/tender-mandatory-criteria/resources/use-list-tender-mandatory-criteria-score.resource'
import { useListTenderMandatoryCriteria } from '@cotiss/tender-mandatory-criteria/resources/use-list-tender-mandatory-criteria.resource'
import { useMutateTenderMandatoryCriteria } from '@cotiss/tender-mandatory-criteria/resources/use-mutate-tender-mandatory-criteria.resource'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'

type Props = {
  tenderResponseId: string
  isEditable?: boolean
}

export const TenderResponseReviewQuestionnaireTab = memo(({ tenderResponseId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { tenderResponse } = useGetTenderResponse(tenderResponseId)
  const [isSavingMap, setIsSavingMap] = useState<Record<string, boolean>>({})
  const { updateTenderMandatoryCriteriaScore } = useMutateTenderMandatoryCriteria()
  const { tenderMandatoryCriteriaScores } = useListTenderMandatoryCriteriaScore({ tenderResponseId })
  const { tenderMandatoryCriteria } = useListTenderMandatoryCriteria({ tenderId: tenderResponse?.tender._id })
  const questionsToRender = map(tenderMandatoryCriteria, ({ _id, description }) => {
    const tenderMandatoryCriteriaScore = find(tenderMandatoryCriteriaScores, { tenderMandatoryCriterion: _id })

    return {
      tenderMandatoryCriteriaId: _id,
      question: description,
      pass: tenderMandatoryCriteriaScore?.pass,
    }
  })

  const handleChange = async (tenderMandatoryCriteriaId: string, pass: boolean) => {
    try {
      setIsSavingMap({ ...isSavingMap, [tenderMandatoryCriteriaId]: true })
      await updateTenderMandatoryCriteriaScore({ tenderMandatoryCriteriaId, tenderResponseId, pass })

      setIsSavingMap({ ...isSavingMap, [tenderMandatoryCriteriaId]: false })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSavingMap({ ...isSavingMap, [tenderMandatoryCriteriaId]: false })
    }
  }

  return (
    <>
      <Text className="font-semibold">Review mandatory checklist</Text>
      <Text className="mt-1" variant="light" size="sm">
        Please review the mandatory checklist below and indicate whether the supplier has met the outlined requirements.
      </Text>

      <div className="border border-gray-200 rounded-lg overflow-hidden mt-4">
        <div className="bg-primary-50 p-3">
          <Text>Mandatory questions ({questionsToRender.length})</Text>
        </div>
        <Table_DEPRECATED>
          <Thead_DEPRECATED className="border-b" variant="white">
            <Th_DEPRECATED className="w-14">#</Th_DEPRECATED>
            <Th_DEPRECATED>Question</Th_DEPRECATED>
            <Th_DEPRECATED className="w-20 text-center">Pass</Th_DEPRECATED>
            <Th_DEPRECATED className="w-20 text-center">Fail</Th_DEPRECATED>
          </Thead_DEPRECATED>
          <Tbody_DEPRECATED>
            {map(questionsToRender, ({ tenderMandatoryCriteriaId, question, pass }, index) => (
              <Tr_DEPRECATED key={tenderMandatoryCriteriaId} variant="white">
                <Td_DEPRECATED className="align-top">
                  <Text>{index + 1}</Text>
                </Td_DEPRECATED>
                <Td_DEPRECATED>
                  <Text className="whitespace-pre-wrap">{question}</Text>
                </Td_DEPRECATED>
                <Td_DEPRECATED className="h-px">
                  <Radio
                    className="items-start justify-center w-full h-full"
                    value={true}
                    onChange={() => handleChange(tenderMandatoryCriteriaId, true)}
                    name={`tender-response-review-questionnaire-${tenderMandatoryCriteriaId}`}
                    isChecked={pass}
                    isDisabled={!isEditable || isSavingMap[tenderMandatoryCriteriaId]}
                  />
                </Td_DEPRECATED>
                <Td_DEPRECATED className="h-px">
                  <Radio
                    className="items-start justify-center w-full h-full"
                    value={false}
                    onChange={() => handleChange(tenderMandatoryCriteriaId, false)}
                    name={`tender-response-review-questionnaire-${tenderMandatoryCriteriaId}`}
                    isChecked={pass === false}
                    isDisabled={!isEditable || isSavingMap[tenderMandatoryCriteriaId]}
                  />
                </Td_DEPRECATED>
              </Tr_DEPRECATED>
            ))}
          </Tbody_DEPRECATED>
        </Table_DEPRECATED>
      </div>
    </>
  )
})
