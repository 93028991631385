import { FormEvent, useState, memo, useEffect } from 'react'
import { map } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { SsoConfigurationModel, SsoProviderName } from '@cotiss/auth/auth.models'
import { useGetSsoConfiguration } from '@cotiss/auth/resources/use-get-sso-configuration.resource'
import { USER_PERMISSION_MAP } from '@cotiss/user/user.constants'
import { UserPermission } from '@cotiss/user/user.models'
import { useMutateAuth } from '@cotiss/auth/resources/use-mutate-auth.resource'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { Text } from '@cotiss/common/components/text.component'

type Props = {
  label: string
  field: FieldName
}

type FormDataValues = {
  loginUrl: string
  metadataUrl: string
  providerName: SsoProviderName
  defaultUserPermission: UserPermission
}

type FieldName = keyof FormDataValues

export const SsoConfigurationEditModal = memo(({ label, field }: Props) => {
  const { configurations } = useGetSsoConfiguration()
  const [isSaving, setIsSaving] = useState(false)
  const [configuration, setConfiguration] = useState<SsoConfigurationModel | null>(null)
  const [errorMessage, setErrorMessage] = useState('')
  const { closeModal } = useCallout()
  const { updateSsoConfiguration } = useMutateAuth()
  const { openToast } = useToast()
  const { track } = useAnalytics()
  const [formData, setFormData] = useState<FormDataValues>({
    loginUrl: '',
    metadataUrl: '',
    providerName: 'okta',
    defaultUserPermission: 'PROCUREMENT_MANAGER',
  })

  useEffect(() => {
    const configuration = configurations?.length ? configurations[0] : null
    if (!configuration) {
      return
    }
    setConfiguration(configuration)
    setFormData({
      loginUrl: configuration.url || '',
      metadataUrl: configuration.metadataUrl || '',
      providerName: configuration.providerName || '',
      defaultUserPermission: configuration.defaultUserPermission ?? USER_PERMISSION_MAP.PROCUREMENT_MANAGER,
    })
  }, [configurations])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!configuration) {
      openToast('Something went wrong. Please refresh and try again.')
      return
    }

    if (field === 'metadataUrl' && !formData.metadataUrl.toLowerCase().includes('https')) {
      setErrorMessage('Metadata URL must use https')
      return
    }

    setIsSaving(true)

    try {
      track('sso_configuration_update_submit')
      await updateSsoConfiguration(configuration.id, {
        ...(field === 'providerName' && formData.providerName && { providerName: formData.providerName }),
        ...(field === 'loginUrl' && formData.loginUrl && { url: formData.loginUrl }),
        ...(field === 'metadataUrl' && formData.metadataUrl && { metadataUrl: formData.metadataUrl }),
        ...(field === 'defaultUserPermission' && formData.defaultUserPermission && { defaultUserPermission: formData.defaultUserPermission }),
      })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(`Couldn't save. Please try again.`, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${configuration ? 'Edit' : 'Add'} ${label.toLowerCase()}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>{label}</Label>
        {field === 'providerName' && (
          <Select_DEPRECATED<SsoProviderName>
            value={formData.providerName}
            options={[
              { label: 'Okta', value: 'okta' },
              { label: 'Microsoft', value: 'microsoft' },
              { label: 'ForgeRock', value: 'forgerock' },
            ]}
            onChange={(value) => setFormData({ ...formData, providerName: value })}
            placeholder
            isRequired
            isError={Boolean(errorMessage)}
          />
        )}
        {(field === 'loginUrl' || field === 'metadataUrl') && (
          <Input
            autoFocus
            placeholder="Type here"
            value={formData[field] as string}
            isRequired
            onChange={({ target }) => setFormData({ ...formData, [field]: target.value })}
            isError={Boolean(errorMessage)}
          />
        )}
        {field === 'defaultUserPermission' && (
          <Select_DEPRECATED<UserPermission>
            value={formData.defaultUserPermission}
            options={map(USER_PERMISSION_MAP, (label, value) => ({ label: label, value: value as UserPermission }))}
            onChange={(value) => setFormData({ ...formData, defaultUserPermission: value })}
            placeholder
            isRequired
            isError={Boolean(errorMessage)}
          />
        )}
        {errorMessage && (
          <Text className="mt-1" variant="danger">
            {errorMessage}
          </Text>
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm isDisabled={false} />
    </Form>
  )
})
