import { memo } from 'react'
import { GqlEvaluationEnvelopeFieldsFragment } from '@gql'
import { Button } from '@cotiss/common/components/button.component'
import { DraggableListItem } from '@cotiss/common/components/draggable-list-item.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { EvaluationEventEnvelopeUpdateModal } from '@cotiss/evaluation-event/modals/evaluation-event-envelope-update.modal'

type Props = {
  className?: string
  evaluationEnvelope: GqlEvaluationEnvelopeFieldsFragment
  isActive?: boolean
  isInOverlay?: boolean
  isEditable?: boolean
  isSortable?: boolean
  isDisabled?: boolean
}

export const EvaluationEventEnvelopeListItem = memo((props: Props) => {
  const { evaluationEnvelope, isEditable, isDisabled, ...restProps } = props
  const { openModal } = useCallout()

  return (
    <DraggableListItem id={evaluationEnvelope.id} isDisabled={isDisabled} {...restProps}>
      <div className="relative flex items-center justify-start w-full p-4">
        <Text className="mr-4 truncate">{evaluationEnvelope.name}</Text>
        {isEditable && (
          <Button
            className="invisible group-hover:visible"
            onClick={() => openModal(<EvaluationEventEnvelopeUpdateModal evaluationEnvelope={evaluationEnvelope} />)}
            state="outline"
            variant="secondary"
            size="xs"
            isDisabled={isDisabled}
          >
            Edit
          </Button>
        )}
      </div>
    </DraggableListItem>
  )
})
