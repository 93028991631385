import { HTMLProps, ReactNode, memo } from 'react'
import classNames from 'classnames'
import { TableState, TableVariant } from '@cotiss/common/components/table.component'

type Props = HTMLProps<HTMLTableCellElement> & {
  children?: ReactNode
  className?: string
  state?: TableState
  variant?: TableVariant
  isHighlighted?: boolean
  isScrollableTable?: boolean
  isFixedColumn?: boolean
  isWrapped?: boolean
}

export const TableTd = memo((props: Props) => {
  const { className, children, isHighlighted, state = 'default', variant = 'white', isFixedColumn, isScrollableTable, isWrapped, ...rest } = props
  const classes = classNames(className, 'relative h-12 px-4 py-2.5', {
    'border border-gray-200': state === 'default' || state === 'split',
    'first:border-l-0': isScrollableTable && state === 'default',
    'bg-secondary-50': isHighlighted && state === 'default',
    'h-16 border-r-0': state === 'split',
    'last:border-r last:rounded-r-lg': state === 'split' && !isFixedColumn,
    'first:rounded-l-lg': state === 'split' && isFixedColumn,
    'bg-primary-50': state !== 'ghost' && variant === 'primary',
    'bg-white': state !== 'ghost' && variant === 'white',
    'whitespace-nowrap': !isWrapped,
    'whitespace-normal': isWrapped,
  })

  return (
    <td {...rest} className={classes}>
      {children}
    </td>
  )
})
