import { memo, useEffect, useMemo, useState } from 'react'
import { GqlPagination } from '@gql'
import { map } from 'lodash'
import { useParams } from 'react-router-dom'
import { Button } from '@cotiss/common/components/button.component'
import { usePlanEventDocument } from '@cotiss/plan-event/hooks/use-plan-event-document.hook'
import { usePlanUser } from '@cotiss/plan-event/hooks/use-plan-user.hook'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { ScrollableTable, ScrollableTableColumn } from '@cotiss/common/components/scrollable-table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'
import { usePlanEventAnalytics } from '@cotiss/plan-event/hooks/use-plan-event-analytics.hook'
import { usePlanEvent } from '@cotiss/plan-event/hooks/use-plan-event.hook'
import { PlanEventDocumentUploadModal } from '@cotiss/plan-event/modals/plan-event-document-upload.modal'

export const PlanEventViewResourcesTab = memo(() => {
  const { openModal } = useCallout()
  const { planEvent } = usePlanEvent()
  const { track } = usePlanEventAnalytics()
  const { planUserInSession } = usePlanUser()
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { planEventId } = useParams<{ planEventId: string }>()
  const [pagination, setPagination] = useState<GqlPagination>()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)
  const canCreateDocuments = !planEvent?.isArchived && planUserInSession?.role === 'owner'
  const { planEventDocuments, queryPlanEventDocumentList, mutateDeletePlanEventDocument } = usePlanEventDocument()

  useEffect(() => {
    track('plan_event_view_resources_tab_view')
  }, [])

  useAsyncEffect(async () => {
    try {
      const { pagination } = await queryPlanEventDocumentList({
        filter: { planEventId },
        pagination: { page: currentPage, pageSize: 100 },
      })

      setPagination(pagination)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [currentPage])

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const { fixedColumns, columns } = useMemo(() => {
    const fixedColumns: ScrollableTableColumn[] = [
      {
        heading: 'Resource name',
        rows: map(planEventDocuments, ({ id: planEventDocumentId, fileName, documentId }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(documentId)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
          cta: canCreateDocuments && (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() => {
                          track('plan_event_view_resources_tab_delete_submit')
                          mutateDeletePlanEventDocument({ planEventDocumentId })
                        }}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
    ]

    const columns: ScrollableTableColumn[] = [
      {
        heading: 'Date created',
        rows: map(planEventDocuments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(planEventDocuments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    return { fixedColumns, columns }
  }, [planEventDocuments])

  if (!isLoading && isError) {
    return <ErrorPanel />
  }

  // TODO: Empty state for non-owner?

  return (
    <>
      <TableHeader className="flex justify-between items-center" variant="white">
        <Text className="font-medium" variant="heading" size="lg">
          Resources
        </Text>
        {canCreateDocuments && (
          <Button onClick={() => openModal(<PlanEventDocumentUploadModal />)} state="translucent" variant="secondary-dark" size="xs">
            + Add resource
          </Button>
        )}
      </TableHeader>
      <ScrollableTable
        fixedColumns={fixedColumns}
        columns={columns}
        pagination={pagination}
        onPageChange={setCurrentPage}
        emptyCta={
          canCreateDocuments && (
            <Button size="sm" state="text" variant="secondary" onClick={() => openModal(<PlanEventDocumentUploadModal />)} isDisabled={isLoading}>
              + Add resource
            </Button>
          )
        }
        isLoading={isLoading}
      />
    </>
  )
})
