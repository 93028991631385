import { memo } from 'react'
import { Text } from '@cotiss/common/components/text.component'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { DocumentViewer } from '@cotiss/document/components/document-viewer.component'
import { DocumentModel } from '@cotiss/document/document.models'

type Props = {
  title: string
  document: DocumentModel
}

export const ContractSupportingDocumentDrawer = memo(({ title, document }: Props) => {
  const renderHeader = () => (
    <Text className="font-semibold" size="h5" variant="heading">
      {title}
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <div className="bg-gray-100 rounded-lg w-full p-6 h-full">
        <DocumentViewer className="w-full h-full" document={document} isDownloadable />
      </div>
    </Drawer>
  )
})
