import { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { map, some } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { routerService } from '@cotiss/common/services/router.service'
import { Select } from '@cotiss/common/components/select.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { ProcurementListPopulatedModel, ProcurementModel } from '@cotiss/procurement/procurement.models'
import { useMutateProcurement } from '@cotiss/procurement/resources/use-mutate-procurement.resource'
import { useGetProject } from '@cotiss/project/resources/use-get-project.resource'
import { useListProject } from '@cotiss/project/resources/use-list-project.resource'
import { useMutateProject } from '@cotiss/project/resources/use-mutate-project.resource'

type FormData = {
  title: string
  projectId: string
}

type Props = {
  procurement?: ProcurementListPopulatedModel | ProcurementModel
}

export const ProcurementCreateUpdateModal = memo(({ procurement }: Props) => {
  const { push } = useHistory()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [projectTitle, setProjectTitle] = useState('')
  const { createProject, updateProject } = useMutateProject()
  const { projects, isLoading: isProjectsLoading } = useListProject()
  const { createProcurement, updateProcurement } = useMutateProcurement()
  const { project, isLoading: isProjectLoading } = useGetProject(procurement?.project)
  const [formData, setFormData] = useState<FormData>({
    title: procurement?.title || '',
    projectId: procurement?.project || '',
  })

  const projectOptions = useMemo(() => {
    const projectOptions = map(projects, (project) => ({
      value: project._id,
      label: project.title,
    }))

    if (formData.projectId && projectTitle && !some(projectOptions, { value: formData.projectId })) {
      projectOptions.push({
        value: formData.projectId,
        label: formData.projectId,
      })
    }

    return projectOptions
  }, [formData, projects])

  useEffect(() => {
    if (project) {
      setProjectTitle(project.title)
    }
  }, [project])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      const formDataToSubmit = { ...formData }

      if (!procurement) {
        if (projectTitle) {
          const project = await createProject({ title: projectTitle })
          formDataToSubmit.projectId = project._id
        }

        const createdProcurement = await createProcurement(formDataToSubmit)
        push(routerService.getHref('/procurement/overview/:procurementId/:tab?/:nestedTab?', { procurementId: createdProcurement._id }))
      } else {
        await Promise.all([
          updateProcurement(procurement._id, formDataToSubmit),
          projectTitle && updateProject(formData.projectId, { title: projectTitle }),
        ])
      }
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleChange = (projectId: string) => {
    if (projectId) {
      setFormData({ ...formData, projectId })
    } else {
      setFormData({ ...formData, projectId: '' })
      setProjectTitle('')
    }
  }

  const handleCreate = (projectTitle: string) => {
    setFormData({ ...formData, projectId: projectTitle })
    setProjectTitle(projectTitle)
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${procurement ? 'Update' : 'Create'} procurement`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Title</Label>
        <Input
          value={formData.title}
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          placeholder="Title"
          maxLength={100}
          isDisabled={isSaving}
          isRequired
        />
        <Label className="mt-4">Project</Label>
        {project ? (
          <Input
            value={projectTitle}
            onChange={({ target }) => setProjectTitle(target.value)}
            placeholder="Project"
            maxLength={100}
            isDisabled={(procurement && isProjectLoading) || isProjectsLoading || isSaving}
            isRequired
          />
        ) : (
          <Select
            value={formData.projectId}
            options={projectOptions}
            onChange={handleChange}
            onCreate={handleCreate}
            isDisabled={(procurement && isProjectLoading) || isProjectsLoading || isSaving}
            isRequired
          />
        )}
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
