type Props = {
  label: string
  count: number
}

export const TabLabelWithCount = ({ label, count }: Props) => {
  const countLabel = count !== undefined ? `(${count})` : ''
  return (
    <div className="inline-flex gap-1">
      {label}
      <span className="text-gray-400">{countLabel}</span>
    </div>
  )
}
