import { FormEvent, memo, useState } from 'react'
import { GqlEvaluationEventFieldsFragment } from '@gql'
import { useHistory } from 'react-router-dom'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  evaluationEvent: GqlEvaluationEventFieldsFragment
}

export const EvaluationEventDuplicateModal = memo(({ evaluationEvent }: Props) => {
  const { push } = useHistory()
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [name, setName] = useState(`${evaluationEvent.name} (1)`)
  const [isSaving, setIsSaving] = useState(false)
  const { mutateDuplicateEvaluationEvent } = useEvaluationEvent()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    track('evaluation_event_list_duplicate_submit')

    try {
      setIsSaving(true)
      const duplicatedEvaluationEvent = await mutateDuplicateEvaluationEvent({ evaluationEventId: evaluationEvent.id, name: name })

      push(routerService.getHref('/evaluation-event/view/:evaluationEventId/:tab?/:nestedTab?', { evaluationEventId: duplicatedEvaluationEvent.id }))
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Duplicate evaluation event" isDisabled={isSaving} />
      <ModalContent>
        <Text>
          Are you sure you want to duplicate this evaluation event? Everything will be copied over, with the exception of submissions and scores.
        </Text>
        <Label className="mt-4">Evaluation event title</Label>
        <Input
          value={name}
          onChange={({ target }) => setName(target.value)}
          placeholder="Enter a unique title for this evaluation event"
          isDisabled={isSaving}
          isRequired
          autoFocus
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
