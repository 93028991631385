import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Route } from '@cotiss/common/components/route.component'
import { ConflictOfInterestListPage } from '@cotiss/conflict-of-interest/pages/conflict-of-interest-list.page'
import { ConflictOfInterestViewPage } from '@cotiss/conflict-of-interest/pages/conflict-of-interest-view.page'

const ConflictOfInterestLayout = memo(() => (
  <Switch>
    <Route path="/conflict-of-interest/list" exact>
      <ConflictOfInterestListPage isAllConflicts />
    </Route>
    <Route path="/conflict-of-interest/view/:conflictOfInterestId" exact>
      <ConflictOfInterestViewPage />
    </Route>
    <Route path="/conflict-of-interest/me/list" exact>
      <ConflictOfInterestListPage />
    </Route>
    <Route path="/conflict-of-interest/me/view/:conflictOfInterestId" exact>
      <ConflictOfInterestViewPage />
    </Route>
  </Switch>
))

export default ConflictOfInterestLayout
