import { FormEvent, memo, useEffect, useMemo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Field } from '@cotiss/common/components/field.component'
import { Form } from '@cotiss/common/components/form.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { Text } from '@cotiss/common/components/text.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { ContractStepCardSkeletonLoading } from '@cotiss/contract/components/contract-step-card-skeleton-loading.component'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'

type Props = {
  onNext: () => void
  onBack: (() => void) | null
  contractShellId: string
}

export const ContractWizardCessationStep = memo(({ onNext, onBack, contractShellId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { openToast } = useToast()
  const { ceaseContract } = useMutateContractShell()
  const { track } = useAnalytics()

  const contract = useMemo(() => (contractShell ? contractService.getContract(contractShell, ['DRAFTING']) : null), [contractShell])
  const [isSaving, setIsSaving] = useState(false)
  const [reason, setReason] = useState(contract?.cessationReason || '')

  useEffect(() => {
    track('contract_wizard_cessation_view')
  }, [])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    // If nothing has changed
    if (reason === contract?.cessationReason) {
      onNext()
      return
    }

    if (!contract) {
      sentryService.captureException({
        exception: 'Attempted to cease contract but no contract was found',
        extras: { contractShellId: contractShell?._id },
      })
      openToast(`Whoops, couldn't save that update. Please try again.`, 'danger')
      return
    }

    try {
      setIsSaving(true)
      await ceaseContract({ contractShellId, contractId: contract?._id, body: { cessationReason: reason } })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    onNext()
  }

  if (isLoading) {
    return (
      <ContractStepCardSkeletonLoading>
        <div className="p-6">
          <Field label={<Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />}>
            <Skeleton className="bg-primary-200 h-4 w-1/2 mb-10" />
          </Field>
        </div>
      </ContractStepCardSkeletonLoading>
    )
  }

  if (!contractShell) {
    return (
      <Card>
        <div className="p-6 h-96 flex items-center justify-center">
          <Text>Couldn&apos;t load contract. Please try again.</Text>
        </div>
      </Card>
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Card>
        <CardHeader className="flex items-center justify-between">
          <div>
            <Text className="mb-1" variant="light" size="sm">
              {contractShell?.title}
            </Text>
            <Text className="font-semibold" variant="heading" size="h5">
              Cessation of contract
            </Text>
          </div>
          <div className="ml-4">
            {onBack && (
              <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
                Back
              </Button>
            )}
            <Button type="submit" variant="secondary" size="sm" isDisabled={isLoading || isSaving}>
              Continue
            </Button>
          </div>
        </CardHeader>
        <div className="p-6">
          <Field className="mb-12" label="Reason for cessation (optional)">
            <TextArea
              className="h-36"
              placeholder="Enter reason for cessation"
              value={reason}
              onChange={({ target }) => setReason(target.value)}
              isDisabled={isSaving}
            />
          </Field>
        </div>
      </Card>
    </Form>
  )
})
