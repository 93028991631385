import { Fragment, ReactNode, useMemo } from 'react'
import classNames from 'classnames'
import { find, orderBy } from 'lodash'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { Text } from '@cotiss/common/components/text.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { userService } from '@cotiss/user/user.service'

type Props = {
  contractShellId: string
  contractId: string
  approvalId: string | undefined
}

// TODO: in the future these logs will be read in from the BE. For now constructing on the FE
type HistoryLog = {
  content: ReactNode
  comment?: string
}

export const ContractApprovalHistory = ({ contractShellId, contractId, approvalId }: Props) => {
  const { contractShell, isLoading } = useGetContractShell(contractShellId)
  const { approval, contract } = useMemo(() => {
    const contract = find(contractShell?.contracts, (contract) => contract._id === contractId)
    return { approval: find(contract?.approvals, ({ _id }) => _id === approvalId), contract }
  }, [contractShell, contractId])

  const logs: HistoryLog[] = useMemo(() => {
    if (!contract || !approval) {
      return []
    }

    const tempLogs: HistoryLog[] = [
      {
        // As we are constructing these logs in the FE for now, this entry is always applicable an therefore is hard-coded
        content: (
          <Text className="mr-1 ml-6">
            <span className="text-gray-400">Contract variation made on the {datetimeService.format(contract.createdAt, 'do MMM yyyy')} by </span>{' '}
            {userService.getFullName(contract.createdBy)} (requester)
          </Text>
        ),
      },
    ]

    let shouldContinue = true

    orderBy(approval.approvers, 'order').forEach((approver) => {
      if (!shouldContinue) {
        return
      }
      if (!['PENDING_APPROVAL', 'NOT_SUBMITTED'].includes(approver.status) && approver.sentAt) {
        tempLogs.push({
          content: (
            <Text className="mr-1 ml-6">
              <span className="text-gray-400">Sent for approval on the {datetimeService.format(approver.sentAt, 'do MMM yyyy')} to </span>{' '}
              {userService.getFullName(approver.assigned)}
            </Text>
          ),
        })
        shouldContinue = false
      }

      if (approver.status === 'REQUESTED_CHANGES' && approver.actionedAt) {
        tempLogs.push({
          content: (
            <Text className="mr-1 ml-6">
              <span className="text-gray-400">
                Request <span className="text-pink-500">Rejected</span> on the {datetimeService.format(approver.actionedAt, 'do MMM yyyy')} by
              </span>{' '}
              {userService.getFullName(approver.assigned)}
            </Text>
          ),
          comment: approver.comment || 'No comment provided',
        })
        shouldContinue = false
      }
      if (approver.status === 'APPROVED' && approver.actionedAt) {
        tempLogs.push({
          content: (
            <Text className="mr-1 ml-6">
              <span className="text-gray-400">
                Request <span className="text-green-500">Approved</span> on the {datetimeService.format(approver.actionedAt, 'do MMM yyyy')} by{' '}
              </span>{' '}
              {userService.getFullName(approver.assigned)}
            </Text>
          ),
          comment: approver.comment || 'No comment provided',
        })
      }
      // If we've made it down here, we should continue to the next approver
      shouldContinue = true
    })

    return tempLogs
  }, [contract, approval])

  if (isLoading) {
    return (
      <div>
        <div className="flex items-center">
          <Skeleton className="w-6 h-6 mr-4" />
          <Skeleton className="w-full h-8" />
        </div>
        <div className="flex items-center mt-8">
          <Skeleton className="w-6 h-6 mr-4" />
          <Skeleton className="w-full h-8" />
        </div>
      </div>
    )
  }

  if (!approval || !contract) {
    return <ErrorPanel />
  }

  return (
    <>
      {logs.map((log, count) => (
        <Fragment key={count}>
          <div className="flex items-center h-[70px]">
            <div className="relative flex justify-center">
              {count !== 0 && <div className="absolute bottom-0 w-[1.5px] h-[50px] bg-secondary-300 flex-shrink-0"></div>}
              <div className="h-2 w-2 bg-secondary-300 rounded-full flex-shrink-0"></div>
              {count + 1 !== logs.length && (
                <div
                  className={classNames('absolute top-0 w-[1.5px] bg-secondary-300 flex-shrink-0', {
                    'h-[140px]': log.comment,
                    'h-[50px]': !log.comment,
                  })}
                ></div>
              )}
            </div>
            <div className="line-clamp-3">{log.content}</div>
          </div>
          <div>
            {log.comment && (
              <div className="rounded-t-lg border border-gray-200 ml-8 mb-4 max-w-xl">
                <div className="p-2 border-b border-gray-200 uppercase text-xs text-light">Comment</div>
                <Text className="p-2 truncate" title={log.comment}>
                  {log.comment}
                </Text>
              </div>
            )}
          </div>
        </Fragment>
      ))}
    </>
  )
}
