import { memo, useState } from 'react'
import { filter, includes, map } from 'lodash'
import { Banner } from '@cotiss/common/components/banner.component'
import { CardFormHeader } from '@cotiss/common/components/card-form-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentList } from '@cotiss/document/components/document-list.component'
import { TenderRequestNonPriceDocumentUploadModal } from '@cotiss/tender/modals/tender-request-non-price-document-upload.modal'
import { TenderRequestPriceDocumentUploadModal } from '@cotiss/tender/modals/tender-request-price-document-upload.modal'
import { useGetTender } from '@cotiss/tender/resources/use-get-tender.resource'
import { useMutateTender } from '@cotiss/tender/resources/use-mutate-tender.resource'

type Props = {
  tenderId: string
  isEditable?: boolean
}

export const TenderRequestResponseFormsTab = memo(({ tenderId, isEditable }: Props) => {
  const { openToast } = useToast()
  const { openModal } = useCallout()
  const { updateTender } = useMutateTender()
  const [isSaving, setIsSaving] = useState(false)
  const { tender, isLoading } = useGetTender(tenderId)

  const handleBulkRemoveNonPriceAttachments = async (documentIdsToRemove: string[]) => {
    try {
      setIsSaving(true)
      const nonPriceAttachments = map(
        filter(tender?.nonPriceAttachments, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateTender(tenderId, { nonPriceAttachments })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  const handleBulkRemovePriceAttachments = async (documentIdsToRemove: string[]) => {
    try {
      setIsSaving(true)
      const priceAttachments = map(
        filter(tender?.priceAttachments, ({ _id }) => !includes(documentIdsToRemove, _id)),
        ({ _id }) => _id
      )
      await updateTender(tenderId, { priceAttachments })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <div className="max-w-5xl">
      <Card className="mb-4">
        <CardFormHeader heading="Non-price forms" hasSeparator={false} />
        <Text className="mb-4" variant="light">
          Upload the forms relating to non-price criteria. These will be filled out and submitted by responders.
        </Text>
        <Hr className="my-4" />
        <Banner className="mb-4" icon="visible" iconVariant="secondary" variant="light">
          <div>
            <Text className="font-semibold" variant="heading">
              The below information will be displayed on the live listing
            </Text>
            <Text size="sm">External or invited parties will have access to this information.</Text>
          </div>
        </Banner>
        {!isLoading && !tender?.nonPriceAttachments?.length && (
          <NoDataPlaceholder
            label="You have not uploaded any non-price forms yet"
            ctaLabel="+ Upload documents"
            onCtaClick={isEditable ? () => openModal(<TenderRequestNonPriceDocumentUploadModal tenderId={tenderId} />) : undefined}
          />
        )}
        {(isLoading || Boolean(tender?.nonPriceAttachments?.length)) && (
          <DocumentList
            documents={tender?.nonPriceAttachments}
            onUpload={isEditable ? () => openModal(<TenderRequestNonPriceDocumentUploadModal tenderId={tenderId} />) : undefined}
            onBulkRemove={isEditable ? handleBulkRemoveNonPriceAttachments : undefined}
            isDisabled={isSaving}
          />
        )}
      </Card>
      <Card className="mb-4">
        <CardFormHeader heading="Price forms" hasSeparator={false} />
        <Text className="mb-4" variant="light">
          Upload the forms relating to price criteria. These will be filled out and submitted by responders.
        </Text>
        <Hr className="my-4" />
        <Banner className="mb-4" icon="visible" iconVariant="secondary" variant="light">
          <div>
            <Text className="font-semibold" variant="heading">
              The below information will be displayed on the live listing
            </Text>
            <Text size="sm">External or invited parties will have access to this information.</Text>
          </div>
        </Banner>
        {!isLoading && !tender?.priceAttachments?.length && (
          <NoDataPlaceholder
            label="You have not uploaded any price forms yet"
            ctaLabel="+ Upload documents"
            onCtaClick={isEditable ? () => openModal(<TenderRequestPriceDocumentUploadModal tenderId={tenderId} />) : undefined}
          />
        )}
        {(isLoading || Boolean(tender?.priceAttachments?.length)) && (
          <DocumentList
            documents={tender?.priceAttachments}
            onUpload={isEditable ? () => openModal(<TenderRequestPriceDocumentUploadModal tenderId={tenderId} />) : undefined}
            onBulkRemove={isEditable ? handleBulkRemovePriceAttachments : undefined}
            isDisabled={isSaving}
          />
        )}
      </Card>
    </div>
  )
})
