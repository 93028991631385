import { memo, useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, findIndex } from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { routerService } from '@cotiss/common/services/router.service'
import { useListMetafield } from '@cotiss/metafield/resources/use-list-metafield.resource'
import { SupplierViewDetails } from '@cotiss/supplier/components/supplier-view-details.component'
import { SupplierViewCertificationTab } from '@cotiss/supplier/tabs/supplier-view-certification.tab'
import { SupplierViewContactTab } from '@cotiss/supplier/tabs/supplier-view-contact.tab'
import { SupplierViewGeneralTab } from '@cotiss/supplier/tabs/supplier-view-general.tab'
import { SupplierViewJobTab } from '@cotiss/supplier/tabs/supplier-view-job.tab'
import { SupplierViewMetafieldsTab } from '@cotiss/supplier/tabs/supplier-view-metafields.tab'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type SupplierViewTab = 'metafields' | 'general' | 'jobs' | 'certifications' | 'users'

export const SupplierViewPage = memo(() => {
  const { user } = useGetLoggedInUser()
  const { push, replace } = useHistory()
  const { permissions } = useUserAccess()
  const { id, tab } = useParams<{ id: string; tab?: SupplierViewTab }>()
  const { metafields } = useListMetafield({ entityType: 'SUPPLIER_PROFILE' })
  const isUserProfile = useMemo(() => user?.account.organisation?._id === id, [user, id])
  const [isEditable, setIsEditable] = useState(isUserProfile)

  const tabs = useMemo(() => {
    const tabs: TabModel<SupplierViewTab>[] = [
      { id: 'metafields', label: 'Custom fields', isHidden: isUserProfile || !permissions.isBuyer || !metafields?.length },
      { id: 'general', label: 'General' },
      { id: 'jobs', label: 'Past work' },
      { id: 'certifications', label: 'Certifications' },
    ]

    if (!isUserProfile) {
      tabs.push({ id: 'users', label: 'Users' })
    }

    return filter(tabs, (tab) => !tab.isHidden)
  }, [metafields])

  const { step, transition, onTransition } = useTransition({ initialStep: findIndex(tabs, ({ id }) => id === tab) + 1 })

  useEffect(() => {
    setIsEditable(isUserProfile)
  }, [isUserProfile])

  useEffect(() => {
    if (!tab) {
      replace(routerService.getHref('/supplier/view/:id/:tab?', { id, tab: tabs[0].id }))
    }

    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    if (newStep && step !== newStep) {
      onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
    }
  }, [tab, tabs])

  const activeTabId = tabs[step - 1].id

  return (
    <Page>
      <PageContent className="flex items-start justify-between">
        <SupplierViewDetails
          organisationId={id}
          onTabChange={(tab) => push(routerService.getHref('/supplier/view/:id/:tab?', { id, tab }))}
          setIsEditable={setIsEditable}
          isUserProfile={isUserProfile}
          isEditable={isEditable}
        />
        <div className="w-2/3">
          <Tabs<SupplierViewTab>
            className="mb-4"
            tab={tab}
            tabs={tabs}
            onChange={({ id: tab }) => push(routerService.getHref('/supplier/view/:id/:tab?', { id, tab }))}
            variant="square"
          />
          <AnimatePresence initial={false} mode="wait">
            <TransitionContainer key={step} transition={transition}>
              {activeTabId === 'metafields' && <SupplierViewMetafieldsTab organisationId={id} isEditable={!isUserProfile && permissions.isBuyer} />}
              {activeTabId === 'general' && <SupplierViewGeneralTab organisationId={id} isEditable={isEditable} />}
              {activeTabId === 'jobs' && <SupplierViewJobTab organisationId={id} isEditable={isEditable} />}
              {activeTabId === 'certifications' && <SupplierViewCertificationTab organisationId={id} isEditable={isEditable} />}
              {activeTabId === 'users' && <SupplierViewContactTab organisationId={id} />}
            </TransitionContainer>
          </AnimatePresence>
        </div>
      </PageContent>
    </Page>
  )
})
