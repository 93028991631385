import { ReactNode } from 'react'
import classNames from 'classnames'

type Props<T> = {
  className?: string
  children: ReactNode
  variant?: 'default' | 'info'
  name?: string
  value?: T
  onChange?: (value?: T) => void
  isSelected?: boolean
  isRequired?: boolean
  isReadOnly?: boolean
  isDisabled?: boolean
}

export function RadioCard<T>(props: Props<T>) {
  const { className, children, variant = 'default', name, value, onChange, isSelected, isReadOnly, isRequired, isDisabled } = props
  let classes = classNames(className, 'relative inline-block rounded-lg', {
    'opacity-50': isDisabled,
    'pointer-events-none': isDisabled || isReadOnly,
    'cursor-pointer': !isDisabled && !isReadOnly,
  })

  if (variant === 'default') {
    classes = classNames(classes, {
      'bg-white': !isSelected,
      'bg-secondary-50': isSelected,
    })
  }

  if (variant === 'info') {
    classes = classNames(classes, 'border', {
      'bg-primary-50 border-transparent': !isSelected,
      'bg-blue-200 border-blue-600': isSelected,
    })
  }

  return (
    <label className={classes}>
      <input
        className="sr-only"
        onChange={() => onChange && onChange(value)}
        type="radio"
        name={name}
        checked={isSelected}
        required={isRequired}
        disabled={isDisabled}
      />
      {children}
    </label>
  )
}
