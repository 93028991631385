import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { every, find, map } from 'lodash'
import { VerticalTabButton } from '@cotiss/common/containers/vertical-tabs/vertical-tab-button.component'
import { VerticalTabsActiveIndicator } from '@cotiss/common/containers/vertical-tabs/vertical-tabs-active-indicator.component'
import { VerticalTabModel } from '@cotiss/common/containers/vertical-tabs/vertical-tabs.model'

type Props<T> = {
  className?: string
  onChange?: (activeTab: VerticalTabModel<T>) => void
  tab?: T | null
  tabs: VerticalTabModel<T>[]
  isDisabled?: boolean
  href?: string
}

export function VerticalTabs<T>({ className, tab, tabs, onChange, isDisabled }: Props<T>) {
  const tabButtonRefs = useRef<(HTMLButtonElement | HTMLAnchorElement)[]>([])
  const initialTabById = tab && find(tabs, ({ id }) => Boolean(id && id === tab))
  const [tabsToManage, setVerticalTabs] = useState<VerticalTabModel<T>[]>(tabs)
  const [activeTab, setActiveTab] = useState<VerticalTabModel<T> | undefined>(initialTabById || tabs[0])
  const classes = classNames(className, 'relative flex flex-col shrink-0 items-start')

  useEffect(() => {
    setVerticalTabs(tabs)
    tabButtonRefs.current = []
  }, [tabs])

  useEffect(() => {
    // Trigger this on change of both tab and tabs, as the parent component might have re-calculated
    // which tabs are available
    const activeTab = tab && find(tabs, ({ id }) => Boolean(id && id === tab))

    activeTab !== null && setActiveTab(activeTab)
  }, [tab, tabs])

  const handleSetActiveTab = (activeTab: VerticalTabModel<T>) => {
    setActiveTab(activeTab)

    if (onChange) {
      onChange(activeTab)
    }
  }

  if (!every(tabs, ({ id: idToCheck }) => find(tabsToManage, { id: idToCheck }))) {
    return null
  }

  return (
    <div className={classes}>
      <VerticalTabsActiveIndicator<T> tabButtonRefs={tabButtonRefs} activeTab={activeTab} tabs={tabsToManage} />
      {map(tabs, (tab, index) => (
        <VerticalTabButton<T>
          key={index}
          activeTab={activeTab}
          setActiveTab={handleSetActiveTab}
          tabs={tabsToManage}
          ref={(ref) => ref && tabButtonRefs.current.push(ref)}
          isDisabled={isDisabled}
          {...tab}
        />
      ))}
    </div>
  )
}
