import { memo } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ConflictOfInterestList } from '@cotiss/conflict-of-interest/components/conflict-of-interest-list.component'
import { ConflictOfInterestCreateNewModal } from '@cotiss/conflict-of-interest/modals/conflict-of-interest-create-new.modal'

type Props = {
  procurementId: string
}

export const ProcurementOverviewConflictOfInterestTab = memo(({ procurementId }: Props) => {
  const { openModal } = useCallout()

  return (
    <>
      <div className="flex items-center justify-between">
        <Text className="font-semibold" size="h7" variant="heading">
          Conflict of Interest Declarations
        </Text>
        <div className="flex items-center ml-8">
          <Button size="sm" onClick={() => openModal(<ConflictOfInterestCreateNewModal procurementId={procurementId} />)}>
            Request conflict declaration
          </Button>
        </div>
      </div>
      <Hr className="my-4" />
      <ConflictOfInterestList procurementId={procurementId} />
    </>
  )
})
