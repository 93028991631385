import { memo } from 'react'
import { Field } from '@cotiss/common/components/field.component'
import { RichText } from '@cotiss/common/components/rich-text.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { ConflictOfInterestTermsBackground } from '@cotiss/conflict-of-interest/components/conflict-of-interest-terms-background.component'
import { useGetConflictOfInterest } from '@cotiss/conflict-of-interest/resources/use-get-conflict-of-interest.resource'

type Props = {
  conflictOfInterestId?: string
}

export const ConflictOfInterestViewCompletedTerms = memo(({ conflictOfInterestId }: Props) => {
  const { conflictOfInterest, isLoading } = useGetConflictOfInterest(conflictOfInterestId)

  return (
    <>
      <Field className="mt-8" label="Background">
        <ConflictOfInterestTermsBackground conflictOfInterestId={conflictOfInterestId} />
      </Field>
      <Field className="mt-8" label="Terms">
        {!isLoading && <>{conflictOfInterest?.terms && <RichText value={conflictOfInterest.terms} />}</>}
        {isLoading && (
          <>
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-full mt-1" />
            <Skeleton className="h-3 w-2/3 mt-1" />
          </>
        )}
      </Field>
    </>
  )
})
