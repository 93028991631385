import { useEffect, useState, ReactNode } from 'react'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { useDeepMemo } from '@cotiss/common/hooks/use-deep-memo.hook'
import { useSortTable } from '@cotiss/common/hooks/use-sort-table.hook'
import { AdvancedFiltersProvider, useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { pickBy, map } from 'lodash'
import { PaginationModel } from '@cotiss/common/components/pagination.component'
import { Filter } from '@cotiss/common/models/filter.model'
import {
  ContractSearchAndFiltersContextProvider,
  ContractSearchAndFiltersContextState,
  useContractSearchAndFiltersContext,
} from '@cotiss/contract/components/contract-search-and-filters-context.component'
import { FilterContractShellSortKey, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { ContractShellFilterPopulatedModel } from '@cotiss/contract/contract.model'
import { CONTRACT_SEARCH_AND_FILTERS_FILTER_FIELDS_WHITELIST } from '@cotiss/contract/contract.constants'

export const useContractSearchAndFilters = () => {
  const { openToast } = useToast()

  const { queryState, processedFilters, queryStateDispatch, isQuerying } = useContractSearchAndFiltersContext()
  const { searchQuery, currentPage } = queryState

  const { advancedFiltersDispatch, validAdvancedFilters } = useAdvancedFiltersContext()
  const { filterContractShell } = useMutateContractShell()
  const [isLoadingContractShells, setIsLoadingContractShells] = useState(false)
  const [contractShells, setContractShells] = useState<ContractShellFilterPopulatedModel[]>([])
  const [pagination, setPagination] = useState<PaginationModel>()
  const { sortKey, sortDirection, onSort } = useSortTable<FilterContractShellSortKey>({ initialKey: 'createdAt', initialSortDirection: 'desc' })

  const handlePageChange = (page: number) => {
    queryStateDispatch({ type: 'UPDATE_CURRENT_PAGE', payload: page })
  }

  useEffect(() => {
    queryStateDispatch({
      type: 'UPDATE_ADVANCED_FILTERS',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      payload: map(validAdvancedFilters, ({ id, ...rest }) => ({ ...rest } as Filter)),
    })
  }, [validAdvancedFilters])

  const refreshContractShells = async () => {
    try {
      setIsLoadingContractShells(true)
      const { contractShells, pagination, meta } = await filterContractShell({
        filters: processedFilters,
        searchQuery,
        currentPage: currentPage,
        pageSize: 20,
        sort: sortKey,
        order: sortDirection,
      })

      setContractShells(contractShells)
      setPagination(pagination)

      // Whitelist only filter fields that appear in the whitelist OR custom metafields
      const filterFields = pickBy(meta, (_, key) => CONTRACT_SEARCH_AND_FILTERS_FILTER_FIELDS_WHITELIST.includes(key) || key.startsWith('metafield'))
      advancedFiltersDispatch({ type: 'SET_FILTER_FIELDS', payload: filterFields })
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
    setIsLoadingContractShells(false)
  }
  const contractListQuery = useDeepMemo(
    () => ({
      queryState,
      sortKey,
      sortDirection,
    }),
    [queryState, sortKey, sortDirection]
  )

  useEffect(() => {
    refreshContractShells()
  }, [contractListQuery])

  return {
    isQuerying,
    queryState,
    queryStateDispatch,
    contractShells,
    isLoadingContractShells,
    pagination,
    handlePageChange,
    onSort,
    processedFilters,
    refreshContractShells,
  }
}

export const ContractSearchAndFiltersProvider = ({
  children,
  initialState,
}: {
  children: ReactNode
  initialState: Partial<ContractSearchAndFiltersContextState>
}) => {
  const initialAdvancedFilters = initialState?.advancedFilters

  return (
    <ContractSearchAndFiltersContextProvider initialState={initialState}>
      <AdvancedFiltersProvider initialFilters={initialAdvancedFilters}>{children}</AdvancedFiltersProvider>
    </ContractSearchAndFiltersContextProvider>
  )
}
