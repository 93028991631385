import { memo, useEffect, useMemo, useState } from 'react'
import { filter, map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { TableRowCta } from '@cotiss/common/components/table-row-cta.component'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { ConfirmModal } from '@cotiss/common/containers/callout/modal/confirm-modal.component'
import { datetimeService } from '@cotiss/common/services/datetime.service'
import { documentService } from '@cotiss/document/document.service'
import { useGetDocument } from '@cotiss/document/resources/use-get-document.resource'
import { OrganisationLogo } from '@cotiss/organisation/components/organisation-logo.component'
import { organisationService } from '@cotiss/organisation/organisation.service'
import { TenderResponseNonPriceDocumentUploadModal } from '@cotiss/tender-response/modals/tender-response-non-price-document-upload.modal'
import { TenderResponsePriceDocumentUploadModal } from '@cotiss/tender-response/modals/tender-response-price-document-upload.modal'
import { useGetTenderResponse } from '@cotiss/tender-response/resources/use-get-tender-response.resource'
import { useMutateTenderResponse } from '@cotiss/tender-response/resources/use-mutate-tender-response.resource'

type Props = {
  tenderResponseId: string
}

export const TenderResponseSubmissionUpdateDrawer = memo(({ tenderResponseId }: Props) => {
  const { openModal } = useCallout()
  const { updateTenderResponse } = useMutateTenderResponse()
  const [documentIdToDownload, setDocumentIdToDownload] = useState('')
  const { tenderResponse, isLoading } = useGetTenderResponse(tenderResponseId)
  const { document: documentToDownload } = useGetDocument(documentIdToDownload)

  useEffect(() => {
    if (documentToDownload?.downloadUrl) {
      window.open(documentToDownload.downloadUrl)
      setDocumentIdToDownload('')
    }
  }, [documentToDownload])

  const handleNonPriceDocumentDelete = async (documentIdToRemove: string) => {
    if (!tenderResponse) {
      return
    }

    const nonPriceAttachments = map(
      filter(tenderResponse.nonPriceAttachments, ({ _id }) => documentIdToRemove !== _id),
      ({ _id }) => _id
    )

    await updateTenderResponse(tenderResponseId, { nonPriceAttachments })
  }

  const handlePriceDocumentDelete = async (documentIdToRemove: string) => {
    if (!tenderResponse) {
      return
    }

    const priceAttachments = map(
      filter(tenderResponse.priceAttachments, ({ _id }) => documentIdToRemove !== _id),
      ({ _id }) => _id
    )

    await updateTenderResponse(tenderResponseId, { priceAttachments })
  }

  const { nonPriceColumns, priceColumns } = useMemo(() => {
    const nonPriceColumns: TableColumn[] = [
      {
        heading: 'Document name',
        rows: map(tenderResponse?.nonPriceAttachments, ({ _id, fileName }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(_id)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
          cta: (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() => handleNonPriceDocumentDelete(_id)}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
      {
        heading: 'Date created',
        rows: map(tenderResponse?.nonPriceAttachments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(tenderResponse?.nonPriceAttachments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    const priceColumns: TableColumn[] = [
      {
        heading: 'Document name',
        rows: map(tenderResponse?.priceAttachments, ({ _id, fileName }) => ({
          content: () => (
            <Button
              className="text-sm underline cursor-pointer truncate inline-block align-middle"
              onClick={() => setDocumentIdToDownload(_id)}
              state="raw"
            >
              <Text>{fileName}</Text>
            </Button>
          ),
          cta: (
            <TableRowCta
              actions={[
                {
                  label: 'Delete',
                  onClick: () =>
                    openModal(
                      <ConfirmModal
                        heading="Delete document"
                        description="Are you sure you want to delete this document?"
                        onSubmit={() => handlePriceDocumentDelete(_id)}
                      />
                    ),
                },
              ]}
            />
          ),
        })),
      },
      {
        heading: 'Date created',
        rows: map(tenderResponse?.priceAttachments, ({ createdAt }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {datetimeService.format(createdAt, 'do MMM yyyy')}
            </Text>
          ),
        })),
      },
      {
        heading: 'Size',
        rows: map(tenderResponse?.priceAttachments, ({ fileSize }) => ({
          content: () => (
            <Text size="sm" variant="light">
              {documentService.formatSize(fileSize)}
            </Text>
          ),
        })),
      },
    ]

    return { nonPriceColumns, priceColumns }
  }, [tenderResponse])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Update submission
    </Text>
  )

  return (
    <Drawer header={renderHeader()}>
      <div className="flex items-center">
        <OrganisationLogo className="mr-4" organisation={tenderResponse?.procurementResponse.supplier} />
        <div className="flex flex-col space-y-4">
          <div>
            {tenderResponse?.procurementResponse.supplier.name && (
              <Text className="font-medium">{tenderResponse?.procurementResponse.supplier.name}</Text>
            )}
            {!tenderResponse?.procurementResponse.supplier.name && <Skeleton className="w-2/3 h-3.5" variant="gray" />}
            <div className="flex mt-2 items-center">
              {tenderResponse?.procurementResponse.supplier.claimedStatus && (
                <Badge variant={tenderResponse?.procurementResponse.supplier.claimedStatus === 'CLAIMED' ? 'success' : 'neutral'} state="outline">
                  {organisationService.getClaimedStatusText(tenderResponse?.procurementResponse.supplier.claimedStatus)}
                </Badge>
              )}
              {!tenderResponse?.procurementResponse.supplier.claimedStatus && <Skeleton className="w-1/2 h-4" variant="gray" />}
            </div>
          </div>
        </div>
      </div>
      <TableHeader className="flex justify-between items-center mt-8">
        <div>
          <Text size="sm" variant="light">
            Envelope 1
          </Text>
          <Text className="font-semibold">Non Price</Text>
        </div>
        <Button
          size="xs"
          state="translucent"
          variant="secondary-dark"
          onClick={() => tenderResponse && openModal(<TenderResponseNonPriceDocumentUploadModal tenderResponse={tenderResponse} />)}
          isDisabled={isLoading}
        >
          + Upload documents
        </Button>
      </TableHeader>
      <Table
        columns={nonPriceColumns}
        emptyCta={
          <Button
            state="text"
            variant="secondary"
            size="sm"
            onClick={() => tenderResponse && openModal(<TenderResponseNonPriceDocumentUploadModal tenderResponse={tenderResponse} />)}
            isDisabled={isLoading}
          >
            + Upload documents
          </Button>
        }
        isLoading={isLoading}
      />

      <TableHeader className="flex justify-between items-center mt-8">
        <div>
          <Text size="sm" variant="light">
            Envelope 2
          </Text>
          <Text className="font-semibold">Price</Text>
        </div>
        <Button
          size="xs"
          state="translucent"
          variant="secondary-dark"
          onClick={() => tenderResponse && openModal(<TenderResponsePriceDocumentUploadModal tenderResponse={tenderResponse} />)}
          isDisabled={isLoading}
        >
          + Upload documents
        </Button>
      </TableHeader>
      <Table
        columns={priceColumns}
        emptyCta={
          <Button
            state="text"
            variant="secondary"
            size="sm"
            onClick={() => tenderResponse && openModal(<TenderResponsePriceDocumentUploadModal tenderResponse={tenderResponse} />)}
            isDisabled={isLoading}
          >
            + Upload documents
          </Button>
        }
        isLoading={isLoading}
      />
    </Drawer>
  )
})
