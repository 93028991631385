import { memo, useMemo } from 'react'
import { AnimatePresence } from 'framer-motion'
import { filter, find, findIndex, flatMap } from 'lodash'
import { useParams } from 'react-router-dom'
import { Badge } from '@cotiss/common/components/badge.component'
import { Header } from '@cotiss/common/components/header.component'
import { PageContent } from '@cotiss/common/components/page-content.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { TransitionContainer } from '@cotiss/common/components/transition-container.component'
import { Tabs } from '@cotiss/common/containers/tabs/tabs.component'
import { TabModel } from '@cotiss/common/containers/tabs/tabs.model'
import { useTransition } from '@cotiss/common/hooks/use-transition.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { useListContractShell } from '@cotiss/contract/resources/use-list-contract-shell.resource'
import { ApprovalTaskTab } from '@cotiss/task/tabs/approval-task.tab'
import { ChairEvaluationTaskTab } from '@cotiss/task/tabs/chair-evaluation-task.tab'
import { ContractTaskTab } from '@cotiss/task/tabs/contract-task.tab'
import { EvaluationTaskTab } from '@cotiss/task/tabs/evaluation-task.tab'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'
import { useGetLoggedInUser } from '@cotiss/user/resources/use-get-logged-in-user.resource'

export type TaskListTabs = 'approval' | 'evaluation' | 'chair' | 'contract'

export const TaskListPage = memo(() => {
  const { tab } = useParams<{ tab?: TaskListTabs }>()
  const { permissions } = useUserAccess()

  const { contractShells } = useListContractShell({ filter: 'approvals' })
  const { user } = useGetLoggedInUser()

  const tabs = useMemo(() => {
    const nonDraftingContracts = filter(
      flatMap(contractShells, (contractShell) => contractShell.contracts),
      ({ status }) => status !== 'DRAFTING'
    )
    const numberContractsPendingApproval = filter(nonDraftingContracts, (contract) =>
      find(
        flatMap(
          filter(contract.approvals, ({ status }) => status === 'PENDING_APPROVAL'),
          ({ approvers }) => approvers
        ),
        (approver) => approver.assigned._id === user?._id && approver.status === 'PENDING_APPROVAL'
      )
    ).length

    const taskListTabs: TabModel<TaskListTabs>[] = [
      { id: 'approval', label: 'Procurement approvals', isHidden: !permissions.hasApproverBase },
      { id: 'evaluation', label: 'Evaluations', isHidden: !permissions.hasOldEvaluationAccess },
      { id: 'chair', label: 'Chair evaluations', isHidden: !permissions.hasOldEvaluationAccess },
      {
        id: 'contract',
        label: (
          <div className="flex">
            <Text className="mr-2">Contract approvals</Text>
            {Boolean(numberContractsPendingApproval) && (
              <Badge state="outline" variant="success">
                {numberContractsPendingApproval}
              </Badge>
            )}
          </div>
        ),
        isHidden: !permissions.hasApproverBase && permissions.hasContractAccess,
      },
    ]

    return filter(taskListTabs, (tab) => !tab.isHidden)
  }, [permissions, contractShells, user])

  const { step, transition, onTransition } = useTransition({
    initialStep: findIndex(tabs, ({ id }) => id === tab) + 1,
  })

  const activeTabId = tabs[step - 1].id

  const handleTabChange = (tab: TaskListTabs) => {
    const newStep = findIndex(tabs, ({ id }) => id === tab) + 1
    onTransition({ step: newStep, transition: newStep > step ? 'right' : 'left' })
  }

  if (!permissions.isBuyer || (!permissions.hasApproverBase && !permissions.hasOldEvaluationAccess)) {
    return <FourOhThreePage />
  }

  return (
    <Page>
      <Header variant="primary">
        <Text className="font-semibold" size="h5" variant="heading">
          Tasks
        </Text>
      </Header>

      <PageContent>
        <Tabs<TaskListTabs>
          className="border-b border-gray-300 w-full mb-8"
          tab={tab}
          tabs={tabs}
          onChange={({ id }) => handleTabChange(id)}
          variant="underline"
        />
        <AnimatePresence initial={false} mode="wait">
          <TransitionContainer key={step} transition={transition}>
            {activeTabId === 'approval' && <ApprovalTaskTab />}
            {activeTabId === 'evaluation' && <EvaluationTaskTab />}
            {activeTabId === 'chair' && <ChairEvaluationTaskTab />}
            {activeTabId === 'contract' && <ContractTaskTab />}
          </TransitionContainer>
        </AnimatePresence>
      </PageContent>
    </Page>
  )
})
