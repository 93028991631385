import { memo, useMemo } from 'react'
import AlertTriangleSvg from '@assets/svg/alert-triangle.svg'
import CotissLogoLightSvg from '@assets/svg/cotiss-logo-light.svg'
import { Badge } from '@cotiss/common/components/badge.component'
import { Page } from '@cotiss/common/components/page.component'
import { Text } from '@cotiss/common/components/text.component'
import { useFeature } from '@cotiss/common/hooks/use-feature.hook'
import { FourOhThreePage } from '@cotiss/common/pages/four-oh-three.page'
import { utilService } from '@cotiss/common/services/util.service'
import { AppErrorPage } from '@cotiss/app/components/app-error-page.component'

export const SsoErrorPage = memo(() => {
  const isSsoConfigurationEnabled = useFeature('sso-configuration')

  const {
    message: errorMessage,
    statusCode,
    error: errorCode,
  } = useMemo(() => utilService.getUrlSearchParams({ params: ['message', 'statusCode', 'error'] }), [])

  if (!isSsoConfigurationEnabled) {
    return <FourOhThreePage />
  }

  if (!errorMessage || !statusCode || !errorCode) {
    return <AppErrorPage />
  }

  return (
    <Page className="flex items-center justify-center bg-white">
      <div className="w-1/2 h-full p-12">
        <div className="flex items-center justify-center w-full h-full">
          <div className="flex flex-col items-center">
            <AlertTriangleSvg />
            <Badge variant="secondary" state="translucent" shape="square" className="mt-4">
              {statusCode} error
            </Badge>
            <Text className="text-xl mt-4 font-semibold" size="h3">
              {errorMessage}
            </Text>
            <Text className="text-xs mt-2" variant="light">
              Please contact your systems administrator | {errorCode}
            </Text>
          </div>
        </div>
      </div>
      <div className=" w-1/2 h-full p-4">
        <div className="flex flex-col items-start justify-between bg-secondary-500 rounded-lg h-full w-full p-10">
          <CotissLogoLightSvg className="h-8" />
          <h1 className="text-white text-5xl leading-tight">
            Better decisions.
            <br />
            Simplified process.
          </h1>
        </div>
      </div>
    </Page>
  )
})
