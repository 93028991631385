import { map } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { FILTER_OPERATION_NAMES } from '@cotiss/common/constants/filters.constants'
import { useAdvancedFiltersContext } from '@cotiss/common/modals/advanced-filters/advanced-filters.context'
import { FilterAllOperations, FilterFieldOptions, FilterValueType } from '@cotiss/common/models/filter.model'
import { datetimeService } from '@cotiss/common/services/datetime.service'

const NOT_FOUND_LABEL = 'Value not found'

const getFilterDetails = (filter: any, filterFields: FilterFieldOptions) => {
  const fieldConfig = filterFields[filter.field]

  const fieldDisplayName = fieldConfig.displayName
  const operationLabel = FILTER_OPERATION_NAMES[filter.operation as FilterAllOperations] ?? ''

  let filterValue = filter.value

  // string and number types require no formatting
  switch (fieldConfig.valueType as FilterValueType) {
    case 'array':
      filterValue = filterValue
        .map((val: any) => fieldConfig?.valueOptions?.find((opt: any) => opt.value === val)?.label ?? NOT_FOUND_LABEL)
        .filter(Boolean)
        .join(', ')
      break
    case 'boolean':
      filterValue = filterValue ? 'True' : 'False'
      break
    case 'date':
      filterValue = datetimeService.format(filter.value, 'do MMM yyyy')
      break
    default:
      break
  }

  return {
    fieldDisplayName,
    operationLabel,
    filterValue,
  }
}

export const AdvancedFiltersPillList = () => {
  const { validAdvancedFilters, filterFields, isInitialized, advancedFiltersDispatch } = useAdvancedFiltersContext()

  if (!isInitialized || !validAdvancedFilters.length) return null

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {map(validAdvancedFilters, (filter) => {
        const { fieldDisplayName, operationLabel, filterValue } = getFilterDetails(filter, filterFields)

        return (
          <Badge shape="rounded" state="translucent" variant="secondary" key={filter.id}>
            <span>{`${fieldDisplayName} ${operationLabel.toLowerCase()}: ${filterValue}`}</span>
            <Button onClick={() => advancedFiltersDispatch({ type: 'REMOVE_FILTER', payload: filter.id })} state="text">
              <Icon icon="x-close" variant="link" size={16} />
            </Button>
          </Badge>
        )
      })}
    </div>
  )
}
