import { memo, useMemo } from 'react'
import { find } from 'lodash'
import { Input } from '@cotiss/common/components/input.component'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { DocumentViewer } from '@cotiss/document/components/document-viewer.component'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractSummaryContractTab = memo(({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading: isContractShellLoading } = useGetContractShell(contractShellId)
  const contract = useMemo(() => find(contractShell?.contracts, (contract) => contract._id === contractId), [contractShell, contractId])

  const { document, documentShellTitle } = useMemo(() => {
    // Currently we only support one document shell of type CONTRACT
    const documentShell = find(contract?.documentShells, { type: 'CONTRACT' })
    return { document: documentShell?.attachments[0].document, documentShellTitle: documentShell?.name }
  }, [contract])

  if (isContractShellLoading) {
    return <Skeleton className="h-64" />
  }

  return (
    <div>
      {!document && <NoDataPlaceholder label="No contract uploaded" />}
      {document && (
        <>
          <div className="w-1/3">
            <Input value={documentShellTitle} isDisabled />
          </div>
          <div className="bg-gray-100 rounded-lg w-full p-6 mr-4 aspect-[1/1.414] mt-6">
            <DocumentViewer document={document} isDownloadable />
          </div>
        </>
      )}
    </div>
  )
})
