import { memo, useMemo, useState } from 'react'
import { GqlPerformanceScorecardUserFieldsFragment } from '@gql'
import { filter, includes, map, some, startCase, toLower } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { Table, TableColumn } from '@cotiss/common/components/table.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { Drawer } from '@cotiss/common/containers/callout/drawer/drawer.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { usePerformanceScorecardUser } from '@cotiss/performance/hooks/use-performance-scorecard-user.hook'
import { useListUser } from '@cotiss/user/resources/use-list-user.resource'
import { userService } from '@cotiss/user/user.service'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  performanceScorecardId: string
  onSubmit: () => Promise<void>
}

export const PerformanceScorecardOwnerAddDrawer = memo(({ performanceScorecardId, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeDrawer } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [userIds, setUserIds] = useState<string[]>([])
  const { users, isLoading: isUsersLoading } = useListUser()
  const { mutateCreatePerformanceScorecardUsers, queryPerformanceScorecardUserList } = usePerformanceScorecardUser()
  const [performanceScorecardUsers, setPerformanceScorecardUsers] = useState<GqlPerformanceScorecardUserFieldsFragment[]>([])

  useAsyncEffect(async () => {
    try {
      setIsLoading(true)
      const { items: performanceScorecardUsers } = await queryPerformanceScorecardUserList({
        filter: { performanceScorecardId, role: 'owner' },
      })

      setPerformanceScorecardUsers(performanceScorecardUsers)
      setIsLoading(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      closeDrawer()
    }
  }, [])

  const handleSubmit = async () => {
    setIsSaving(true)

    try {
      await mutateCreatePerformanceScorecardUsers({
        performanceScorecardId,
        performanceScorecardUsers: map(userIds, (userId) => ({ userId, roles: ['owner'] })),
      })
      await onSubmit()
      closeDrawer()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
    }
  }

  const handleChange = (userId: string) => {
    if (includes(userIds, userId)) {
      setUserIds(filter(userIds, (userIdToCheck) => userIdToCheck !== userId))
    } else {
      setUserIds([...userIds, userId])
    }
  }

  const { columns } = useMemo(() => {
    const columns: TableColumn[] = [
      {
        heading: ' ',
        thClassName: 'w-12',
        rows: map(users, ({ _id, permissions }) => {
          const isAdded = some(performanceScorecardUsers, { userId: _id })

          return {
            content: () => (
              <Checkbox
                value={_id}
                onChange={() => handleChange(_id)}
                isChecked={isAdded || includes(userIds, _id)}
                isDisabled={isAdded || isSaving || !includes(permissions, 'PERFORMANCE_MANAGER')}
              />
            ),
          }
        }),
      },
      {
        heading: 'Name',
        rows: map(users, (user) => {
          const isAdded = some(performanceScorecardUsers, { userId: user._id })
          const hasPermission = includes(user.permissions, 'PERFORMANCE_MANAGER')

          return {
            content: () => <Text>{userService.getFullName(user)}</Text>,
            cta: (isAdded || !hasPermission) && (
              <Tooltip tooltip={isAdded ? 'Already added' : 'User needs the "performance manager" permission to be assigned as an owner.'}>
                <Icon icon="lock" />
              </Tooltip>
            ),
          }
        }),
      },
      {
        heading: 'Email',
        rows: map(users, ({ email }) => ({
          content: () => <Text>{email}</Text>,
        })),
      },
      {
        heading: 'Roles',
        rows: map(users, ({ permissions }) => ({
          content: () => (
            <div>
              {!permissions.length && '--'}
              {map(permissions, (permission) => (
                <Badge key={permission} className="mr-1" state="translucent" variant="secondary">
                  {startCase(toLower(permission))}
                </Badge>
              ))}
            </div>
          ),
        })),
      },
    ]

    return { columns }
  }, [userIds, users, performanceScorecardUsers])

  const renderHeader = () => (
    <Text className="font-medium truncate" size="h5" variant="heading">
      Add owner
    </Text>
  )

  const renderFooter = () => (
    <div className="flex items-center">
      <Button type="submit" variant="secondary" isLoading={isSaving} isDisabled={!userIds.length}>
        Save
      </Button>
      <Text className="ml-2">{userIds.length} selected</Text>
    </div>
  )

  return (
    <Drawer header={renderHeader()} footer={renderFooter()} onSubmit={handleSubmit}>
      <TableHeader>
        <Text className="font-medium" size="lg">
          Select user
        </Text>
        <Text variant="light">Select a user below to continue.</Text>
      </TableHeader>
      <Table columns={columns} isLoading={isLoading || isUsersLoading} />
    </Drawer>
  )
})
