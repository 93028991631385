import { memo } from 'react'
import FourOhThreeLockSvg from '@assets/svg/403-lock.svg'
import { Badge } from '@cotiss/common/components/badge.component'
import { Page } from '@cotiss/common/components/page.component'
import { Section } from '@cotiss/common/components/section.component'
import { Text } from '@cotiss/common/components/text.component'

export const FourOhThreePage = memo(() => (
  <Page className="flex items-center justify-center">
    <Section className="text-center" isCentered>
      <FourOhThreeLockSvg className="mx-auto" />
      <Badge state="translucent" variant="secondary" shape="square">
        403 Error
      </Badge>
      <Text className="font-semibold text-center mt-6" size="h2" variant="heading">
        Access denied
      </Text>
      <Text className="mt-2" variant="light">
        You don&apos;t have permission to access this page.
      </Text>
    </Section>
  </Page>
))
