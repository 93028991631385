import { memo } from 'react'
import { Card } from '@cotiss/common/components/card.component'
import { ExternalLink } from '@cotiss/common/components/external-link.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { EXTERNAL_LINK } from '@cotiss/common/constants/external-link.constants'

export const BuyerViewSupportTab = memo(() => (
  <Card>
    <Label>Terms and conditions</Label>
    <Text>
      Please review Cotiss’s terms and conditions before applying to listings from this portal. Terms and conditions can be found{' '}
      <ExternalLink href={EXTERNAL_LINK.termsAndConditions} isInline>
        here
      </ExternalLink>
    </Text>

    <Label className="mt-8">Privacy policy</Label>
    <Text>
      Please review Cotiss’s privacy policy before viewing these listings. They can be found{' '}
      <ExternalLink href={EXTERNAL_LINK.privacyPolicy} isInline>
        here
      </ExternalLink>
    </Text>

    <Label className="mt-8">Contact us</Label>
    <ExternalLink href="mailto:customersuccess@cotiss.com?subject=Support%20Query">Contact us</ExternalLink>
  </Card>
))
