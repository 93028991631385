import { memo } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { filter } from 'lodash'
import { useUserAccess } from '@cotiss/user/hooks/use-user-access.hook'

export const APP_SIDE_NAV_TABS = ['buyer', 'supplier', 'service'] as const
export type AppSideNavTab = (typeof APP_SIDE_NAV_TABS)[number]

type Props = {
  activeTab: AppSideNavTab
  setActiveTab: (activeTab: AppSideNavTab) => void
}

export const AppSideNavTabs = memo(({ activeTab, setActiveTab }: Props) => {
  const { permissions } = useUserAccess()
  const accessCount = filter([permissions.hasBuyerAccess, permissions.hasSupplierAccess, permissions.hasServiceAccess], Boolean).length

  if (accessCount === 1) {
    return null
  }

  let animateX = '0'
  if (accessCount === 2) {
    if (permissions.hasBuyerAccess) {
      animateX = activeTab === 'buyer' ? '0' : '100%'
    } else {
      animateX = activeTab === 'supplier' ? '0' : '100%'
    }
  }

  if (accessCount === 3) {
    animateX = activeTab === 'buyer' ? '0' : activeTab === 'supplier' ? '100%' : '200%'
  }

  return (
    <div className="relative flex items-center justify-between bg-gray-100 rounded h-8 p-0.5 mt-4">
      <motion.div
        className={classNames('absolute left-0 top-0 h-full p-0.5', {
          'w-full': accessCount === 1,
          'w-1/2': accessCount === 2,
          'w-1/3': accessCount === 3,
        })}
        animate={{ x: animateX }}
        transition={{ type: 'tween' }}
        initial={false}
      >
        <div className="bg-white rounded h-full w-full" />
      </motion.div>
      {permissions.hasBuyerAccess && (
        <button className="relative w-full text-sm" onClick={() => setActiveTab('buyer')} type="button">
          Buyer
        </button>
      )}
      {permissions.hasSupplierAccess && (
        <button className="relative w-full text-sm" onClick={() => setActiveTab('supplier')} type="button">
          Supplier
        </button>
      )}
      {permissions.hasServiceAccess && (
        <button className="relative w-full text-sm" onClick={() => setActiveTab('service')} type="button">
          Service
        </button>
      )}
    </div>
  )
})
