import { FormEvent, memo, useState } from 'react'
import { map } from 'lodash'
import { Form } from '@cotiss/common/components/form.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { DocumentUpload } from '@cotiss/document/components/document-upload.component'
import { DocumentModel } from '@cotiss/document/document.models'
import { usePerformanceScorecardMetricCycleDocument } from '@cotiss/performance/hooks/use-performance-scorecard-metric-cycle-document.hook'

type Props = {
  performanceScorecardMetricCycleId: string
}

export const PerformanceScorecardMetricCycleDocumentUploadModal = memo(({ performanceScorecardMetricCycleId }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [documents, setDocuments] = useState<DocumentModel[]>([])
  const { mutateCreatePerformanceScorecardMetricCycleDocuments } = usePerformanceScorecardMetricCycleDocument()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await mutateCreatePerformanceScorecardMetricCycleDocuments({
        performanceScorecardMetricCycleId,
        documents: map(documents, ({ _id, fileName, fileSize, mimeType }) => ({
          documentId: _id,
          fileName,
          fileSize,
          mimeType,
        })),
      })
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[450px] max-w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Upload documents" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <DocumentUpload documents={documents} onChange={setDocuments} />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
