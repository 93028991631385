import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import { every, filter, map } from 'lodash'
import { Button } from '@cotiss/common/components/button.component'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { EvaluationEventEnvelopeWeightList } from '@cotiss/evaluation-event/components/evaluation-event-envelope-weight-list.component'
import { EvaluationEventParentCriteriaWeightList } from '@cotiss/evaluation-event/components/evaluation-event-parent-criteria-weight-list.component'
import { evaluationEventService } from '@cotiss/evaluation-event/evaluation-event.service'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEnvelope } from '@cotiss/evaluation-event/hooks/use-evaluation-envelope.hook'
import { useEvaluationEventAnalytics } from '@cotiss/evaluation-event/hooks/use-evaluation-event-analytics.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'

type Props = {
  onNext: () => void
  onBack: () => void
}

export const EvaluationEventWizardWeightingStep = memo(({ onNext, onBack }: Props) => {
  const { track } = useEvaluationEventAnalytics()
  const { evaluationEvent } = useEvaluationEvent()
  const { evaluationCriteria } = useEvaluationCriteria()
  const { evaluationEnvelopes } = useEvaluationEnvelope()
  const { canContinue } = useMemo(() => {
    const canContinue = every(evaluationEnvelopes, ({ id, weight }) => {
      return weight && every(filter(evaluationCriteria, { evaluationEnvelopeId: id, parentEvaluationCriteriaId: null }), ({ weight }) => weight)
    })

    return { canContinue }
  }, [evaluationEvent])

  const [envelopeWeightById, setEnvelopeWeightById] = useState<Record<string, number>>(
    evaluationEventService.getWeightById({ items: evaluationEnvelopes })
  )
  const envelopeWeightPercentageById = useMemo(() => {
    const totalEnvelopeWeight = evaluationEventService.getTotalWeight({ weightById: envelopeWeightById })

    return evaluationEventService.getWeightedPercentageById({ weightById: envelopeWeightById, totalWeight: totalEnvelopeWeight })
  }, [envelopeWeightById])

  useEffect(() => {
    track('evaluation_event_wizard_weight_view')
  }, [])

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <div>
          <Text className="font-semibold" variant="heading" size="h5">
            Weight
          </Text>
          <Text size="sm" variant="light">
            Every envelope and criteria must have a weight assigned.
          </Text>
        </div>
        <div className="ml-4">
          <Button className="mr-2" onClick={onBack} state="ghost" variant="secondary" size="sm">
            Back
          </Button>
          <Button onClick={onNext} variant="secondary" size="sm" isDisabled={!canContinue}>
            Continue
          </Button>
        </div>
      </CardHeader>
      <Text className="font-medium" variant="heading" size="lg">
        Envelope weights
      </Text>
      <Text size="sm" variant="light">
        Select the relative weights for all envelopes.
      </Text>
      <EvaluationEventEnvelopeWeightList
        className="mt-4"
        envelopes={evaluationEnvelopes}
        envelopeWeightById={envelopeWeightById}
        envelopeWeightPercentageById={envelopeWeightPercentageById}
        setEnvelopeWeightById={setEnvelopeWeightById}
        isEditable
      />

      <Text className="font-medium mt-8" variant="heading" size="lg">
        Criteria weights
      </Text>
      <Text size="sm" variant="light">
        Select the relative weights for all criteria.
      </Text>
      {map(evaluationEnvelopes, ({ id, name, order }, index) => (
        <Fragment key={id}>
          <TableHeader className={classNames({ 'mt-8': index, 'mt-4': !index })}>
            <Text size="sm" variant="light">
              Envelope {order}.
            </Text>
            <Text className="font-semibold">{name}</Text>
          </TableHeader>
          <EvaluationEventParentCriteriaWeightList
            parentCriteria={filter(evaluationCriteria, { evaluationEnvelopeId: id, parentEvaluationCriteriaId: null })}
            envelopeWeightPercentageById={envelopeWeightPercentageById}
            envelopeId={id}
            isEditable
          />
        </Fragment>
      ))}
    </Card>
  )
})
