import { CSSProperties, memo, ReactNode } from 'react'
import classNames from 'classnames'
import { Button } from '@cotiss/common/components/button.component'

type Props = {
  className?: string
  children?: ReactNode
  variant?: 'primary' | 'gray'
  title?: string
  style?: CSSProperties
  onClick?: () => void
}

export const Avatar = memo(({ className, children, variant = 'primary', title, style, onClick }: Props) => {
  const classes = classNames(className, 'inline-block rounded p-1', {
    'hover:bg-secondary-100': onClick,
  })
  const avatarClasses = classNames('flex items-center justify-center shrink-0 bg-white border text-xs rounded-full w-8 h-8', {
    'text-primary-500 border-primary-500': variant === 'primary',
    'text-gray-300 border-gray-300': variant === 'gray',
  })

  return onClick ? (
    <Button className={classes} onClick={onClick} state="raw" style={style}>
      <div className={avatarClasses}>{children}</div>
    </Button>
  ) : (
    <div className={classes} title={title} style={style}>
      <div className={avatarClasses}>{children}</div>
    </div>
  )
})
