import { memo } from 'react'
import { Switch } from 'react-router-dom'
import { Redirect } from '@cotiss/common/components/redirect.component'
import { Route } from '@cotiss/common/components/route.component'
import { PlanEventListPage } from '@cotiss/plan-event/pages/plan-event-list.page'
import { PlanEventViewPage } from '@cotiss/plan-event/pages/plan-event-view.page'

const PlanEventLayout = memo(() => {
  return (
    <Switch>
      <Route path="/plan-event/list/:tab?" exact>
        <PlanEventListPage />
      </Route>
      <Route path="/plan-event/view/:planEventId/:tab?/:nestedTab?" exact>
        <PlanEventViewPage />
      </Route>
      <Redirect from="/plan-event" to="/plan-event/list/:tab?" exact />
    </Switch>
  )
})

export default PlanEventLayout
