import { memo, useState } from 'react'
import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { ErrorPanel } from '@cotiss/common/components/error-panel.component'
import { ScrollableTable } from '@cotiss/common/components/scrollable-table.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { TableHeader } from '@cotiss/common/components/table-header.component'
import { Text } from '@cotiss/common/components/text.component'
import { useAsyncEffect } from '@cotiss/common/hooks/use-async-effect.hook'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { EvaluationEventSummaryScoring } from '@cotiss/evaluation-event/components/evaluation-event-summary-scoring.component'
import { useEvaluationCriteria } from '@cotiss/evaluation-event/hooks/use-evaluation-criteria.hook'
import { useEvaluationEvent } from '@cotiss/evaluation-event/hooks/use-evaluation-event.hook'
import { useEvaluationUser } from '@cotiss/evaluation-event/hooks/use-evaluation-user.hook'

export const EvaluationEventViewTeamScoringTab = memo(() => {
  const [isError, setIsError] = useState(false)
  const { evaluationEvent } = useEvaluationEvent()
  const [isLoading, setIsLoading] = useState(true)
  const { queryEvaluationUserList } = useEvaluationUser()
  const { queryEvaluationCriteriaList } = useEvaluationCriteria()

  useAsyncEffect(async () => {
    if (!evaluationEvent) {
      return
    }

    try {
      // TODO: We may have to think about paginating these resources.
      await Promise.all([
        queryEvaluationUserList({ filter: { evaluationEventId: evaluationEvent.id } }),
        queryEvaluationCriteriaList({ filter: { evaluationEventId: evaluationEvent.id } }),
      ])
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      setIsError(true)
    }

    setIsLoading(false)
  }, [])

  return (
    <Card>
      <CardHeader className="flex items-center justify-between">
        <Text className="font-semibold" variant="heading" size="h5">
          Scoring
        </Text>
      </CardHeader>
      {isLoading && (
        <>
          <TableHeader>
            <Skeleton className="h-3 w-1/3" />
          </TableHeader>
          <ScrollableTable
            fixedColumns={[
              {
                heading: 'Sub-criteria',
                rows: [],
              },
            ]}
            columns={[
              {
                heading: 'Rating scale',
                rows: [],
              },
            ]}
            isLoading
          />
        </>
      )}
      {!isLoading && isError && <ErrorPanel />}
      {!isLoading && !isError && <EvaluationEventSummaryScoring />}
    </Card>
  )
})
