import { CardHeader } from '@cotiss/common/components/card-header.component'
import { Card } from '@cotiss/common/components/card.component'
import { Hr } from '@cotiss/common/components/hr.component'
import { Text } from '@cotiss/common/components/text.component'
import { SettingsOrganisationFormLogo } from '@cotiss/settings/components/settings-organisation-form-logo.component'
import { SettingsOrganisationFormName } from '@cotiss/settings/components/settings-organisation-form-name.component'

export const SettingsOrganisationDisplayTab = () => {
  return (
    <Card>
      <CardHeader>
        <Text className="font-medium" variant="heading" size="h5">
          Display
        </Text>
      </CardHeader>

      <SettingsOrganisationFormLogo />

      <Hr className="my-4" />
      <SettingsOrganisationFormName />
    </Card>
  )
}
