import { FormEvent, memo, useMemo, useState } from 'react'
import { filter, flatMap, map, some, toLower, uniq, upperFirst } from 'lodash'
import { Select_DEPRECATED } from '@cotiss/common/components/deprecated/select.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Select } from '@cotiss/common/components/select.component'
import { TextArea } from '@cotiss/common/components/text-area.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ScheduledRateModel } from '@cotiss/contract/contract.model'
import { UpdateScheduledRatesBulkBody, useMutateContractShell } from '@cotiss/contract/resources/use-mutate-contract-shell.resource'
import { contractService } from '@cotiss/contract/contract.service'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { MetricUnit } from '@cotiss/common/models/units.model'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { METRIC_UNIT_OPTIONS } from '@cotiss/common/constants/units.constants'
import { useAnalytics } from '@cotiss/common/hooks/use-analytics.hook'
import { utilService } from '@cotiss/common/services/util.service'

type ScheduledRatesFormData = {
  id: string
  title: string
  tag: string
  amount: string
  unit: MetricUnit
  description: string
}

type Props = {
  scheduledRate?: ScheduledRateModel
  contractShellId: string
}

export const ContractWizardScheduleRatesModal = memo(({ scheduledRate, contractShellId }: Props) => {
  const { track } = useAnalytics()
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const { updateScheduledRatesBulk } = useMutateContractShell()
  const { contractShell } = useGetContractShell(contractShellId)

  const { allTags, existingScheduledRates, contract } = useMemo(() => {
    if (!contractShell) {
      return {
        tagOptionsText: [],
        existingScheduledRates: [],
        contract: null,
      }
    }

    const contract = contractService.getContract(contractShell, ['DRAFTING'])
    const allTags = uniq(flatMap(contractShell?.contracts, ({ scheduledRates }) => map(scheduledRates, (sr) => toLower(sr.tag))))

    return { allTags, existingScheduledRates: contract?.scheduledRates || [], contract }
  }, [contractShell])

  const [formData, setFormData] = useState<ScheduledRatesFormData>({
    id: scheduledRate?._id || utilService.generateUid(),
    title: scheduledRate?.title || '',
    tag: scheduledRate?.tag || '',
    amount: scheduledRate?.amount.toString() || '',
    unit: scheduledRate?.unit || 'h',
    description: scheduledRate?.description || '',
  })

  const tagOptions = useMemo(() => {
    const tagOptions = map(allTags, (tag) => ({
      value: tag,
      label: upperFirst(tag),
    }))

    if (formData.tag && !some(tagOptions, { value: formData.tag })) {
      tagOptions.push({
        value: formData.tag,
        label: formData.tag,
      })
    }

    return tagOptions
  }, [allTags, formData])

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!contract) {
      // TODO: error
      return
    }

    const parsedScheduledRates: UpdateScheduledRatesBulkBody['items'] = []

    // If we're adding a new rate
    if (!existingScheduledRates?.find((rate) => rate._id === formData.id)) {
      track('contract_wizard_schedule_rates_create_submit')
      parsedScheduledRates.push(...existingScheduledRates, {
        ...formData,
        index: existingScheduledRates.length,
        tag: formData.tag.length ? formData.tag : '',
        amount: Number(formData.amount),
      })
    }

    const existingRate = existingScheduledRates?.find((rate) => rate._id === formData.id)

    // If we're updating an existing rate
    if (existingRate) {
      track('contract_wizard_schedule_rates_update_submit')
      parsedScheduledRates.push(...filter(existingScheduledRates, (rate) => rate._id !== formData.id), {
        ...formData,
        index: existingRate.index,
        tag: formData.tag.length ? formData.tag : '',
        amount: Number(formData.amount),
      })
    }

    try {
      setIsSaving(true)
      await updateScheduledRatesBulk(contractShellId, contract?._id, { items: parsedScheduledRates })
      setIsSaving(false)
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }

    setIsSaving(false)
    closeModal()
  }

  return (
    <Form className="w-[450px]" onSubmit={handleSubmit}>
      <ModalHeader heading={`${scheduledRate ? 'Edit row' : 'Add row'}`} isDisabled={isSaving} />
      <ModalContent>
        <Label>Item</Label>
        <Input
          value={formData.title}
          placeholder="Enter item"
          onChange={({ target }) => setFormData({ ...formData, title: target.value })}
          isDisabled={isSaving}
          isRequired
        />

        <Label className="mt-6">Tag</Label>
        <Select
          value={formData.tag}
          options={tagOptions}
          onChange={(tag) => setFormData({ ...formData, tag })}
          onCreate={(tag) => setFormData({ ...formData, tag })}
          isDisabled={isSaving}
        />

        <div className="flex">
          <div className="mr-2 w-1/2">
            <Label className="mt-6">Unit price</Label>
            <Input
              type="number"
              value={formData.amount}
              onChange={({ target }) => setFormData({ ...formData, amount: target.value })}
              isDisabled={isSaving}
              placeholder="Enter unit price"
              isRequired
            />
          </div>
          <div>
            <Label className="mt-6">Unit</Label>
            <Select_DEPRECATED<MetricUnit>
              className="w-42"
              value={formData.unit}
              options={METRIC_UNIT_OPTIONS}
              onChange={(value) => setFormData({ ...formData, unit: value })}
              isDisabled={isSaving}
              placeholder
              isRequired
            />
          </div>
        </div>

        <Label className="mt-6">Description (optional)</Label>
        <TextArea
          className="h-32"
          value={formData.description}
          placeholder="Enter description"
          onChange={({ target }) => setFormData({ ...formData, description: target.value })}
          isDisabled={isSaving}
        />
      </ModalContent>
      <ModalFooter isSaving={isSaving} isForm />
    </Form>
  )
})
