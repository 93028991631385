import { memo, useState } from 'react'
import classNames from 'classnames'
import { map, uniqBy } from 'lodash'
import { Badge } from '@cotiss/common/components/badge.component'
import { Button } from '@cotiss/common/components/button.component'
import { DropdownContent } from '@cotiss/common/components/dropdown-content.component'
import { Dropdown } from '@cotiss/common/components/dropdown.component'
import { Icon } from '@cotiss/common/components/icon.component'
import { Text } from '@cotiss/common/components/text.component'
import { TenderValidationErrorModel } from '@cotiss/tender/tender.models'

type Props = {
  errors: TenderValidationErrorModel[]
  className?: string
}

// TODO: Move this to the tender domain.

export const RemainingTasksButton = memo(({ className, errors }: Props) => {
  const classes = classNames(className, 'relative')
  const validationErrors = uniqBy(errors, 'reason')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  return (
    <div className={classes}>
      <Button onClick={() => setIsDropdownOpen(true)} state="translucent" variant="secondary" size="sm">
        <Icon className="mr-2" icon="bullet-list" size={20} />
        Remaining tasks ({validationErrors.length})
        <Icon className="ml-2" icon="chevron-down" size={16} />
      </Button>
      <Dropdown className="right-0 origin-top-right" onClose={() => setIsDropdownOpen(false)} isOpen={isDropdownOpen}>
        <DropdownContent hasPadding={false}>
          <div className="flex items-center justify-between bg-white border-b border-gray-100 rounded-t-md py-2.5 px-4">
            <Text variant="light">Remaining Tasks</Text>
            {Boolean(validationErrors.length) && (
              <Badge className="ml-2" state="translucent" variant="warning">
                {validationErrors.length} required
              </Badge>
            )}
          </div>
          <div className="max-h-96 overflow-y-auto py-2 px-4">
            {!validationErrors.length && <Text className="text-center p-2">✨ No remaining tasks ✨</Text>}
            {Boolean(validationErrors.length) && (
              <>
                {map(validationErrors, ({ reason }, index) => (
                  <div key={index} className="relative flex items-center whitespace-normal rounded-lg p-2 w-64">
                    <div className="shrink-0 bg-pink-500 rounded-full h-2 w-2 mr-3" />

                    <Text>{reason}</Text>
                  </div>
                ))}
              </>
            )}
          </div>
        </DropdownContent>
      </Dropdown>
    </div>
  )
})
