import { ReactNode, HTMLProps, memo } from 'react'
import classNames from 'classnames'

type Props = HTMLProps<HTMLTableSectionElement> & {
  children: ReactNode
  className?: string
}

export const Tbody_DEPRECATED = memo(({ className, children, ...rest }: Props) => (
  <tbody {...rest} className={classNames(className, 'divide-y divide-gray-100')}>
    {children}
  </tbody>
))
