import { useCallback } from 'react'
import { SearchInput } from '@cotiss/common/components/search-input.component'
import { useDebouncedChangeHandler } from '@cotiss/common/hooks/use-debounced-change-handler.hook'
import { usePreferredSupplierSearchFiltersContext } from '@cotiss/preferred-supplier/components/preferred-supplier-search-filters-context.component'

type Props = {
  isLoading?: boolean
  isDisabled?: boolean
  className?: string
}

export const PreferredSupplierListSearchInput = ({ isDisabled, className }: Props) => {
  const { queryState, queryStateDispatch } = usePreferredSupplierSearchFiltersContext()

  const updateSearchQuery = useCallback((value: string) => queryStateDispatch({ type: 'UPDATE_SEARCH_QUERY', payload: value }), [queryStateDispatch])

  const { tempValue, handleDebouncedChange, handleClear } = useDebouncedChangeHandler(queryState.searchQuery, updateSearchQuery)

  return (
    <SearchInput
      onChange={({ target }) => handleDebouncedChange(target.value)}
      onClear={handleClear}
      isDisabled={isDisabled}
      value={tempValue}
      placeholder="Search organisation name"
      className={className}
    />
  )
}
