import { FormEvent, memo, useState } from 'react'
import { Button } from '@cotiss/common/components/button.component'
import { Checkbox } from '@cotiss/common/components/checkbox.component'
import { Form } from '@cotiss/common/components/form.component'
import { Input } from '@cotiss/common/components/input.component'
import { Label } from '@cotiss/common/components/label.component'
import { Text } from '@cotiss/common/components/text.component'
import { useCallout } from '@cotiss/common/containers/callout/callout.provider'
import { ModalContent } from '@cotiss/common/containers/callout/modal/modal-content.component'
import { ModalFooter } from '@cotiss/common/containers/callout/modal/modal-footer.component'
import { ModalHeader } from '@cotiss/common/containers/callout/modal/modal-header.component'
import { useToast } from '@cotiss/common/containers/toast/toast.provider'
import { sentryService } from '@cotiss/common/services/sentry.service'
import { PreferredSupplierContactModel } from '@cotiss/preferred-supplier/resources/use-mutate-preferred-supplier.resource'
import { Tooltip } from '@cotiss/common/components/tooltip.component'

type Props = {
  isTenderInvitation?: boolean
  contact?: PreferredSupplierContactModel
  onSubmit: (contact: PreferredSupplierContactModel) => void | Promise<unknown>
}

export const PreferredSupplierAddUserModal = memo(({ isTenderInvitation, contact, onSubmit }: Props) => {
  const { openToast } = useToast()
  const { closeModal } = useCallout()
  const [isSaving, setIsSaving] = useState(false)
  const [formData, setFormData] = useState<PreferredSupplierContactModel>({
    email: contact?.email ?? '',
    firstName: contact?.firstName ?? '',
    lastName: contact?.lastName ?? '',
    phoneNumber: contact?.phoneNumber ?? '',
    sendInvitationEmail: contact?.sendInvitationEmail ?? false,
  })

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      setIsSaving(true)
      await onSubmit(formData)
      closeModal()
    } catch (error: any) {
      sentryService.captureException({ exception: error })
      openToast(error.message, 'danger')
      setIsSaving(false)
    }
  }

  return (
    <Form className="min-w-[650px] max-w-[650px]" onSubmit={handleSubmit}>
      <ModalHeader heading="Add user" isDisabled={isSaving} />
      <ModalContent isScrollable>
        <Label>Email address</Label>
        <Input
          value={formData.email}
          onChange={({ target }) => setFormData({ ...formData, email: target.value })}
          isRequired
          placeholder="Enter email address"
          isDisabled={isSaving}
          type="email"
        />
        <Label className="mt-6">First name (optional)</Label>
        <Input
          value={formData.firstName}
          onChange={({ target }) => setFormData({ ...formData, firstName: target.value })}
          placeholder="Enter first name"
          isDisabled={isSaving}
        />
        <Label className="mt-6">Surname (optional)</Label>
        <Input
          value={formData.lastName}
          onChange={({ target }) => setFormData({ ...formData, lastName: target.value })}
          placeholder="Enter surname"
          isDisabled={isSaving}
        />
        <Label className="mt-6">Phone number (optional)</Label>
        <Input
          value={formData.phoneNumber}
          onChange={({ target }) => setFormData({ ...formData, phoneNumber: target.value })}
          placeholder="Enter phone number"
          isDisabled={isSaving}
        />
      </ModalContent>
      <ModalFooter className="flex items-center justify-between" isSaving={isSaving}>
        {!isTenderInvitation && (
          <Tooltip tooltip="Users will automatically receive a tender invitation" isEnabled={Boolean(isTenderInvitation)}>
            <label className="flex items-center cursor-pointer" htmlFor="send-email-invitation-checkbox">
              <Checkbox
                id="send-email-invitation-checkbox"
                className="mr-1"
                isChecked={isTenderInvitation ? true : formData.sendInvitationEmail}
                onChange={() => setFormData({ ...formData, sendInvitationEmail: !formData.sendInvitationEmail })}
                isDisabled={isTenderInvitation}
              />
              <Text>Send this user an email to join Cotiss</Text>
            </label>
          </Tooltip>
        )}
        {isTenderInvitation && <div />} {/* Placeholder for align buttons right if isTenderInvitation is true */}
        <div className="justify-self-end">
          <Button className="mr-2" onClick={() => closeModal()} state="ghost" variant="link" isDisabled={isSaving}>
            Cancel
          </Button>
          <Button type="submit" variant="secondary" isLoading={isSaving}>
            Confirm
          </Button>
        </div>
      </ModalFooter>
    </Form>
  )
})
