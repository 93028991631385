import { memo } from 'react'
import classNames from 'classnames'
import { map, slice } from 'lodash'
import { Avatar } from '@cotiss/common/components/avatar.component'
import { Button } from '@cotiss/common/components/button.component'
import { Text } from '@cotiss/common/components/text.component'
import { userService } from '@cotiss/user/user.service'

const MAX_AVATAR_COUNT = 3

type Props = {
  className?: string
  users: {
    firstName?: string
    lastName?: string

    // These are to support our old user model.
    firstname?: string
    surname?: string
  }[]
  onClick?: () => void
}

export const UserAvatarGroup = memo(({ className, users, onClick }: Props) => {
  const classes = classNames(className, 'inline-flex items-center rounded', {
    'hover:bg-secondary-100': onClick,
  })

  const renderContent = () => (
    <>
      {map(slice(users, 0, MAX_AVATAR_COUNT), (user, index) => (
        <Avatar
          key={index}
          className={index ? '-ml-4' : ''}
          variant={index ? 'gray' : 'primary'}
          style={{ zIndex: MAX_AVATAR_COUNT - index }}
          title={userService.getFullName(user)}
        >
          {userService.getInitials(user)}
        </Avatar>
      ))}
      {users.length > MAX_AVATAR_COUNT && (
        <Text className="mr-1" size="sm" variant="link">
          +{users.length - MAX_AVATAR_COUNT}
        </Text>
      )}
    </>
  )

  return onClick ? (
    <Button className={classes} onClick={onClick} state="raw">
      {renderContent()}
    </Button>
  ) : (
    <div className={classes}>{renderContent()}</div>
  )
})
