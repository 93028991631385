import { memo, useMemo } from 'react'
import { filter, find } from 'lodash'
import { NoDataPlaceholder } from '@cotiss/common/components/no-data-placeholder.component'
import { Skeleton } from '@cotiss/common/components/skeleton.component'
import { ContractSupportingDocumentsTable } from '@cotiss/contract/components/contract-supporting-documents-table.component'
import { useGetContractShell } from '@cotiss/contract/resources/use-get-contract-shell.resource'

type Props = {
  contractShellId: string
  contractId: string
}

export const ContractSummarySupportingDocumentsTab = memo(({ contractId, contractShellId }: Props) => {
  const { contractShell, isLoading: isContractShellLoading } = useGetContractShell(contractShellId)

  const supportingDocumentShells = useMemo(() => {
    const contract = find(contractShell?.contracts, (contract) => contract._id === contractId)
    return filter(contract?.documentShells, { type: 'SUPPORTING' })
  }, [contractId])

  if (isContractShellLoading) {
    return <Skeleton className="h-64" />
  }

  return (
    <div className="pt-8 max-w-5xl">
      {!supportingDocumentShells.length && <NoDataPlaceholder label="No supporting documents added" illustration="dot-list" />}
      {Boolean(supportingDocumentShells.length) && (
        <ContractSupportingDocumentsTable documentShells={supportingDocumentShells} contractShellId={contractShellId} />
      )}
    </div>
  )
})
