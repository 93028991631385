import { memo, useState } from 'react'
import { Input } from '@cotiss/common/components/input.component'
import { MetafieldNumberModel } from '@cotiss/metafield/metafield.model'
import { MetafieldValueModel } from '@cotiss/metafield-value/metafield-value.model'

type Props = {
  className?: string
  metafield: MetafieldNumberModel
  metafieldValue?: MetafieldValueModel
  onChange: (value: any) => void
}

export const MetafieldFormNumber = memo(({ className, metafield, metafieldValue, onChange }: Props) => {
  const [formData, setFormData] = useState(metafieldValue?.fieldValue?.value || '')

  const handleChange = (value: string) => {
    setFormData(value)
    onChange({ value })
  }

  return (
    <Input
      type="number"
      className={className}
      value={formData}
      onChange={({ target }) => handleChange(target.value)}
      min={metafield.validation?.min}
      max={metafield.validation?.max}
      step={metafield.validation?.step}
      placeholder={metafield.validation?.placeholder || metafield.fieldLabel}
      isRequired={metafield.isRequired}
    />
  )
})
